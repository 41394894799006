import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import Constants from "variables/Constants";
import renderHTML from "react-render-html";
const classes = makeStyles(styles);

export default class Face_To_Face_Batch extends Component {
  state = {
    visible: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    secondaryCategories: [],
  };
  componentDidMount() {
    this.getFaceToFaceBatch();
  }

  getFaceToFaceBatch = () => {
    axios.get(Constants.getUrls.faceToFaceBatch).then((resp) => {
      console.log(resp);
      this.setState({
        secondaryCategories: resp.data.data,
      });
    });
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios.get(Constants.getUrls.deleteFaceBatch + id).then(() => {
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        // window.location.reload();
        this.setState({ visible: false }, () => {
          this.getFaceToFaceBatch();
        });
      }, 2000);
    });
  };
  handleModal = (sec) => {
    console.log(sec);
    this.setState({
      rowId: sec.id,
      visible: true,
    });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={this.state.message}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody>
                <h3>Face To Face Batch</h3>
                <Link to="/admin/AddFacetoFaceBatch">
                  <Button className="" color="warning">
                    Add Batch
                  </Button>
                </Link>

                <Link to="/admin/studentsregistration">
                  <Button
                    className="btn-round"
                    style={{
                      backgroundColor: "#BA90C6",
                      color: "#fff",
                      marginLeft: "15px"
                    }}
                  >
                    Add Student
                  </Button>
                </Link>

              </CardBody>
              <Table
                tableHead={[
                  "S.no",
                  "Batch Id",
                  "Batch Name",
                  "Display Name",
                  "Start Date",
                  "End Date",
                  "Total Days",
                  "View Days",
                  // "Add Student",
                  "Students",
                  "Action",
                ]}
                tableData={this.state.secondaryCategories.map((sec, idx) => [
                  [idx + 1],
                  [sec?.id],
                  [sec?.batch_name],
                  [sec?.display_name],
                  [sec?.batch_start_date],
                  [sec?.batch_end_date],
                  [sec?.total_batch_days],
                  [
                    <Link
                      key={idx}
                      to={{
                        pathname: "/admin/facetofacebatchdays/" + sec.id,
                        state: { categoryData: sec },
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#5D9C59",
                        }}
                      >
                        View Days
                      </Button>
                    </Link>,
                  ],
                  // [
                  //   <Link
                  //     key={idx}
                  //     to={{
                  //       pathname: "/admin/studentsregistration/" + sec.id,
                  //       state: { categoryData: sec },
                  //     }}
                  //   >
                  //     <Button
                  //       className="btn-round"
                  //       style={{
                  //         backgroundColor: "#BA90C6",
                  //         color: "#fff",
                  //       }}
                  //     >
                  //       Add Student
                  //     </Button>
                  //   </Link>,
                  // ],
                  [
                    <Link
                      key={idx}
                      to={{
                        pathname: "/admin/faceToFaceBatchStudents/" + sec?.id,
                        state: { categoryData: sec },
                      }}
                    >
                      <Button className="btn-round" style={{
                        backgroundColor:"#FF6D60",
                        color: "#fff"
                      }}>
                        Batch Students
                      </Button>
                    </Link>,
                  ],
                  [
                    <Link
                      key={idx}
                      to={{
                        pathname: "/admin/updateFaceToFaceBatch/" + sec.id,
                        state: { categoryData: sec },
                      }}
                    >
                      <Button className="btn-round" color="info">
                        Update
                      </Button>
                    </Link>,
                    <Button
                      className="btn-round"
                      style={{
                        backgroundColor: "#E21818",
                      }}
                      onClick={() => {
                        this.handleModal(sec);
                      }}
                    >
                      Delete
                    </Button>,
                  ],
                ])}
              />
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Batch Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
      </div>
    );
  }
}
