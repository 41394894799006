import React, { Component } from "react";
import "../scss/Testimonials.scss";
import JoinNow from "./JoinNow";
import YouTube from "react-youtube";
import YouTubeTestimonials from "./YouTubeTestimonials";

export default class Testimonials extends Component {
  // _onReady(event) {
  //   // access to player in all event handlers via event.target
  //   event.target.pauseVideo();
  // }
  render() {
    const opts = {
      height: "248",
      width: "95%",
      playerVars: {
        autoplay: 0,
      },
    };
    return (
      <div>
        <div className="testimonials">
          <div>
            <h2 className="whatSay">
              What Students <br />
              <span>
                Say About
                <span style={{ color: "#1f1f95" }}> Aman Khedia Sir</span>
              </span>
              <br />
              <span className="whatFont" style={{ color: "#077c07" }}>
                Business Mathematics Statistics & LR
              </span>
            </h2>
          </div>

          <div>
            <div
              className="col-md-12 contain container"
              style={{ marginTop: "30px" }}
            >
              <div className="col-md-4 studentRating2">
                <div className="boxArea">
                  <div className="boxArea2">
                    <YouTubeTestimonials
                      embedId={"Z8MYxxnmZGE"}
                      height={"248"}
                    />
                  </div>
                </div>
                <div>
                  <div className="col-md-12 bottomTesti">
                    <div
                      className="col-md-6"
                      style={{
                        position: "relative",
                        top: "10px",
                        right: "20px",
                      }}
                    >
                      <h5 className="studentName">Charu Modi </h5>
                      <h5 className="batchName">Batch: Param Batch</h5>
                    </div>
                    <div className="col-md-6">
                      <button className="marks">Marks 88</button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-4 studentRating2"
                style={{ position: "relative", right: "50px" }}
              >
                <div className="boxArea">
                  <div className="boxArea2">
                    <YouTubeTestimonials
                      embedId={"1c49F4Q5wi4"}
                      height={"248"}
                    />
                  </div>
                </div>
                <div>
                  <div className="col-md-12 bottomTesti">
                    <div
                      className="col-md-6"
                      style={{
                        position: "relative",
                        top: "10px",
                        right: "20px",
                      }}
                    >
                      <h5 className="studentName">Piyush Singla</h5>
                      <h5 className="batchName">Batch: Param Batch</h5>
                    </div>
                    <div className="col-md-6">
                      <button className="marks">Marks 78</button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 studentRating3"
                style={{ position: "relative", right: "90px" }}
              >
                <div className="boxArea">
                  <div className="boxArea2">
                    <YouTubeTestimonials
                      embedId={"lrWmfIHpfls"}
                      height={"248"}
                    />
                  </div>
                </div>
                <div>
                  <div className="col-md-12 bottomTesti">
                    <div
                      className="col-md-6"
                      style={{
                        position: "relative",
                        top: "10px",
                        right: "20px",
                      }}
                    >
                      <h5 className="studentName">Kunal</h5>
                      <h5 className="batchName">Batch: OLD Batch</h5>
                    </div>
                    <div className="col-md-6">
                      <button className="marks">Marks 92</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
