const mainDomain = "https://core.akclasses.in/";
const domain = "https://admin.akclasses.in/";
// const mainDomain = "http://localhost:8000/";
const AppConstants = {
  getUrls: {
    downloadable: mainDomain + "api/files?type=public",
    subjects: mainDomain + "api/subjects?",
    ads: mainDomain + "api/advts?",
    queries: mainDomain + "api/queries?",
    levels: mainDomain + "api/levels?",
    questions: mainDomain + "api/qanda?",
    faculties: mainDomain + "api/faculty",
    counts: mainDomain + "api/counts",
    notification: mainDomain + "api/notification?",
    announcement: mainDomain + "api/announcements?",
    liveStream: mainDomain + "api/stream",
    getSingleQuery: mainDomain + "api/getSingleQuery/",

    FaceCat: domain + "api/faceToFaceCategory",
    FaceLevel: domain + "api/faceToFaceLevel",
    FaceStream: domain + "api/facetofaceStream",

    singleFaceCat: domain + "api/faceToFaceCategory/",
    singleFaceLevel: domain + "api/faceToFaceLevel/",
    singleFaceStream: domain + "api/facetofaceStream/",
  },
  postUrls: {
    storeAnnouncement: mainDomain + "api/storeAnnouncement",
    downloadQueryFile: mainDomain + "api/downloadQueryFile",
    login: mainDomain + "api/login",
    postSubject: mainDomain + "api/subjects",
    postAds: mainDomain + "api/advts",
    postDownloadables: mainDomain + "api/files",
    facultyCreate: mainDomain + "api/register",
    postLevels: mainDomain + "api/levels",
    liveStreamUpdate: mainDomain + "api/streamUpdate",
    createQuestion: mainDomain + "api/qanda",
    addFaceCategory: domain + "api/faceToFaceCategory",
    addFaceLevel: domain + "api/faceToFaceLevel",
    addFaceStream: domain + "api/facetofaceStream",
  },
  updateUrls: {
    updateSubject: mainDomain + "api/subjects/",
    updateAds: mainDomain + "api/advts/",
    updateDownloadables: mainDomain + "api/files/",
    updateQueries: mainDomain + "api/qanda/",
    updateAnswers: mainDomain + "api/qanda/",
    updateLevels: mainDomain + "api/levels/",
    updateQueryStatus: mainDomain + "api/queries/",
    updateChapter: mainDomain + "api/subjects/",
    updateFaceCat: domain + "api/faceToFaceCategory/",
    updateFaceLevel: domain + "api/faceToFaceLevel/",
    updateFaceStream: domain + "api/facetofaceStream/",
  },
  deleteUrls: {
    deleteAds: mainDomain + "api/advts/",
    deleteDownloadable: mainDomain + "api/files/",
    deleteFaceCat: domain + "api/faceCat/",
    deleteFaceLevel: domain + "api/faceToFaceLevel/",
    deleteFaceStream: domain + "api/facetofaceStream/",
  }
};
export default AppConstants;
