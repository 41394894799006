import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import editorConfiguration from "variables/EditorConfiguration";
import Datetime from "react-datetime";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";
const classes = makeStyles(styles);

const fd = new FormData();
class AddBatch extends Component {
  state = {
    type: "",
    batch_name: "",
    description: "",
    googlesheetdetails: "",
    batch_date: "",
    is_validity: "No",
    registration_start_date: "",
    registration_end_date: "",
    capacity: "",
    image: [],
    color: "",
    category: "",
    courses: [],
    facultyArr: [],
    showBoth: false,
    catData: [],
    amenities: [],
    amenity: "",
    redirect: false,
    aAlert: false,
    bAlert: false,
    notification: false,
    loading: false,
    allAttempts: [],
    attempt: "",
  };
  componentDidMount() {
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          courses: resp.data,
        });
      });
    axios.get("https://admin.akclasses.in/api/facultyR").then((resp) => {
      //console.log(resp.data);
      this.setState({
        facultyArr: resp.data,
      });
    });
    axios.get(Constants.getUrls.getAmenities + "?per_page=10").then((res) => {
      console.log(res);
      this.setState({
        amenities: res.data.data,
      });
    });
    axios.get("https://admin.akclasses.in/api/attempt").then((resp) => {
      console.log(resp);
      this.setState(
        {
          allAttempts: resp.data,
        },
        () => {
          console.log(this.state.allAttempts);
        }
      );
    });
  }
  findFaculty = (data) => {
    let obj = this.state.facultyArr.find((v) => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleDrop = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        showBoth: false,
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.type)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              catData: resp.data.product.variant,
            });
            let viewIds = [];
            let views = [];
            this.state.catData.forEach((v) => {
              if (viewIds.includes(v.view_and_validity.id) === false) {
                viewIds.push(v.view_and_validity.id);
                views.push(v.view_and_validity);
              }
            });
            //console.log(views);
            this.setState({ catData: views }, () => {
              this.setState({
                showBoth: this.state.catData.length >= 1 ? true : false,
              });
            });
          });
      }
    );
  };
  handleFile = (e) => {
    this.setState({
      image: e.target.files[0],
    });
    console.log(e.target.files[0]);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // let payLoad = {
    //   batch_name: this.state.batch_name,
    //   description: this.state.description,
    //   batch_date: this.state.batch_date,
    //   registration_start_date: this.state.registration_start_date,
    //   registration_end_date: this.state.registration_end_date,
    //   capacity: this.state.capacity,
    // };
    // console.log(payLoad);
    this.setState({
      loading: true,
    });

    fd.append("batch_name", this.state.batch_name);
    fd.append("description", this.state.description);
    fd.append("google_sheet", this.state.googlesheetdetails);
    fd.append("batch_date", this.state.batch_date);
    fd.append("is_validity", this.state.is_validity);
    fd.append("registration_start_date", this.state.registration_start_date);
    fd.append("registration_end_date", this.state.registration_end_date);
    fd.append("capacity", this.state.capacity);
    fd.append("image", this.state.image);
    fd.append("color", this.state.color);
    fd.append("attempt", this.state.attempt);
    axios.post(Constants.postUrls.addBatches, fd).then((resp) => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false,
      });
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/batches"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Batch Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Batch</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Batch"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ batch_name: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <label>Upload Image</label>
                        <br />
                        <input
                          id="image"
                          name="image"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                          required={true}
                          style={{ marginTop: "15px" }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <label>Select Color</label>
                        <br />
                        <input
                          id="color"
                          name="color"
                          onChange={(e) => {
                            this.setState({ color: e.target.value }, () => {
                              console.log(this.state.color);
                            });
                          }}
                          type="color"
                          required={true}
                          style={{
                            marginTop: "15px",
                            height: "50px",
                            width: "200px",
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Attempt
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.attempt}
                            inputProps={{
                              required: true,
                              name: "category",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  { attempt: e.target.value },
                                  () => {
                                    console.log(this.state.attempt);
                                  }
                                );
                              },
                            }}
                          >
                            {this.state.allAttempts.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6} style={{marginTop:"40px"}}>
                        <div
                          style={{
                            position: "relative",
                            bottom: "11px",
                            border: "none"
                          }}
                        >
                          <label htmlFor="Amenities">Amenities</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.amenities}
                            value={this.state.amenity}
                            onChange={data => {
                              this.setState({ amenity: data });
                            }}
                            labelledBy={"Amenities"}
                          />
                        </div>
                      </GridItem> */}

                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ marginTop: "40px" }}
                      >
                        <label>Batch Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  batch_date: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.batch_date);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Batch Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ marginTop: "40px" }}
                      >
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Show Validity
                          </InputLabel>
                          <Select
                            // key={}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.is_validity}
                            inputProps={{
                              name: "status",
                              id: "simple-select",
                              onChange: (e) =>
                                this.setState({ is_validity: e.target.value }),
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Yes"
                            >
                              Yes
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="No"
                            >
                              No
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>

                      {this.state.is_validity === "Yes" ? (
                        <>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            style={{ marginTop: "40px" }}
                          >
                            <label>Registration Start Date</label>
                            <FormControl fullWidth>
                              <Datetime
                                timeFormat={false}
                                onChange={(e) => {
                                  let year = e._d.getFullYear().toString();
                                  let month = (e._d.getMonth() + 1)
                                    .toString()
                                    .padStart(2, "0");
                                  let day = e._d
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0");
                                  this.setState(
                                    {
                                      registration_start_date:
                                        year + "/" + month + "/" + day,
                                    },
                                    () => {
                                      //console.log(this.state.registration_start_date);
                                    }
                                  );
                                }}
                                inputProps={{
                                  required: true,
                                  placeholder: "Registration Start Date",
                                }}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            style={{ marginTop: "40px" }}
                          >
                            <label>Registration End Date</label>
                            <FormControl fullWidth>
                              <Datetime
                                timeFormat={false}
                                onChange={(e) => {
                                  let year = e._d.getFullYear().toString();
                                  let month = (e._d.getMonth() + 1)
                                    .toString()
                                    .padStart(2, "0");
                                  let day = e._d
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0");
                                  this.setState(
                                    {
                                      registration_end_date:
                                        year + "/" + month + "/" + day,
                                    },
                                    () => {
                                      //console.log(this.state.registration_end_date);
                                    }
                                  );
                                }}
                                inputProps={{
                                  required: true,
                                  placeholder: "Registration End Date",
                                }}
                              />
                            </FormControl>
                          </GridItem>
                        </>
                      ) : null}

                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ marginTop: "40px" }}
                      >
                        <CustomInput
                          labelText="Capacity"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ capacity: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Description</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          data={this.state.description}
                          // config={editorConfiguration}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              description: data,
                              bAlert: false,
                            });
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Google Sheet Details"
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({
                                googlesheetdetails: e.target.value,
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <span
                        style={{
                          marginLeft: "15px",
                          color: "green",
                          fontSize: "13px",
                          fontWeight: "400",
                        }}
                      >
                        {
                          'Sample Link Details: {"sheet_id":"14Tnrl4tTxgVTD-QEKIKsOIgSeAIC0OE1V9-6J37xkUA","sheet_name":"ak"}'
                        }
                      </span>

                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Batch"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/batches",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddBatch;
