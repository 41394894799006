/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
let fd = new FormData();
class UpdateFAQ extends Component {
  state = {
    title: "",
    description: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    //console.log(categoryData);

    this.setState({
      title: categoryData.QUESTION,
      description: categoryData.ANSWER
    });
  }
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    fd.append("question", this.state.title);
    fd.append("answer", this.state.description);
    let categoryData = this.props.location.state.categoryData;
    let categoryId = categoryData.ID;
    axios
      .post(
        "https://admin.akclasses.in/api/faqQuestion/update/" + categoryId,
        fd
      )
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/faqs"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"FAQ Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>FAQ</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <label htmlFor="">Question</label>
                        <br />
                        <textarea
                          name="title"
                          onChange={this.handleInput}
                          className="form-control"
                          cols="119"
                          rows="6"
                          required={true}
                          value={this.state.title}
                        ></textarea>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label htmlFor="">Answer</label>
                        <br />
                        <textarea
                          name="description"
                          onChange={this.handleInput}
                          className="form-control"
                          cols="119"
                          rows="6"
                          required={true}
                          value={this.state.description}
                        ></textarea>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update FAQ"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/faqs"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateFAQ;
