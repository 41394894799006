/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
let ProgressBar = props => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = props => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
let fd = new FormData();
class ImportUsers extends Component {
  state = {
    file: [],
    progress: 0,
    notification: false,
    mediaProgress: false,
    loading: false
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    fd.append("file", this.state.file);
    axios
      .post("https://admin.akclasses.in/api/user_import", fd, {
        onUploadProgress: progressEvent => {
          this.setState({
            progress: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
            mediaProgress: true
          });
          if (this.state.progress === 100) {
            this.setState({ mediaProgress: false });
          }
        }
      })
      .then(() => {
        // console.log(resp);
        this.setState({
          notification: true,
          loading: false
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };
  handleFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Users Imported!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <EditIcon />
                </CardIcon>
                <h4 style={{ color: "black" }}>Import Users</h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <label>File</label>
                      <br />
                      <input
                        name="file"
                        onChange={this.handleFile}
                        required
                        type="file"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <br />
                      {this.state.mediaProgress ? (
                        <ProgressBar percentage={this.state.progress} />
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? <Loader /> : "Import Users"}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Link
                        to={{
                          pathname: "/admin/adduser"
                        }}
                      >
                        <Button className="btn-round" color="success">
                          Add Single User
                        </Button>
                      </Link>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default ImportUsers;
