/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import { makeStyles } from "@material-ui/core/styles";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
// const classes = makeStyles(styles);
// const perPages = ["10", "25", "50", "100"];
class Strategies extends Component {
  state = {
    notifyStatus: false,
    empty: true,
    secondaryCategories: [],
    status: "",
    cTitle: "",
    active: 1,
    cId: 0,
    day: 1,
    id: 0,
    courseId: "",
    // per_page: "10",
    currentPage: 1,
    topics: [],
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    foundTopic: [],
    sec: {}
  };
  componentDidMount() {
    let categoryData = this.props.location.state
    console.log(categoryData);
    this.setState(
      {
        sec: categoryData,
        secondaryCategories: this.props.location.state.data.strategydays,
        id: this.props.location.state.data.id,
        // course: this.props.location.state.courseId,
        // duration: this.props.location.state.duration,
        cId: this.props.location.state.data.course.ID,
        cTitle: this.props.location.state.data.course.TITLE,
        day: this.props.location.state.data.strategydays.length + 1,
        courseId: this.props.location.state.data.course.ID,
      },
      () => {
        axios.get("https://admin.akclasses.in/api/topic").then((resp) => {
          // console.log(resp.data);
          this.setState(
            {
              topics: resp.data,
            }
            // () => {
            //   // console.log(this.state.secondaryCategories)
            //   this.state.secondaryCategories.map((sec) => {
            //     if (sec.type === "Revision") {
            //       sec.multitopic.map((v) => {
            //         // console.log(this.state.topics);
            //         this.state.topics.map((val) => {
            //           // console.log(val.topic.id);
            //           // console.log(v);
            //           if (val.topic.id === v) {
            //             console.log("hii")
            //             this.state.foundTopic.push( val.topic.name);
            //             console.log(this.state.foundTopic);
            //           }
            //         });
            //       });
            //     }
            //   });
            // }
          );
        });
      }
    );
  }

  findFaculty = (data) => {
    let arr = [];
    this.state.topics.forEach((v) => {
      if (data.includes(v.id)) {
        arr.push(v);
      }
    });
    // console.log(arr);
    return arr;
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                {/* <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <h3>{"Strategies for " + this.state.cTitle}</h3>
                <Link
                  to={{
                    pathname: "/admin/addstrategy",
                    state: {
                      sec: this.state.sec,
                      data: this.state.secondaryCategories,
                      courseId: this.state.courseId,
                      duration: this.state.duration,
                      id: this.state.id,
                      title: this.state.cTitle,
                    },
                  }}
                >
                  <Button className="" color="warning">
                    {"Add Day " + this.state.day}
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: "/admin/strategies",
                  }}
                >
                  <Button className="btn-round" color="danger">
                    Cancel
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Type",
                    "Topic",
                    "Day Id",
                    "Lectures",
                    "Paper",
                    "Day",
                    "Target Time",
                    "Actions",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [sec.type],
                      [
                        sec.type === "Lectures"
                          ? sec?.topic?.name
                          : sec.type === "Revision"
                          ? this.state.topics.map((v, i) => {
                              return sec.multitopic === null
                                ? ""
                                : sec.multitopic.map((val) => {
                                    if (val === v?.topic?.id) {
                                      // return "Hello"
                                      return v?.topic?.name + ", ";
                                    }
                                    //  return ( val.topic.id === v ? "hello" : "NA")
                                  });
                            })
                          : sec.type === "TestPapers"
                          ? "NA"
                          : this.findFaculty(sec.multitopic).map((v, i) => (
                              <span key={i}>
                                {v.name +
                                  (i ===
                                  this.findFaculty(sec.multitopic).length - 1
                                    ? ""
                                    : ",")}
                              </span>
                            )),
                      ],
                      [sec?.id],
                      [
                        sec.type === "Lectures"
                          ? sec.lectures.map((v, i) => (
                              <span key={i}>
                                {v + (i === sec.lectures.length - 1 ? "" : ",")}
                              </span>
                            ))
                          : "NA",
                      ],
                      [sec.paper ? sec.paper.TITLE : "NA"],
                      [index + 1],
                      [<span key={index}>{sec.target_time + " Hrs"}</span>],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/updatestrategy/" + sec.id,
                            state: {
                              sec: this.state.sec,
                              categoryData: sec,
                              day: index + 1,
                              sId: this.state.id,
                              title: this.state.cTitle,
                              cId: this.state.cId,
                              course: this.state.courseId,
                            },
                          }}
                        >
                          <Button className="btn-round" color="info">
                            Update
                          </Button>
                        </Link>,
                      ],
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty ? null : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Strategies;
