import React, { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Modal, Box, Typography, TextField, Snackbar } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles(styles);

const Tracker = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [tabel, setTabel] = useState([]);

  const [openCreate, setOpenCreate] = useState(false);
  const [subject, setSubject] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [subjectId, setSubjectId] = useState([]);
  const [chapterId, setChapterId] = useState([]);
  const [subChapterId, setSubChapterId] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deleteSubChapterId, setDeleteSubChapterId] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [updateSubChapterId, setUpdateSubChapterId] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [updateChapterId, setUpdateChapterId] = useState("");
  const [trackerId, setTrackerId] = useState("");
  const [createSnackBar, setCreateSnackBar] = useState(false);

  useEffect(() => {
    // console.log(localStorage.getItem("accessToken"))

    fetchTracker();
    fetchSubjects();
    if (subjectId) {
      fetchChapter();
    }
    if (chapterId) {
      subchapters();
    }
  }, [subjectId, chapterId]);

  const subchapters = async () => {
    try {
      // For each selected chapter, fetch subchapters
      const subchapterRequests = chapterId.map((id) =>
        axios.get(`https://admin.akclasses.in/api/subchapter/?chapter_id=${id}`)
      );
      const subchapterResponses = await Promise.all(subchapterRequests);

      const allSubchapters = subchapterResponses.flatMap(
        (response) => response.data.data
      );

      console.log(allSubchapters);
      setTableData(allSubchapters);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTracker = async () => {
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/revisiontracker`
      );
      console.log("tracker", res.data);
      setTabel(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubjects = async () => {
    try {
      const res = await axios.get(`https://admin.akclasses.in/api/subject/`);
      setSubject(res.data.data);
      console.log(res.data.data, "subject");
    } catch (error) {
      console.error(error);
    }
  };

  const fetchChapter = async () => {
    try {
      const fetchChapterRequests = subjectId.map((id) =>
        axios.get(`https://admin.akclasses.in/api/chapter/?subject_id=${id}`)
      );
      const fetchChapterResponses = await Promise.all(fetchChapterRequests);
      const allChapters = fetchChapterResponses.flatMap(
        (response) => response.data.data
      );
      console.log(allChapters, "chapter");
      setChapter(allChapters);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const payLoad = {
      title: title,
      description: description,
      subjects: [{id:Number(subjectId),chapters:[{id:Number(chapterId),sub_chapters:subChapterId}]}],
     
    };

    try {
      await axios.post(
        `https://admin.akclasses.in/api/revisiontracker/${trackerId}`,
        payLoad
      );
      setSnackbarOpen(true);
      handleCloseUpdate();
      fetchTracker();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const confirmDelete = (id) => {
    setDeleteSubChapterId(id);
    setTrackerId(id);
    setOpenDeleteConfirm(true);
  };

  const handleDelete = async () => {
    try {
      const res = await axios.delete(
        `https://admin.akclasses.in/api/revisiontracker/${trackerId}`
      );
      setDeleteSnackbarOpen(true);
      console.log(res.data);
      setOpenDeleteConfirm(false);
      fetchTracker();
      subchapters();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenUpdateModal = (tracker) => {
    setTitle(tracker.title || "");
    setDescription(tracker.description || "");
    setSubjectId(tracker.subject?.id || "");
    setChapterId(tracker.chapter ? [tracker.chapter.id] : []);
    setSubChapterId(tracker.subchapter ? [tracker.subchapter.id] : []);

    setUpdateSubChapterId(tracker.id);
    setTrackerId(tracker.id);

    setOpenUpdate(true);
  };

  const handleClose = () => {
    setOpenCreate(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payLoad = {
      title: title,
      description: description,
      subjects: [{id:Number(subjectId),chapters:[{id:Number(chapterId),sub_chapters:subChapterId}]}],
   
    };
    try {
      const res = await axios.post(
        `https://admin.akclasses.in/api/revisiontracker`,
        payLoad
      );

      console.log(res.data);
      setCreateSnackBar(true);
      setOpenCreate(false);
      subchapters();
      setSubjectId("");
      setChapterId("");
    } catch (error) {
      console.log(error);
      console.log("subjectId:", subjectId);
      console.log("chapterId:", chapterId);
      console.log("subChapterId:", subChapterId);
    }
  };
  const handleCloseUpdate = () => {
    setSubjectId("");
    setTitle("");
    setDescription("");
    setUpdateChapterId([]);
    setUpdateSubChapterId([]);
    setOpenUpdate(false);
    setTitle("");
    setDescription("");
  };

  const handleStatusChange = async (id, status) => {
    try {
      const updatedStatus = status === "active" ? "active" : "inactive";
      await axios.post(
        `https://admin.akclasses.in/api/revisiontracker/${id}`,
        {
          status: updatedStatus,
        }
      );
      fetchTracker();
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={openCreate}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Tracker Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                multiple
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map((id) => subject.find((item) => item.id === id)?.title)
                    .join(", ")
                }
              >
                {subject.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <Checkbox checked={subjectId.indexOf(item.id) > -1} />
                    <label htmlFor={item.id}> {item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <label>chapter</label>
              <Select
                multiple
                value={chapterId}
                onChange={(e) => setChapterId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map((id) => chapter.find((item) => item.id === id)?.title)
                    .join(", ")
                }
              >
                {chapter.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <Checkbox checked={chapterId.indexOf(item.id) > -1} />
                    <label htmlFor={item.id}> {item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <label>Subchapter</label>
              <Select
                multiple
                value={subChapterId}
                onChange={(e) => setSubChapterId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) => tableData.find((item) => item.id === id)?.title
                    )
                    .join(", ")
                }
              >
                {tableData.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <Checkbox checked={subChapterId.indexOf(item.id) > -1} />
                    <label htmlFor={item.id}> {item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              required
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="rose"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal open={openDeleteConfirm} onClose={handleCloseDeleteConfirm}>
        <Box sx={modalStyle}>
          <Typography variant="h6">Confirm Deletion</Typography>
          <Typography>Are you sure you want to delete this chapter?</Typography>
          <Button color="success" onClick={handleDelete}>
            Confirm
          </Button>
          <Button color="rose" onClick={handleCloseDeleteConfirm}>
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Tracker
          </Typography>
          <form onSubmit={handleUpdate}>
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
              >
                {subject.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <label>Chapter</label>
              <Select
                multiple
                value={chapterId}
                onChange={(e) => setChapterId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map((id) => chapter.find((item) => item.id === id)?.title)
                    .join(", ")
                }
              >
                {chapter.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <Checkbox checked={chapterId.indexOf(item.id) > -1} />
                    <label htmlFor={item.id}> {item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <label>Subchapter</label>
              <Select
                multiple
                value={subChapterId}
                onChange={(e) => {
                  console.log(e.target.value);

                  setSubChapterId(e.target.value);
                }}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) => tableData.find((item) => item.id === id)?.title
                    )
                    .join(", ")
                }
              >
                {tableData.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <Checkbox checked={subChapterId.indexOf(item.id) > -1} />
                    <label htmlFor={item.id}> {item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="rose"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleCloseUpdate}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Updated Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />

      <Snackbar
        open={deleteSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setDeleteSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Deleted Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />
      <Snackbar
        open={createSnackBar}
        autoHideDuration={3000}
        onClose={() => setCreateSnackBar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Created successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger"></CardIcon>
            </CardHeader>
            <CardBody>
              <Button color="success" onClick={() => setOpenCreate(true)}>
                ADD tracker
              </Button>
              <Table
                tableHead={[
                  "ID",
                  "TITLE",
                  "DESCRIPTION",
                  "SUBJECT NAME",
                  "CHAPTER",
                  "SUB CHAPTER",
                  "STATUS",
                  "ACTION",
                ]}
                tableData={tabel.map((item, index) => [
                  index + 1,
                  item.trackers.length > 0 ? item.trackers[0].title : "N/A",
                  item.trackers.length > 0
                    ? item.trackers[0].description
                    : "N/A",
                  item.subjects.length > 0 ? item.subjects[0].title : "N/A",
                  item.chapters.length > 0 ? item.chapters[0].title : "N/A",
                  item.subchapters.length > 0
                    ? item.subchapters[0].title
                    : "N/A",

                  [
                    <Select
                      key={index}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={item.status || "inactive"}
                      inputProps={{
                        name: "status",
                      }}
                      onChange={(e) =>
                        handleStatusChange(item.tracking_id, e.target.value)
                      }
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="active"
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="inactive"
                      >
                        Inactive
                      </MenuItem>
                    </Select>,
                  ],

                  [
                    <Button
                      style={{ backgroundColor: "#f72585" }}
                      key={`update-${index}`}
                      onClick={() => handleOpenUpdateModal(item.tracking_id)}
                      className="btn-round"
                    >
                      Update
                    </Button>,
                    <Button
                      style={{ backgroundColor: "#3a86ff" }}
                      key={`delete-${index}`}
                      onClick={() => confirmDelete(item.tracking_id)}
                      className="btn-round"
                    >
                      Delete
                    </Button>,
                  ],
                ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Tracker;
