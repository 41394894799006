/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
// const serverKey = "AIzaSyAH2BRw0K9t8UULl8GYhfflQGrnYRtvsSo";
class LiveStreaming extends Component {
  state = {
    url: "",
    description: "",
    title: "",
    show: false,
    loading: false,
    redirect: false,
  };
  componentDidMount() {
    axios.get(Constants.getUrls.liveStream).then((resp) => {
      this.setState({
        url: resp.data.stream_link,
        title: resp.data.title,
        description: resp.data.description,
      });
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .post(Constants.postUrls.liveStreamUpdate, {
        title: this.state.title,
        description: this.state.description,
        stream_link: this.state.url,
      })
      .then(() => {
        // const payload = {
        //   notification: {
        //     title: "AK Classes",
        //     text: this.state.title,
        //     sound: "default",
        //     badge: "8",

        //     color: "red",

        //     click_action: "MY_INTENT"
        //   },
        //   data: {
        //     obj: {
        //       Variable1: "value1",
        //       Variable2: "value2"
        //     }
        //   },
        //   priority: "high",
        //   to: "/topics/allUsers"
        // };

        // let notificationId = firebase
        //   .database()
        //   .ref("adminNotifications")
        //   .push().key;
        // firebase
        //   .database()
        //   .ref("adminNotifications/" + notificationId)
        //   .update({ title: this.state.title });

        // axios({
        //   method: "post",
        //   url: "https://fcm.googleapis.com/fcm/send",
        //   data: payload,
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: "key=" + serverKey
        //   }
        // })
        //   .then(function() {
        //     // console.log(response);
        //   })
        //   .catch(function() {
        //     // console.log(response);
        //   });
        this.setState({
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        this.setState({
          show: true,
          loading: false,
        });
      });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/levels"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.show ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Home Screen Video Updated Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Update Home Screen Video</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.title,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Url"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.url,
                            onChange: (e) =>
                              this.setState({ url: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Description</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={
                            this.state.description ? this.state.description : ""
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              description: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update"}
                        </Button>
                        {/* <Link
                          to={{
                            pathname: "/admin/levels"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link> */}
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default LiveStreaming;
