import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Datetime from "react-datetime";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";

const classes = makeStyles(styles);
class AddQuatraEntry extends Component {
  state = {
    description: "",
    amount: "",
    date: "",
    from: "",
    to: "",
    modes: [],
    redirect: false,
    notification: false,
    loading: false,
  };
  componentDidMount() {
    axios
      .get("https://admin.akclasses.in/api/expenseCategoriesR")
      .then((resp) => {
        //   console.log(resp.data);
        this.setState({
          modes: resp.data,
        });
      });
  }
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let payLoad = {
      mode_trs_from_id: this.state.from,
      mode_trs_to_id: this.state.to,
      amount: parseFloat(this.state.amount),
      date: this.state.date,
      description: this.state.description,
    };
    //console.log(payLoad);
    axios.post("https://admin.akclasses.in/api/quatra", payLoad).then(() => {
      //console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false,
      });
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/quatraentries"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Quantra Entry Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Quantra Entry</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Transfer From
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.from}
                            inputProps={{
                              name: "from",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  from: e.target.value,
                                });
                              },
                            }}
                          >
                            {this.state.modes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Tansfer To
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.to}
                            inputProps={{
                              name: "to",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  to: e.target.value,
                                });
                              },
                            }}
                          >
                            {this.state.modes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Amount"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "number",
                            onChange: (e) =>
                              this.setState({ amount: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  date: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.strDate);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Description</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              description: data,
                            });
                          }}
                        />
                        <br />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Add Quantra Entry"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/quatraentries",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddQuatraEntry;
