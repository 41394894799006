import React, { Component } from "react";
import "../scss/CourseDetail.scss";
import subject_bg from "../images/subject_bg.jpg";
import ak_classes_book from "../images/ak_classes_book.png";
import pdf from "../images/pdf.png";
import axios from "axios";
import Constants from "./common/Constants";
import YoutubeEmbed from "./YoutubeEmbed";
import { Link } from "react-router-dom";
import init from "../components/helper/WindowToken";
import CourseDetailResp from "./Screens/CourseDetailResp";
import Header from "./common/Header";
import Footer from "./common/Footer";
// import renderHTML from "react-render-html";
import renderHTML from "react-render-html";
import loader from "../images/97739-loader.json";
import Lottie from "react-lottie";

export default class CourseDetails extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    answer: false,
    attemptType: window.location.pathname.split("/")[5],
    batch_id: window.location.pathname.split("/")[7],
    subject_id: window.location.pathname.split("/")[6],
    course_id: window.location.pathname.split("/")[8],
    batch_wings_id: window.location.pathname.split("/")[9],
    subjectDetails: "",
    courseDetails: "",
    variantDetails: [],
    showBolck: true,
    showDemo: true,
    variants: [],

    // for variant state
    oss: [],
    views: [],
    medias: [],
    osType: [],
    mediaType: [],
    viewType: [],
    variant: [],
    courseDetails: [],
    variant_id: "",
    offerPrice: "",
    base_offerPrice: "",
    offPrice: 0,
    isLoading: true,
    userDetails: [],
    about: "",
    data: {
      faculty_detail: {
        about_faculty: "",
      },
    },
    couponApplied: false,
  };
  componentDidMount() {
    console.log(this.state.attemptType);

    // this.userData();

    console.log(this.props);
    this.getProductDetails();
    this.getUserInfo();
  }

  // userData = () =>{
  //   let token = localStorage.getItem("token");
  //   // console.log(init());
  //   // if (init() === "success") {
  //     console.log(token);
  //     axios.get(Constants.getUrls.user).then((resUser) => {
  //       console.log("user deatail", resUser);
  //       this.setState(
  //         {
  //           userDetails: resUser.data,
  //           login: true,
  //         },
  //         () => {
  //           console.log(this.state.userDetails);
  //         }
  //       );
  //     });
  //   // }
  // }
  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        console.log(res.data);
        this.setState({ userDetails: res.data }, () => {
          console.log(this.state.userInfo);
        });
      });
    }
  };

  getProductDetails = () => {
    axios
      .get(
        Constants.getUrls.productDetails +
          "?batch_id=" +
          this.state.batch_id +
          "&subject_id=" +
          this.state.subject_id +
          "&course_id=" +
          this.state.course_id +
          "&batch_wings_id=" +
          this.state.batch_wings_id
      )
      .then((resp) => {
        console.log("coursedetails", resp);
        this.setState(
          {
            offPrice: resp.data.data.course.VALUE,
            subjectDetails: resp.data.data,
            courseDetails: resp.data.data.course,
            variantDetails: resp.data.data.course.variant,
            isLoading: false,
            product_id: resp.data.data.course.ID,
          },
          () => {
            this.setState({
              about: this.state.courseDetails?.faculties?.about_us,
            });
            console.log("subjectDetails", this.state.subjectDetails);
            console.log(this.state.courseDetails);
          }
        );
        this.getActiveVariants();
      })
      .catch((err) => {
        if (err) {
          console.log(err.respones);
        }
      });
  };
  handleCoupon = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };
  verifyCoupon = () => {
    if (!this.state.couponApplied) {
      this.setState({
        couponFail: false,
        couponSuccess: false,
      });
      const payload = {
        coupon: this.state.coupon_code,
        product_id: this.state.product_id,
      };
      console.log(payload);
      axios.post(Constants.postUrls.coupon, payload).then((res) => {
        console.log(res);
        if (res.data.status === "fail") {
          this.setState({
            couponFail: true,
            couponSuccess: false,
            coupon_msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({
              openCoupon: false,
              coupon_code: "",
              couponSuccess: false,
              couponFail: false,
            });
          }, 1500);
        } else {
          const couponAmount = parseInt(res.data.coupon_detail.DISCOUNT_AMOUNT);
          const discountedPrice = this.state.offPrice
            ? this.state.offPrice - couponAmount
            : couponAmount;
          const mainPrice = this.state.mainPrice - couponAmount;
          this.setState({
            couponFail: false,
            couponSuccess: true,
            coupon: couponAmount,
            coupon_msg: "Wohho! Coupon Applied! ₹ " + couponAmount + " off.",
            offPrice: discountedPrice,
            mainPrice: mainPrice,
            couponApplied: true,
          });
          setTimeout(() => {
            this.setState({
              openCoupon: false,
              coupon_code: "",
              couponSuccess: false,
              couponFail: false,
            });
          }, 1500);
        }
      });
    } else {
      this.setState({
        couponFail: true,
        couponSuccess: false,
        coupon_msg: "Coupon already applied",
      });
      setTimeout(() => {
        this.setState({ openCoupon: false });
      }, 1500);
    }
  };

  getActiveVariants = () => {
    axios
      .get(Constants.getUrls.getCoursesActiveVarient + this.state.course_id)
      .then((respVarient) => {
        console.log("variants", respVarient);
        this.setState({
          variants: respVarient.data.product.variant,
        });

        let VarArray = respVarient.data.product.variant.filter(
          (variant) =>
            variant.status === "active" || variant.status === "Active"
        );
        console.log(VarArray);
        if (respVarient.data.product.TYPE === "COURSES") {
          let oss = [];
          let views = [];
          let medias = [];
          if (VarArray ? VarArray?.length > 0 : "") {
            let osidOfZero = VarArray[0].os_type_id;
            VarArray.forEach((item) => {
              if (oss ? oss?.length > 0 : "") {
                const findOS = oss.find((os) => os.id === item.os_type_id);

                if (!findOS) {
                  oss.push(item.os_type);
                }
              } else {
                oss.push(item.os_type);
              }
              if (item.os_type_id === osidOfZero) {
                if (medias ? medias?.length > 0 : "") {
                  const findMedia = medias.find(
                    (media) => media.id === item.media_type_id
                  );
                  if (!findMedia) {
                    medias.push(item.media_type);
                  }
                } else {
                  medias.push(item.media_type);
                }
              }
              // console.log("medias", medias);
              if (medias ? medias?.length > 0 : "") {
                if (
                  item.os_type_id === osidOfZero &&
                  medias[0].id === item.media_type_id
                ) {
                  if (views ? views?.length > 0 : "") {
                    const findViews = views.find(
                      (view) => view.id === item.view_and_validity_id
                    );
                    if (!findViews) {
                      views.push(item.view_and_validity);
                    }
                  } else {
                    views.push(item.view_and_validity);
                  }
                }
              }
            });

            this.setState(
              {
                oss,
                views,
                medias,
                osType: osidOfZero,
                mediaType: respVarient.data.product.variant[0].media_type_id,
                viewType:
                  respVarient.data.product.variant[0].view_and_validity_id,
                variant: respVarient.data.product.variant,
                variant_id: respVarient.data.product.variant[0].id,
                price: respVarient.data.product.variant[0].price,
                // offerPrice: respVarient.data.product.variant[0].price,
                totalAmt:
                  respVarient.data.product.variant[0].price -
                  respVarient.data.product.VALUE,
                base_offerPriceVariant:
                  respVarient.data.product.variant[0].price,
              },
              () => {
                console.log("price", this.state.price);
                this.setState(
                  {
                    mainPrice: this.state.price - this.state.offPrice,
                  },
                  () => {
                    console.log("main", this.state.mainPrice);
                  }
                );
              }
            );
          }
        }
      });
  };

  handleOS = (e) => {
    // console.log(e);
    this.setState(
      {
        osType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        console.log(this.state.osType);

        // let oss = [];
        let views = [];
        let medias = [];
        if (this.state.variant ? this.state.variant?.length > 0 : "") {
          this.state.variant.forEach((item) => {
            console.log(item.os_type_id, this.state.osType);
            if (item.os_type_id === parseInt(this.state.osType)) {
              if (medias ? medias?.length > 0 : "") {
                const findMedia = medias.find(
                  (media) => media.id === item.media_type_id
                );
                if (!findMedia) {
                  medias.push(item.media_type);
                }
              } else {
                medias.push(item.media_type);
              }
            }
            // console.log(medias);
            if (medias ? medias?.length > 0 : "") {
              if (
                item.os_type_id === parseInt(this.state.osType) &&
                item.media_type_id === medias[0].id
              ) {
                if (views ? views?.length > 0 : "") {
                  const findViews = views.find(
                    (view) => view.id === item.view_and_validity_id
                  );
                  if (!findViews) {
                    views.push(item.view_and_validity);
                  }
                } else {
                  views.push(item.view_and_validity);
                }
              }
            }
          });
          console.log("views", views);
          console.log("medias", medias);
          const selectedArray = this.state.variant.filter(
            (item) => item.os_type_id === parseInt(this.state.osType)
          );
          this.setState(
            {
              // oss,
              views,
              medias,
              // osType: osidOfZero,
              mediaType: selectedArray[0].media_type_id,
              viewType: selectedArray[0].view_and_validity_id,
              variant_id: selectedArray[0].id,
              price: selectedArray[0].price,
              offerPrice: selectedArray[0].offer_price,
              base_offerPrice: selectedArray[0].offer_price,
            },
            () => {
              console.log(this.state.price, this.state.offPrice);
              this.setState(
                {
                  mainPrice: this.state.coupon
                    ? this.state.price - this.state.coupon
                    : this.state.price - this.state.offPrice,
                },
                () => {
                  console.log(this.state.mainPrice);
                }
              );
            }
          );
        }
      }
    );
  };

  handleMedia = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        mediaType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        let views = [];
        if (this.state.variant ? this.state.variant?.length > 0 : "") {
          this.state.variant.forEach((item) => {
            // console.log(item.os_type_id, this.state.osType);
            if (
              item.os_type_id === parseInt(this.state.osType) &&
              item.media_type_id === parseInt(this.state.mediaType)
            ) {
              if (views ? views?.length > 0 : "") {
                const findViews = views.find(
                  (view) => view.id === item.view_and_validity_id
                );
                if (!findViews) {
                  views.push(item.view_and_validity);
                }
              } else {
                views.push(item.view_and_validity);
              }
            }
          });
          console.log("views", views);
          const selectedArray = this.state.variant.filter(
            (item) =>
              item.os_type_id === parseInt(this.state.osType) &&
              item.media_type_id === parseInt(this.state.mediaType)
          );
          this.setState(
            {
              // oss,
              views,
              // medias,
              // osType: osidOfZero,
              // mediaType: selectedArray[0].media_type_id,
              viewType: selectedArray[0].view_and_validity_id,
              variant_id: selectedArray[0].id,
              price: selectedArray[0].price,
              offerPrice: selectedArray[0].offer_price,
              base_offerPrice: selectedArray[0].offer_price,
            },
            () => {
              console.log(this.state.price, this.state.offPrice);
              this.setState(
                {
                  mainPrice: this.state.price - this.state.offPrice,
                },
                () => {
                  console.log(this.state.mainPrice);
                }
              );
            }
          );
        }
      }
    );
  };

  handleViews = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        viewType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        const selectedObject = this.state.variant.find(
          (item) =>
            item.os_type_id === parseInt(this.state.osType) &&
            item.media_type_id === parseInt(this.state.mediaType) &&
            item.view_and_validity_id === parseInt(this.state.viewType)
        );
        console.log(selectedObject);
        this.setState(
          {
            variant_id: selectedObject.id,
            price: selectedObject.price,
            offerPrice: selectedObject.offer_price,
            base_offerPrice: selectedObject.offer_price,
          },
          () => {
            console.log(this.state.price, this.state.offPrice);
            this.setState(
              {
                mainPrice: this.state.price - this.state.offPrice,
              },
              () => {
                console.log(this.state.mainPrice);
              }
            );
          }
        );
      }
    );
  };

  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  download(id, filepath) {
    axios
      .get(
        "https://admin.akclasses.in/api/download_question_pdf/" +
          80 +
          "?file=" +
          filepath
      )
      .then((res) => {
        console.log(res);
      });
  }

  render() {
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <Header />
        <div className="Responsive">
          <CourseDetailResp />
        </div>
        {/*  */}

        <div className="Web">
          {this.state.isLoading ? (
            <div style={{ marginTop: 100 }}>
              <Lottie
                options={defaultLoader}
                height={80}
                width={80}
                isStopped={this.state.isStopped}
                isPaused={this.state.isPaused}
              />
            </div>
          ) : (
            <>
              <section className="section1" style={{ height: "85px" }}>
                <h1 className="fonts">Course Details</h1>

                <h5 className="fonts titleName">
                  {this.state.courseDetails?.TITLE}
                </h5>
              </section>
              <div className="container contain2">
                <div
                  className="row"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "60% 40%",
                  }}
                >
                  <div className="col-sm-8">
                    <section className="section2" style={{ marginTop: "25px" }}>
                      <div className="attemptCourseDetail">
                        <p>
                          <span className="showing">
                            Details of{" "}
                            <span
                              className="jun"
                              style={{
                                color: this.state.subjectDetails?.batch?.color,
                              }}
                            >
                              {this.state.courseDetails?.TITLE}
                            </span>{" "}
                          </span>
                        </p>
                      </div>
                      <div className="attemptCourseDetail2">
                        <p className="showingDetail">
                          You have opted:{" "}
                          <button
                            className="batchButtonDetail"
                            style={{
                              backgroundColor: this.state.subjectDetails?.batch
                                ?.color,
                            }}
                          >
                            {this.state.subjectDetails?.batch?.batch_name}
                          </button>{" "}
                          <span style={{ paddingLeft: "20px" }}>
                            for attempt:
                          </span>{" "}
                          <span
                            style={{
                              color: this.state.subjectDetails?.batch?.color,
                            }}
                          >
                            {this.state.subjectDetails?.batch?.attempt?.title}
                          </span>
                        </p>
                      </div>
                      <div className=" attemptCourseDetail3">
                        <img
                          src={
                            Constants.imgUrl +
                            "/" +
                            this.state.subjectDetails?.course?.THUMB_IMAGE_PATH
                          }
                          alt=""
                          width={"100%"}
                          height={"100%"}
                        />
                        {/* <div>
                      <h3
                        style={{
                          paddingLeft: "30px",
                          fontFamily: "'Raleway', sans-serif",
                          fontWeight: "bold",
                          marginBottom: "38px",
                        }}
                      >
                        What you will get
                      </h3>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-5">
                        <img
                          src={ak_classes_book}
                          alt=""
                          className="akBookImg"
                        />
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-6">
                        <ul style={{ listStyle: "none" }}>
                          <li className="flex">
                            <span>&#10004; &nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span>
                              <div>
                                AK Classes with it's unique and simple learning
                                methodologies, thousands of
                              </div>
                            </span>
                          </li>
                          <li className="flex">
                            <span>&#10004; &nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span>
                              <div>
                                AK Classes with it's unique and simple learning
                                methodologies, thousands of
                              </div>
                            </span>
                          </li>
                          <li className="flex">
                            <span>&#10004; &nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span>
                              <div>
                                AK Classes with it's unique and simple learning
                                methodologies, thousands of AK Classes with it's
                                unique and simple learning methodologies,
                                thousands of
                              </div>
                            </span>
                          </li>
                          <li className="flex">
                            <span>&#10004; &nbsp;</span>
                            <span>
                              <div>AK Classes with it's unique</div>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                      </div>
                      <div className=" attemptCourseDetail4">
                        <ul className="activate">
                          <li className="font">
                            Students can activate course instantly after
                            purchase by login to their dashboard/profile on
                            AKClasses Website.{" "}
                            <span className="activationProcess">
                              Click here to know about Activation Process.
                            </span>{" "}
                          </li>
                          <li className="font">
                            Books will be delivered within 5 to 6 days after
                            order completion.
                          </li>
                        </ul>
                      </div>
                      <div className="attemptCourseDetail5">
                        <h3 className="systemReq">System Requirements:</h3>
                        <div
                          className="col-md-12"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "50% 50%",
                          }}
                        >
                          <div className="col-md-7">
                            <ul className="activate2">
                              <li className="font2">
                                CPU With 2GB RAM, Windows 7/8/9/10{" "}
                              </li>
                              <li className="font2">
                                Android with version 6 and above
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <div
                              className="downloadArea"
                              // target="_blank"
                              // onClick={() => {
                              //   this.download(
                              //     this.state.course_id,
                              //     this.state.courseDetails.SYLLABUS_PDF
                              //   );
                              // }}
                            >
                              <a
                                href={
                                  "https://admin.akclasses.in/api/download_question_pdf/ " +
                                  this.state.course_id +
                                  "?file=" +
                                  this.state.courseDetails.SYLLABUS_PDF
                                }
                                style={{ textDecoration: "none" }}
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => {
                                  this.download(
                                    this.state.course_id,
                                    this.state.courseDetails.SYLLABUS_PDF
                                  );
                                }}
                              >
                                <div style={{ display: "inline-flex" }}>
                                  {/* <span> */}
                                  <img src={pdf} alt="" className="pdfImg" />
                                  {/* </span> */}
                                  <p className="lectureIndex">
                                    Download Lecture Index
                                  </p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="attemptCourseDetail6">
                        <span>
                          <span>
                            <button
                              className={
                                this.state.showBolck
                                  ? "classroomBenefits"
                                  : "aboutFac"
                              }
                              onClick={() => {
                                this.setState({ showBolck: true });
                              }}
                            >
                              Smart Classroom Benefits
                            </button>
                          </span>
                          <span>
                            <button
                              className={
                                this.state.showBolck
                                  ? "aboutFac"
                                  : "classroomBenefits"
                              }
                              onClick={() => {
                                this.setState({ showBolck: false });
                              }}
                            >
                              About The Faculty
                            </button>
                          </span>
                        </span>
                      </div>
                      {this.state.showBolck ? (
                        <div className="row attemptCourseDetail7">
                          <h4 className="systemReq2">
                            Your preparation is our responsibility is the motto
                            of Smart Classroom
                          </h4>
                          <div style={{ paddingTop: "20px", width: "90%" }}>
                            <ul style={{ listStyle: "none" }}>
                              <li className="flexSmart">
                                {/* <span>&#10004; &nbsp;</span> */}
                                <i
                                  class="fa-solid fa-check "
                                  style={{
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                  }}
                                ></i>
                                <span>
                                  {" "}
                                  <div>
                                    You will get daily targets for the subject
                                    preparation which will help you be on track
                                    till the exam.
                                  </div>{" "}
                                </span>
                              </li>
                              <li className="flexSmart">
                                {/* <span>&#10004; &nbsp;</span> */}
                                <i
                                  class="fa-solid fa-check "
                                  style={{
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                  }}
                                ></i>
                                <span>
                                  {" "}
                                  <div>
                                    You will get daily targets for the subject
                                    preparation which will help you be on track
                                    till the exam.
                                  </div>{" "}
                                </span>
                              </li>
                              <li className="flexSmart">
                                {/* <span>&#10004; &nbsp;&nbsp;&nbsp;&nbsp;</span> */}
                                <i
                                  class="fa-solid fa-check "
                                  style={{
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                  }}
                                ></i>
                                <span>
                                  {" "}
                                  <div>
                                    You will get daily targets for the subject
                                    preparation which will help you be on track
                                    till the exam. You will get daily targets
                                    for the subject preparation which will help
                                    you be on track till the exam.
                                  </div>{" "}
                                </span>
                              </li>
                              <li className="flexSmart">
                                <i
                                  class="fa-solid fa-check "
                                  style={{
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                  }}
                                ></i>
                                <span>
                                  {" "}
                                  <div>
                                    You will get daily targets for the subject
                                    preparation which will help you be on track
                                    till the exam.
                                  </div>{" "}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.about?.length > 0 ? (
                            <div className="row attemptCourseDetail7">
                              <div className="aboutFaculty">
                                <img
                                  src={
                                    Constants.imgUrl +
                                    this.state.courseDetails?.faculties?.image
                                  }
                                  width="150px"
                                  height="180px"
                                />
                                <span
                                  style={{
                                    top: "40%",
                                    display: "inline-flex",
                                    marginLeft: "40px",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  {this.state.courseDetails?.faculties?.name}
                                </span>
                              </div>
                              <div>
                                <p>{renderHTML(this.state.about)}</p>
                              </div>
                            </div>
                          ) : (
                            <p style={{ textAlign: "left", paddingTop: 10 }}>
                              Data not found
                            </p>
                          )}
                        </>
                      )}
                    </section>
                  </div>
                  <div className="section3">
                    <div className="col-sm-4 rightSide">
                      <div
                        style={{
                          marginLeft: "30px",
                        }}
                      >
                        <span>
                          <button
                            className={
                              this.state.showDemo
                                ? "blueBtnDetail"
                                : "blueBtnDetail1"
                            }
                            onClick={() => {
                              this.setState({ showDemo: true });
                            }}
                          >
                            About the Course
                          </button>
                          <button
                            className={
                              this.state.showDemo
                                ? "grayBtnDetail"
                                : "grayBtnDetail1"
                            }
                            onClick={() => {
                              this.setState({ showDemo: false });
                            }}
                          >
                            Watch Demo
                          </button>
                        </span>
                      </div>
                      <div className="variants">
                        <div className="vidArea">
                          {this.state.showDemo ? (
                            <YoutubeEmbed
                              embedId={this.state.courseDetails?.DEMO_LINK}
                            />
                          ) : (
                            <YoutubeEmbed
                              embedId={this.state.courseDetails?.DEMO_LINK1}
                            />
                          )}
                        </div>
                        <div
                          className="row"
                          style={{
                            marginTop: "50px",
                            paddingBottom: "7px",
                            paddingTop: "5px",
                            display: "grid",
                            gridTemplateColumns: "33% 33% 33%",
                          }}
                        >
                          <div className="col-sm-4 deletePrice">
                            <del style={{ color: "red" }}>
                              Rs.{this.state.price}
                            </del>
                          </div>
                          <div className="col-sm-4 offPrice">
                            Rs.
                            {this.state.offPrice} Off
                          </div>
                          <div className="col-sm-4 rupeeColor">
                            Rs.{this.state.mainPrice}
                          </div>
                        </div>
                        <div>
                          <h5 className="pricesNote">
                            *Prices Inclusive of all taxes
                          </h5>
                        </div>
                        <div className="oprSystem">
                          <h4>Operating Systems</h4>
                          <div>
                            {this.state.oss.map((os, index) => (
                              <span
                                className="varName"
                                style={{ marginRight: 0 }}
                              >
                                <input
                                  type="radio"
                                  value={os.id}
                                  checked={
                                    os.id === this.state.osType ? true : false
                                  }
                                  onClick={() => this.handleOS(os.id)}
                                />{" "}
                                {os.os_type}
                              </span>
                            ))}
                          </div>
                        </div>

                        <div className="oprSystem">
                          <h4>Media</h4>
                          <div>
                            {this.state.medias.map((media, index) => (
                              <span
                                className="varName"
                                style={{ marginRight: 0 }}
                              >
                                <input
                                  type="radio"
                                  value={media.id}
                                  checked={
                                    media.id === this.state.mediaType
                                      ? true
                                      : false
                                  }
                                  onClick={() => this.handleMedia(media.id)}
                                />{" "}
                                {media.media_type}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="oprSystem">
                          <h4>Views & Validity</h4>
                          <div>
                            {this.state.views.map((view, index) => (
                              <span
                                className="nineMonths varName"
                                style={{ marginRight: 0 }}
                              >
                                <input
                                  type="radio"
                                  value={view.id}
                                  checked={
                                    view.id === this.state.viewType
                                      ? true
                                      : false
                                  }
                                  onClick={() => this.handleViews(view.id)}
                                />{" "}
                                {view.view_and_validity}
                              </span>
                            ))}
                          </div>
                        </div>

                        {this.state.openCoupon && (
                          <div>
                            <div className={"couponWrapper"}>
                              <input
                                name="coupon_code"
                                onChange={this.handleCoupon}
                                value={this.state.coupon_code}
                                placeholder="Enter code here.."
                                style={{
                                  padding: "6px 10px",
                                  width: "70%",
                                }}
                              />
                              <button
                                onClick={this.verifyCoupon}
                                className="verifyBtn"
                              >
                                Verify
                              </button>
                            </div>
                            {this.state.couponSuccess ? (
                              <p className="couponSuccessMessage">
                                {this.state.coupon_msg}
                              </p>
                            ) : null}
                            {this.state.couponFail ? (
                              <p className="couponFailMessage">
                                {this.state.coupon_msg}
                              </p>
                            ) : null}
                          </div>
                        )}
                        <div
                          className="applyBtnArea"
                          onClick={() => {
                            this.setState({ openCoupon: true });
                          }}
                        >
                          <span className="applyCoupon">Apply Coupon</span>
                        </div>
                        <div className="buyBtnArea">
                          <Link
                            to={{
                              pathname: "/admin/akWebPortal/checkout/",
                              state: {
                                coupon: this.state.coupon_code
                                  ? this.state.coupon_code
                                  : "",
                                batchId: this.state.batch_id,
                                batchWingId: this.state.batch_wings_id,
                                base_total: this.state.totalAmt,
                                coupon_amount: this.state.coupon,
                                grand_total: this.state.coupon_code
                                  ? this.state.totalAmt - this.state.coupon
                                  : this.state.totalAmt,
                                sku: this.state.courseDetails?.SKU,

                                product_details: {
                                  TITLE: this.state.courseDetails?.TITLE,
                                  product_id: this.state.course_id,
                                  quantity: 1,
                                  type: "COURSE",
                                  variant_id: this.state.variant_id,
                                  os_type_id: this.state.osType,
                                  media_type_id: this.state.mediaType,
                                  attempt: this.state.attemptType,
                                  view_and_validity_id: this.state.mediaType,
                                },
                                product: this.state.data,
                                userDetails: this.state.userDetails,
                              },
                            }}
                            // to="/admin/akWebPortal/checkout/"
                            // state={{
                            //   coupon: this.state.coupon_code
                            //     ? this.state.coupon_code
                            //     : "",
                            //   batchId: this.state.batch_id,
                            //   batchWingId: this.state.batch_wings_id,
                            //   base_total: this.state.totalAmt,
                            //   coupon_amount: this.state.coupon,
                            //   grand_total: this.state.coupon_code
                            //     ? this.state.totalAmt - this.state.coupon
                            //     : this.state.totalAmt,
                            //   sku: this.state.courseDetails?.SKU,

                            //   product_details: {
                            //     TITLE: this.state.courseDetails?.TITLE,
                            //     product_id: this.state.course_id,
                            //     quantity: 1,
                            //     type: "COURSE",
                            //     variant_id: this.state.variant_id,
                            //     os_type_id: this.state.osType,
                            //     media_type_id: this.state.mediaType,
                            //     attempt: this.state.attemptType,
                            //     view_and_validity_id: this.state.mediaType,
                            //   },
                            //   product: this.state.data,
                            //   userDetails: this.state.userDetails,
                            // }}
                            onClick={() =>
                              this.setState(
                                {
                                  base_total: this.state.offerPrice,
                                },
                                () => {
                                  console.log(this.state.base_total);
                                }
                              )
                            }
                          >
                            <button className="buyCourseBtn">
                              Buy This Course
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
