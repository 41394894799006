import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
class UpdateSource extends Component {
  state = {
    name: "",
    description: "",
    redirect: false,
    notification: false,
    loading: false,
    source_id: "",
  };

  componentDidMount() {
    var sourceId = window.location.pathname.split('/')[3];
    console.log(sourceId);
    this.setState({
        source_id: sourceId
    })
    axios.get(Constants.getUrls.getSingleSource + sourceId).then(resp => {
        console.log(resp);
        this.setState({
            name: resp.data.name,
            description: resp.data.description,
        });
      });
  }
  

  handleSubmit = e => {
    e.preventDefault();
    let payLoad = {
      name: this.state.name,
      description: this.state.description
    };
    this.setState({
      loading: true
    });
    axios.put(Constants.postUrls.updateSource + this.state.source_id, payLoad).then(resp => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/source"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Source Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Source</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"                          
                          id="float"
                          inputProps={{
                              required: true,
                              value: this.state.name,
                              onChange: e =>
                                this.setState({ name: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Description"                          
                          id="float"
                          inputProps={{
                              required: true,
                              value: this.state.description,
                              onChange: e =>
                                this.setState({ description: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Source"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/source"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateSource;
