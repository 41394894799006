import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Constants from "variables/Constants";
// import editorConfiguration from "variables/EditorConfiguration";
const classes = makeStyles(styles);

export default class AddQuestionBankChapter extends Component {
  state = {
    redirect: false,
    notification: false,
    loading: false,
    level: "",
    subject: "",
    levels: [],
    subjects: [],
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    title: "",
    description: "",
  };
  componentDidMount() {
    axios.get(Constants.getUrls.getQuestionBankLevelData).then((resp) => {
      console.log(resp.data);
      this.setState({
        levels: resp.data,
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.level === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.subject === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.title === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.description === "") {
      this.setState({
        dAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      let payLoad = {
        level_id: this.state.level,
        subject_id: this.state.subject,
        title: this.state.title,
        description: this.state.description,
      };
      this.setState({
        loading: true,
      });
      axios
        .post(Constants.postUrls.createQuestionBankChapter, payLoad)
        .then((resp) => {
          // console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };

  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_with_levels + this.state.level)
          .then((resp) => {
            console.log(resp.data);
            this.setState({
              subjects: resp.data.subjects,
              subject: "",
              chapters: [],
              chapter: "",
            });
          });
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/questionbankchapter"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Chapter Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Question Bank Chapter</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Chapter"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({
                                title: e.target.value,
                                cAlert: false,
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleSubject,
                            }}
                          >
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.subject}
                            inputProps={{
                              name: "subject",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  subject: e.target.value,
                                  bAlert: false,
                                });
                                //   console.log(e.target.value);
                              },
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Description</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          data={this.state.description}
                          // config={editorConfiguration}
                          //   data={this.props.location.state.categoryData.QUESTION}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              description: data,
                              dAlert: false,
                            });
                          }}
                        />
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Chapter"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/questionbankchapter",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
