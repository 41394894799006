import React, { Component } from "react";
import Constants from "../variables/Constants";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { MultiSelect } from "react-multi-select-component";

const classes = makeStyles(styles);
class AddWhatStudentsSay extends Component {
  state = {
    videoLink: "",
    studentName: "",
    marks: "",
    ftof_batch_ids: [],
    allFaceBatches: [],
    selected_batch: [],
    batch_wings: [],
    addedWings: [],
    batch_wings_id: [],
    redirect: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    notification: false,
    loading: false,
  };
  componentDidMount() {
    // Get Face to Face Batch
    axios.get(Constants.getUrls.getBatches + "?per_page=100").then((resp) => {
      console.log(resp);
      resp.data.data.map((item) => {
        this.state.allFaceBatches.push({
          label: item?.batch_name + "(" + item.attempt.title + ")",
          value: item.id,
        });
      });
      this.setState(
        {
          allFaceBatches: this.state.allFaceBatches,
        },
        () => {
          console.log(this.state.allFaceBatches);
        }
      );
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.state.selected_batch.map((v) => {
      this.state.ftof_batch_ids.push(v.value);
    });
    this.setState(
      {
        ftof_batch_ids: this.state.ftof_batch_ids,
      },
      () => {
        console.log(this.state.ftof_batch_ids);
      }
    );
    let payLoad = {
      video_link: this.state.videoLink,
      student_name: this.state.studentName,
      batch_id: this.state.ftof_batch_ids,
      marks: this.state.marks,
    };
    if (this.state.videoLink === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.ftof_batch_ids.length === 0) {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.studentName === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.marks === "") {
      this.setState({ dAlert: true });
      return;
    } else {
      this.setState({
        loading: true,
      });
      axios
        .post("https://admin.akclasses.in/api/addWhatStudentSay", payLoad)
        .then((resp) => {
          console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/whatStudentsSay"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Subject Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>What Students Says</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Video Link"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ videoLink: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Student Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ studentName: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Batch
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.ftof_batch_ids}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  ftof_batch_ids: e.target.value,
                                  cAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.allFaceBatches.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.value}
                              >
                                {v.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Marks"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ marks: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={6}></GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Add What Student Says"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/whatStudentsSay",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddWhatStudentsSay;
