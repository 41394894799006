import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
class FooterAboutUs extends Component {
  state = {
    heading: "",
    defGoal: "",
    redirect: false,
    notification: false,
    loading: false,
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/footerAbout").then((resp) => {
      //console.log(resp.data);
      this.setState({
        // heading: resp.data,
        defGoal: resp.data.CONTENT,
      });
    });
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let payLoad = {
      // heading: this.state.heading,
      content: this.state.defGoal,
    };
    axios
      .post("https://admin.akclasses.in/api/footerAbout/update", payLoad)
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            notification: false,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Content Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Footer About Us</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      {/* <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Heading"
                          inputProps={{
                            value: this.state.heading,
                            onChange: e =>
                              this.setState({ heading: e.target.value }, () => {
                                //console.log(this.state.heading);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Content</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.state.defGoal}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              defGoal: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Update"}
                      </Button>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default FooterAboutUs;
