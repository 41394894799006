/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import { makeStyles } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
// import Datetime from "react-datetime";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/core components
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
// const classes = makeStyles(styles);
let fd = new FormData();

class UpdateBackupOrder extends Component {
  state = {
    tran: "",
    cusName: "",
    sKey: "",
    orderDate: "",
    contact: "",
    email: "",
    faculties: [],
    exMode: "",
    vendorCommission: 0,
    vendorSelected: false,
    facultyCommission: 0,
    add1: "",
    akShare: 100,
    warning: false,
    add2: "",
    city: "",
    orderId: "",
    state: "",
    country: "India",
    pincode: "",
    delStatus: "",
    payStatus: "",
    shipDate: "",
    exModes: [],
    products: [],
    variants: [],
    mediaTypes: [],
    osTypes: [],
    views: [],
    delivery: ["Order Placed", "Packaged", "Shipped", "Delivered", "Cancelled"],
    payment: ["Collected", "Not_Collected"],
    vendors: [],
    trackingNo: "",
    total: "",
    productId: "",
    mediaId: "",
    osId: "",
    viewId: "",
    reference: "",
    variantId: "",
    redirect: false,
    max: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    // console.log(categoryData);
    axios.get("https://admin.akclasses.in/api/faculty").then(resp => {
      // console.log(resp.data);
      this.setState({
        faculties: resp.data
      });
    });
    this.setState(
      {
        // exMode: categoryData.MODE_ID,
        // tran: categoryData.TRANSACTION_ID,
        cusName: categoryData.name,
        // orderDate: categoryData.order_date,
        contact: categoryData.mobile_no,
        email: categoryData.email,
        // sKey: categoryData.serial_key,
        // akShare: categoryData.AK_SHARE,
        add1: categoryData.address,
        // add2: categoryData.ADDRESS_2,
        city: categoryData.city,
        // orderId: categoryData.ORDER_ID,
        state: categoryData.state,
        // country: categoryData.COUNTRY,
        pincode: categoryData.pincode
        // delStatus: categoryData.DELIVERY_STATUS,
        // payStatus: categoryData.PAYMENT_STATUS,
        // shipDate: categoryData.SHIPPING_DATE,
        // trackingNo: categoryData.TRACKING_NO,
        // variantId: categoryData.variant ? categoryData.variant.id : "",
        // total: categoryData.GRAND_TOTAL ? categoryData.GRAND_TOTAL : "",
        // osId: categoryData.variant ? categoryData.variant.os_type_id : "",
        // mediaId: categoryData.variant ? categoryData.variant.media_type_id : "",
        // viewId: categoryData.variant
        // ? categoryData.variant.view_and_validity_id
        // : "",
        // productId: categoryData.course ? categoryData.course.ID : ""
        // reference: categoryData.vender_list ? categoryData.vender_list.id : ""
      }
      // () => {
      //   if (this.state.productId !== "") {
      //     axios
      //       .get(
      //         "https://admin.akclasses.in/api/course/" + this.state.productId
      //       )
      //       .then(resp => {
      //         // console.log(resp.data);
      //         // let facultyCommission = 0;
      //         // resp.data.product.faculty.forEach(v => {
      //         //   facultyCommission += parseInt(v.faulty_share);
      //         // });
      //         this.setState(
      //           {
      //             // facultyCommission,
      //             variants: resp.data.product.variant
      //           },
      //           () => {
      //             let osIds = [];
      //             let osTypes = [];
      //             this.state.variants.forEach(v => {
      //               if (osIds.includes(v.os_type.id) === false) {
      //                 osIds.push(v.os_type.id);
      //                 osTypes.push(v.os_type);
      //               }
      //             });
      //             //console.log(osTypes);
      //             this.setState({ osTypes });
      //             let mediaTypes = [];
      //             let mediaIds = [];
      //             this.state.variants.forEach(v => {
      //               if (mediaIds.includes(v.media_type.id) === false) {
      //                 if (v.os_type_id === this.state.osId) {
      //                   mediaIds.push(v.media_type.id);
      //                   mediaTypes.push(v.media_type);
      //                 }
      //               }
      //             });
      //             //console.log(mediaTypes);
      //             this.setState({ mediaTypes });
      //             let viewIds = [];
      //             let views = [];
      //             this.state.variants.forEach(v => {
      //               if (viewIds.includes(v.view_and_validity.id) === false) {
      //                 if (
      //                   v.os_type_id === this.state.osId &&
      //                   v.media_type_id === this.state.mediaId
      //                 ) {
      //                   viewIds.push(v.view_and_validity.id);
      //                   views.push(v.view_and_validity);
      //                 }
      //               }
      //             });
      //             //console.log(views);
      //             this.setState({ views });
      //           }
      //         );
      //       });
      //   }
      // }
    );
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
      .then(resp => {
        //console.log(resp.data);
        this.setState({
          products: resp.data
        });
      });
    // axios
    //   .get("https://admin.akclasses.in/api/expenseCategoriesR")
    //   .then(resp => {
    //     //console.log(resp.data);
    //     this.setState({
    //       exModes: resp.data
    //     });
    //   });
    // axios.get("https://admin.akclasses.in/api/venderR").then(resp => {
    //   //console.log(resp.data);
    //   this.setState({
    //     vendors: resp.data
    //   });
    // });
  }
  findFaculty = data => {
    let obj = this.state.faculties.find(v => v.id === data[0].faculty_id);
    return obj.name;
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.max === true) {
      this.setState({ max: true });
      // } else if (this.state.warning === true) {
      //   this.setState({ warning: true });
    } else {
      this.setState({
        loading: true
      });
      //   fd.append("tx_id", this.state.tran);
      fd.append("name", this.state.cusName);
      fd.append("contact_no", this.state.contact);
      fd.append("email", this.state.email);
      fd.append("address", this.state.add1);
      //   fd.append("address_2", "");
      fd.append("city", this.state.city);
      fd.append("state", this.state.state);
      //   fd.append("country", "");
      fd.append("pincode", this.state.pincode);
      //   fd.append("delivery_status", this.state.delStatus);
      //   fd.append("payment_status", this.state.payStatus);
      //   fd.append("shipping_date", "");
      // fd.append("subject_id", this.state.productId);
      // fd.append("serial_key", this.state.sKey);
      //   fd.append("vender", this.state.reference);
      //   fd.append("shipping", "");
      //   fd.append("grand_total", this.state.total);
      //   fd.append("ordID", this.state.orderId);
      //   fd.append("order_type", "COURSE");
      // fd.append("exam_given", "no");
      // fd.append("order_date", this.state.orderDate);
      fd.append("_method", "put");
      //   fd.append("media_type_id", this.state.mediaId);
      //   fd.append("os_type_id", this.state.osId);
      //   fd.append("ak_share", this.state.akShare);
      //   fd.append("mode", this.state.exMode);
      //   fd.append("variant_id", this.state.variantId);
      //   fd.append("view_and_validity_id", this.state.viewId);

      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.id;
      axios
        .post("https://admin.akclasses.in/api/backup/" + categoryId, fd)
        .then(() => {
          //   localStorage.setItem("category", "Course");
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        });
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleProducts = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.productId)
          .then(resp => {
            // console.log(resp.data);
            this.setState(
              {
                variants: resp.data.product.variant
              },
              () => {
                this.setState(
                  {
                    mediaId: this.state.variants[0]
                      ? this.state.variants[0].media_type_id
                      : "",
                    osId: this.state.variants[0]
                      ? this.state.variants[0].os_type_id
                      : "",
                    viewId: this.state.variants[0]
                      ? this.state.variants[0].view_and_validity_id
                      : "",
                    variantId: this.state.variants[0]
                      ? this.state.variants[0].id
                      : "",
                    total: this.state.variants[0]
                      ? this.state.variants[0].offer_price
                      : 0
                  },
                  () => {
                    let osIds = [];
                    let osTypes = [];
                    this.state.variants.forEach(v => {
                      if (osIds.includes(v.os_type.id) === false) {
                        osIds.push(v.os_type.id);
                        osTypes.push(v.os_type);
                      }
                    });
                    //console.log(osTypes);
                    this.setState({ osTypes });
                    let mediaTypes = [];
                    let mediaIds = [];
                    this.state.variants.forEach(v => {
                      if (mediaIds.includes(v.media_type.id) === false) {
                        if (v.os_type_id === this.state.osId) {
                          mediaIds.push(v.media_type.id);
                          mediaTypes.push(v.media_type);
                        }
                      }
                    });
                    //console.log(mediaTypes);
                    this.setState({ mediaTypes });
                    let viewIds = [];
                    let views = [];
                    this.state.variants.forEach(v => {
                      if (viewIds.includes(v.view_and_validity.id) === false) {
                        if (
                          v.os_type_id === this.state.osId &&
                          v.media_type_id === this.state.mediaId
                        ) {
                          viewIds.push(v.view_and_validity.id);
                          views.push(v.view_and_validity);
                        }
                      }
                    });
                    //console.log(views);
                    this.setState({ views });
                  }
                );
              }
            );
            let facultyCommission = 0;
            resp.data.product.faculty.forEach(v => {
              facultyCommission += v.faulty_share;
            });
            if (this.state.vendorSelected) {
              axios
                .get(
                  "https://admin.akclasses.in/api/get_vendor_product/" +
                    this.state.reference +
                    "?product_id=" +
                    this.state.productId
                )
                .then(resp => {
                  // console.log(resp.data);
                  if (resp.data.length > 0) {
                    let vendorCommission = resp.data.find(
                      v => v.product_id === parseInt(this.state.productId)
                    ).commission_percent;
                    this.setState(
                      {
                        vendorCommission,
                        warning: false
                      },
                      () => {
                        // console.log(vendorCommission);
                        let total =
                          facultyCommission +
                          parseInt(this.state.vendorCommission);
                        this.setState({
                          akShare: 100 - total
                        });
                      }
                    );
                  } else {
                    this.setState({
                      warning: true,
                      akShare: 100 - facultyCommission
                    });
                  }
                });
            } else {
              this.setState({
                akShare: 100 - facultyCommission,
                facultyCommission
              });
            }
          });
      }
    );
    // //console.log(e.target.value);
  };
  handleOsType = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        eAlert: false
      },
      () => {
        let mediaTypes = [];
        let mediaIds = [];
        this.state.variants.forEach(v => {
          if (mediaIds.includes(v.media_type.id) === false) {
            if (v.os_type_id === this.state.osId) {
              mediaIds.push(v.media_type.id);
              mediaTypes.push(v.media_type);
            }
          }
        });
        //console.log(mediaTypes);
        this.setState({ mediaTypes }, () => {
          this.setState(
            {
              mediaId: this.state.mediaTypes[0]
                ? this.state.mediaTypes[0].id
                : ""
            },
            () => {
              let viewIds = [];
              let views = [];
              this.state.variants.forEach(v => {
                if (viewIds.includes(v.view_and_validity.id) === false) {
                  if (
                    v.os_type_id === this.state.osId &&
                    v.media_type_id === this.state.mediaId
                  ) {
                    viewIds.push(v.view_and_validity.id);
                    views.push(v.view_and_validity);
                  }
                }
              });
              //console.log(views);
              this.setState({ views }, () => {
                this.setState(
                  {
                    viewId: this.state.views[0] ? this.state.views[0].id : ""
                  },
                  () => {
                    let requiredVariant = this.state.variants.find(
                      v =>
                        v.os_type_id === this.state.osId &&
                        v.media_type_id === this.state.mediaId &&
                        v.view_and_validity_id === this.state.viewId
                    );
                    // console.log(requiredVariant);
                    this.setState({
                      variantId: requiredVariant ? requiredVariant.id : "",
                      total: requiredVariant ? requiredVariant.offer_price : 0
                    });
                  }
                );
              });
            }
          );
        });
      }
    );
  };
  handleMediaType = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false
      },
      () => {
        let viewIds = [];
        let views = [];
        this.state.variants.forEach(v => {
          if (viewIds.includes(v.view_and_validity.id) === false) {
            if (
              v.os_type_id === this.state.osId &&
              v.media_type_id === this.state.mediaId
            ) {
              viewIds.push(v.view_and_validity.id);
              views.push(v.view_and_validity);
            }
          }
        });
        //console.log(views);
        this.setState({ views }, () => {
          this.setState(
            {
              viewId: this.state.views[0] ? this.state.views[0].id : ""
            },
            () => {
              let requiredVariant = this.state.variants.find(
                v =>
                  v.os_type_id === this.state.osId &&
                  v.media_type_id === this.state.mediaId &&
                  v.view_and_validity_id === this.state.viewId
              );
              // console.log(requiredVariant);
              this.setState({
                variantId: requiredVariant ? requiredVariant.id : "",
                total: requiredVariant ? requiredVariant.offer_price : 0
              });
            }
          );
        });
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/backuporders"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Order Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Backup Order</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={"Order ID: " + this.state.orderId}
                          id="disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true
                          }}
                        />
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Transaction Details"
                          id="float"
                          inputProps={{
                            value: this.state.tran,
                            onChange: e =>
                              this.setState({ tran: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <label>Order Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  orderDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.orderDate);
                                }
                              );
                            }}
                            inputProps={{
                              value: this.state.orderDate,
                              placeholder: "Order Date"
                            }}
                          />
                        </FormControl>
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Name"
                          id="float"
                          inputProps={{
                            value: this.state.cusName,
                            onChange: e =>
                              this.setState({ cusName: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Contact#"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.contact,
                            onChange: e =>
                              this.setState({ contact: e.target.value }, () => {
                                if (this.state.contact.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            value: this.state.email,
                            onChange: e =>
                              this.setState({ email: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Address"
                          id="float"
                          inputProps={{
                            value: this.state.add1,
                            onChange: e =>
                              this.setState({ add1: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/*  <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(optional)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ add2: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            value: this.state.city,
                            onChange: e =>
                              this.setState({ city: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="State"
                          id="float"
                          inputProps={{
                            value: this.state.state,
                            onChange: e =>
                              this.setState({ state: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Serial Key"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.sKey,
                            onChange: e =>
                              this.setState({ sKey: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Ak Share"
                          id="float"
                          inputProps={{
                            disabled: true,
                            type: "float",
                            value: this.state.akShare
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Postcode/ZIP"
                          id="float"
                          inputProps={{
                            value: this.state.pincode,
                            onChange: e =>
                              this.setState({ pincode: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Delivery Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.delStatus}
                            inputProps={{
                              name: "delStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  delStatus: e.target.value,
                                  aAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.delivery.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Payment Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.payStatus}
                            inputProps={{
                              name: "payStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  payStatus: e.target.value,
                                  bAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.payment.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  shipDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.shipDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "Shipping Date"
                            }}
                          />
                        </FormControl>
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Tracking No.(if any)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ trackingNo: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Products
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.productId}
                            inputProps={{
                              name: "productId",
                              id: "simple-select",
                              onChange: e => {
                                this.handleProducts(e);
                              }
                            }}
                          >
                            {this.state.products.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.course.ID}
                              >
                                {
                                  <span>
                                    {v.course.TITLE}(
                                    {v.course.faculty.length > 1
                                      ? "AK Team"
                                      : this.findFaculty(v.course.faculty)}
                                    )
                                  </span>
                                }
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            OS Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.osId}
                            inputProps={{
                              name: "osId",
                              id: "simple-select",
                              onChange: e => {
                                this.handleOsType(e);
                              }
                            }}
                          >
                            {this.state.osTypes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.os_type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.eAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Media Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.mediaId}
                            inputProps={{
                              name: "mediaId",
                              id: "simple-select",
                              onChange: e => {
                                this.handleMediaType(e);
                              }
                            }}
                          >
                            {this.state.mediaTypes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.media_type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Views & Validity
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.viewId}
                            inputProps={{
                              name: "viewId",
                              id: "simple-select",
                              onChange: e => {
                                this.setState(
                                  {
                                    viewId: e.target.value,
                                    fAlert: false
                                  },
                                  () => {
                                    let requiredVariant = this.state.variants.find(
                                      v =>
                                        v.os_type_id === this.state.osId &&
                                        v.media_type_id ===
                                          this.state.mediaId &&
                                        v.view_and_validity_id ===
                                          this.state.viewId
                                    );
                                    //console.log(requiredVariant);
                                    this.setState({
                                      variantId: requiredVariant
                                        ? requiredVariant.id
                                        : "",
                                      total: requiredVariant
                                        ? requiredVariant.offer_price
                                        : 0
                                    });
                                  }
                                );
                              }
                            }}
                          >
                            {this.state.views.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.view_and_validity}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Grand Total"
                          id="float"
                          inputProps={{
                            value: this.state.total,
                            // disabled: true,
                            onChange: e =>
                              this.setState({ total: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Reference By
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.reference}
                            inputProps={{
                              name: "reference",
                              id: "simple-select",
                              onChange: e => {
                                this.setState(
                                  {
                                    reference: e.target.value,
                                    vendorSelected: true,
                                    gAlert: false
                                  },
                                  () => {
                                    axios
                                      .get(
                                        "https://admin.akclasses.in/api/get_vendor_product/" +
                                          this.state.reference +
                                          "?product_id=" +
                                          this.state.productId
                                      )
                                      .then(resp => {
                                        // console.log(resp.data);
                                        if (resp.data.length > 0) {
                                          let vendorCommission = resp.data.find(
                                            v =>
                                              v.product_id ===
                                              parseInt(this.state.productId)
                                          ).commission_percent;
                                          this.setState(
                                            {
                                              vendorCommission,
                                              warning: false
                                            },
                                            () => {
                                              let total =
                                                this.state.facultyCommission +
                                                parseInt(
                                                  this.state.vendorCommission
                                                );
                                              this.setState({
                                                akShare: 100 - total
                                              });
                                            }
                                          );
                                        } else {
                                          this.setState({ warning: true });
                                        }
                                      });
                                  }
                                );
                              }
                            }}
                          >
                            {this.state.vendors.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.institute_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.gAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                        {this.state.warning ? (
                          <div>
                            <span style={{ color: "red" }}>
                              This Vendor is Not Available for Selected
                              Product!!!
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Income Mode
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.exMode}
                            inputProps={{
                              name: "exMode",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  exMode: e.target.value,
                                  hAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.exModes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.hAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem> */}
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? (
                          <Loader />
                        ) : (
                          "Update Backup Order"
                        )}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/backuporders"
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateBackupOrder;
