import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import editorConfiguration from "../variables/EditorConfiguration";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";

const classes = makeStyles(styles);

// const selectId = window.location.pathname.split("/")[3]
// console.log(selectId)
//
class UpdateBatchWings extends Component {
  state = {
    redirect: false,
    notification: false,
    loading: false,
    title: "",
    description: "",
    batchWingId: "",
    batch: [],
    batches: [],
    selectedBatch: [],
    updatedBatch: [],
    attempts: [],
    selectedAttempts: [],
  };
  componentDidMount() {
    this.getAttempts();
    let id = window.location.pathname.split("/")[3];
    this.setState({
      batchWingId: id,
    });
    axios
      .get("https://admin.akclasses.in/api/batchWings/" + id)
      .then((resp) => {
        console.log(resp);
        resp.data.batches.forEach((val) => {
          // Check if the ID already exists in selectedBatch array
          if (!this.state.selectedBatch.some(batch => batch.value === val.batch?.id)) {
            // If the ID doesn't exist, push it to selectedBatch array
            this.setState(prevState => ({
              selectedBatch: [
                ...prevState.selectedBatch,
                {
                  label: val.batch?.batch_name,
                  value: val.batch?.id,
                }
              ]
            }));
          }
        });
        resp.data.attemptDetails.map((val) => {
          this.state.selectedAttempts.push({
            label: val.title,
            value: val.id,
          });
        });
        this.setState({
          title: resp.data.batchwing.title,
          description: resp.data.batchwing.description,
          selectedBatch: this.state.selectedBatch,
          selectedAttempts: this.state.selectedAttempts,
          attempt_id: resp.data.batchwing?.attempt_id,
        },() => {
          this.getAttemptsBatches(this.state.selectedAttempts);
        });
      });
      
  }
  getAttempts = () => {
      axios
      .get("https://admin.akclasses.in/api/activeAttempts")
      .then((resp) => {
        console.log(resp);
        resp.data.map((val) => {
          this.state.attempts.push({
            label: val.title,
            value: val.id,
          });
        });
        this.setState({
          attempts: this.state.attempts,
        });
      });

  };
 

  getAttemptsBatches = (selectedAttempts) => {
    // Assuming selectedAttempts is an array of selected attempt IDs
    console.log(selectedAttempts);
  
    // Clear previous batches
    this.setState({ batches: [] });
  
    selectedAttempts.forEach((attemptId) => {
      axios
        .get(Constants.getUrls.getBatches + "?per_page=50")
        .then((resp) => {
          console.log(resp.data.data);
          resp.data.data.forEach((val) => {
            console.log(attemptId);
            console.log(val.attempt.id);
            if (attemptId.value === val.attempt.id) {
              this.setState((prevState) => ({
                batches: [
                  ...prevState.batches,
                  {
                    label: val.batch_name + " (" + val.attempt.title + ")",
                    value: val.id,
                  },
                ],
              }));
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching batches:", error);
        });
    });
  };


  handleSubmit = (e) => {
    e.preventDefault();
  
    // Extracting values from the selected attempts
    const selectedAttemptsValues = this.state.selectedAttempts.map((attempt) => attempt.value);
  
    // Extracting values from the selected batches
    const selectedBatchesValues = this.state.selectedBatch.map((batch) => batch.value);
    const selectedBatchesValuesFormatted = selectedBatchesValues.map(id => ({ 'value': id }));
    console.log(selectedBatchesValuesFormatted);
  
    let payLoad = {
      title: this.state.title,
      description: this.state.description,
      attempt_id: selectedAttemptsValues,
      batch_id: selectedBatchesValuesFormatted,
    };
  
    this.setState({
      loading: true,
    });
  
    axios
      .put(Constants.postUrls.updateBatchWing + this.state.batchWingId, payLoad)
      .then((resp) => {
        console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error updating batch wing:", error);
        this.setState({
          loading: false,
        });
      });
  };

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.state.selectedBatch.forEach((v) => {
  //     this.state.updatedBatch.push({ value: v.value });
  //   });
  //   let payLoad = {
  //     title: this.state.title,
  //     description: this.state.description,
  //     attempt_id: this.state.attempt_id,
  //     batch_id: this.state.updatedBatch,
  //   };
  //   this.setState({
  //     loading: true,
  //   });
  //   axios
  //     .put(Constants.postUrls.updateBatchWing + this.state.batchWingId, payLoad)
  //     .then((resp) => {
  //       console.log(resp);
  //       setTimeout(() => {
  //         this.setState({
  //           redirect: true,
  //         });
  //       }, 1000);
  //       this.setState({
  //         notification: true,
  //         loading: false,
  //       });
  //     });
  // };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/batchwings"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Batch Wing Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Batch Wing</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.title,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Attempt
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.attempt_id}
                            inputProps={{
                              required: true,
                              name: "attempt_id",
                              id: "simple-select",
                              onChange: this.getAttemptsBatches,
                            }}
                          >
                            {this.state.attempts.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem> */}

                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{
                            zIndex: "100",

                            width: "100%",
                          }}
                        >
                          <span>Select Attempt</span>
                          <MultiSelect
                            disableSearch
                            options={this.state.attempts}
                            value={this.state.attempt_id}
                            onChange={(data) => {
                              this.setState({ attempt_id: data }, () =>
                                console.log(this.state.attempt_id)
                              );
                            }}
                            labelledBy={"Attempts"}
                          />
                        </FormControl>
                      </GridItem> */}

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{
                            zIndex: "100",

                            width: "100%",
                          }}
                        >
                          <span>Select Attempt</span>
                          <MultiSelect
                            disableSearch
                            options={this.state.attempts}
                            value={this.state.selectedAttempts}
                            onChange={(data) => {
                              this.setState({ selectedAttempts: data }, () =>
                                this.getAttemptsBatches(data)
                              );
                            }}
                            labelledBy={"Attempts"}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{
                            zIndex: "100",

                            width: "100%",
                          }}
                        >
                          <span>Select Batch</span>
                          <MultiSelect
                            disableSearch
                            options={this.state.batches}
                            value={this.state.selectedBatch}
                            onChange={(data) => {
                              this.setState({ selectedBatch: data }, () =>
                                console.log(this.state.selectedBatch)
                              );
                            }}
                            labelledBy={"Batches"}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Description</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          value={this.state.description}
                          // config={editorConfiguration}
                          data={this.state.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              description: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Update Batch Wing"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/batchwings",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateBatchWings;
