/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { MultiSelect } from "react-multi-select-component";
import Constants from "variables/Constants";
import CustomInput from "components/CustomInput/CustomInput";

const courseId = window.location.pathname.split("/")[3];
console.log(courseId);
class AddCommission extends Component {
  state = {
    redirect: false,
    notification: false,
    loading: false,
    courier: "",
    pool: "",
    vsmart: "",
    gst: 18,
    faculty: 100 - 18,
    facultyAmt: "",
    course_Id: window.location.pathname.split("/")[3],
  };

  componentDidMount() {
    console.log(window.location.pathname.split("/")[3]);
    axios
      .get(
        Constants.getUrls.getCourseCommission +
          "?course_id=" +
          this.state.course_Id
      )
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            courier: 0,
            gst: resp.data.data.gst,
            pool: resp.data.data.pool,
            vsmart: resp.data.data.vsmart,
            faculty: resp.data.data.faculty,
          });
        }
      });
  }

  facultyCommAmt = () => {
    console.log(this.state.courier);
    console.log(this.state.pool);
    console.log(this.state.facultyAmt);
    this.setState(
      {
        facultyAmt: this.state.courier + this.state.pool,
      },
      () => {
        console.log(this.state.facultyAmt);
      }
    );
  };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();

    axios
      .get(
        Constants.getUrls.getCourseCommission +
          "?course_id=" +
          this.state.course_Id
      )
      .then((respGet) => {
        console.log(respGet);
        if (respGet.data.status === "success") {
          let payLoad = {
            course_id: this.state.course_Id,
            courier: this.state.courier,
            pool: this.state.pool,
            vsmart: this.state.vsmart,
            faculty: this.state.faculty,
            gst: parseFloat(this.state.gst),
          };
          console.log(this.state.productsIds);
          axios
            .put(
              Constants.postUrls.updateCourseCommission + this.state.course_Id,
              payLoad
            )
            .then((resp) => {
              console.log(resp);
              if (resp.data.status === "success") {
                this.setState({
                  notification: true,
                  loading: false,
                });
                setTimeout(() => {
                  this.setState({
                    redirect: true,
                  });
                }, 2000);
              }
            });
        } else {
          let payLoad = {
            course_id: this.state.course_Id,
            courier: this.state.courier,
            pool: this.state.pool,
            vsmart: this.state.vsmart,
            faculty: this.state.faculty,
            gst: parseFloat(this.state.gst),
          };
          console.log(this.state.productsIds);
          axios
            .post(Constants.postUrls.addCourseCommission, payLoad)
            .then((resp) => {
              console.log(resp);
              if (resp.data.status === "success") {
                this.setState({
                  notification: true,
                  loading: false,
                });
                setTimeout(() => {
                  this.setState({
                    redirect: true,
                  });
                }, 2000);
              }
            });
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/coursesmaster"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Commission Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Commission</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      {/* <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                      >
                          <CustomInput
                            labelText="Courier"
                            id="float"
                            inputProps={{
                              value:this.state.courier,
                              required: true,
                              onChange: e =>
                                this.setState({ 
                                    courier: e.target.value,
                                    facultyAmt: 100-e.target.value
                                })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                      </GridItem> */}

                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                      >
                        <CustomInput
                          labelText="GST"
                          id="float"
                          inputProps={{
                            readOnly: "true",
                            type: "number",
                            value: this.state.gst,
                            required: true,
                            onChange: (e) =>
                              this.setState({
                                gst: e.target.value,
                                faculty: 100 - e.target.value,
                                pool: "",
                                vsmart: "",
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                      >
                        <CustomInput
                          labelText="Pool"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.pool,
                            required: true,
                            onChange: (e) =>
                              this.setState(
                                {
                                  pool: e.target.value,
                                },
                                () => {
                                  this.setState({
                                    faculty:
                                      100 -
                                      (parseInt(this.state.gst) +
                                        parseInt(this.state.pool)),
                                  });
                                }
                              ),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                      >
                        <CustomInput
                          labelText="Vsmart"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.vsmart,
                            required: true,
                            onChange: (e) =>
                              this.setState(
                                {
                                  vsmart: e.target.value,
                                },
                                () => {
                                  this.setState({
                                    faculty:
                                      100 -
                                      (parseInt(this.state.gst) +
                                        parseInt(
                                          this.state.pool ? this.state.pool : 0
                                        ) +
                                        parseInt(this.state.vsmart)),
                                  });
                                }
                              ),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                      >
                        <CustomInput
                          labelText="Faculty"
                          id="float"
                          inputProps={{
                            readOnly: "true",
                            type: "number",
                            value: this.state.faculty,
                            required: true,
                            onChange: (e) =>
                              this.setState({ faculty: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Commission"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/coursesmaster",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddCommission;
