import React, { Component } from "react";
import Table from "components/Table/Table.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import Loader from "components/Loader";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import FormControl from "@material-ui/core/FormControl";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import MenuItem from "@material-ui/core/MenuItem";
import AddAlert from "@material-ui/icons/AddAlert";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Button from "components/CustomButtons/Button.js";
import Modal from "react-awesome-modal";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
const orders = ["Order Placed", "Shipped"];
const perPages = ["10", "25", "50", "100"];
class VendorOrdersOffline extends Component {
  state = {
    notifyStatus: false,
    secondaryCategories: [],
    status: "",
    pKey: "",
    frmDate: "",
    toDate: "",
    osType: "",
    mediaType: "",
    viewValidity: "",
    ordType: "Order Placed",
    trackingNo: "",
    city: "",
    state: "",
    contact: "",
    per_page: "10",
    active: 1,
    act: 0,
    logic: "",
    notification: false,
    delModal: false,
    repButton: false,
    logistics: [],
    visible: false,
    loading: false,
    delLoading: false,
    ord: true,
    empty: false,
    userSearch: false,
    proDetail: false,
    notifyDelete: false,
    logDetail: false,
    comment: false,
    print: false,
    printView: true,
    notifyNote: false,
    product: "",
    address: "",
    searchOrderId: "",
    pincode: "",
    notes: "",
    courierCom: "",
    tracLink: "",
    delStatus: "",
    price: "",
    rowId: "",
    category: "",
    student: "",
    payStatus: "",
    shippingDate: "",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    total: 0,
    from: 0,
    to: 0,
    lastPageView: false,
    firstPageView: false,
    view: false,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    btnStatus: "",
    btnPending: false,
    btnApprove: false,
  };

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
      logDetail: false,
      proDetail: false,
      delModal: false,
      print: false,
      comment: false,
    });
  }
  componentDidMount() {
    this.getVendorOfflineOrder();
    axios.get("https://admin.akclasses.in/api/logistic").then((resp) => {
      //console.log(resp.data.data);
      this.setState({
        logistics: resp.data.data,
      });
    });
  }

  getVendorOfflineOrder = () => {
    let category = localStorage.getItem("category");
    this.setState({ category }, () => {
      axios
        .get(
          "https://admin.akclasses.in/api/vendorOfflineOrders" +
            "?order_type=" +
            this.state.category +
            "&status=" +
            this.state.ordType +
            "&per_page=" +
            this.state.per_page +
            "&paymentStatus=" +
            this.state.btnStatus
        )
        .then((resp) => {
          console.log(resp);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              secondaryCategories: resp.data.data.data,
              total: resp.data.data.total,
              from: resp.data.data.from,
              to: resp.data.data.to,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray,
              });
            }
          );
        });
    });
  };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/vendorOfflineOrders" +
              "?order_type=" +
              this.state.category +
              "&status=" +
              this.state.ordType +
              "&per_page=" +
              this.state.per_page +
              "&paymentStatus=" +
              this.state.btnStatus
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                ord: true,
                secondaryCategories: resp.data.data.data,
                total: resp.data.data.total,
                from: resp.data.data.from,
                to: resp.data.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  handleCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/vendorOfflineOrders" +
              "?order_type=" +
              this.state.category +
              "&status=" +
              this.state.ordType +
              "&per_page=" +
              this.state.per_page +
              "&paymentStatus=" +
              this.state.btnStatus
          )
          .then((resp) => {
            // console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                fromInc: 1,
                active: 1,
                toInc: 5,
                secondaryCategories: resp.data.data.data,
                total: resp.data.data.total,
                from: resp.data.data.from,
                to: resp.data.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  currentPage = (v) => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/vendorOfflineOrders" +
        "?order_type=" +
        this.state.category +
        "&status=Order Placed" +
        "&per_page=" +
        this.state.per_page +
        "&paymentStatus=" +
        this.state.btnStatus +
        "&page="
      : this.state.userSearch
      ? "https://admin.akclasses.in/api/offlineOrder/show?name=" +
        this.state.searchOrderId +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/offline_order_datevise?order_type=" +
        this.state.category +
        "&frmdate=" +
        this.state.frmDate +
        "&todate=" +
        this.state.toDate +
        "&per_page=" +
        this.state.per_page +
        "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleSelect = (e, ordId, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .post(
            "https://admin.akclasses.in/api/offlineOrder/order_status/" + id,
            {
              order_status: this.state.delStatus,
            }
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState({
                notifyStatus: false,
              });
            }, 2000);
          });
      }
    );
  };

  viewShipping = (id) => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_vendor_offline_order_status/" + id
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          visible: true,
          logDetail: true,
          proDetail: false,
          addShip: true,
          print: false,
          delModal: false,
          // product: resp.data.course ? resp.data.course.TITLE : "",
          tracLink: resp.data.logistic_provider
            ? resp.data.logistic_provider.tracking_link
            : "",
          trackingNo: resp.data.TRACKING_NO ? resp.data.TRACKING_NO : "",
          courierCom: resp.data.logistic_provider
            ? resp.data.logistic_provider.name
            : "",

          comment: false,
        });
      });
  };

  searchReport = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/offline_order_datevise?order_type=" +
          this.state.category +
          "&frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        //console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            ord: false,
            repButton: true,
            fromInc: 1,
            active: 1,
            toInc: 5,
            userSearch: false,
            empty: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handleModal = (sec) => {
    console.log("hi");
    this.setState({
      delModal: true,
      visible: true,
      rowId: sec.ID,
    });
  };

  // handleModal = sec => {
  //   this.setState({
  //     product: sec.course ? sec.course.TITLE : "",
  //     orderId: sec.ORDER_ID,
  //     rowId: sec.ID,
  //     osType: sec.variant ? sec.variant.os_type.os_type : "",
  //     mediaType: sec.variant ? sec.variant.media_type.media_type : "",
  //     viewValidity: sec.variant
  //       ? sec.variant.view_and_validity.view_and_validity
  //       : "",
  //     delStatus: sec.DELIVERY_STATUS ? sec.DELIVERY_STATUS : "",
  //     address: sec.ADDRESS ? sec.ADDRESS : sec.user ? sec.user.ADDRESS_1 : "",
  //     // address: sec.ADDRESS ? sec.ADDRESS : "",
  //     city: sec.CITY ? sec.CITY : sec.user ? sec.user.CITY : "",
  //     pincode: sec.PINCODE ? sec.PINCODE : sec.user ? sec.user.PINCODE : "",
  //     pKey: sec.PRODUCT_KEY ? sec.PRODUCT_KEY : "",
  //     price: sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "",
  //     student: sec.NAME
  //       ? sec.NAME
  //       : sec.user
  //       ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
  //         " " +
  //         (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
  //       : "",
  //     trackingNo: sec.TRACKING_NO ? sec.TRACKING_NO : "",
  //     tracLink: sec.logistic_provider
  //       ? sec.logistic_provider.tracking_link
  //       : "",
  //     courierCom: sec.logistic_provider ? sec.logistic_provider.name : "",
  //     shippingDate: sec.SHIPPING_DATE ? sec.SHIPPING_DATE : "",
  //     orderDate: sec.ORDER_DATE,
  //     notes: sec.NOTES ? sec.NOTES : "",
  //     payStatus: sec.PAYMENT_STATUS ? sec.PAYMENT_STATUS : "",
  //     // pincode: sec.PINCODE ? sec.PINCODE : "",
  //     // city: sec.CITY ? sec.CITY : "",
  //     state: sec.STATE ? sec.STATE : "",
  //     contact: sec.CONTACT_NO
  //       ? sec.CONTACT_NO
  //       : sec.user
  //       ? sec.user.MOBILE
  //       : "",
  //     productId: sec.course ? sec.course.id : "",
  //     reference: sec.vender_list ? sec.vender_list.id : ""
  //   });
  //   this.openModal();
  // };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios
      .post("https://admin.akclasses.in/api/offlineOrder/delete/" + id)
      // .post("https://admin.akclasses.in/api/deleteVendorOfflineOrder/" + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    axios
      .post(
        "https://admin.akclasses.in/api/offlineOrder/notes/" + this.state.rowId,
        {
          note: this.state.notes,
        }
      )
      .then(() => {
        //console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({ notifyNote: false });
          window.location.reload();
        }, 2000);
      });
  };

  addShipping = () => {
    this.setState({ loading: true });
    let payLoad = {
      tracking_no: this.state.trackingNo,
      prod_key: this.state.pKey,
      logistic: this.state.logic,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/shipping_detail/" + this.state.orderId,
        payLoad
      )
      .then(() => {
        //console.log(resp);
        this.closeModal();
        this.setState({
          notification: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  handleOrders = (btn, index) => {
    axios
      .get(
        "https://admin.akclasses.in/api/vendorOfflineOrders?status=" +
          btn +
          "&order_type=" +
          this.state.category +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        // console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            empty: false,
            fromInc: 1,
            btnIdArray,
            active: 1,
            toInc: 5,
            ordType: btn,
            ord: true,
            act: index,
            userSearch: false,
            secondaryCategories: resp.data.data.data,
            total: resp.data.data.total,
            from: resp.data.data.from,
            to: resp.data.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.logic);
      }
    );
  };

  searchOrderId = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/offlineOrder/show?name=" +
          this.state.searchOrderId +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        // console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            userSearch: true,
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            ord: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
        // Object.size = obj => {
        //   let size = 0,
        //     key;
        //   for (key in obj) {
        //     if (obj.hasOwnProperty(key)) size++;
        //   }
        //   return size;
        // };

        // // Get the size of an object
        // let size = Object.size(resp.data);
        // if (size > 0) {
        //   let array1 = [];
        //   array1.push(resp.data);
        //   this.setState({
        //     searchOrderId: "",
        //     empty: true
        //     // secondaryCategories: array1
        //   });
        // } else {
        //   this.setState({
        //     searchOrderId: "",
        //     empty: true,
        //     secondaryCategories: []
        //   });
        // }
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Shipping Added Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.mailSent ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Email Sent Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <Button
                  color={this.state.btnPending ? "primary" : "secondary"}
                  onClick={() => {
                    this.setState({
                      btnStatus: "Pending",
                      btnPending: true,
                      btnApprove: false,
                    });
                    this.getVendorOfflineOrder();
                  }}
                >
                  Pending
                </Button>
                <Button
                  color={this.state.btnApprove ? "primary" : "secondary"}
                  onClick={() => {
                    this.setState({
                      btnStatus: "Approve",
                      btnApprove: true,
                      btnPending: false,
                    });
                    this.getVendorOfflineOrder();
                  }}
                >
                  Approve
                </Button>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.category}
                      inputProps={{
                        name: "category",
                        id: "simple-select",
                        onChange: e => {
                          this.handleCategory(e);
                        }
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Course"
                      >
                        Course
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Test_Series"
                      >
                        Test Series
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Books"
                      >
                        Books
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Extend_Validity"
                      >
                        Extension
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <br />
                <label
                  style={{
                    marginLeft: "12px",
                    color: "black",
                    fontSize: "17px"
                  }}
                >
                  Filter The Orders
                </label>
                <br />
                <br />
                <label
                  style={{ color: "black", position: "relative", left: "10px" }}
                >
                  By Attempt
                </label>
                <br />
                {orders.map((btn, index) => (
                  <Button
                    key={index}
                    id={btn}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    color={this.state.act === index ? "danger " : "info"}
                    onClick={() => {
                      this.handleOrders(btn, index);
                    }}
                  >
                    {btn}
                  </Button>
                ))}
                <br />
                <GridContainer style={{ position: "relative", left: "10px" }}>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <label
                      style={{
                        color: "black"
                      }}
                    >
                      By Date
                    </label>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={e => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              frmDate: year + "/" + month + "/" + day
                            },
                            () => {
                              //console.log(this.state.frmDate);
                            }
                          );
                        }}
                        inputProps={{
                          placeholder: "From Date"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={e => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              toDate: year + "/" + month + "/" + day
                            },
                            () => {
                              //console.log(this.state.toDate);
                            }
                          );
                        }}
                        inputProps={{
                          placeholder: "To Date"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <Button
                      style={{
                        position: "relative",
                        top: "13px",
                        right: "20px"
                      }}
                      onClick={this.searchReport}
                      color="success"
                    >
                      Search
                    </Button>
                  </GridItem>
                  {this.state.repButton ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <br />
                      <a
                        href={
                          "https://admin.akclasses.in/api/offline_order_excel_report?frmdate=" +
                          this.state.frmDate +
                          "&todate=" +
                          this.state.toDate
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "60px"
                          }}
                          onClick={() => {
                            this.setState({ repButton: false });
                          }}
                          color="danger"
                        >
                          Download
                        </Button>
                      </a>
                    </GridItem>
                  ) : null}

                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <label
                      style={{
                        color: "black",
                        marginTop: "-23px !important"
                      }}
                    >
                      By User Name
                    </label>

                    <CustomInput
                      style={
                        {
                          // marginLeft: "30px"
                        }
                      }
                      labelText="User Name"
                      id="float"
                      inputProps={{
                        required: true,
                        value: this.state.searchOrderId,
                        onChange: e =>
                          this.setState({ searchOrderId: e.target.value })
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <Button
                      style={{
                        position: "relative",
                        top: "13px",
                        right: "15px"
                        // marginBottom:"-26px"
                      }}
                      onClick={this.searchOrderId}
                      color="success"
                    >
                      Search
                    </Button>
                  </GridItem>
                </GridContainer> */}
                <h3>Offline Orders</h3>
                {/* <Link to={"/admin/addvendorofflineorder"}>
                  <Button className="" color="warning">
                    Add Offline Order
                  </Button>
                </Link> */}
                <Table
                  style={{
                    fontWeight: "bold",
                  }}
                  tableHead={[
                    "Sr No.",
                    "Order No",
                    <div key={1} style={{ width: "70px" }}>
                      Order Date
                    </div>,
                    <div key={1} style={{ width: "150px" }}>
                      Product Details
                    </div>,
                    "Student Details",
                    "Shipping Details",
                    "Order Status",
                    "Grand Total",
                    "Update",
                    "Delete",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [sec.ORDER_ID],
                      [
                        sec.ORDER_DATE.slice(8, 10) +
                          "-" +
                          sec.ORDER_DATE.slice(5, 7) +
                          "-" +
                          sec.ORDER_DATE.slice(0, 4),
                      ],
                      [sec.course ? sec.course.TITLE : "NA"],
                      [
                        <div key={index}>
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {sec.NAME
                              ? sec.NAME
                              : sec.user
                              ? (sec.user.FIRST_NAME
                                  ? sec.user.FIRST_NAME
                                  : "") +
                                " " +
                                (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
                              : "NA"}
                            ,
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "15px",
                              // fontWeight:'bold',
                              color: "blue",
                            }}
                          >
                            {sec.EMAIL
                              ? sec.EMAIL
                              : sec.user
                              ? sec.user.email
                              : "NA"}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {sec.CONTACT_NO
                              ? sec.CONTACT_NO
                              : sec.user
                              ? sec.user.MOBILE
                              : "NA"}
                            ,
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {sec.ADDRESS
                              ? sec.ADDRESS
                              : sec.user
                              ? sec.user.ADDRESS_1
                              : "NA"}
                          </span>
                          ,
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {sec.CITY
                              ? sec.CITY
                              : sec.user
                              ? sec.user.CITY
                              : "NA"}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {sec.PINCODE
                              ? sec.PINCODE
                              : sec.user
                              ? sec.user.PINCODE
                              : "NA"}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            PW :{" "}
                            {sec.user
                              ? sec.user.DE_PASSWORD
                                ? sec.user.DE_PASSWORD
                                : "NA"
                              : "NA"}
                          </span>
                        </div>,
                      ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.viewShipping(sec.ORDER_ID);
                          }}
                          color="success"
                        >
                          Shipped
                        </Button>,
                      ],
                      [sec.PAYMENT_STATUS],
                      [sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"],
                      [
                        <Link
                          key={index}
                          style={{ color: "black" }}
                          to={{
                            pathname:
                              "/admin/updatevendorordersoffline/" + sec.ID,
                            // "/admin/update" +
                            // this.state.category +
                            // "offlineorder/" +
                            // sec.ID
                            state: { categoryData: sec },
                          }}
                        >
                          <Button color="info">Update</Button>
                          {/* <i className="material-icons">&#xe3c9;</i> */}
                        </Link>,
                      ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.handleModal(sec);
                          }}
                          className="btn-round"
                          color="danger"
                        >
                          {this.state.act === parseInt(sec.ID) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>,
                      ],
                      // [
                      //   <Select
                      //     key={index}
                      //     MenuProps={{
                      //       className: classes.selectMenu
                      //     }}
                      //     classes={{
                      //       select: classes.select
                      //     }}
                      //     value={sec.DELIVERY_STATUS}
                      //     inputProps={{
                      //       name: "status",
                      //       id: "simple-select",
                      //       onChange: e => {
                      //         this.handleSelect(e, sec.ORDER_ID, sec.ID);
                      //       }
                      //     }}
                      //   >
                      //     {/* <MenuItem
                      //       classes={{
                      //         root: classes.selectMenuItem,
                      //         selected: classes.selectMenuItemSelected
                      //       }}
                      //       value=""
                      //     >
                      //       Select Status
                      //     </MenuItem> */}
                      //     {orders.map((v, i) => (
                      //       <MenuItem
                      //         key={i}
                      //         classes={{
                      //           root: classes.selectMenuItem,
                      //           selected: classes.selectMenuItemSelected
                      //         }}
                      //         value={v}
                      //       >
                      //         {v}
                      //       </MenuItem>
                      //     ))}
                      //   </Select>
                      // ],
                      //   [
                      //     sec.DELIVERY_STATUS === "Order Placed" ? (
                      //       <Button
                      //         key={index}
                      //         color="danger"
                      //         onClick={() => {
                      //           this.handleModal(sec);
                      //         }}
                      //       >
                      //         <i
                      //           style={{
                      //             color: "white",
                      //             fontSize: "18px",
                      //             margin: "0px"
                      //           }}
                      //           className="material-icons"
                      //         >
                      //           &#xe558;
                      //         </i>
                      //       </Button>
                      //     ) : (
                      //       <Button
                      //         style={{ cursor: "pointer" }}
                      //         onClick={() => {
                      //           this.handleModal(sec);
                      //           this.setState({
                      //             visible: true,
                      //             proDetail: false,
                      //             logDetail: true,
                      //             print: false,
                      //             delModal: false,
                      //             comment: false
                      //           });
                      //         }}
                      //         key={index}
                      //         color="success"
                      //       >
                      //         {sec.DELIVERY_STATUS}
                      //       </Button>
                      //     )
                      //   ],
                      //   [
                      //     <span key={1}>
                      //       <span
                      //         style={{ cursor: "pointer" }}
                      //         onClick={() => {
                      //           this.handleModal(sec);
                      //           this.setState({
                      //             visible: true,
                      //             proDetail: true,
                      //             print: false,
                      //             logDetail: false,
                      //             delModal: false,
                      //             comment: false
                      //           });
                      //         }}
                      //       >
                      //         <i className="material-icons">&#xe417;</i>
                      //       </span>
                      //       <span
                      //         style={{ cursor: "pointer" }}
                      //         onClick={() => {
                      //           this.handleModal(sec);
                      //           this.setState({
                      //             visible: true,
                      //             comment: true,
                      //             print: false,
                      //             logDetail: false,
                      //             delModal: false,
                      //             proDetail: false
                      //           });
                      //         }}
                      //       >
                      //         <i className="material-icons">&#xe0b9;</i>
                      //       </span>
                      //       <span
                      //         style={{ cursor: "pointer" }}
                      //         onClick={() => {
                      //           this.handleModal(sec);
                      //           this.setState({
                      //             visible: true,
                      //             comment: false,
                      //             logDetail: false,
                      //             delModal: false,
                      //             print: true,
                      //             proDetail: false
                      //           });
                      //         }}
                      //       >
                      //         <i className="material-icons">&#xe8ad;</i>
                      //       </span>
                      //       <Link
                      //         key={index}
                      //         style={{ color: "black" }}
                      //         to={{
                      //           pathname:
                      //             "/admin/update" +
                      //             this.state.category +
                      //             "offlineorder/" +
                      //             sec.ID,
                      //           state: { categoryData: sec }
                      //         }}
                      //       >
                      //         <i className="material-icons">&#xe3c9;</i>
                      //       </Link>
                      //       <span
                      //         style={{ cursor: "pointer", color: "red" }}
                      //         onClick={() => {
                      //           this.handleModal(sec);
                      //           this.setState({
                      //             visible: true,
                      //             comment: false,
                      //             logDetail: false,
                      //             print: false,
                      //             delModal: true,
                      //             proDetail: false
                      //           });
                      //         }}
                      //       >
                      //         <i className="material-icons">&#xe5c9;</i>
                      //       </span>
                      //     </span>
                      //   ]
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visible}
          width="1000"
          height="330"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                <CardBody>
                  {this.state.proDetail ? (
                    <div>
                      <h3>Product Details</h3>
                      <Table
                        tableHead={[
                          "Product Name",
                          "Price",
                          "Product Key",
                          "OS Type",
                          "Media Type",
                          "Views & Validity",
                          // "Tracking No.",
                          // "Shipping Date",
                          "Payment Status",
                          "Delivery Status",
                        ]}
                        tableData={[
                          [
                            this.state.product,
                            this.state.price,
                            this.state.pKey,
                            this.state.osType,
                            this.state.mediaType,
                            this.state.viewValidity,
                            // this.state.trackingNo,
                            // this.state.shippingDate,
                            this.state.payStatus,
                            this.state.delStatus,
                          ],
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.print ? (
                    <div>
                      <div>
                        <img
                          src="https://akclasses.in/static/media/akLogo.93e45c6ce5fc7e0fbea5.png"
                          alt=""
                          width="200px"
                          style={{
                            paddingBottom: "15px",
                            textAlign: "center",
                            marginLeft: "350px",
                            marginRight: "350px",
                          }}
                        />
                      </div>
                      <div>
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          To,
                        </h6>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.student}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.address}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.city},{this.state.state},
                          {this.state.pincode}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.contact}
                        </span>
                      </div>
                      <br />
                      <div>
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          From,
                        </h6>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          The Park Meadows, Flat No. 101,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Plot No. 48, B.U. Kothari Layout Kachimet,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Amaravati Road, Nagpur. (MH) 440033
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Office: +91 876 657 5808
                        </span>
                      </div>
                      {this.state.printView ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ printView: false });
                                setTimeout(() => {
                                  window.print();
                                }, 1);
                                setTimeout(() => {
                                  this.setState({ printView: true });
                                }, 2);
                              }}
                            >
                              Print
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      ) : null}
                    </div>
                  ) : this.state.comment ? (
                    <div>
                      <h3>Add Note</h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Note"
                            id="float"
                            inputProps={{
                              required: true,
                              value: this.state.notes,
                              onChange: (e) =>
                                this.setState({ notes: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Note Added Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={this.handleSubmit}
                          >
                            {this.state.loading ? <Loader /> : "Add"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.delModal ? (
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Order Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.logDetail ? (
                    <div>
                      <h3>Shipping Details</h3>
                      <Table
                        tableHead={[
                          "Courier Company",
                          "Tracking No.",
                          "Track Now",
                          // "Product"
                        ]}
                        tableData={[
                          [
                            this.state.courierCom,
                            this.state.trackingNo,
                            // this.state.product,
                            <a
                              key={1}
                              href={this.state.tracLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button color="danger">
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            </a>,
                          ],
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <form>
                      <h4 style={{ textAlign: "center" }}>
                        Order ID:{this.state.orderId}
                      </h4>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Product Name :" + this.state.product}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                          <CustomInput
                            labelText={"Student Name :" + this.state.student}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Address :" + this.state.address}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Producy Key :" + this.state.pKey}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Logistics</label>
                            <select
                              style={{ padding: "11px" }}
                              name="logic"
                              // value={this.state.logic}
                              onChange={this.handleInput}
                            >
                              <option value="">Select</option>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Order Status</label>
                            <select
                              style={{ padding: "11px" }}
                              name="delStatus"
                              value={this.state.delStatus}
                              onChange={(e) => {
                                this.handleSelect(
                                  e,
                                  this.state.orderId,
                                  this.state.rowId
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {orders.map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Tracking ID"
                            id="float"
                            inputProps={{
                              disabled: false,
                              onChange: (e) =>
                                this.setState({ trackingNo: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              disabled: false,
                              onChange: (e) =>
                                this.setState({ pKey: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.addShipping}
                          >
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                                className="material-icons"
                              >
                                &#xe558;
                              </i>
                            )}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            <i
                              style={{
                                color: "white",
                                fontSize: "18px",
                                margin: "0px",
                              }}
                              className="material-icons"
                            >
                              &#xe5c9;
                            </i>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty === false ? (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default VendorOrdersOffline;
