import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Constants from "../variables/Constants";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Modal from "react-awesome-modal";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import Loader from "components/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import { IconButton } from "@material-ui/core";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
class CoursesMaster extends Component {
  state = {
    notifyStatus: false,
    notifyStockStatus: false,
    notifyNote: false,
    ord: true,
    levels: [],
    streams: [],
    subjects: [],
    categories: [],
    sequenceArray: [],
    category: "",
    sequence: "",
    faculty: false,
    warn: false,
    add: false,
    level: "",
    stream: "",
    subject: "",
    secondaryCategories: [],
    facultyArr: [],
    status: "",
    active: 1,
    productId: 0,
    price: "",
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    visible: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    project_course_status: "",
    notifyProject: false,
    deleteId: "",
    visibleReplica: false,
    replicaId: "",
    notification: false,
    message: "",
    attempts: [],
    attempt_id: "",
    language: "",
    apply_filter: false,
    ftofbatch: "",
    courseid: "",
  };
  componentDidMount() {
    this.getCourses();
    axios
      .get("https://admin.akclasses.in/api/activeAttempts")
      .then((respattempt) => {
        console.log(respattempt);
        this.setState({
          attempts: respattempt.data,
        });
      });
    // axios.get("https://admin.akclasses.in/api/courseCategoriesR").then(resp => {
    axios.get(Constants.getUrls.courseCategoriesR).then((resp) => {
      //console.log(resp.data);
      this.setState({
        categories: resp.data,
      });
    });
  }
  getCourses = () => {
    axios
      .get(
        Constants.getUrls.getCourses +
          "?type=COURSES&per_page=" +
          this.state.per_page +
          "&attempt_id=" +
          this.state.attempt_id +
          "&lang=" +
          this.state.language +
          "&category_id=" +
          this.state.category +
          "&level_id=" +
          this.state.level +
          "&stream_id=" +
          this.state.stream +
          "&subject_id=" +
          this.state.subject
      )
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        let sequenceArray = [];
        resp.data.data.forEach((v) => {
          sequenceArray.push(parseInt(v.SEQUENCE));
        });
        // console.log(sequenceArray);
        this.setState(
          {
            btnIdArray,
            sequenceArray,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handleFtofbatch = (cid, fid) => {
    console.log(cid, fid);
    this.setState(
      {
        courseid: cid,
        ftofbatch: fid,
      },
      () => {
        let payload = {
          courseid: this.state.courseid,
          ftofbatch: this.state.ftofbatch,
        };
        axios.post(Constants.postUrls.add_ftofbatch, payload).then((resp) => {
          this.getCourses();
          console.log(resp);
        });
      }
    );
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/courses?type=COURSES&per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            let sequenceArray = [];
            resp.data.data.forEach((v) => {
              sequenceArray.push(parseInt(v.SEQUENCE));
            });
            this.setState(
              {
                btnIdArray,
                sequenceArray,
                ord: true,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  handleFacultyModal = (id) => {
    this.setState({ productId: id, faculty: true }, () => {
      axios
        .get(
          "https://admin.akclasses.in/api/facultydetail?product_id=" +
            this.state.productId
        )
        .then((resp) => {
          this.setState({ facultyArr: resp.data, visible: true });
        });
    });
  };
  handleSequenceModal = (id, value, seq) => {
    if (value === "Add") {
      this.setState({ add: true, sequence: "" });
    } else {
      this.setState({ add: false, sequence: seq });
    }
    this.setState({ productId: id, faculty: false, visible: true });
  };
  addSequence = () => {
    if (
      this.state.sequenceArray.includes(parseInt(this.state.sequence)) === true
    ) {
      this.setState({ warn: true });
      setTimeout(() => {
        this.setState({ warn: false });
      }, 2000);
    } else {
      this.setState({ seqLoading: true, warn: false });
      let seqArray = [];
      seqArray.push({
        id: this.state.productId,
        sequence: parseInt(this.state.sequence),
      });
      // console.log(seqArray);
      axios
        .post("https://admin.akclasses.in/api/add_sequence", {
          products_sequence: seqArray,
        })
        .then(() => {
          // console.log(resp.data);
          this.setState({ notifyNote: true, seqLoading: false });
          setTimeout(() => {
            this.setState({ notifyNote: false });
            this.getCourses();
            // window.location.reload();
          }, 2000);
        });
    }
  };
  updateSequence = () => {
    if (
      this.state.sequenceArray.includes(parseInt(this.state.sequence)) === true
    ) {
      this.setState({ warn: true });
      setTimeout(() => {
        this.setState({ warn: false });
      }, 2000);
    } else {
      this.setState({ seqLoading: true, warn: false });
      let seqArray = [];
      seqArray.push({
        id: this.state.productId,
        sequence: parseInt(this.state.sequence),
      });
      axios
        .post("https://admin.akclasses.in/api/add_sequence", {
          products_sequence: seqArray,
        })
        .then(() => {
          // console.log(resp.data);
          this.setState({ notifyNote: true, seqLoading: false });
          setTimeout(() => {
            this.setState({ notifyNote: false });
            // window.location.reload();
            this.getCourses();
          }, 2000);
        });
    }
  };
  currentPage = (v) => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/courses?type=COURSES&per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/get_by_cate_levl_strm?per_page=" +
        this.state.per_page +
        "&category_id=" +
        this.state.category +
        "&level_id=" +
        this.state.level +
        "&stream_id=" +
        this.state.stream +
        "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      let obj_to_array = Object.entries(resp.data?.data).map((obj) => ({
        key_name: obj[1],
      }));
      // console.log(obj_to_array);
      this.setState({ secondaryCategories: [] });
      obj_to_array.map((sec, idx) => {
        this.state.secondaryCategories.push(sec.key_name);

        if (idx === obj_to_array.length - 1) {
          this.setState(
            {
              secondaryCategories: this.state.secondaryCategories,
            },
            () => {
              console.log(this.state.secondaryCategories);
            }
          );
        }
      });
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        // secondaryCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post("https://admin.akclasses.in/api/course/status/" + id, payload)
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              // window.location.reload();
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5,
                },
                () => {
                  this.getCourses();
                }
              );
            }, 1000);
          });
      }
    );
  };
  handleProjectVisibility = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        console.log(this.state.project_course_status);
        const payload = {
          project_course_status: this.state.project_course_status,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/updateProjectStatus/" + id,
            payload
          )
          .then((res) => {
            console.log(res);
            this.setState({
              notifyProject: true,
            });
            setTimeout(() => {
              // window.location.reload();
              this.setState(
                {
                  notifyProject: false,
                  // fromInc: 1,
                  // active: 1,
                  // toInc: 5
                },
                () => {
                  this.getCourses();
                }
              );
            }, 1000);
          });
      }
    );
  };
  handleStock = (e, id) => {
    const payload = {
      stock_status: e.target.value,
    };
    axios
      .post("https://admin.akclasses.in/api/updateStockStatus/" + id, payload)
      .then(() => {
        //console.log(res);
        this.setState({
          notifyStockStatus: true,
        });
        setTimeout(() => {
          // window.location.reload();
          this.setState(
            {
              notifyStockStatus: false,
              fromInc: 1,
              active: 1,
              toInc: 5,
            },
            () => {
              this.getCourses();
            }
          );
        }, 1000);
      });
  };
  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        streams: [],
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
              level: "",
            });
          });
      }
    );
  };

  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
              stream: "",
            });
          });
      }
    );
  };

  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get(
            "https://admin.akclasses.in/api/getStreamsSubject?stream_id=" +
              this.state.stream
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              subjects: resp.data.data,
              subject: "",
            });
          });
      }
    );
  };

  searchRecord_old = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_by_cate_levl_strm?per_page=" +
          this.state.per_page +
          "&attempt_id=" +
          this.state.attempt_id +
          "&lang=" +
          this.state.language +
          "&category_id=" +
          this.state.category +
          "&level_id=" +
          this.state.level +
          "&stream_id=" +
          this.state.stream +
          "&subject_id=" +
          this.state.subject
      )
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        let sequenceArray = [];
        resp.data.data.forEach((v) => {
          sequenceArray.push(parseInt(v.SEQUENCE));
        });
        console.log(resp.data.data);
        this.setState(
          {
            ord: false,
            btnIdArray,
            sequenceArray,
            secondaryCategories: resp.data.data,
            fromInc: 1,
            active: 1,
            toInc: 5,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            console.log(this.state.secondaryCategories);
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  searchRecord = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_by_cate_levl_strm?per_page=" +
          this.state.per_page +
          "&attempt_id=" +
          this.state.attempt_id +
          "&lang=" +
          this.state.language +
          "&category_id=" +
          this.state.category +
          "&level_id=" +
          this.state.level +
          "&stream_id=" +
          this.state.stream +
          "&subject_id=" +
          this.state.subject
      )
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        let sequenceArray = [];
        resp.data.data.forEach((v) => {
          sequenceArray.push(parseInt(v.SEQUENCE));
        });
        console.log(resp.data.data);
        this.setState({
          secondaryCategories: resp.data.data
        }, () => {
          console.log(this.state.secondaryCategories);
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  };

  updateSpreadsheet = (courseId) => {
    console.log(courseId);
    let payload = {
      course_id: courseId
    }
    axios.post(Constants.postUrls.deleteOrdersSheetData, payload).then(resp => {
      console.log(resp);
      if(resp.data.status==='success')
      {
        axios.post(Constants.postUrls.updateOrdersSpreadsheet, payload).then(res => {
          console.log(res);
          if(res.data.status==='success')
          {
            this.setState({
              notification: true,
              message: res.data.message
            });
            setTimeout(() => {
              this.setState({
                notification: false,
              })
            }, 3000);
          }
        })
      }
    })
  }

  replicaConfirmation = (courseId) => {
    this.setState({
      visibleReplica: true,
      replicaId: courseId,
    });
  };

  createReplica = (replicaId) => {
    console.log(replicaId);
    let payload = {
      course_id: replicaId,
    };
    axios.post(Constants.postUrls.createReplica, payload).then((resp) => {
      console.log(resp);
      if (resp.data.status === "success") {
        this.setState({
          visibleReplica: false,
        });
        this.getCourses();
      }
    });
  };

  handleDelete = (id) => {
    this.setState({ visibleDelete: true, deleteId: id });
  };

  deleteCourse = (e) => {
    console.log(this.state.deleteId);
    axios
      .post(Constants.postUrls.deleteCourse + this.state.deleteId)
      .then((res) => {
        console.log(res);
        this.setState({
          visibleDelete: false,
        });
        this.getCourses();
      });
  };

  handleIsFeatured = (e, id) => {
    console.log(e.target.value);
    const payload = {
      is_featured: e.target.value,
      course_id: id,
    };
    axios
      .post("https://admin.akclasses.in/api/courses/update_featured", payload)
      .then((res) => {
        console.log(res);
        this.setState({
          notification: true,
          message: this.state.message,
        });
        setTimeout(() => {
          // window.location.reload();
          this.setState(
            {
              notification: false,
              // fromInc: 1,
              // active: 1,
              // toInc: 5
            },
            () => {
              this.getCourses();
            }
          );
        }, 1000);
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.notifyStockStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Stock Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={this.state.message}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={() => {
                    this.setState({
                      apply_filter: !this.state.apply_filter,
                    });
                  }}
                  style={{
                    position: "relative",
                  }}
                  color="success"
                >
                  {this.state.apply_filter === false
                    ? "Apply Filter"
                    : "Hide Filter"}
                </Button>
                {this.state.apply_filter ? (
                  <div>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        required={true}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Attempt
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.attempt_id}
                          inputProps={{
                            name: "attempt_id",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState({
                                attempt_id: e.target.value,
                              });
                            },
                          }}
                        >
                          {this.state.attempts.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v?.id}
                            >
                              {v?.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        required={true}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Language
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.language}
                          inputProps={{
                            name: "language",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState({
                                [e.target.name]: e.target.value,
                              });
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="Hindi"
                          >
                            Hindi
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="English"
                          >
                            English
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        required={true}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Category
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.category}
                          inputProps={{
                            name: "category",
                            id: "simple-select",
                            onChange: this.handleLevel,
                          }}
                        >
                          {this.state.categories.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.ID}
                            >
                              {v.CATEGORY}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Level
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.level}
                          inputProps={{
                            required: true,
                            name: "level",
                            id: "simple-select",
                            onChange: this.handleStream,
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Category First
                          </MenuItem>
                          {this.state.levels.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Stream
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.stream}
                          inputProps={{
                            required: true,
                            name: "stream",
                            id: "simple-select",
                            onChange: this.handleSubject,
                            // onChange: e => {
                            //   this.setState({
                            //     stream: e.target.value
                            //   });
                            // }
                            // //console.log(e.target.value);
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Level First
                          </MenuItem>
                          {this.state.streams.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Subject
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.subject}
                          inputProps={{
                            required: true,
                            name: "subject",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState({
                                subject: e.target.value,
                              });
                            },
                            // //console.log(e.target.value);
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Stream First
                          </MenuItem>
                          {this.state.subjects.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <Button
                      onClick={this.getCourses}
                      style={{
                        position: "relative",
                        left: "20%",
                      }}
                      color="success"
                    >
                      Search
                    </Button>
                  </div>
                ) : (
                  ""
                )}

                <h3>Courses Master</h3>
                <Link to="/admin/addcoursesmaster">
                  <Button className="" color="warning">
                    Add Courses Master
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Course Title",
                    "Course Id",
                    "Price",
                    "Duration",
                    "Attempt",
                    "Batches",
                    <div key={1} style={{ width: "100px" }}>
                      Face To Face Batches
                    </div>,
                    "Sequence",
                    "Faculty",
                    "Image",
                    "Status",
                    "Is Featured",
                    "Projects",
                    "Stock Status",
                    "Budget Mode",
                    "Strategy",
                    "Batch Wings",
                    "Replica",
                    "Commission",
                    "Update",
                    "Action",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [sec.TITLE],
                      [sec?.ID],
                      [
                        sec.variant.length > 0
                          ? sec.variant.find((v) => parseInt(v.is_delete) === 0)
                              .price
                          : "NA",
                      ],
                      [sec.DURATION],
                      [sec?.attempt?.title],
                      [
                        sec.course_batches
                          ? sec.course_batches.map((coursebatch, index) => {
                              return coursebatch
                                ? coursebatch.batch
                                  ? coursebatch.batch.batch_name + ", "
                                  : ""
                                : "";
                            })
                          : "",
                      ],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={
                            sec.ftofbatch === null ? "None" : sec.ftofbatch
                          }
                          inputProps={{
                            name: "ftofbatches",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleFtofbatch(sec.ID, e.target.value);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            // key={i}
                            value={"None"}
                          >
                            None
                          </MenuItem>
                          {sec.ftofbatches.map((val, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={val.id}
                            >
                              {val.batch_name}
                            </MenuItem>
                          ))}
                        </Select>,
                      ],
                      [
                        sec.SEQUENCE ? (
                          <Button
                            key={index}
                            color="info"
                            onClick={() => {
                              this.handleSequenceModal(
                                sec.ID,
                                "Update",
                                sec.SEQUENCE
                              );
                            }}
                          >
                            {sec.SEQUENCE}
                          </Button>
                        ) : (
                          <Tooltip
                            id="tooltip-top"
                            title="Add Sequence"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              aria-label="Sequence"
                              className={classes.tableActionButton}
                            >
                              <span
                                class="material-icons"
                                onClick={() => {
                                  this.handleSequenceModal(sec.ID, "Add", "");
                                }}
                              >
                                toc
                              </span>
                            </IconButton>
                          </Tooltip>

                          // <Button
                          //   style={{ cursor: "pointer" }}
                          //   onClick={() => {
                          //     this.handleSequenceModal(sec.ID, "Add", "");
                          //   }}
                          //   key={index}
                          //   color="success"
                          // >
                          //   Add
                          // </Button>
                        ),
                      ],
                      [
                        // <Button
                        //   key={index}
                        //   onClick={() => {
                        //     this.handleFacultyModal(sec.ID);
                        //   }}
                        //   className="btn-round"
                        //   color="danger"
                        // >
                        //   Details
                        // </Button>,
                        <Tooltip
                          id="tooltip-top"
                          title="Details"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Details"
                            className={classes.tableActionButton}
                          >
                            <span
                              class="material-icons"
                              onClick={() => {
                                this.handleFacultyModal(sec.ID);
                              }}
                            >
                              description
                            </span>
                          </IconButton>
                        </Tooltip>,
                      ],
                      [
                        <img
                          key={index}
                          width="80px"
                          src={Constants.imgUrl + sec.IMAGE_PATH}
                          alt=""
                        />,
                      ],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sec.STATUS}
                          inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleSelect(e, sec.ID);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ACTIVE"
                          >
                            ACTIVE
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="INACTIVE"
                          >
                            INACTIVE
                          </MenuItem>
                        </Select>,
                      ],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sec?.is_featured}
                          inputProps={{
                            name: "project_course_status",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleIsFeatured(e, sec.ID);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="both"
                          >
                            BOTH
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={1}
                          >
                            Featured
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={0}
                          >
                            Non Featured
                          </MenuItem>
                        </Select>,
                      ],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sec.project_course_status}
                          inputProps={{
                            name: "project_course_status",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleProjectVisibility(e, sec.ID);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="both"
                          >
                            BOTH
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ak1"
                          >
                            AK CLASSES
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ak2"
                          >
                            V SMART
                          </MenuItem>
                        </Select>,
                      ],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sec.stock_status}
                          inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleStock(e, sec.ID);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="1"
                          >
                            Out of Stock
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            In Stock
                          </MenuItem>
                        </Select>,
                      ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/coursebudgets/" + sec.ID,
                          }}
                        >
                          {/* <Button className="btn-round" color="success">
                            Add Budget
                          </Button> */}

                          <Tooltip
                            id="tooltip-top"
                            title="Add Budget"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              aria-label="Add Budget"
                              className={classes.tableActionButton}
                            >
                              <span class="material-icons">
                                account_balance_wallet
                              </span>
                            </IconButton>
                          </Tooltip>
                        </Link>,
                      ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/addcoursestrategy/" + sec.ID,
                            id: sec.ID,
                            strategies: sec.strategy,
                          }}
                        >
                          {/* <Button className="btn-round" color="danger">
                            Add Strategy
                          </Button> */}
                          <Tooltip
                            id="tooltip-top"
                            title="Add Strategy"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              aria-label="Add Strategy"
                              className={classes.tableActionButton}
                            >
                              <span class="material-icons">
                                settings_input_composite
                              </span>
                            </IconButton>
                          </Tooltip>
                        </Link>,
                      ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/addwings/" + sec.ID,
                            id: sec.ID,
                            strategies: sec.strategy,
                          }}
                        >
                          {/* <Button className="btn-round" color="danger">
                            Add Batch Wings
                          </Button> */}
                          <Tooltip
                            id="tooltip-top"
                            title="Add Batch Wings"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              aria-label="Add Batch Wings"
                              className={classes.tableActionButton}
                            >
                              <span class="material-icons">join_left</span>
                            </IconButton>
                          </Tooltip>
                        </Link>,
                      ],
                      [
                        // <Button
                        //   className="btn-round"
                        //   color="success"
                        //   onClick={() => {
                        //     this.replicaConfirmation(sec.ID);
                        //   }}
                        // >
                        //   Add Replica
                        // </Button>,

                        <Tooltip
                          id="tooltip-top"
                          title="Add Replica"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Add Replica"
                            className={classes.tableActionButton}
                          >
                            <span
                              class="material-icons"
                              onClick={() => {
                                this.replicaConfirmation(sec.ID);
                              }}
                            >
                              content_copy
                            </span>
                          </IconButton>
                        </Tooltip>,
                      ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/addcommission/" + sec.ID,
                            id: sec.ID,
                          }}
                        >
                          {/* <Button className="btn-round" color="primary">
                            Add Commission
                          </Button> */}
                          <Tooltip
                            id="tooltip-top"
                            title="Add Commission"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              aria-label="Add Commission"
                              className={classes.tableActionButton}
                            >
                              <span class="material-icons">paid</span>
                            </IconButton>
                          </Tooltip>
                        </Link>,
                      ],
                      [
                        <div key={index}>
                          <Link
                            to={{
                              pathname: "/admin/updatecoursesmaster/" + sec.ID,
                              state: {
                                categoryData: sec,
                                directVariant: false,
                              },
                            }}
                          >
                            {/* <Button className="btn-round" color="info">
                              Course
                            </Button> */}
                            <Tooltip
                              id="tooltip-top"
                              title="Update Course"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Update Course"
                                className={classes.tableActionButton}
                              >
                                <span class="material-icons">border_color</span>
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Link
                            to={{
                              pathname: "/admin/updatecoursesmaster/" + sec.ID,
                              state: { categoryData: sec, directVariant: true },
                            }}
                          >
                            {/* <Button
                              style={{ width: "105px" }}
                              className="btn-round"
                              color="warning"
                            >
                              Variants
                            </Button> */}
                            <Tooltip
                              id="tooltip-top"
                              title="Variants"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Variants"
                                className={classes.tableActionButton}
                              >
                                <span class="material-icons">
                                  dashboard_customize
                                </span>
                              </IconButton>
                            </Tooltip>
                          </Link>
                          {/* <Link
                            to={{
                              pathname: "/admin/updatecoursesmaster/" + sec.ID,
                              state: { categoryData: sec, directVariant: true },
                            }}
                          > */}
                            <Tooltip
                              id="tooltip-top"
                              title="Update Sheet"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                              onClick={() => {
                                this.updateSpreadsheet(sec.ID);
                              }}
                            >
                              <IconButton
                                aria-label="Update Sheet"
                                className={classes.tableActionButton}
                              >
                                <span class="material-icons">
                                  description
                                </span>
                              </IconButton>
                            </Tooltip>
                          {/* </Link> */}
                        </div>,
                      ],
                      [
                        // <Button
                        //   style={{ cursor: "pointer" }}
                        //   onClick={() => {
                        //     this.handleDelete(sec.ID);
                        //   }}
                        //   key={index}
                        //   color="danger"
                        // >
                        //   Delete
                        // </Button>,

                        <Tooltip
                          id="tooltip-top"
                          title="Delete"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Delete"
                            className={classes.tableActionButton}
                          >
                            <span
                              class="material-icons"
                              onClick={() => {
                                this.handleDelete(sec.ID);
                              }}
                            >
                              delete
                            </span>
                          </IconButton>
                        </Tooltip>,
                      ],
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
        <Modal
          visible={this.state.visible}
          width="1000"
          height="330"
          effect="fadeInUp"
          onClickAway={() => {
            this.setState({ visible: false });
          }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  {this.state.faculty ? (
                    <div>
                      <h3>Faculty Details</h3>
                      <Table
                        tableHead={["Sr.No", "Name", "Share"]}
                        tableData={this.state.facultyArr.map((sec, index) => [
                          [index + 1],
                          [sec.name],
                          [sec.faculty_share],
                        ])}
                      />
                      <Button
                        onClick={() => {
                          this.setState({ visible: false });
                        }}
                        className="btn-round"
                        color="danger"
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : this.state.add ? (
                    <div>
                      <h3>Add Sequence</h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Sequence"
                            id="float"
                            inputProps={{
                              value: this.state.sequence,
                              type: "number",
                              onChange: (e) =>
                                this.setState({ sequence: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Sequence Added Successfuly!
                              </span>
                            </div>
                          ) : null}
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>
                                Sequence Already Assigned!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={this.addSequence}
                          >
                            {this.state.seqLoading ? <Loader /> : "Add"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <div>
                      <h3>Update Sequence</h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Sequence"
                            id="float"
                            inputProps={{
                              value: this.state.sequence,
                              type: "number",
                              onChange: (e) =>
                                this.setState({ sequence: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Sequence Updated Successfuly!
                              </span>
                            </div>
                          ) : null}
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>
                                Sequence Already Assigned!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={this.updateSequence}
                          >
                            {this.state.seqLoading ? <Loader /> : "Update"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>

        <Modal
          visible={this.state.visibleDelete}
          width="435px"
          height="277px"
          effect="fadeInUp"
          onClickAway={() =>
            this.setState({
              visibleDelete: false,
            })
          }
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <h3>Are You Sure You Want to Delete It?</h3>
                    <div>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="danger"
                          onClick={this.deleteCourse}
                        >
                          Delete
                        </Button>
                        <Button
                          className=""
                          color="warning"
                          onClick={() => {
                            this.setState({ visibleDelete: false });
                          }}
                        >
                          Cancel
                        </Button>
                      </GridItem>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>

        <Modal
          visible={this.state.visibleReplica}
          width="500px"
          height="277px"
          effect="fadeInUp"
          onClickAway={() =>
            this.setState({
              visibleReplica: false,
            })
          }
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <h3>Are You Sure You Want to Make Replica?</h3>
                    <div>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="danger"
                          onClick={() => {
                            this.createReplica(this.state.replicaId);
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          className=""
                          color="warning"
                          onClick={() => {
                            this.setState({ visibleReplica: false });
                          }}
                        >
                          No
                        </Button>
                      </GridItem>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
      </div>
    );
  }
}

export default CoursesMaster;
