/* eslint-disable react/prop-types */
import Constants from "../variables/Constants";
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/core components
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
let fd = new FormData();
const courses = [
  // "9th Standard",
  // "10th Standard",
  "CA Foundation",
  "CA Inter",
  "CA Final"
];

class UpdateFaceToFaceOfflineOrder extends Component {
  state = {
    tran: "",
    cusName: "",
    orderDate: "",
    contact: "",
    email: "",
    faculties: [],
    exMode: "",
    vendorCommission: 0,
    vendorSelected: false,
    facultyCommission: 0,
    add1: "",
    akShare: 100,
    warning: false,
    add2: "",
    city: "",
    orderId: "",
    send_key: "",
    send_mail_to_user: "",
    state: "",
    country: "India",
    pincode: "",
    delStatus: "",
    payStatus: "",
    shipDate: "",
    exModes: [],
    products: [],
    variants: [],
    mediaTypes: [],
    osTypes: [],
    views: [],
    delivery: ["Order Placed", "Packaged", "Shipped", "Delivered", "Cancelled"],
    payment: ["Collected", "Pending"],
    vendors: [],
    attempts: [],
    trackingNo: "",
    total: "",
    productId: "",
    mediaId: "",
    osId: "",
    viewId: "",
    reference: "",
    variantId: "",
    attempt: "",
    redirect: false,
    max: false,
    notification: false,
    loading: false,
    levels: [],
    streams: [],
    subjects: [],
    categories: [],
    facetofaces: [],
    facetoface:"",
    category:"",
    level:"",
    stream:"",
    subject:"",
    catAlert: false,
    levelAlert: false,
    streamAlert: false,
    subjectAlert: false,
    staticCourses: "",
    batchwings: "",
    batch_wings: [],
    batch: "",
    batches: [],
    batch_id: "",
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    console.log(categoryData);
    axios.get("https://admin.akclasses.in/api/getfaceCats").then(resp => {
      this.setState(
        {
          tran: categoryData.transaction_id,
          cusName: categoryData.user_name,
          orderDate: categoryData.order_date,
          contact: categoryData.contact,
          email: categoryData.email,
          add1: categoryData.address,
          add2: categoryData.address_2,
          orderId: categoryData.order_id,
          city: categoryData.city,
          state: categoryData.state,
          country: categoryData.country,
          pincode: categoryData.pincode,
          delStatus: categoryData.delivery_status,
          payStatus: categoryData.payment_status,
          total: categoryData.grand_total ? categoryData.grand_total : "",
          category: categoryData.category_id,
          level: categoryData.level_id,
          stream: categoryData.stream_id,
          subject: categoryData.subject_id,
          facetoface: categoryData.facetoface_id,
          categories: resp.data.data
        },
        () => {
          console.log(this.state.productId)
  
          // axios.get("https://admin.akclasses.in/api/getfaceCats").then(resp => {
          //   console.log(resp);
          //   this.setState({
          //     categories: resp.data.data
          //   });
          // });
  
          axios.get("https://admin.akclasses.in/api/getCategyLevels?category_id=" + this.state.category + "&type=COURSE")
          .then(resp => {
            console.log(resp.data);
            this.setState({
              levels: resp.data
            });
          },() => {
            console.log(this.state.levels);
          });
  
          axios.get("https://admin.akclasses.in/api/getLevelsStream?level_id=" + this.state.level + "&type=COURSE")
            .then(resp => {
              console.log(resp);
              this.setState({
                streams: resp.data
              });
            });
  
          axios.get("https://admin.akclasses.in/api/getFaceSubjects?stream_id=" + this.state.stream)
          .then(resp => {
            console.log(resp);
            this.setState({
              subjects: resp.data.data
            });
          });
  
          axios
            .get("https://admin.akclasses.in/api/getFaceSubjects?stream_id=" + this.state.stream)
            .then(resp => {
              // console.log(resp.data);
              this.setState({
                subjects: resp.data.data
              });
            });
  
          axios
            .get("https://admin.akclasses.in/api/getFaceToFace?category_id=" + this.state.category + "&level_id=" + this.state.level + "&stream_id=" + this.state.stream + "&subject_id=" + this.state.subject)
            .then(resp => {
              console.log(resp);
              this.setState({
                facetofaces: resp.data.data
              });
            });
  
        }
        
        );
      });
  }


  findFaculty = data => {
    let obj = this.state.faculties.find(v => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.max === true) {
      this.setState({ max: true });
    } else if (this.state.warning === true) {
      this.setState({ warning: true });
    } else {
      this.setState({
        loading: true
      });
     
      fd.append("transaction_id", this.state.tran);
      fd.append("user_name", this.state.cusName);
      fd.append("contact", this.state.contact);
      fd.append("email", this.state.email);
      fd.append("address", this.state.add1);
      fd.append("city", this.state.city);
      fd.append("state", this.state.state);
      fd.append("country", "India");
      fd.append("pincode", this.state.pincode);
      fd.append("delivery_status", this.state.delStatus);
      fd.append("payment_status", this.state.payStatus);
      fd.append("grand_total", this.state.total);
      fd.append("order_id", this.state.orderId);
      fd.append("order_type", "COURSE");
      fd.append("order_date", this.state.orderDate);
      fd.append("order_time", this.state.orderTime);
      fd.append("category_id", this.state.category);
      fd.append("level_id", this.state.level);
      fd.append("stream_id", this.state.stream);
      fd.append("subject_id", this.state.subject);
      fd.append("facetoface_id", this.state.facetoface);
      fd.append("_method", "PUT");

      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.id;
      axios
        .post(
          "https://admin.akclasses.in/api/facetofaceOfflineOrders/" + categoryId,
          fd
        )
        .then(() => {
          localStorage.setItem("category", "Course");
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        });
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleProducts = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        aAlert: false
      },
      () => {
        console.log(this.state.batch)
        axios
        // .get("https://admin.akclasses.in/api/coursesR?type=COURSES" )
        // .get("https://admin.akclasses.in/api/selectedProducts?type=COURSES" + "&category_id=" + this.state.category + "&level_id=" + this.state.level + "&stream_id=" + this.state.stream + "&subject_id=" + this.state.subject + "&batch_wings_id=" + this.state.batchwings + "&batch_id=" + this.state.batch )
        .then(resp => {
          console.log(resp.data);
          this.setState({
            products: resp.data
          });
        });
      }
    );
  }
  handleVarients = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.productId)
          .then(resp => {
            // console.log(resp.data);
            this.setState(
              {
                variants: resp.data.product.variant
              },
              () => {
                this.setState(
                  {
                    mediaId: this.state.variants[0]
                      ? this.state.variants[0].media_type_id
                      : "",
                    osId: this.state.variants[0]
                      ? this.state.variants[0].os_type_id
                      : "",
                    viewId: this.state.variants[0]
                      ? this.state.variants[0].view_and_validity_id
                      : "",
                    variantId: this.state.variants[0]
                      ? this.state.variants[0].id
                      : "",
                    total: this.state.variants[0]
                      ? this.state.variants[0].offer_price
                      : 0
                  },
                  () => {
                    let osIds = [];
                    let osTypes = [];
                    this.state.variants.forEach(v => {
                      if (osIds.includes(v.os_type.id) === false) {
                        osIds.push(v.os_type.id);
                        osTypes.push(v.os_type);
                      }
                    });
                    //console.log(osTypes);
                    this.setState({ osTypes });
                    let mediaTypes = [];
                    let mediaIds = [];
                    this.state.variants.forEach(v => {
                      if (mediaIds.includes(v.media_type.id) === false) {
                        if (v.os_type_id === this.state.osId) {
                          mediaIds.push(v.media_type.id);
                          mediaTypes.push(v.media_type);
                        }
                      }
                    });
                    //console.log(mediaTypes);
                    this.setState({ mediaTypes });
                    let viewIds = [];
                    let views = [];
                    this.state.variants.forEach(v => {
                      if (viewIds.includes(v.view_and_validity.id) === false) {
                        if (
                          v.os_type_id === this.state.osId &&
                          v.media_type_id === this.state.mediaId
                        ) {
                          viewIds.push(v.view_and_validity.id);
                          views.push(v.view_and_validity);
                        }
                      }
                    });
                    //console.log(views);
                    this.setState({ views });
                  }
                );
              }
            );
            let facultyCommission = 0;
            resp.data.product.faculty.forEach(v => {
              facultyCommission += v.faulty_share;
            });
            if (this.state.vendorSelected) {
              axios
                .get(
                  "https://admin.akclasses.in/api/get_vendor_product/" +
                    this.state.reference +
                    "?product_id=" +
                    this.state.productId
                )
                .then(resp => {
                  // console.log(resp.data);
                  if (resp.data.length > 0) {
                    let vendorCommission = resp.data.find(
                      v => v.product_id === parseInt(this.state.productId)
                    ).commission_percent;
                    this.setState(
                      {
                        vendorCommission,
                        warning: false
                      },
                      () => {
                        // console.log(vendorCommission);
                        let total =
                          facultyCommission +
                          parseInt(this.state.vendorCommission);
                        this.setState({
                          akShare: 100 - total
                        });
                      }
                    );
                  } else {
                    this.setState({
                      warning: true,
                      akShare: 100 - facultyCommission
                    });
                  }
                });
            } else {
              this.setState({
                akShare: 100 - facultyCommission,
                facultyCommission
              });
            }
          });
      }
    );
    // //console.log(e.target.value);
  };
  handleOsType = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        eAlert: false
      },
      () => {
        let mediaTypes = [];
        let mediaIds = [];
        this.state.variants.forEach(v => {
          if (mediaIds.includes(v.media_type.id) === false) {
            if (v.os_type_id === this.state.osId) {
              mediaIds.push(v.media_type.id);
              mediaTypes.push(v.media_type);
            }
          }
        });
        //console.log(mediaTypes);
        this.setState({ mediaTypes }, () => {
          this.setState(
            {
              mediaId: this.state.mediaTypes[0]
                ? this.state.mediaTypes[0].id
                : ""
            },
            () => {
              let viewIds = [];
              let views = [];
              this.state.variants.forEach(v => {
                if (viewIds.includes(v.view_and_validity.id) === false) {
                  if (
                    v.os_type_id === this.state.osId &&
                    v.media_type_id === this.state.mediaId
                  ) {
                    viewIds.push(v.view_and_validity.id);
                    views.push(v.view_and_validity);
                  }
                }
              });
              //console.log(views);
              this.setState({ views }, () => {
                this.setState(
                  {
                    viewId: this.state.views[0] ? this.state.views[0].id : ""
                  },
                  () => {
                    let requiredVariant = this.state.variants.find(
                      v =>
                        v.os_type_id === this.state.osId &&
                        v.media_type_id === this.state.mediaId &&
                        v.view_and_validity_id === this.state.viewId
                    );
                    // console.log(requiredVariant);
                    this.setState({
                      variantId: requiredVariant ? requiredVariant.id : "",
                      total: requiredVariant ? requiredVariant.offer_price : 0
                    });
                  }
                );
              });
            }
          );
        });
      }
    );
  };
  handleMediaType = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false
      },
      () => {
        let viewIds = [];
        let views = [];
        this.state.variants.forEach(v => {
          if (viewIds.includes(v.view_and_validity.id) === false) {
            if (
              v.os_type_id === this.state.osId &&
              v.media_type_id === this.state.mediaId
            ) {
              viewIds.push(v.view_and_validity.id);
              views.push(v.view_and_validity);
            }
          }
        });
        //console.log(views);
        this.setState({ views }, () => {
          this.setState(
            {
              viewId: this.state.views[0] ? this.state.views[0].id : ""
            },
            () => {
              let requiredVariant = this.state.variants.find(
                v =>
                  v.os_type_id === this.state.osId &&
                  v.media_type_id === this.state.mediaId &&
                  v.view_and_validity_id === this.state.viewId
              );
              // console.log(requiredVariant);
              this.setState({
                variantId: requiredVariant ? requiredVariant.id : "",
                total: requiredVariant ? requiredVariant.offer_price : 0
              });
            }
          );
        });
      }
    );
  };
  handleLevel = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        aAlert: false
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/getCategyLevels?category_id=" + this.state.category + "&type=COURSE"
          )
          .then(resp => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data
            });
          });
      }
    );
  };
  handleStream = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/getLevelsStream?level_id=" + this.state.level + "&type=COURSE")
          .then(resp => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data
            });
          });
      }
    );
  };
  handleSubject = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false
      },
      () => {
        // console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/getFaceSubjects?stream_id=" + this.state.stream)
          .then(resp => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.data
            });
          });
      }
    );
  };

  handleFaceToFace = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false
      },
      () => {
        // console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/getFaceToFace?category_id=" + this.state.category + "&level_id=" + this.state.level + "&stream_id=" + this.state.stream + "&subject_id=" + this.state.subject)
          .then(resp => {
            console.log(resp);
            this.setState({
              facetofaces: resp.data.data
            });
          });
      }
    );
  };

  handleWings = e =>{
    this.setState({
      [e.target.name]: e.target.value,
      fAlert: false
    }, ()=>{
      axios
      .get("https://admin.akclasses.in/api/getBatchCourses/" + this.state.subject)
      .then(resp => {
        console.log(resp);
        this.setState({
          products: resp.data.data
        });
      });
      // console.log(this.state.subject);
      axios.get("https://admin.akclasses.in/api/subjectWings/" + this.state.subject)
      .then((resp)=>{
        // console.log(resp.data.wings)
        this.setState({
          batch_wings: resp.data.wings
        })
      })
    })
  }
  handleBatch = e=>{
    this.setState({
      [e.target.name]: e.target.value,
      fAlert: false
    },()=>{
      
      axios.get("https://admin.akclasses.in/api/wingBatch/" + this.state.batchwings)
      .then((resp)=>{
        // console.log(resp)
        this.setState({
          batches: []
        })
        resp.data.data.map((v)=>{
          if(v.batch)
          {
            this.state.batches.push({
              label: v.batch ? v.batch.batch_name : "",
              value: v.batch ? v.batch.id : "",
            })
          }
        })
        this.setState({
          batches: this.state.batches
        }, ()=>{ console.log(this.state.batches)})
      })
    })
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/facetofaceofflineorder"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Order Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Course Offline Order</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={"Order ID: " + this.state.orderId}
                          id="disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Transaction Details"
                          id="float"
                          inputProps={{
                            value: this.state.tran,
                            onChange: e =>
                              this.setState({ tran: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Order Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  orderDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.orderDate);
                                }
                              );
                            }}
                            inputProps={{
                              value: this.state.orderDate,
                              placeholder: "Order Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Name"
                          id="float"
                          inputProps={{
                            value: this.state.cusName,
                            onChange: e =>
                              this.setState({ cusName: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Contact#"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.contact,
                            onChange: e =>
                              this.setState({ contact: e.target.value }, () => {
                                if (this.state.contact.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            value: this.state.email,
                            onChange: e =>
                              this.setState({ email: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(House number and Street name*)"
                          id="float"
                          inputProps={{
                            value: this.state.add1,
                            onChange: e =>
                              this.setState({ add1: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            value: this.state.city,
                            onChange: e =>
                              this.setState({ city: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="State"
                          id="float"
                          inputProps={{
                            value: this.state.state,
                            onChange: e =>
                              this.setState({ state: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Postcode/ZIP"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.pincode,
                            onChange: e =>
                              this.setState({ pincode: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                     
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Delivery Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.delStatus}
                            inputProps={{
                              name: "delStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  delStatus: e.target.value,
                                  aAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.delivery.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Payment Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.payStatus}
                            inputProps={{
                              name: "payStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  payStatus: e.target.value,
                                  bAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.payment.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel
                            }}
                          >
                            {this.state.categories.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.catAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: this.handleSubject
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleFaceToFace
                              // onChange: e => {
                              //   this.setState({
                              //     fAlert: false,
                              //     subject: e.target.value
                              //   });
                              // }
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Stream First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Face To Face
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.facetoface}
                              inputProps={{
                                required: true,
                                name: "facetoface",
                                id: "simple-select",
                                onChange: this.handleInput
                                // //console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value=""
                              >
                                Select Subject First
                              </MenuItem>
                              {this.state.facetofaces.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.city}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.dAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Grand Total"
                          id="float"
                          inputProps={{
                            value: this.state.total,
                            // disabled: true,
                            onChange: e =>
                              this.setState({ total: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                    </GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? (
                          <Loader />
                        ) : (
                          "Update Course Offline Order"
                        )}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/facetofaceofflineorder"
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateFaceToFaceOfflineOrder;
