/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Datetime from "react-datetime";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class UpdateCouponCode extends Component {
  state = {
    code: "",
    name: "",
    amount: "",
    strDate: "",
    productId: 0,
    faculties: [],
    courses: [],
    endDate: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/faculty").then(resp => {
      // console.log(resp.data);
      this.setState({
        faculties: resp.data
      });
    });
    let categoryData = this.props.location.state.categoryData;
    // console.log(categoryData);
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
      .then(resp => {
        // console.log(resp.data);
        this.setState({
          courses: resp.data,
          productId: categoryData.course ? categoryData.course.ID : 0,
          code: categoryData.CODE,
          name: categoryData.CODE_NAME,
          amount: categoryData.DISCOUNT_AMOUNT,
          strDate: categoryData.START_DATE,
          endDate: categoryData.END_DATE
        });
      });
  }
  findFaculty = data => {
    let obj = this.state.faculties.find(v => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      type: "COUPON_CODE",
      order_type: "online",
      code: this.state.code,
      code_name: this.state.name,
      discount_amount: this.state.amount,
      start_date: this.state.strDate,
      end_date: this.state.endDate,
      ref_product_id: this.state.productId
    };
    let categoryData = this.props.location.state.categoryData;
    let categoryId = categoryData.ID;
    axios
      .post(
        "https://admin.akclasses.in/api/couponCode/update/" + categoryId,
        payLoad
      )
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/couponcodes"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Coupon Code Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Coupon Code</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Course
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.productId}
                            inputProps={{
                              name: "productId",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  productId: e.target.value
                                });
                              }
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.course.ID}
                              >
                                {
                                  <span>
                                    {v.course.TITLE}(
                                    {v.course.faculty.length > 1
                                      ? "AK Team"
                                      : this.findFaculty(v.course.faculty)}
                                    )
                                  </span>
                                }
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Coupon Code"
                          id="float"
                          inputProps={{
                            value: this.state.code,
                            required: true,
                            onChange: e =>
                              this.setState({ code: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Coupon Name/Label"
                          id="float"
                          inputProps={{
                            value: this.state.name,
                            required: true,
                            onChange: e =>
                              this.setState({ name: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Discount Amount"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.amount,
                            onChange: e =>
                              this.setState({ amount: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  strDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.strDate);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              value: this.state.strDate,
                              placeholder: "Start Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  endDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.endDate);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              value: this.state.endDate,
                              placeholder: "End Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Update Coupon Code"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/couponcodes"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateCouponCode;
