import React, { Component } from "react";
import "../scss/Footer.scss";
import akLogo from "../images/AK-logo1.png";
import sendIcon from "../images/sendIcon.png";
import Fb from "../images/facebook-circular-logo.png";
import twitter from "../images/twitter.png";
import instagram from "../images/instagram.png";
import linkedin from "../images/linkedin.png";
import headphones from "../images/headphones.png";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div
          className="col-md-12 col-sm-12 "
          style={{ position: "relative", top: "-60px" }}
        >
          <div className="col-md-4 col-sm-4">
            <div>
              <img src={akLogo} alt="" className="footerLogo" />
            </div>
            <div
              className={window.innerWidth === 1396 ? "details1396" : "details"}
            >
              <h5 className="forDetail">
                For Details: <br />
                48/101, The Park Meadows, Kachimet, <br />
                Amravati Road, Nagpur, 440033 <br />
                (Maharashtra) India <br />
                <br />
                Email Us: bvlhelpdesk@gmail.com
              </h5>
              <br />
              <br />
              <div className=" iconFlexDiv">
                <img src={Fb} alt="" className="fbLogo" />
                <img src={twitter} alt="" className="fbLogo" />{" "}
                <img src={instagram} alt="" className="fbLogo" />
                <img src={linkedin} alt="" className="fbLogo" />
              </div>
            </div>
          </div>
          <div className="dispalyAddlink">
            <div className="col-md-2  col-sm-2 fonts">
              <div>
                <h3 style={{ fontWeight: "bold" }}>Quick Links</h3>
              </div>
              <div className="instructor">
                <h4>Become Instructor</h4>
                <h4>Issue Redressal</h4>
                <h4>Privacy Policy</h4>
                <h4>Terms & Conditions</h4>
                <h4>Return Policy</h4>
              </div>
            </div>
            <div className="col-md-2 col-sm-2  fonts">
              <div>
                <h3 style={{ fontWeight: "bold" }}>Explore</h3>
              </div>
              <div className="explore">
                <h4>CA Courses</h4>
                <h4>Study Material</h4>
                <h4>Franchisee Centers</h4>
                <h4>Franchisee Login</h4>
                <h4>Quick Enquiry</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 fonts">
            <div>
              <h3 style={{ fontWeight: "bold" }}>Subscribe Free Newsletter</h3>
            </div>
            <div className="mailbox">
              <span className="emailHexFooter">&#9993;</span>
              <input
                type="text"
                className={
                  window.innerWidth === 1396
                    ? "inputMailFooter1396"
                    : "inputMailFooter"
                }
                placeholder="Enter Email"
              />
              <span className="sendEmailFooter">
                <img src={sendIcon} alt="" className="sendImg" />
              </span>
            </div>
            <div className="downloadApp">
              <h2>Download App</h2>
            </div>
          </div>
        </div>
        <div className="footerLast">
          <div
            className="col-md-12"
            style={{ position: "relative", top: "33px" }}
          >
            <div
              className="col-md-4 borderRight"
              //   style={{borderRight:"1px solid #d6cfcf"}}
            >
              <h4 className="akName">
                Ak Classes. <br />
                Designed and developed by <br />
                The Space Element
              </h4>
            </div>
            <div>
              <div
                className="col-md-4 borderRight haveQuestionMain"
                //    style={{borderRight:"1px solid #d6cfcf",height:"80px"}}
              >
                <span>
                  <img src={headphones} alt="" className="headphone" />
                  <span className="haveQuestion">
                    Have a question? Call us <br />
                    <span className="mobileNumber">
                      9356841259 | 8979874542{" "}
                    </span>{" "}
                    <br />
                    <br />
                  </span>
                </span>
              </div>
              <div className="col-md-4">
                <h4 className="akNamePara">
                  In case of Pen Drive issues/ Videos Lectures issues: <br />
                  Please Call: 0878465415, 9787445212 <br />
                  Call Timings: 10am to 1pm & 2pm to 5pm
                  <br />
                  In case phone is busy, please Whatsapp on 8979854652
                </h4>
              </div>
            </div>
            <div
              className="col-md-4 borderRight akName1"
              //   style={{borderRight:"1px solid #d6cfcf"}}
            >
              <h4 className="akName1">
                Ak Classes. <br />
                Designed and developed by The Space Element
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
