/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { MultiSelect } from "react-multi-select-component";
import Constants from "variables/Constants";

const selectId = window.location.pathname.split("/")[3];
console.log(selectId);
class AddWings extends Component {
  state = {
    wings: [],
    batchwings: [],
    batchwingsadded: [],
    // batchwingsadded: [],
    batchAdded: [],
    products: [],
    productIds: [],
    notAddedData: [],
    addedData: [],
    redirect: false,
    notification: false,
    loading: false,
    course_id: "",
  };
  componentDidMount() {
    const courseId = window.location.pathname.split("/")[3];
    this.setState({
      course_id: courseId,
    });
    console.log(courseId);

    axios
      .get(
        "https://admin.akclasses.in/api/show_course_wings" +
          "?course_id=" +
          courseId
      )
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          resp.data.notAddedData.forEach((v) => {
            this.state.batchwings.push({
              label: v.title,
              value: v.id,
            });
          });
          console.log(resp.data.addedData);
          // resp.data.addedData.forEach(v => {
          //   this.state.batchwingsadded.push({
          //       label : v.title,
          //       value : v.id
          //   }, ()=>{
          //     console.log(this.state.batchwingsadded)
          //   });
          // });

          this.setState({
            batchwings: this.state.batchwings,
            batchwingsadded: resp.data.addedData,
          });

          // this.setState({
          //   batchwingsadded: resp.data
          // })
          console.log(this.state.batchwingsadded);
        }
      });

    // axios.get("https://admin.akclasses.in/api/batchWings" )
    // .then((res)=>{
    //   console.log(res)
    //   res.data.forEach(val =>{
    //     this.state.batchAdded.push({
    //       label: val.title,
    //       value: val.id,
    //     })
    //   })
    // })
    console.log(this.state.batchwings);

    // axios.get(Constants.getUrls.assignCourseWings + "?course_id=" + courseId).then(resp1 => {
    //     console.log(resp1);
    //     axios.get(Constants.getUrls.getCourseWings).then(resp2 => {
    //       console.log(resp1.data.batchwings);
    //       console.log(resp2.data);
    //       if(resp1.data.batchwings.length > 0)
    //       {
    //         let onlyInA = resp1.data.batchwings.filter(
    //           this.comparer(
    //             resp2.data.filter(v => v.course_id === courseId)
    //           )
    //         );
    //         let onlyInB = resp2.data
    //           .filter(v => v.course_id === courseId)
    //           .filter(this.comparer(resp1.data.batchwings));
    //         onlyInA.concat(onlyInB).forEach(v => {
    //           this.state.batchwings.push({
    //             label: v.batch_wings_id,
    //             value: v.id
    //           });
    //         });
    //         this.setState({
    //           batchwingsadded: resp1.data.batchwings,
    //           batchwings: this.state.batchwings
    //         });
    //       }
    //       else
    //       {
    //         axios.get(Constants.getUrls.getBatchWings).then(resp3 => {
    //           console.log(resp3);
    //           this.setState({
    //             batchwings: resp3.data
    //           });
    //         })
    //       }

    //     });

    // })
  }

  // comparer = otherArray => {
  //   return function(current) {
  //     return (
  //       otherArray.filter(function(other) {
  //         return other.id == current.id;
  //       }).length == 0
  //     );
  //   };
  // };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    this.state.products.forEach((v) => {
      this.state.productIds.push(v.value);
    });
    let payLoad = {
      course_id: this.state.course_id,
      batch_wings_id: this.state.productIds,
    };
    console.log(this.state.productsIds);
    axios.post(Constants.postUrls.addCourseWings, payLoad).then((resp) => {
      console.log(resp);
      setTimeout(() => {
        window.location.href = "/admin/addwings/" + selectId;
      }, 1000);
      this.setState({
        notification: true,
        loading: false,
      });
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/addwings/" + selectId} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Batch Wings Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Batch Wings</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <div
                          style={{
                            position: "relative",
                            bottom: "11px",
                            border: "none",
                          }}
                        >
                          <label htmlFor="Courses">Batch Wings</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.batchwings}
                            value={this.state.products}
                            onChange={(data) => {
                              this.setState({ products: data });
                            }}
                            labelledBy={"Batch Wings"}
                          />
                        </div>
                      </GridItem>
                      {this.state.batchwingsadded.length >= 1 ? (
                        <GridItem xs={12} sm={12} md={12}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px black solid",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tr
                              style={{
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Sr.No
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Batch Wings
                              </th>
                              {/* <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Days
                              </th> */}
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                            {this.state.batchwingsadded.map((v, i) => (
                              <tr
                                style={{
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                                key={i}
                              >
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {i + 1}
                                </td>
                                <td
                                  style={{
                                    padding: "4px 10px 4px 10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {v.title}
                                </td>
                                {/* <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {v.duration}
                                </td> */}
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <input
                                    style={{
                                      backgroundColor: "red",
                                      borderRadius: "10%",
                                      border: "none",
                                      padding: "10px",
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      this.state.batchwingsadded.splice(i, 1);
                                      this.setState({
                                        batchwingsadded: this.state
                                          .batchwingsadded,
                                      });
                                      axios
                                        .delete(
                                          "https://admin.akclasses.in/api/courseWings/" +
                                            v.id
                                        )
                                        .then((resp) => {
                                          console.log(resp);
                                        });
                                    }}
                                    type="button"
                                    value="Delete"
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </GridItem>
                      ) : null}

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Batch Wings"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/coursesmaster",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddWings;
