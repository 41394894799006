import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import {Link} from 'react-router-dom'


class YoutubeVideo extends Component {
  state = {
    category: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/youtubeVideo").then(resp => {
      console.log(resp.data);
      this.setState({
        category: resp.data.CONTENT
      });
    });
  }
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      content: this.state.category
    };
    axios
      .post("https://admin.akclasses.in/api/youtubeVideo/update", payLoad)
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            notification: false
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Content Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Youtube Video</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                          labelText="Youtube Video Link"
                          inputProps={{
                            value: this.state.category,
                            onChange: e =>
                              this.setState(
                                { category: e.target.value },
                                () => {
                                  //console.log(this.state.category);
                                }
                              )
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Update"}
                      </Button>
                     
                      {/* <Link to="/admin/YoutubeTry">
                      <Button className="" color="rose">
                        try
                      </Button>
                    </Link> */}
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default YoutubeVideo;
