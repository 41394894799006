/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import Constants from "../variables/Constants";
import CardBody from "components/Card/CardBody.js";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
let fd = new FormData();
class AboutUs extends Component {
  state = {
    heading: "",
    defGoal: "",
    img1: [],
    img2: [],
    img3: [],
    progress: 0,
    redirect: false,
    notification: false,
    loading: false,
    mediaProgress: false,
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/aboutUs").then((resp) => {
      //console.log(resp);
      this.setState({
        heading: resp.data.HEADING,
        img1: resp.data.CONTENT_1,
        img2: resp.data.CONTENT_2,
        img3: resp.data.CONTENT_3,
        defGoal: resp.data.CONTENT,
      });
    });
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    //console.log(fi.files[0].width);
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      //console.log(this.state.img3);
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    fd.append("heading", this.state.heading);
    fd.append("image_1", this.state.img1);
    fd.append("image_2", this.state.img2);
    fd.append("image_3", this.state.img3);
    fd.append("content", this.state.defGoal);
    axios
      .post("https://admin.akclasses.in/api/aboutUs/update", fd, {
        onUploadProgress: (progressEvent) => {
          this.setState({
            progress: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
            mediaProgress: true,
          });
          if (this.state.progress === 100) {
            this.setState({ mediaProgress: false });
          }
        },
      })
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Content Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>About Us</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Heading"
                          inputProps={{
                            value: this.state.heading,
                            onChange: (e) =>
                              this.setState({ heading: e.target.value }, () => {
                                //console.log(this.state.heading);
                              }),
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Index of Video Lecture</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.state.defGoal}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              defGoal: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                        <br />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Image 1</label>
                        <br />
                        <input
                          id="i1"
                          name="img1"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                        />
                        <img
                          width="200px"
                          src={Constants.imgUrl + this.state.img1}
                          alt=""
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Image 2</label>
                        <br />
                        <input
                          name="img2"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                        />
                        <img
                          width="200px"
                          src={Constants.imgUrl + this.state.img2}
                          alt=""
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Image 3</label>
                        <br />
                        <input
                          name="img3"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                        />
                        <img
                          width="200px"
                          src={Constants.imgUrl + this.state.img3}
                          alt=""
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridItem xs={12} sm={12} md={5}>
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Update"}
                      </Button>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AboutUs;
