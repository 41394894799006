import React, { Component } from "react";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class MarketingPanel extends Component {
  state = {
    notifyNote: false,
    message: "",
    loading: false,
    aAlert: false,
    bAlert: false,
    users: ""
  };
  sendMessage = () => {
    if (this.state.users === "") {
      this.setState({ aAlert: true });
    } else if (this.state.message === "") {
      this.setState({ bAlert: true });
    } else {
      this.setState({ loading: true });
      let payLoad = {};
      if (this.state.users === "all") {
        payLoad = {
          message: this.state.message
        };
      } else {
        payLoad = {
          message: this.state.message,
          type: this.state.users
        };
      }
      axios
        .post("https://admin.akclasses.in/api/send_sms_to_all", payLoad)
        .then(() => {
          this.setState({
            notifyNote: true,
            loading: false
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
    }
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyNote ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Messages Sent!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
              <CardBody>
                <div>
                  <h3 style={{ textAlign: "center" }}>Send Messages</h3>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Select Users
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.users}
                          inputProps={{
                            name: "users",
                            id: "simple-select",
                            onChange: e => {
                              this.setState({
                                users: e.target.value,
                                aAlert: false
                              });
                            }
                          }}
                        >
                          {["All Users", "Free Users", "Premium Users"].map(
                            (v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={
                                  v === "All Users"
                                    ? "all"
                                    : v === "Free Users"
                                    ? "free"
                                    : "test_premium"
                                }
                              >
                                {v}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      {this.state.aAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <br />
                      <label>Message</label>
                      <br />
                      <textarea
                        name="message"
                        cols="118"
                        required
                        rows="5"
                        onChange={e => {
                          this.setState({
                            message: e.target.value,
                            bAlert: false
                          });
                        }}
                      >
                        {this.state.message}
                      </textarea>
                      {this.state.bAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Button
                        className=""
                        color="warning"
                        onClick={this.sendMessage}
                      >
                        {this.state.loading ? "Sending..." : "Send"}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default MarketingPanel;
