import React, { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Modal, Box, Typography, TextField, Snackbar } from "@mui/material";


const useStyles = makeStyles(styles);

const Test1 = () => {
    const classes = useStyles();
    const [openCreate,setOpenCreate] = useState(false)

    const tableData = [{
        name:"mayur",
        age:"26",
        mobile:"7694957493"
    },{
        name:"sagar",
        age:"26",
        mobile:"7694957493"
    },
]
const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
         <Modal
        open={openCreate}
        onClose={()=>setOpenCreate(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Subject Form
          </Typography>
          <form 
        //   onSubmit={createSubject}
          >
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
            //   onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              required
            //   onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              className="btn-round"
              color="success"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="warning"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={()=>setOpenCreate(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>
        <Link to="/admin/test2">
        go to 
        </Link>


        <h1>Table</h1>
        <GridContainer>
        <GridItem xs={12}> 
        <Card>
        <CardHeader color="rose" icon>
              <CardIcon color="danger"></CardIcon>
            </CardHeader>
        
        <CardBody>
              <Button
                className="btn-round"
                color="info"
                // onClick={() => setOpenCreate(true)}
              >
                Add Subject
              </Button>

              <Table
                tableHead={["Id", "name", "age", "mobile", "Actions"]}
                tableData={tableData.map((item, index) => [
                  index + 1,
                  item.name,
                  item.age,
                  item.mobile,

                  
                  [
                    <Button
                      key={`update-${index}`}
                      onClick={() => setOpenCreate(true)}
                      className="btn-round"
                      color="warning"
                    >
                      Update
                    </Button>,
                    <Button
                      key={`delete-${index}`}
                    //   onClick={() => handleDelete(item)}
                      className="btn-round"
                      color="danger"
                    >
                      Delete
                    </Button>,
                  ],
                ])}
              />
            </CardBody>

       


        </Card>

        </GridItem>


        </GridContainer>



        
    </div>
  )
}

export default Test1