/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { MultiSelect } from "react-multi-select-component";
import Constants from "variables/Constants";

const selectId = window.location.pathname.split("/")[3];
console.log(selectId);
class AddWings extends Component {
  state = {
    wings: [],
    amenities: [],
    newAddedAmenity: [],
    // newAddedAmenity: [],
    batchAdded: [],
    amenity: [],
    amenityIds: [],
    notAddedData: [],
    addedData: [],
    redirect: false,
    notification: false,
    loading: false,
    batch_id: "",
  };
  componentDidMount() {
    const batchId = window.location.pathname.split("/")[3];
    this.setState({
      batch_id: batchId,
    });
    console.log(batchId);

    axios
      .get(
        "https://admin.akclasses.in/api/show_alloted_amenities" +
          "?batch_id=" +
          batchId
      )
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          resp.data.notAddedData.forEach((v) => {
            this.state.amenities.push({
              label: v.title,
              value: v.id,
            });
          });
          console.log(resp.data.addedData);
          resp.data.addedData.forEach((v) => {
            this.state.newAddedAmenity.push(
              {
                label: v.title,
                value: v.id,
              },
              () => {
                console.log(this.state.newAddedAmenity);
              }
            );
          });

          this.setState({
            amenities: this.state.amenities,
            newAddedAmenity: resp.data.addedData,
          });

          // this.setState({
          //   newAddedAmenity: resp.data
          // })
          console.log(this.state.newAddedAmenity);
        }
      });

    // axios.get("https://admin.akclasses.in/api/batchWings" )
    // .then((res)=>{
    //   console.log(res)
    //   res.data.forEach(val =>{
    //     this.state.batchAdded.push({
    //       label: val.title,
    //       value: val.id,
    //     })
    //   })
    // })
    console.log(this.state.amenities);

    // axios.get(Constants.getUrls.assignCourseWings + "?course_id=" + courseId).then(resp1 => {
    //     console.log(resp1);
    //     axios.get(Constants.getUrls.getCourseWings).then(resp2 => {
    //       console.log(resp1.data.batchwings);
    //       console.log(resp2.data);
    //       if(resp1.data.batchwings.length > 0)
    //       {
    //         let onlyInA = resp1.data.batchwings.filter(
    //           this.comparer(
    //             resp2.data.filter(v => v.course_id === courseId)
    //           )
    //         );
    //         let onlyInB = resp2.data
    //           .filter(v => v.course_id === courseId)
    //           .filter(this.comparer(resp1.data.batchwings));
    //         onlyInA.concat(onlyInB).forEach(v => {
    //           this.state.batchwings.push({
    //             label: v.batch_wings_id,
    //             value: v.id
    //           });
    //         });
    //         this.setState({
    //           newAddedAmenity: resp1.data.batchwings,
    //           batchwings: this.state.batchwings
    //         });
    //       }
    //       else
    //       {
    //         axios.get(Constants.getUrls.getBatchWings).then(resp3 => {
    //           console.log(resp3);
    //           this.setState({
    //             batchwings: resp3.data
    //           });
    //         })
    //       }

    //     });

    // })
  }

  // comparer = otherArray => {
  //   return function(current) {
  //     return (
  //       otherArray.filter(function(other) {
  //         return other.id == current.id;
  //       }).length == 0
  //     );
  //   };
  // };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    this.state.amenity.forEach((v) => {
      this.state.amenityIds.push(v.value);
    });
    let payLoad = {
      batch_id: this.state.batch_id,
      batch_amenity_id: this.state.amenityIds,
    };
    console.log(this.state.productsIds);
    axios.post(Constants.postUrls.addAllotedAmenity, payLoad).then((resp) => {
      console.log(resp);
      setTimeout(() => {
        window.location.href = "/admin/batches/" + selectId;
      }, 1000);
      this.setState({
        notification: true,
        loading: false,
      });
    });
  };
  render() {
    // if (this.state.redirect) {
    //   return <Redirect to={"/admin/batchhes/" + selectId} />;
    // }
    //  else {
    return (
      <div>
        <GridContainer>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Batch Wings Added!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <EditIcon />
                </CardIcon>
                <h4 style={{ color: "black" }}>Amenities</h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <GridContainer>
                    <GridItem
                      style={{ marginTop: "11px" }}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                    >
                      <div
                        style={{
                          position: "relative",
                          bottom: "11px",
                          border: "none",
                        }}
                      >
                        <label htmlFor="Courses">Amenities</label>
                        <MultiSelect
                          disableSearch
                          options={this.state.amenities}
                          value={this.state.amenity}
                          onChange={(data) => {
                            this.setState({ amenity: data });
                          }}
                          labelledBy={"Amenities"}
                        />
                      </div>
                    </GridItem>
                    {this.state.newAddedAmenity.length >= 1 ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <table
                          style={{
                            width: "100%",
                            border: "1px black solid",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tr
                            style={{
                              border: "1px black solid",
                              borderCollapse: "collapse",
                            }}
                          >
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              Sr.No
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              Batch Wings
                            </th>
                            {/* <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Days
                              </th> */}
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                          {this.state.newAddedAmenity.map((v, i) => (
                            <tr
                              style={{
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                              key={i}
                            >
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                {i + 1}
                              </td>
                              <td
                                style={{
                                  padding: "4px 10px 4px 10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                {v.title}
                              </td>
                              {/* <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {v.duration}
                                </td> */}
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <input
                                  style={{
                                    backgroundColor: "red",
                                    borderRadius: "10%",
                                    border: "none",
                                    padding: "10px",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    this.state.newAddedAmenity.splice(i, 1);
                                    this.setState({
                                      newAddedAmenity: this.state
                                        .newAddedAmenity,
                                    });
                                    axios
                                      .delete(
                                        "https://admin.akclasses.in/api/allotedAmenities/" +
                                          v.id
                                      )
                                      .then((resp) => {
                                        console.log(resp);
                                      });
                                  }}
                                  type="button"
                                  value="Delete"
                                />
                              </td>
                            </tr>
                          ))}
                        </table>
                      </GridItem>
                    ) : null}

                    <GridItem xs={12} sm={12} md={12}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? <Loader /> : "Add Amenity"}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/batches",
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
  // }
}

export default AddWings;
