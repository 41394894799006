/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Modal from "react-awesome-modal";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
class UpdateVendor extends Component {
  state = {
    name: "",
    pName: "",
    email: "",
    vendorCommission: "",
    poolCommission: "",
    vsmartCommission: "",
    facultyCommission: 100-18,
    gst: 18,
    subject: "",
    percent: "",
    faculty: [],
    faculties: [],
    facultyIds: [],
    facultyWithName: "",
    facultyId: "",
    facultyArr: [],
    atLeast: false,
    warn: false,
    warning: false,
    mobile: "",
    redirect: false,
    max: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    // axios.get("https://admin.akclasses.in/api/facultyR").then(resp => {
      axios.get(Constants.getUrls.facultyR).then(resp => {
      //console.log(resp.data);
      this.setState({
        faculties: resp.data
      });
    });
    let categoryData = this.props.location.state.categoryData;
    //console.log(categoryData);
    // axios.get("https://admin.akclasses.in/api/coursesR").then(resp => {
      axios.get(Constants.getUrls.coursesR).then(resp => {
      console.log(resp);
      this.setState({
        facultyArr: resp.data,
        name: categoryData.institute_name,
        pName: categoryData.person_name,
        email: categoryData.email,
        // commission: categoryData.commission_percent,
        mobile: categoryData.mobile
      });
    });

    axios
      .get(
        // "https://admin.akclasses.in/api/get_vendor_product/" + categoryData.id
        Constants.getUrls.get_vendor_product + categoryData.id
      )
      .then(resp => {
        // console.log(resp.data);
        this.setState({ faculty: resp.data }, () => {
          this.state.faculty.forEach(v => {
            this.state.facultyIds.push(parseInt(v.product_id));
          });
          this.setState({ facultyIds: this.state.facultyIds });
          // console.log(facultyIds);
          // console.log(this.state.faculty);
        });
      });
  }
  openModal() {
    this.setState({
      visible: true
    });
  }
  findFaculty = data => {
    let obj = this.state.faculties.find(v => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  closeModal() {
    this.setState({
      visible: false
    });
  }
  handleSubmit = e => {
    e.preventDefault();
    let facultyDetails = [];
    this.state.faculty.forEach(v => {
      facultyDetails.push({
        product_id: v.product_id,
        commission_percent: v.vshare ? v.vshare : 0,
        pool_percent: v.pshare ? v.pshare : 0,
        vsmart_percent: v.vmshare ? v.vmshare : 0,
        faculty_percent: v.fshare ? v.fshare : 0,
        gst: v.gst ? v.gst : 0,
      });
    });
    if (facultyDetails.length === 0) {
      this.setState({ atLeast: true });
    } else if (this.state.max === true) {
      this.setState({ max: true });
    } else {
      this.setState({
        loading: true
      });
      let payLoad = {
        institute_name: this.state.name,
        person_name: this.state.pName,
        shares: facultyDetails,
        email: this.state.email,
        mobile: this.state.mobile
      };
      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.id;
      axios
        .post(
          // "https://admin.akclasses.in/api/vender/update/" + categoryId,
          Constants.postUrls.venderUpdate + categoryId,
          payLoad
        )
        .then(resp => {
          // console.log(resp.data);
          if (resp.data.status === "failed") {
            this.setState({ warn: true, loading: false });
            setTimeout(() => {
              this.setState({ warn: false });
            }, 2000);
          } else if (resp.data.status === "fail") {
            this.setState({
              subject: resp.data.product,
              percent: resp.data.commission_percent,
              warning: true,
              loading: false
            });
            setTimeout(() => {
              this.setState({ warning: false });
            }, 5000);
          } else {
            setTimeout(() => {
              this.setState({
                redirect: true
              });
            }, 1000);
            this.setState({
              notification: true,
              loading: false
            });
          }
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/vendors"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Vendor Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            {this.state.warn ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Vendor Already Exists!"}
                  icon={AddAlert}
                  color="danger"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Vendor</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Institute Name"
                          id="float"
                          inputProps={{
                            value: this.state.name,
                            required: true,
                            onChange: e =>
                              this.setState({ name: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Person Name"
                          id="float"
                          inputProps={{
                            value: this.state.pName,
                            required: true,
                            onChange: e =>
                              this.setState({ pName: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            disabled: true,
                            value: this.state.email,
                            onChange: e =>
                              this.setState({ email: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Mobile"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.mobile,
                            required: true,
                            onChange: e =>
                              this.setState({ mobile: e.target.value }, () => {
                                if (this.state.mobile.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      {this.state.faculty.length >= 1 ? (
                        <GridItem xs={12} sm={12} md={12}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px black solid",
                              borderCollapse: "collapse"
                            }}
                          >
                            <tr
                              style={{
                                border: "1px black solid",
                                borderCollapse: "collapse"
                              }}
                            >
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Sr.No
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Product
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Centered Commission
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Pool Commission
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Vsmart Commission
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Faculty Commission
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                GST
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                              >
                                Action
                              </th>
                            </tr>
                            {this.state.faculty.map((v, i) => (
                              <tr
                                style={{
                                  border: "1px black solid",
                                  borderCollapse: "collapse"
                                }}
                                key={i}
                              >
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {i + 1}
                                </td>
                                <td
                                  style={{
                                    padding: "4px 10px 4px 10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {v.fName}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {v.vshare}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {v.pshare}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {v.vmshare}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {v.fshare}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  {v.gst}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse"
                                  }}
                                >
                                  <input
                                    style={{
                                      backgroundColor: "red",
                                      borderRadius: "10%",
                                      border: "none",
                                      padding: "10px",
                                      color: "white"
                                    }}
                                    onClick={() => {
                                      this.state.faculty.splice(i, 1);
                                      this.state.facultyIds.splice(i, 1);
                                      this.setState(
                                        {
                                          faculty: this.state.faculty,
                                          facultyIds: this.state.facultyIds
                                        },
                                        () => {
                                          // console.log(this.state.faculty);
                                          if (this.state.faculty.length === 0) {
                                            this.setState({ atLeast: true });
                                          } else {
                                            this.setState({ atLeast: false });
                                          }
                                        }
                                      );
                                    }}
                                    type="button"
                                    value="Delete"
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          onClick={() => {
                            this.setState({
                              visible: true
                            });
                          }}
                          className="btn-round"
                          color="success"
                        >
                          Add Commission
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {this.state.atLeast ? (
                          <div>
                            <span style={{ color: "red" }}>
                              *Add Atleast One Product Commission
                            </span>
                          </div>
                        ) : null}
                        {this.state.warning ? (
                          <div>
                            <span style={{ color: "red" }}>
                              *Commission Percent For {this.state.subject}{" "}
                              should be Less than {this.state.percent}.
                            </span>
                          </div>
                        ) : null}
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Vendor"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/vendors"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Modal
            visible={this.state.visible}
            width="700"
            height="320"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <GridContainer style={{ paddingLeft: "85px", paddingTop: "20px" }}>
              <GridItem xs={12} sm={12} md={5}>
                <FormControl fullWidth>
                  <label>Product</label>
                  <select
                    style={{ padding: "11px" }}
                    name="facultyId"
                    value={this.state.facultyId}
                    onChange={e => {
                      this.setState({ facultyId: e.target.value }, () => {
                        this.state.facultyArr.forEach(v => {
                          if (parseInt(this.state.facultyId) === v.course.ID) {
                            this.setState({ facultyWithName: v.course.TITLE });
                          }
                        });
                      });
                    }}
                  >
                    <option value="">Select</option>
                    {this.state.facultyArr.map((v, i) => (
                      <option key={i} value={v.course.ID}>
                        {v.course.TITLE +
                          "(" +
                          v.course.TYPE +
                          "," +
                          (v.course.faculty.length === 1
                            ? this.findFaculty(v.course.faculty)
                            : "AK Team") +
                          ")"}
                      </option>
                    ))}
                  </select>
                </FormControl>
                {this.state.bAlert ? (
                  <div>
                    <span style={{ color: "red" }}>*required</span>
                  </div>
                ) : null}
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Vendor Commission"
                  id="float"
                  inputProps={{
                    type: "float",
                    value: this.state.vendorCommission,
                    required: true,
                    onChange: e => this.setState({ 
                      vendorCommission: e.target.value,
                    },()=>{
                      this.setState({
                        facultyCommission: 100-(parseInt(this.state.gst)+parseInt(this.state.vendorCommission)),
                      })
                    })
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Pool Commission"
                  id="float"
                  inputProps={{
                    type: "float",
                    value: this.state.poolCommission,
                    required: true,
                    onChange: e => this.setState({ 
                      poolCommission: e.target.value
                    },() => {
                      this.setState({
                        facultyCommission: 100-(parseInt(this.state.gst)+parseInt(this.state.vendorCommission?this.state.vendorCommission:0)+parseInt(this.state.vsmartCommission?this.state.vsmartCommission:0)+parseInt(this.state.poolCommission)),
                      })
                    })
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Vsmart Commission"
                  id="float"
                  inputProps={{
                    type: "float",
                    value: this.state.vsmartCommission,
                    required: true,
                    onChange: e => this.setState({ 
                      vsmartCommission: e.target.value
                    },() => {
                      this.setState({
                        facultyCommission: 100-(parseInt(this.state.gst)+parseInt(this.state.vendorCommission?this.state.vendorCommission:0)+parseInt(this.state.poolCommission?this.state.poolCommission:0)+parseInt(this.state.vsmartCommission)),
                      })
                    })
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Faculty Commission"
                  id="float"
                  inputProps={{
                    type: "float",
                    readOnly:"true",
                    value: this.state.facultyCommission,
                    required: true,
                    onChange: e => this.setState({ facultyCommission: e.target.value })
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="GST"
                  id="float"
                  inputProps={{
                    readOnly:"true",
                    type: "float",
                    value: this.state.gst,
                    required: true,
                    onChange: e => this.setState({ 
                      gst: e.target.value 
                    },()=>{
                      this.setState({
                        facultyCommission: 100-(parseInt(this.state.gst)),
                        vendorCommission:"",
                        poolCommission:"",
                        vsmartCommission:"",
                      })
                    })
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  // style={{ marginTop: "20px" }}
                  onClick={() => {
                    if (
                      this.state.facultyIds.includes(this.state.facultyId) ===
                      false
                    ) {
                      this.state.facultyIds.push(this.state.facultyId);
                      this.state.faculty.push({
                        product_id: parseInt(this.state.facultyId),
                        fName: this.state.facultyWithName,
                        vshare: parseFloat(this.state.vendorCommission),
                        pshare: parseFloat(this.state.poolCommission),
                        vmshare: parseFloat(this.state.vsmartCommission),
                        fshare: parseFloat(this.state.facultyCommission),
                        gst: parseFloat(this.state.gst),
                      });
                      // console.log(this.state.faculty);
                      this.setState(
                        {
                          faculty: this.state.faculty,
                          facultyIds: this.state.facultyIds,
                          vendorCommission: "",
                          poolCommission: "",
                          vsmartCommission: "",
                          facultyCommission: "",
                          facultyId: "",
                          gst: ""
                        },
                        () => {
                          // console.log(this.state.faculty);
                          if (this.state.faculty.length === 0) {
                            this.setState({ atLeast: true });
                          } else {
                            this.setState({ atLeast: false });
                          }
                        }
                      );
                    }
                    this.setState({
                      visible: false
                    });
                  }}
                  className="btn-round"
                  color="success"
                >
                  Add
                </Button>
              </GridItem>
            </GridContainer>
          </Modal>
        </div>
      );
    }
  }
}

export default UpdateVendor;
