import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Constants from "variables/Constants";
// import editorConfiguration from "variables/EditorConfiguration";
class AddQuestionBankLevel extends Component {
  state = {
    redirect: false,
    notification: false,
    loading: false,
    aAlert: false,
    bAlert: false,
    title: "",
    description: "",
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.title === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.description === "") {
      this.setState({
        bAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      let payLoad = {
        title: this.state.title,
        description: this.state.description,
      };
      // console.log(payLoad);
      axios
        .post(Constants.postUrls.createQuestionBankLevel, payLoad)
        .then((resp) => {
          console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/questionbanklevel"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Level Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Question Bank Level</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Level"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({
                                title: e.target.value,
                                aAlert: false,
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Description</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          data={this.state.description}
                          // config={editorConfiguration}
                          //   data={this.props.location.state.categoryData.QUESTION}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              description: data,
                              bAlert: false,
                            });
                          }}
                        />
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Level"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/questionbanklevel",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddQuestionBankLevel;
