import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
import Modal from "react-awesome-modal";
import Loader from "components/Loader";
import renderHTML from "react-render-html";
import MathJax from "react-mathjax-preview";
import CustomInput from "components/CustomInput/CustomInput.js";
const classes = makeStyles(styles);

export default class LiveQuizQuestion extends Component {
  state = {
    loading: false,
    notifyUpdate: false,
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    questionBank: [],
    quizId: "",
    timing: 0,
    timingModal: false,
  };
  componentDidMount() {
    this.getquestion();
  }
  getquestion = () => {
    const quizId = window.location.pathname.split("/")[3];
    axios.get(Constants.getUrls.getLiveQuizQuestions + quizId).then((resp) => {
      //   console.log(resp);
      if (resp) {
        this.setState({
          questionBank: resp.data,
          quizId: quizId,
        });
      } else {
        this.setState({
          questionBank: resp.data,
          quizId: quizId,
        });
      }
    });
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios
      .delete(Constants.postUrls.deleteLiveQuizQuestion + id)
      .then((resp) => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };
  handleUpdateTime = (id) => {
    this.setState({
      loading: true,
    });
    let payload = {
      id: id,
      timing: this.state.timing,
    };
    axios
      .post("https://admin.akclasses.in/api/updateTiming", payload)
      .then((resp) => {
        // console.log(resp);
        this.setState({
          loading: false,
          notifyUpdate: true,
        });
        setTimeout(() => {
          // window.location.reload();
          this.setState(
            {
              timingModal: false,
              visible: false,
            },
            () => {
              this.getquestion();
            }
          );
        }, 2000);
      });
  };
  handleTiming = (question) => {
    console.log(question);
    this.setState({
      rowId: question.id,
      timingModal: true,
      visible: true,
    });
  };
  handleModal = (question) => {
    this.setState({
      rowId: question.id,
      visible: true,
    });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Question</h3>
                {
                  <Link
                    to={{
                      pathname:
                        "/admin/addlivequizquestion/" + btoa(this.state.quizId),
                    }}
                  >
                    <Button className="" color="warning">
                      Add Questions
                    </Button>
                  </Link>
                }
                <Table
                  tableHead={[
                    "Sr.No.",
                    "Level",
                    "Subject",
                    "Chapter",
                    "Question",
                    "Timing",
                    "Update Timing",
                    "Delete",
                  ]}
                  tableData={
                    this.state.questionBank &&
                    this.state.questionBank.map((question, index) => [
                      [index + 1],
                      [question?.level?.title],
                      [question?.subject?.title],
                      [question?.chapter?.title],
                      [<MathJax math={question?.questionbank?.question} />],
                      [question?.timing],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.handleTiming(question);
                          }}
                          className="btn-round"
                          color="success"
                        >
                          Update Timing
                        </Button>,
                      ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.handleModal(question);
                          }}
                          className="btn-round"
                          color="danger"
                        >
                          {this.state.act === parseInt(question?.id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>,
                      ],
                    ])
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    {this.state.visible === true &&
                    this.state.timingModal === false ? (
                      <div>
                        <h3>Are You Sure You Want to Delete It?</h3>
                        {this.state.notifyDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Question Deleted Successfully!
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleDelete(this.state.rowId);
                              }}
                            >
                              {this.state.delLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : (
                      <div>
                        <h3>Update Question Time(seconds)</h3>
                        {this.state.notifyUpdate ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Question Timing Successfully!
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                              labelText="Enter time in seconds"
                              inputProps={{
                                type: "Number",
                                value: this.state.timing,
                                onChange: (e) =>
                                  this.setState(
                                    { timing: e.target.value },
                                    () => {
                                      //   console.log(this.state.timing);
                                    }
                                  ),
                              }}
                              id="float"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <br />
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleUpdateTime(this.state.rowId);
                              }}
                            >
                              {this.state.loading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
      </div>
    );
  }
}
