/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
import { Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { atob } from "js-base64";
const classes = makeStyles(styles);
export default class AddSeriesQuestion extends Component {
  state = {
    question: "",
    level: "",
    subject: "",
    chapter: "",
    products: [],
    productIds: [],
    questions: [],
    levels: [],
    subjects: [],
    chapters: [],
    redirect: false,
    notification: false,
    loading: false,
    submission: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    no_of_questions: "",
    greaterQuestion: false,
    alreadyAddedQuestion: false,
    addedQuestions: [],
    is_deleted: 0,
  };

  componentDidMount() {
    axios
      .get(
        Constants.getUrls.getQuestionBankLevel +
          "?perPage=" +
          this.state.perPage
      )
      .then((resp) => {
        // console.log(resp);
        let pageArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          pageArray.push(i);
        }
        this.setState(
          {
            pageArray,
            levels: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let paginationArray = this.state.pageArray.filter(
              (page) => page >= this.state.fromInc && page <= this.state.toInc
            );
            this.setState({
              paginationArray,
            });
          }
        );
      });
  }

  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_with_levels + this.state.level)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.subjects,
              subject: "",
              chapters: [],
              chapter: "",
              aAlert: false,
            });
          });
      }
    );
  };

  handleChapter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_subject + this.state.subject)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              chapters: resp.data.chapter,
              chapter: "",
              bAlert: false,
            });
          });
      }
    );
  };

  getquestion = () => {
    axios
      .get(
        Constants.getUrls.getQuestionBank +
          "?is_filter=1" +
          "&level_id=" +
          this.state.level +
          "&subject_id=" +
          this.state.subject +
          "&chapter_id=" +
          this.state.chapter +
          "&is_deleted=" +
          this.state.is_deleted
      )
      .then((resp) => {
        // console.log(resp);
        let pageArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          pageArray.push(i);
        }
        this.setState({
          pageArray,
          questions: resp.data.data,
          total: resp.data.total,
          from: resp.data.from,
          to: resp.data.to,
          // notifyStatus: true,
          // message: ,
        });
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/seriesquestions"} />;
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {this.state.greaterQuestion ? (
                <div
                  style={{
                    position: "relative",
                    left: "600px",
                    width: "400px",
                  }}
                >
                  <SnackbarContent
                    message={
                      "You Can Add Only " +
                      this.state.no_of_questions +
                      " Question."
                    }
                    icon={AddAlert}
                    color="success"
                  />
                </div>
              ) : null}
              {this.state.alreadyAddedQuestion ? (
                <div
                  style={{
                    position: "relative",
                    left: "600px",
                    width: "400px",
                  }}
                >
                  <SnackbarContent
                    message={"This Question Is Already Added."}
                    icon={AddAlert}
                    color="success"
                  />
                </div>
              ) : null}
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Add Live Quiz Question</h4>
                </CardHeader>

                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleSubject,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleChapter,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Chapter
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.chapter}
                            inputProps={{
                              required: true,
                              name: "chapter",
                              id: "simple-select",
                              onChange: this.handleQuestion,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Subject First
                            </MenuItem>
                            {this.state.chapters.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <br />
                        <div
                          style={{
                            position: "relative",
                            bottom: "11px",
                            border: "none",
                          }}
                        >
                          <label htmlFor="Questions">Add Questions</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.questions}
                            value={this.state.products}
                            onChange={(data) => {
                              console.log(data.length);
                              console.log(this.state.no_of_questions);
                              if (data.length > this.state.no_of_questions) {
                                this.setState({
                                  greaterQuestion: true,
                                });
                                setTimeout(() => {
                                  this.setState({
                                    greaterQuestion: false,
                                  });
                                }, 6000);
                              } else {
                                this.setState({
                                  products: data,
                                  dAlert: false,
                                  greaterQuestion: false,
                                });
                              }
                            }}
                            labelledBy={"Questions"}
                          />
                        </div>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Questions"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/seriesquestions",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>

                      {this.state.submission ? (
                        <div style={{ position: "relative", left: "600px" }}>
                          <SnackbarContent
                            message={"Questions Added Successfully!"}
                            icon={AddAlert}
                            color="success"
                          />
                        </div>
                      ) : null}
                      {this.state.notification ? (
                        <div style={{ position: "relative", left: "600px" }}>
                          <SnackbarContent
                            message={"Question Added!"}
                            icon={AddAlert}
                            color="success"
                          />
                        </div>
                      ) : null}
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
