/* eslint-disable react/prop-types */
import AddAlert from "@material-ui/icons/AddAlert";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loader from "components/Loader";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Constants from "../variables/Constants";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
const classes = makeStyles(styles);
let fd = new FormData();
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
class UpdateWhatStudentsSay extends Component {
  state = {
    videoLink: "",
    studentName: "",
    marks: "",
    progress: 0,
    mediaProgress: false,
    id: "",
    categories: [],
    redirect: false,
    notification: false,
    loading: false,
    description: "",
    status: "",
    is_deleted: "",
    id: 0,
    categoryData: {},
    batch_id: 0,
    batches: [],
  };
  componentDidMount() {
    this.getbatches();
    let categoryData = this.props.location.state.categoryData;
    console.log(categoryData);
    this.setState(
      {
        categoryData,
        batch_id: categoryData.batch_id,
        videoLink: categoryData.video_link,
        studentName: categoryData.student_name,
        marks: categoryData.marks,
        status: categoryData.status,
        is_deleted: categoryData.is_deleted,
      },
      () => {
        console.log(this.state.title);
      }
    );
  }
  getbatches = () => {
    axios
      .get("https://admin.akclasses.in/api/batches?per_page=100")
      .then((res) => {
        console.log("batches", res);
        this.setState(
          {
            batches: res.data.data,
          },
          () => {
            console.log(this.state.batches);
          }
        );
      });
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    const payload = {
      batch_id: this.state.batch_id,
      video_link: this.state.videoLink,
      student_name: this.state.studentName,
      marks: this.state.marks,
      status: this.state.status,
      is_deleted: this.state.is_deleted,
    };
    console.log(payload);
    axios
      .put(Constants.postUrls.updateWhatStudentSay + this.state.categoryData.id, payload)
      .then(() => {
        // console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/whatStudentsSay"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Record Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Update What Students Say</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Video Link"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.videoLink,
                            onChange: (e) =>
                              this.setState({ videoLink: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Student Name"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.studentName,
                            onChange: (e) =>
                              this.setState({ studentName: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Batch
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            required={true}
                            value={this.state.batch_id}
                            inputProps={{
                              required: true,
                              name: "batch_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    batch_id: e.target.value,

                                    aAlert: false,
                                  },
                                  () => {
                                    console.log(this.state.batch_id);
                                  }
                                );
                              },
                            }}
                          >
                            {this.state.batches.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.batch_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Marks"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.marks,
                            onChange: (e) =>
                              this.setState({ marks: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Update What Student Say"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/whatStudentsSay",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateWhatStudentsSay;
