import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class AddPremiunUser extends Component {
  state = {
    course: "",
    name: "",
    email: "",
    mobile: "",
    aAlert: false,
    warn: false,
    max: false,
    city: "",
    state: "",
    courses: [
      "9th Standard",
      "10th Standard",
      "CA Foundation",
      "CA Inter",
      "CA Final"
    ],
    redirect: false,
    notification: false,
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.max === true) {
      this.setState({ max: true });
    } else {
      let payLoad = {
        first_name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile,
        city: this.state.city,
        state: this.state.state,
        attempt: this.state.course,
        type: "Premium"
      };
      if (this.state.course === "") {
        this.setState({
          aAlert: true
        });
      } else {
        this.setState({
          loading: true
        });
        axios
          .post("https://admin.akclasses.in/api/addUser?type=Premium", payLoad)
          .then(resp => {
            if (resp.data.status === "failed") {
              this.setState({ warn: true, loading: false });
              setTimeout(() => {
                this.setState({ warn: false });
              }, 2000);
            } else {
              setTimeout(() => {
                this.setState({
                  redirect: true
                });
              }, 1000);
              this.setState({
                notification: true,
                loading: false
              });
            }
          });
      }
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/premiumusers"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Premium User Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            {this.state.warn ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"User Already Exists!"}
                  icon={AddAlert}
                  color="danger"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Premium User</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ name: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Mobile Number"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: e =>
                              this.setState({ mobile: e.target.value }, () => {
                                if (this.state.mobile.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ city: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="State"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ state: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            required: true,
                            onChange: e =>
                              this.setState({ email: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Course Name
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.course}
                            inputProps={{
                              name: "course",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  course: e.target.value,
                                  aAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Premium User"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/premiumusers"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddPremiunUser;
