/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import renderHTML from "react-render-html";
// import { makeStyles } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
import MathJax from "react-mathjax-preview";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
// import Webcam from "react-webcam";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import Constants from "variables/AppConstants";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
// const classes = makeStyles(styles);
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
class AnswerQuery extends Component {
  state = {
    answer: "",
    answerQuery: "",
    media: { name: "" },
    media_type: "",
    question: "",
    mediaProgress: false,
    camera: false,
    progress: 0,
    fileExt: "",
    recordingDisabled: true,
    playDisabled: true,
    resolutions: [
      { id: 1, width: 852, height: 480 },
      { id: 2, width: 1280, height: 720 },
    ],
    selectedResolution: localStorage.getItem("id"),
    downloadDisabled: true,
    showSubmit: true,
    show: false,
    loading: false,
    redirect: false,
  };

  componentDidMount() {
    console.log(this.state.resolutions.map((v) => v.width));
    const questionData = this.props.location.state.questionData;
    console.log(questionData);
    // localStorage.setItem("questionId", parseInt(questionData.id));
    this.setState({
      question: questionData.text,
      // question: questionData.question,
      //   answer: questionData.answer
      answer: this.props.location.state.queryFacultyAnswer,
    });
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    const fi = document.getElementById("File");
    // Check if any file is selected.
    if (fi.files.length > 0) {
      for (let i = 0; i <= fi.files.length - 1; i++) {
        const fsize = fi.files.item(i).size;
        const file = Math.round(fsize / 1024);
        // The size of the file.
        if (file <= 102400) {
          this.setState(
            {
              [e.target.name]: e.target.files[0],
            },
            () => console.log(this.state.media)
          );
          const fileExt = e.target.files[0].name.split(".").pop();

          if (fileExt === "mp4" || fileExt === "mkv" || fileExt === "mov") {
            this.setState({
              media_type: "video",
            });
          }
          if (fileExt === "jpg" || fileExt === "jpeg" || fileExt === "png") {
            this.setState({
              media_type: "image",
            });
          }
          if (fileExt === "pdf") {
            this.setState({
              media_type: "pdf",
            });
          }
        } else {
          alert("File too Big, please select a file less than 100 mb");
          e.target.value = null;
        }
      }
    }
  };
  handleSubmit = (e) => {
    console.log(this.state.answer);
    console.log(this.state.answerQuery);
    e.preventDefault();
    const questionData = this.props.location.state.questionData;
    // const questionId = parseInt(questionData.id);
    let fd = new FormData();
    if (this.state.answer) {
      if (this.state.answer != "null" && this.state.media.name.length > 0) {
        fd.append("answer", this.state.answer);
        fd.append("mediaType", this.state.media_type);
        fd.append("media", this.state.media);
        fd.append("status", "closed");
        fd.append("_method", "post");
      } else if (
        this.state.answer != "null" &&
        this.state.media.name.length == 0
      ) {
        fd.append("answer", this.state.answer);
        fd.append("status", "closed");
        fd.append("_method", "post");
      } else if (
        this.state.answer == "null" &&
        this.state.media.name.length > 0
      ) {
        fd.append("mediaType", this.state.media_type);
        fd.append("media", this.state.media);
        fd.append("status", "closed");
        fd.append("_method", "post");
      }
      fd.append("question", questionData.text);
      fd.append("levelId", questionData.level_id);
      fd.append("subjectId", questionData.subject_id);
      fd.append("chapterId", questionData.chapter_id);
      fd.append("queryId", questionData.id);

      if (this.state.answer == "null" && this.state.media.name.length == 0) {
        console.log("nothing selected");
      } else {
        axios
          .post(Constants.postUrls.createQuestion, fd, {
            onUploadProgress: (progressEvent) => {
              this.setState({
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
                mediaProgress: true,
              });
              // console.log(
              //   "Upload Progress" +
              //     Math.round((progressEvent.loaded / progressEvent.total) * 100) +
              //     "%"
              // );
            },
          })
          .then((resp) => {
            console.log(resp);
            this.setState({
              questionId: resp.data.data.query_id,
              loading: false,
              show: true,
            });
            localStorage.removeItem("id");
            localStorage.removeItem("width");
            localStorage.removeItem("height");
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 1000);
          });
      }
    } else {
      if (
        this.state.answerQuery != "null" &&
        this.state.media.name.length > 0
      ) {
        fd.append("answer", this.state.answerQuery);
        fd.append("mediaType", this.state.media_type);
        fd.append("media", this.state.media);
        fd.append("status", "closed");
        fd.append("_method", "post");
      } else if (
        this.state.answerQuery != "null" &&
        this.state.media.name.length == 0
      ) {
        fd.append("answer", this.state.answerQuery);
        fd.append("status", "closed");
        fd.append("_method", "post");
      } else if (
        this.state.answerQuery == "null" &&
        this.state.media.name.length > 0
      ) {
        fd.append("mediaType", this.state.media_type);
        fd.append("media", this.state.media);
        fd.append("status", "closed");
        fd.append("_method", "post");
      }
      fd.append("question", questionData.text);
      fd.append("levelId", questionData.level_id);
      fd.append("subjectId", questionData.subject_id);
      fd.append("chapterId", questionData.chapter_id);
      fd.append("queryId", questionData.id);

      if (
        this.state.answerQuery == "null" &&
        this.state.media.name.length == 0
      ) {
        console.log("nothing selected");
      } else {
        axios
          .post(Constants.postUrls.createQuestion, fd, {
            onUploadProgress: (progressEvent) => {
              this.setState({
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
                mediaProgress: true,
              });
              // console.log(
              //   "Upload Progress" +
              //     Math.round((progressEvent.loaded / progressEvent.total) * 100) +
              //     "%"
              // );
            },
          })
          .then((resp) => {
            // console.log(resp);
            this.setState({
              questionId: resp.data.data.query_id,
              loading: false,
              show: true,
            });
            localStorage.removeItem("id");
            localStorage.removeItem("width");
            localStorage.removeItem("height");
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 1000);
          });
      }
    }
  };
  render() {
    setTimeout(() => {
      const videoElement = document.querySelector("video");
      const audioInputSelect = document.querySelector("select#audioSource");
      const audioOutputSelect = document.querySelector("select#audioOutput");
      const videoSelect = document.querySelector("select#videoSource");
      const selectors = [audioInputSelect, audioOutputSelect, videoSelect];
      if (audioOutputSelect) {
        audioOutputSelect.disabled = !("sinkId" in HTMLMediaElement.prototype);
      }

      function gotDevices(deviceInfos) {
        // Handles being called several times to update labels. Preserve values.
        const values = selectors.map((select) => select.value);
        selectors.forEach((select) => {
          while (select.firstChild) {
            select.removeChild(select.firstChild);
          }
        });
        for (let i = 0; i !== deviceInfos.length; ++i) {
          const deviceInfo = deviceInfos[i];
          const option = document.createElement("option");
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === "audioinput") {
            option.text =
              deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
            audioInputSelect.appendChild(option);
          } else if (deviceInfo.kind === "audiooutput") {
            option.text =
              deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
            audioOutputSelect.appendChild(option);
          } else if (deviceInfo.kind === "videoinput") {
            option.text =
              deviceInfo.label || `camera ${videoSelect.length + 1}`;
            videoSelect.appendChild(option);
          } else {
            console.log("Some other kind of source/device: ", deviceInfo);
          }
        }
        selectors.forEach((select, selectorIndex) => {
          if (
            Array.prototype.slice
              .call(select.childNodes)
              .some((n) => n.value === values[selectorIndex])
          ) {
            select.value = values[selectorIndex];
          }
        });
      }

      navigator.mediaDevices
        .enumerateDevices()
        .then(gotDevices)
        .catch(handleError);

      // Attach audio output device to video element using device/sink ID.
      function attachSinkId(element, sinkId) {
        if (typeof element.sinkId !== "undefined") {
          element
            .setSinkId(sinkId)
            .then(() => {
              console.log(`Success, audio output device attached: ${sinkId}`);
            })
            .catch((error) => {
              let errorMessage = error;
              if (error.name === "SecurityError") {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
              }
              console.error(errorMessage);
              // Jump back to first output device in the list as it's the default.
              audioOutputSelect.selectedIndex = 0;
            });
        } else {
          console.warn("Browser does not support output device selection.");
        }
      }

      function changeAudioDestination() {
        const audioDestination = audioOutputSelect.value;
        attachSinkId(videoElement, audioDestination);
      }

      // function gotStream(stream) {
      //   window.stream = stream; // make stream available to console
      //   videoElement.srcObject = stream;
      //   // Refresh button list in case labels have become available
      //   return navigator.mediaDevices.enumerateDevices();
      // }

      function handleError(error) {
        console.log(
          "navigator.MediaDevices.getUserMedia error: ",
          error.message,
          error.name
        );
      }

      async function start() {
        // if (window.stream) {
        //   window.stream.getTracks().forEach(track => {
        //     track.stop();
        //   });
        // }
        const audioSource = audioInputSelect.value;
        const videoSource = videoSelect.value;
        let hasEchoCancellation = document.querySelector("#echoCancellation")
          .checked;
        let constraints = {
          audio: {
            deviceId: audioSource ? { exact: audioSource } : undefined,
            echoCancellation: { exact: hasEchoCancellation },
          },
          video: {
            deviceId: videoSource ? { exact: videoSource } : undefined,
            width: localStorage.getItem("width"),
            height: localStorage.getItem("height"),
          },
        };

        console.log("Using media constraints:", constraints);
        await init(constraints);
        // navigator.mediaDevices
        //   .getUserMedia(constraints)
        //   .then(gotStream)
        //   .then(gotDevices)
        //   .catch(handleError);
      }
      if (audioInputSelect) {
        audioInputSelect.onchange = start;
      }
      if (audioOutputSelect) {
        audioOutputSelect.onchange = changeAudioDestination;
      }
      if (videoSelect) {
        videoSelect.onchange = start;
      }

      let mediaRecorder;
      let recordedBlobs;

      const errorMsgElement = document.querySelector("span#errorMsg");
      const recordedVideo = document.querySelector("video#recorded");
      const recordButton = document.querySelector("button#record");
      if (recordedVideo) {
        recordedVideo.style.display = "none";
      }
      if (recordButton) {
        recordButton.addEventListener("click", () => {
          if (recordButton.textContent === "START RECORDING") {
            startRecording();
          } else {
            stopRecording();
            recordButton.textContent = "START RECORDING";
            playButton.disabled = false;
            downloadButton.disabled = false;
            submitButton.disabled = false;
          }
        });
      }

      const playButton = document.querySelector("button#play");
      const gumVideo = document.querySelector("video#gum");
      if (playButton && gumVideo) {
        playButton.addEventListener("click", () => {
          if (playButton.textContent === "PLAY") {
            recordedVideo.style.display = "block";
            gumVideo.style.display = "none";
            playButton.textContent = "STOP";
            playButton.style.backgroundColor = "#F1C40F";
            console.log(recordedBlobs);
            const superBuffer = new Blob(recordedBlobs, { type: "video/webm" });

            console.log(window.URL.createObjectURL(superBuffer));
            recordedVideo.src = null;
            recordedVideo.srcObject = null;
            recordedVideo.src = window.URL.createObjectURL(superBuffer);
            recordedVideo.controls = true;
            recordedVideo.play();
          } else {
            playButton.textContent = "PLAY";
            playButton.style.backgroundColor = "#7DCEA0";
            recordedVideo.pause();
            // recordedVideo.src = null;
            // recordedVideo.srcObject = null;
            // recordedVideo.controls = false;
          }
        });
      }

      const downloadButton = document.querySelector("button#download");
      if (downloadButton) {
        downloadButton.addEventListener("click", () => {
          const blob = new Blob(recordedBlobs, { type: "video/webm" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "test.webm";
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 100);
        });
      }

      const submitButton = document.querySelector("button#submit");
      const sendProgress = document.querySelector("progress#sendProgress");
      var spa = document.querySelector("span#spa");
      if (submitButton && sendProgress && spa) {
        submitButton.addEventListener("click", () => {
          submitButton.textContent = "UPLOADING... PLEASE WAIT";
          const blob = new Blob(recordedBlobs, { type: "video/webm" });
          console.log("blob", blob);
          var reader = new window.FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function() {
            let base64 = reader.result.split(",")[1];
            // console.log(base64);
            let fd = new FormData();
            fd.append("record_video", base64);
            fd.append("status", "closed");
            fd.append("_method", "put");
            // let payLoad = {
            //   record_video: base64,
            //   status: "closed",
            //   _method: "put"
            // };
            axios
              .post(
                Constants.updateUrls.updateAnswers +
                  localStorage.getItem("questionId"),
                fd,
                {
                  onUploadProgress: (progressEvent) => {
                    sendProgress.value += Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    );
                    spa.innerHTML = sendProgress.value + "%";
                  },
                }
              )
              .then((resp) => {
                console.log(resp);
                localStorage.removeItem("id");
                localStorage.removeItem("width");
                localStorage.removeItem("height");
                submitButton.textContent = "UPLOADED SUCCESSFULLY";
                setTimeout(() => {
                  window.location.href = "/admin/queries";
                }, 2000);
              });
          };
        });
      }

      function handleDataAvailable(event) {
        console.log("handleDataAvailable", event);
        if (event.data && event.data.size > 0) {
          console.log(event.data);
          recordedBlobs.push(event.data);
        }
      }

      var minutesLabel = document.getElementById("minutes");
      var secondsLabel = document.getElementById("seconds");
      var totalSeconds = 0;

      function setTime() {
        ++totalSeconds;
        if (minutesLabel && secondsLabel) {
          secondsLabel.innerHTML = pad(totalSeconds % 60);
          minutesLabel.innerHTML = pad(parseInt(totalSeconds / 60));
        }
      }

      function pad(val) {
        var valString = val + "";
        if (valString.length < 2) {
          return "0" + valString;
        } else {
          return valString;
        }
      }
      var interval;
      function startRecording() {
        interval = setInterval(setTime, 1000);
        recordedBlobs = [];
        let options = { mimeType: "video/webm;codecs=vp9,opus" };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.error(`${options.mimeType} is not supported`);
          options = { mimeType: "video/webm;codecs=vp8,opus" };
          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.error(`${options.mimeType} is not supported`);
            options = { mimeType: "video/webm" };
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
              console.error(`${options.mimeType} is not supported`);
              options = { mimeType: "" };
            }
          }
        }

        try {
          console.log(window.stream);
          mediaRecorder = new MediaRecorder(window.stream, options);
        } catch (e) {
          console.error("Exception while creating MediaRecorder:", e);
          errorMsgElement.innerHTML = `Exception while creating MediaRecorder: ${JSON.stringify(
            e
          )}`;
          return;
        }

        console.log(
          "Created MediaRecorder",
          mediaRecorder,
          "with options",
          options
        );
        recordButton.textContent = "STOP RECORDING";
        playButton.disabled = true;
        downloadButton.disabled = true;
        mediaRecorder.onstop = (event) => {
          console.log("Recorder stopped: ", event);
          console.log("Recorded Blobs: ", recordedBlobs);
        };
        mediaRecorder.ondataavailable = handleDataAvailable;
        mediaRecorder.start();
        console.log("MediaRecorder started", mediaRecorder);
      }

      function stopRecording() {
        mediaRecorder.stop();
        clearInterval(interval);
      }

      function handleSuccess(stream) {
        recordButton.disabled = false;
        console.log("getUserMedia() got stream:", stream);
        window.stream = stream;

        const gumVideo = document.querySelector("video#gum");
        gumVideo.srcObject = stream;
      }

      async function init(constraints) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          handleSuccess(stream);
        } catch (e) {
          console.error("navigator.getUserMedia error:", e);
          errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
        }
      }

      const startButton = document.querySelector("button#start");
      if (startButton) {
        startButton.addEventListener("click", async () => {
          let hasEchoCancellation = document.querySelector("#echoCancellation")
            .checked;
          let constraints = {
            audio: {
              echoCancellation: { exact: hasEchoCancellation },
            },
            video: {
              width: localStorage.getItem("width"),
              height: localStorage.getItem("height"),
            },
          };
          console.log("Using media constraints:", constraints);
          await init(constraints);
        });
      }
    }, 1000);
    if (this.state.redirect) {
      return <Redirect to={"/admin/queries"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.show ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Answer Submitted Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Answer A Query</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <label htmlFor="">Query</label>
                        <br />
                        <br />
                        <MathJax math={this.state.question} />
                        <br />
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ marginLeft: "-15px" }}
                        >
                          <label>Answer</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={this.state.answerQuery}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                answerQuery: data,
                                specificationAlert: false,
                              });
                            }}
                          />
                        </GridItem>
                        <br />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Media</label>
                        <br />
                        <input
                          id="File"
                          name="media"
                          onChange={this.handleFile}
                          accept=".mov, .pdf, .jpeg, .png, .jpg, .mp4, .mkv"
                          type="file"
                        />
                        {/* <br />
                        <br />
                        <a href={this.state.media}>View File</a> */}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          onClick={() =>
                            this.setState({
                              camera: !this.state.camera,
                              showSubmit: !this.state.showSubmit,
                            })
                          }
                          color="warning"
                        >
                          {this.state.camera ? "Hide" : "Show"} Setup
                        </Button>
                        {this.state.showSubmit ? (
                          <Button className="" color="success" type="submit">
                            {this.state.loading ? <Loader /> : "Submit"}
                          </Button>
                        ) : null}

                        <Link
                          to={{
                            pathname: "/admin/queries",
                          }}
                        >
                          <Button
                            onClick={() => {
                              localStorage.removeItem("id");
                              localStorage.removeItem("width");
                              localStorage.removeItem("height");
                            }}
                            className="btn-round"
                            color="danger"
                          >
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                  {this.state.camera ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <br />
                      <div className="select">
                        <label htmlFor="audioSource">
                          Audio input source:{" "}
                        </label>
                        <select id="audioSource"></select>
                      </div>
                      <br />
                      <div className="select">
                        <label htmlFor="audioOutput">
                          Audio output destination:{" "}
                        </label>
                        <select id="audioOutput"></select>
                      </div>
                      <br />
                      <div className="select">
                        <label htmlFor="videoSource">Video source: </label>
                        <select id="videoSource"></select>
                      </div>
                      <br />
                      <div className="select">
                        <label>Video Resolution: </label>
                        <select
                          value={this.state.selectedResolution}
                          onChange={(e) => {
                            console.log(e.target.value);
                            console.log(this.state.resolutions);
                            let obj = this.state.resolutions.find(
                              (v) => +v?.id === +e.target.value
                            );
                            localStorage.setItem("id", obj?.id);
                            localStorage.setItem("width", obj?.width);
                            localStorage.setItem("height", obj?.height);
                            window.location.reload();
                          }}
                        >
                          {this.state.resolutions.map((v, i) => (
                            <option
                              key={i}
                              value={v.id}
                            >{`${v.width} x ${v.height}`}</option>
                          ))}
                        </select>
                      </div>
                      {/* <video id="video" playsinline autoPlay></video> */}
                      <div style={{ textAlign: "center" }}>
                        <video
                          style={{
                            width: "70%",
                            // height: "400px",
                            padding: "8px",
                          }}
                          id="gum"
                          playsInline
                          autoPlay
                          muted
                        ></video>
                        <video
                          style={{
                            width: "70%",
                            marginLeft: "15%",
                            // height: "400px",
                            padding: "8px",
                          }}
                          id="recorded"
                          playsInline
                          loop
                        ></video>
                      </div>
                      <label>Upload Progress :</label>
                      <br />
                      <progress
                        id="sendProgress"
                        max={100}
                        value={0}
                        style={{ width: "40%", height: "24px" }}
                      ></progress>
                      <span
                        style={{
                          position: "relative",
                          left: "2px",
                          bottom: "4px",
                        }}
                        id="spa"
                      ></span>
                      <br />
                      <label>Video Length</label>
                      <br />
                      <div>
                        <span id="minutes">00</span>:
                        <span id="seconds">00</span>
                      </div>
                      <div>
                        <button
                          style={{
                            padding: "12px 24px",
                            margin: "4px",
                            color: "white",
                            backgroundColor: "#2ECC71",
                            border: "none",
                            cursor: "pointer",
                            borderRadius: "2px",
                          }}
                          id="start"
                        >
                          START CAMERA
                        </button>
                        <button
                          style={{
                            padding: "12px 24px",
                            margin: "4px",
                            color: "white",
                            cursor: "pointer",
                            backgroundColor: this.state.recordingDisabled
                              ? "#EF593B"
                              : "#FC4C2D",
                            border: "none",
                            borderRadius: "2px",
                          }}
                          id="record"
                          disabled
                        >
                          START RECORDING
                        </button>
                        <button
                          style={{
                            padding: "12px 24px",
                            margin: "4px",
                            cursor: "pointer",
                            color: "white",
                            backgroundColor: "#7DCEA0",
                            border: "none",
                            borderRadius: "2px",
                          }}
                          id="play"
                          disabled
                        >
                          PLAY
                        </button>
                        <button
                          style={{
                            padding: "12px 24px",
                            margin: "4px",
                            color: "white",
                            cursor: "pointer",
                            backgroundColor: this.state.downloadDisabled
                              ? "#EF593B"
                              : "#FC4C2D",
                            border: "none",
                            borderRadius: "2px",
                          }}
                          id="download"
                          disabled
                        >
                          DOWNLOAD
                        </button>
                        <button
                          style={{
                            padding: "12px 24px",
                            margin: "4px",
                            color: "white",
                            backgroundColor: "#2ECC71",
                            border: "none",
                            cursor: "pointer",
                            borderRadius: "2px",
                          }}
                          id="submit"
                          disabled
                        >
                          SUBMIT
                        </button>
                        <button
                          style={{
                            padding: "12px 24px",
                            margin: "4px",
                            color: "white",
                            cursor: "pointer",
                            backgroundColor: "#7FB3D5",
                            border: "none",
                            borderRadius: "2px",
                          }}
                          onClick={() => window.location.reload()}
                        >
                          RERECORD
                        </button>
                      </div>
                      <div>
                        <h4>Media Stream Constraints options</h4>
                        <p>
                          Echo cancellation:{" "}
                          <input type="checkbox" id="echoCancellation" />
                        </p>
                      </div>
                      <div>
                        <span id="errorMsg"></span>
                      </div>
                    </GridItem>
                  ) : null}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AnswerQuery;
