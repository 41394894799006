import React, { Component } from "react";
import "../../scss/CourseDetailResp.scss";
import subject_bg from "../../images/subject_bg.jpg";

import ak_classes_book from "../../images/ak_classes_book.png";
import pdf from "../../images/pdf.png";
import axios from "axios";
import Constants from "../common/Constants";

import { Link } from "react-router-dom";
import YoutubeEmbed from "../YoutubeEmbed";
import init from "../helper/WindowToken";
import renderHTML from "react-render-html";
import loader from "../../images/97739-loader.json";
import Lottie from "react-lottie";
export default class CourseDetailResp extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    answer: false,
    attemptType: window.location.pathname.split("/")[5],
    batch_id: window.location.pathname.split("/")[5],
    subject_id: window.location.pathname.split("/")[4],
    course_id: window.location.pathname.split("/")[6],
    batch_wings_id: window.location.pathname.split("/")[7],
    subjectDetails: "",
    courseDetails: "",
    variantDetails: [],
    showBolck: true,
    showDemo: true,
    variants: [],

    // for variant state
    oss: [],
    views: [],
    medias: [],
    osType: [],
    mediaType: [],
    viewType: [],
    variant: [],
    courseDetails: [],
    variant_id: "",
    offerPrice: "",
    base_offerPrice: "",
    offPrice: 0,
    isLoading: true,
    userDetails: [],
    about: "",
    data: {
      faculty_detail: {
        about_faculty: "",
      },
    },
  };
  componentDidMount() {
    // this.userData();
    console.log(this.props);
    this.getProductDetails();
    this.getUserInfo();
  }

  // userData = () =>{
  //   let token = localStorage.getItem("token");
  //   // console.log(init());
  //   // if (init() === "success") {
  //     console.log(token);
  //     axios.get(Constants.getUrls.user).then((resUser) => {
  //       console.log("user deatail", resUser);
  //       this.setState(
  //         {
  //           userDetails: resUser.data,
  //           login: true,
  //         },
  //         () => {
  //           console.log(this.state.userDetails);
  //         }
  //       );
  //     });
  //   // }
  // }
  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        console.log(res.data);
        this.setState({ userDetails: res.data }, () => {
          console.log(this.state.userInfo);
        });
      });
    }
  };

  getProductDetails = () => {
    axios
      .get(
        Constants.getUrls.productDetails +
          "?batch_id=" +
          this.state.batch_id +
          "&subject_id=" +
          this.state.subject_id +
          "&course_id=" +
          this.state.course_id +
          "&batch_wings_id=" +
          this.state.batch_wings_id
      )
      .then((resp) => {
        // console.log(resp);
        this.setState(
          {
            offPrice: resp.data.data.course.VALUE,
            subjectDetails: resp.data.data,
            courseDetails: resp.data.data.course,
            variantDetails: resp.data.data.course.variant,
            isLoading: false,
          },
          () => {
            this.setState({
              about: this.state.courseDetails?.faculties?.about_us,
            });
            console.log(this.state.subjectDetails);
            console.log(this.state.courseDetails);
          }
        );
        this.getActiveVariants();
      })
      .catch((err) => {
        if (err) {
          console.log(err.respones);
        }
      });
  };

  getActiveVariants = () => {
    axios
      .get(Constants.getUrls.getCoursesActiveVarient + this.state.course_id)
      .then((respVarient) => {
        console.log("variants", respVarient);
        this.setState({
          variants: respVarient.data.product.variant,
        });

        let VarArray = respVarient.data.product.variant.filter(
          (variant) =>
            variant.status === "active" || variant.status === "Active"
        );
        console.log(VarArray);
        if (respVarient.data.product.TYPE === "COURSES") {
          let oss = [];
          let views = [];
          let medias = [];
          if (VarArray ? VarArray?.length > 0 : "") {
            let osidOfZero = VarArray[0].os_type_id;
            VarArray.forEach((item) => {
              if (oss ? oss?.length > 0 : "") {
                const findOS = oss.find((os) => os.id === item.os_type_id);

                if (!findOS) {
                  oss.push(item.os_type);
                }
              } else {
                oss.push(item.os_type);
              }
              if (item.os_type_id === osidOfZero) {
                if (medias ? medias?.length > 0 : "") {
                  const findMedia = medias.find(
                    (media) => media.id === item.media_type_id
                  );
                  if (!findMedia) {
                    medias.push(item.media_type);
                  }
                } else {
                  medias.push(item.media_type);
                }
              }
              // console.log("medias", medias);
              if (medias ? medias?.length > 0 : "") {
                if (
                  item.os_type_id === osidOfZero &&
                  medias[0].id === item.media_type_id
                ) {
                  if (views ? views?.length > 0 : "") {
                    const findViews = views.find(
                      (view) => view.id === item.view_and_validity_id
                    );
                    if (!findViews) {
                      views.push(item.view_and_validity);
                    }
                  } else {
                    views.push(item.view_and_validity);
                  }
                }
              }
            });

            this.setState(
              {
                oss,
                views,
                medias,
                osType: osidOfZero,
                mediaType: respVarient.data.product.variant[0].media_type_id,
                viewType:
                  respVarient.data.product.variant[0].view_and_validity_id,
                variant: respVarient.data.product.variant,
                variant_id: respVarient.data.product.variant[0].id,
                price: respVarient.data.product.variant[0].price,
                // offerPrice: respVarient.data.product.variant[0].price,
                totalAmt:
                  respVarient.data.product.variant[0].price -
                  respVarient.data.product.VALUE,
                base_offerPriceVariant:
                  respVarient.data.product.variant[0].price,
              },
              () => {
                console.log("price", this.state.price);
                this.setState(
                  {
                    mainPrice: this.state.price - this.state.offPrice,
                  },
                  () => {
                    console.log("main", this.state.mainPrice);
                  }
                );
              }
            );
          }
        }
      });
  };

  handleOS = (e) => {
    // console.log(e);
    this.setState(
      {
        osType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        console.log(this.state.osType);

        // let oss = [];
        let views = [];
        let medias = [];
        if (this.state.variant ? this.state.variant?.length > 0 : "") {
          this.state.variant.forEach((item) => {
            console.log(item.os_type_id, this.state.osType);
            if (item.os_type_id === parseInt(this.state.osType)) {
              if (medias ? medias?.length > 0 : "") {
                const findMedia = medias.find(
                  (media) => media.id === item.media_type_id
                );
                if (!findMedia) {
                  medias.push(item.media_type);
                }
              } else {
                medias.push(item.media_type);
              }
            }
            // console.log(medias);
            if (medias ? medias?.length > 0 : "") {
              if (
                item.os_type_id === parseInt(this.state.osType) &&
                item.media_type_id === medias[0].id
              ) {
                if (views ? views?.length > 0 : "") {
                  const findViews = views.find(
                    (view) => view.id === item.view_and_validity_id
                  );
                  if (!findViews) {
                    views.push(item.view_and_validity);
                  }
                } else {
                  views.push(item.view_and_validity);
                }
              }
            }
          });
          console.log("views", views);
          console.log("medias", medias);
          const selectedArray = this.state.variant.filter(
            (item) => item.os_type_id === parseInt(this.state.osType)
          );
          this.setState(
            {
              // oss,
              views,
              medias,
              // osType: osidOfZero,
              mediaType: selectedArray[0].media_type_id,
              viewType: selectedArray[0].view_and_validity_id,
              variant_id: selectedArray[0].id,
              price: selectedArray[0].price,
              offerPrice: selectedArray[0].offer_price,
              base_offerPrice: selectedArray[0].offer_price,
            },
            () => {
              console.log(this.state.price, this.state.offPrice);
              this.setState(
                {
                  mainPrice: this.state.price - this.state.offPrice,
                },
                () => {
                  console.log(this.state.mainPrice);
                }
              );
            }
          );
        }
      }
    );
  };

  handleMedia = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        mediaType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        let views = [];
        if (this.state.variant ? this.state.variant?.length > 0 : "") {
          this.state.variant.forEach((item) => {
            // console.log(item.os_type_id, this.state.osType);
            if (
              item.os_type_id === parseInt(this.state.osType) &&
              item.media_type_id === parseInt(this.state.mediaType)
            ) {
              if (views ? views?.length > 0 : "") {
                const findViews = views.find(
                  (view) => view.id === item.view_and_validity_id
                );
                if (!findViews) {
                  views.push(item.view_and_validity);
                }
              } else {
                views.push(item.view_and_validity);
              }
            }
          });
          console.log("views", views);
          const selectedArray = this.state.variant.filter(
            (item) =>
              item.os_type_id === parseInt(this.state.osType) &&
              item.media_type_id === parseInt(this.state.mediaType)
          );
          this.setState(
            {
              // oss,
              views,
              // medias,
              // osType: osidOfZero,
              // mediaType: selectedArray[0].media_type_id,
              viewType: selectedArray[0].view_and_validity_id,
              variant_id: selectedArray[0].id,
              price: selectedArray[0].price,
              offerPrice: selectedArray[0].offer_price,
              base_offerPrice: selectedArray[0].offer_price,
            },
            () => {
              console.log(this.state.price, this.state.offPrice);
              this.setState(
                {
                  mainPrice: this.state.price - this.state.offPrice,
                },
                () => {
                  console.log(this.state.mainPrice);
                }
              );
            }
          );
        }
      }
    );
  };

  handleViews = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        viewType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        const selectedObject = this.state.variant.find(
          (item) =>
            item.os_type_id === parseInt(this.state.osType) &&
            item.media_type_id === parseInt(this.state.mediaType) &&
            item.view_and_validity_id === parseInt(this.state.viewType)
        );
        console.log(selectedObject);
        this.setState(
          {
            variant_id: selectedObject.id,
            price: selectedObject.price,
            offerPrice: selectedObject.offer_price,
            base_offerPrice: selectedObject.offer_price,
          },
          () => {
            console.log(this.state.price, this.state.offPrice);
            this.setState(
              {
                mainPrice: this.state.price - this.state.offPrice,
              },
              () => {
                console.log(this.state.mainPrice);
              }
            );
          }
        );
      }
    );
  };

  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  download(id, filepath) {
    axios
      .get(
        "https://admin.akclasses.in/api/download_question_pdf/" +
          80 +
          "?file=" +
          filepath
      )
      .then((res) => {
        console.log(res);
      });
  }

  render() {
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        {this.state.isLoading ? (
          <div style={{ marginTop: 100 }}>
            <Lottie
              options={defaultLoader}
              height={80}
              width={80}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}
            />
          </div>
        ) : (
          <>
            <section className="section1" style={{ height: "85px" }}>
              <h1 className="fonts">Course Details</h1>

              <h5 className="fonts titleName">
                {this.state.courseDetails?.TITLE}
              </h5>
            </section>

            <section>
              <div className="container-fluid">
                <div className="row attemptCourse2">
                  {/* {this.state.subjectDetails.length > 0 ? ( */}
                  {/* {this.state.subjectDetails.map((detail) => ( */}
                  <div className="col-md-12 leftCourseLand">
                    <div className="col-md-8">
                      <div className="row attemptCourseDetail">
                        <span>
                          <span className="showing">
                            Details of{" "}
                            <span
                              className="jun"
                              style={{
                                color: this.state.subjectDetails?.batch?.color,
                              }}
                            >
                              {/* Business Mathematics Statistics & LR */}
                              {this.state.courseDetails?.TITLE}
                            </span>{" "}
                          </span>
                        </span>
                      </div>
                      <div className="row attemptCourseDetail2">
                        <div className="col-xs-6">
                          <span className="showingDetail">
                            {" "}
                            You have opted:
                          </span>
                        </div>
                        <div className="col-xs-6">
                          <button
                            className="batchButtonDetail"
                            style={{
                              backgroundColor:
                                this.state.subjectDetails?.batch?.color,
                            }}
                          >
                            {this.state.subjectDetails?.batch?.batch_name}
                          </button>
                        </div>
                      </div>
                      <div className="row attemptCourseDetail2">
                        <div className="col-xs-6">
                          <span className="showingDetail"> for attempt:</span>
                        </div>
                        <div className="col-xs-6">
                          <span
                            style={{
                              color: this.state.subjectDetails?.batch?.color,
                              fontWeight: "bold",
                              marginTop: "12px",
                              display: "flex",
                            }}
                          >
                            {this.state.subjectDetails?.batch?.attempt?.title}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 rightSide">
                        <div>
                          <span>
                            <button
                              className={
                                this.state.showDemo
                                  ? "blueBtnDetail"
                                  : "blueBtnDetail1"
                              }
                              onClick={() => {
                                this.setState({ showDemo: true });
                              }}
                            >
                              About the Course
                            </button>
                            <button
                              className={
                                this.state.showDemo
                                  ? "grayBtnDetail"
                                  : "grayBtnDetail1"
                              }
                              onClick={() => {
                                this.setState({ showDemo: false });
                              }}
                            >
                              Watch Demo
                            </button>
                          </span>
                        </div>
                        <div className="variants">
                          <div className="vidArea">
                            {this.state.showDemo ? (
                              <YoutubeEmbed
                                embedId={this.state.courseDetails?.DEMO_LINK}
                              />
                            ) : (
                              <YoutubeEmbed
                                embedId={this.state.courseDetails?.DEMO_LINK1}
                              />
                            )}
                          </div>
                          <div
                            className="col-md-12 ContentBelowYT"
                            style={{ paddingBottom: "7px", paddingTop: "5px" }}
                          >
                            {/* {this.state.variantDetails.map((var)=>())} */}
                            <div className="col-md-3 deletePrice col-xs-3">
                              <del style={{ color: "red" }}>
                                Rs.{this.state.price}
                              </del>
                            </div>
                            <div className="col-md-6 offPrice  col-xs-6">
                              Rs.{this.state.offPrice} Off
                            </div>
                            <div className="col-md-3 rupeeColor  col-xs-3">
                              Rs.{this.state.mainPrice}
                            </div>
                          </div>
                          <div>
                            <h5 className="pricesNote">
                              *Prices Inclusive of all taxes
                            </h5>
                          </div>
                          <div className="oprSystem">
                            <h4>Operating Systems</h4>
                            <div>
                              {this.state.oss.map((os, index) => (
                                <span
                                  className="varName"
                                  style={{ marginRight: 0 }}
                                >
                                  <input
                                    type="radio"
                                    value={os.id}
                                    checked={
                                      os.id === this.state.osType ? true : false
                                    }
                                    onClick={() => this.handleOS(os.id)}
                                  />{" "}
                                  {os.os_type}
                                </span>
                              ))}
                            </div>
                          </div>

                          <div className="oprSystem">
                            <h4>Media</h4>
                            <div>
                              {this.state.medias.map((media, index) => (
                                <span
                                  className="varName"
                                  style={{ marginRight: 0 }}
                                >
                                  <input
                                    type="radio"
                                    value={media.id}
                                    checked={
                                      media.id === this.state.mediaType
                                        ? true
                                        : false
                                    }
                                    onClick={() => this.handleMedia(media.id)}
                                  />{" "}
                                  {media.media_type}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="oprSystem">
                            <h4>Views & Validity</h4>
                            <div>
                              {this.state.views.map((view, index) => (
                                <span
                                  className="nineMonths varName"
                                  style={{ marginRight: 0 }}
                                >
                                  <input
                                    type="radio"
                                    value={view.id}
                                    checked={
                                      view.id === this.state.viewType
                                        ? true
                                        : false
                                    }
                                    onClick={() => this.handleViews(view.id)}
                                  />{" "}
                                  {view.view_and_validity}
                                </span>
                              ))}
                            </div>
                          </div>

                          {/* <div className="oprSystem">
                      <h4>Views</h4>
                      <div>
                        <span>
                          <span>
                            <input type="radio" checked />
                          </span>
                          <span className="varName">Unlimited</span>
                        </span>
                      </div>
                    </div> */}

                          {/* <div className="oprSystem">
                      <h4>Validity</h4>
                      <div>
                        <span>
                          <span>
                            <input type="radio" />
                          </span>
                          <span className="varName">9 Months</span>
                          <span>
                            <input type="radio" />
                          </span>
                          <span className="varName">12 Months</span>
                        </span>
                      </div>
                    </div> */}

                          <div className="buyBtnArea">
                            <Link
                              to="/checkout"
                              state={{
                                coupon: this.state.coupon_code
                                  ? this.state.coupon_code
                                  : "",
                                batchId: this.state.batch_id,
                                batchWingId: this.state.batch_wings_id,
                                base_total: this.state.totalAmt,
                                coupon_amount: this.state.coupon,
                                grand_total: this.state.coupon_code
                                  ? this.state.totalAmt - this.state.coupon
                                  : this.state.totalAmt,
                                sku: this.state.courseDetails?.SKU,
                                product_details: {
                                  TITLE: this.state.courseDetails?.TITLE,
                                  product_id: this.state.course_id,
                                  quantity: 1,
                                  type: "COURSE",
                                  variant_id: this.state.variant_id,
                                  os_type_id: this.state.osType,
                                  media_type_id: this.state.mediaType,
                                  attempt: this.state.attemptType,
                                  view_and_validity_id: this.state.mediaType,
                                },
                                product: this.state.data,
                                userDetails: this.state.userDetails,
                              }}
                              onClick={() =>
                                this.setState(
                                  {
                                    base_total: this.state.offerPrice,
                                  },
                                  () => {
                                    console.log(this.state.base_total);
                                  }
                                )
                              }
                            >
                              <button className="buyCourseBtn">
                                Buy this Course
                              </button>
                            </Link>
                          </div>

                          <div className="applyBtnArea">
                            <span className="applyCoupon">Apply Coupon</span>
                          </div>
                        </div>
                      </div>

                      <div className="row attemptCourseDetail3">
                        <img
                          src={
                            Constants.imgUrl +
                            "/" +
                            this.state.subjectDetails?.course?.THUMB_IMAGE_PATH
                          }
                          alt=""
                          width={"100%"}
                          height={"100%"}
                        />
                        {/* <div>
                      <h3
                        style={{
                          paddingLeft: "30px",
                          fontFamily: "'Raleway', sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        What you will get
                      </h3>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-5">
                        <img
                          src={ak_classes_book}
                          alt=""
                          className="akBookImg"
                        />
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-6" style={{ marginTop: '10px' }}>
                        <ul style={{ listStyle: "none" }}>
                          <li className="flex">
                            <span>&#10004; &nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span>
                              {" "}
                              <div className="fonts">
                                AK Classes with it's unique and simple learning
                                methodologies, thousands of
                              </div>{" "}
                            </span>
                          </li>
                          <li className="flex">
                            <span>&#10004; &nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span>
                              {" "}
                              <div className="fonts">
                                AK Classes with it's unique and simple learning
                                methodologies, thousands of
                              </div>{" "}
                            </span>
                          </li>
                          <li className="flex">
                            <span>&#10004; &nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span>
                              {" "}
                              <div className="fonts">
                                AK Classes with it's unique and simple learning
                                methodologies, thousands of AK Classes with it's
                                unique and simple learning methodologies,
                                thousands of
                              </div>{" "}
                            </span>
                          </li>
                          <li className="flex">
                            <span>&#10004; &nbsp;</span>
                            <span>
                              {" "}
                              <div>AK Classes with it's unique</div>{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                      </div>

                      <div className="row attemptCourseDetail4">
                        <ul className="activate">
                          <li className="font">
                            Students can activate course instantly after
                            purchase by login to their dashboard/profile on
                            AKClasses Website.{" "}
                            <span className="activationProcess">
                              Click here to know About Activation Process.
                            </span>{" "}
                          </li>
                          <li className="font">
                            Books will be delivered within 5 to 6 days after
                            order completion.
                          </li>
                        </ul>
                      </div>

                      <div className="row attemptCourseDetail5">
                        <h3 className="systemReq">System Requirements:</h3>
                        <div className="col-md-12">
                          <div className="col-md-7">
                            <ul className="activate2">
                              <li className="font2">
                                CPU With 2GB Ram, Windows 7/8/9/10{" "}
                              </li>
                              <li className="font2">
                                Android with version 6 and above
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <div
                              className="downloadArea"
                              // target="_blank"
                              // onClick={() => {
                              //   this.download(
                              //     this.state.course_id,
                              //     this.state.courseDetails.SYLLABUS_PDF
                              //   );
                              // }}
                            >
                              <a
                                href={
                                  "https://admin.akclasses.in/api/download_question_pdf/ " +
                                  this.state.course_id +
                                  "?file=" +
                                  this.state.courseDetails.SYLLABUS_PDF
                                }
                                style={{ textDecoration: "none" }}
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => {
                                  this.download(
                                    this.state.course_id,
                                    this.state.courseDetails.SYLLABUS_PDF
                                  );
                                }}
                              >
                                <span>
                                  <span>
                                    <img src={pdf} alt="" className="pdfImg" />
                                  </span>
                                  <span className="lectureIndex">
                                    Download Lecture Index
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row attemptCourseDetail6">
                        <span>
                          <span>
                            <button
                              className={
                                this.state.showBolck
                                  ? "classroomBenefits"
                                  : "aboutFac"
                              }
                              onClick={() => {
                                this.setState({ showBolck: true });
                              }}
                            >
                              Smart Classroom Benefits
                            </button>
                          </span>
                          <span>
                            <button
                              className={
                                this.state.showBolck
                                  ? "aboutFac"
                                  : "classroomBenefits"
                              }
                              onClick={() => {
                                this.setState({ showBolck: false });
                              }}
                            >
                              About the Faculty
                            </button>
                          </span>
                        </span>
                      </div>
                      {this.state.showBolck ? (
                        <div className="row attemptCourseDetail7">
                          <h4 className="systemReq2">
                            Your preparation is our responsibility is the motto
                            of Smart Classroom
                          </h4>
                          <div style={{ paddingTop: "20px", width: "90%" }}>
                            <ul style={{ listStyle: "none" }}>
                              <li className="flexSmart">
                                {/* <span>&#10004; &nbsp;</span> */}
                                <i
                                  class="fa-solid fa-check "
                                  style={{
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                  }}
                                ></i>
                                <span>
                                  {" "}
                                  <div className="font">
                                    You will get daily targets for the subject
                                    preparation which will help you be on track
                                    till the exam.
                                  </div>{" "}
                                </span>
                              </li>
                              <li className="flexSmart">
                                {/* <span>&#10004; &nbsp;</span> */}
                                <i
                                  class="fa-solid fa-check "
                                  style={{
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                  }}
                                ></i>
                                <span>
                                  {" "}
                                  <div className="font">
                                    You will get daily targets for the subject
                                    preparation which will help you be on track
                                    till the exam.
                                  </div>{" "}
                                </span>
                              </li>
                              <li className="flexSmart">
                                {/* <span>&#10004; &nbsp;&nbsp;&nbsp;&nbsp;</span> */}
                                <i
                                  class="fa-solid fa-check "
                                  style={{
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                  }}
                                ></i>
                                <span>
                                  {" "}
                                  <div className="font">
                                    You will get daily targets for the subject
                                    preparation which will help you be on track
                                    till the exam. You will get daily targets
                                    for the subject preparation which will help
                                    you be on track till the exam.
                                  </div>{" "}
                                </span>
                              </li>
                              <li className="flexSmart">
                                {/* <span>&#10004; &nbsp;</span> */}
                                <i
                                  class="fa-solid fa-check "
                                  style={{
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                  }}
                                ></i>
                                <span>
                                  {" "}
                                  <div className="font">
                                    You will get daily targets for the subject
                                    preparation which will help you be on track
                                    till the exam.
                                  </div>{" "}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.about?.length > 0 ? (
                            <div className="row attemptCourseDetail7">
                              <div>
                                <img
                                  src={
                                    Constants.imgUrl +
                                    this.state.courseDetails?.faculties?.image
                                  }
                                  width="150px"
                                  height="180px"
                                />
                                <span style={{ top: "40%" }}>
                                  {this.state.courseDetails?.faculties?.name}
                                </span>
                              </div>
                              <div style={{ padding: "10px" }}>
                                <p>
                                  {/* {renderHTML( this.state.about)} */}
                                  {renderHTML(this.state.about)}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <p style={{ textAlign: "left", paddingTop: 10 }}>
                              {" "}
                              Data not found{" "}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* ))} */}
                  {/* ) : (
                <div
                  className="col-md-4 regiBatches"
                  style={{
                    textAlign: "center",
                    marginTop: "20%",
                    marginLeft: "20%",
                    fontSize: "24px",
                    fontFamily: "raleway",
                  }}
                >
                  Product Details not available.
                </div>
              )}  */}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    );
  }
}
