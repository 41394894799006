import React, { Component } from "react";
import Table from "components/Table/Table.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/AppConstants";
const classes = makeStyles(styles);

class DeviceInfo extends Component {
  state = {
    devices: [],
    loader: true,
    noData: false,
    status: false
  };

  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/getDeviceInfo")
      .then((resp) => {
        console.log(resp);
        this.setState({
          loader: false,
          devices: resp.data.data.data
        });
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
        this.setState({
          loader: false,
          noData: true
        });
      });
  }
//   handleStatus = (e, id) => {
//     this.setState(
//       {
//         [e.target.name]: e.target.value
//       },
//       () => {
//         const statusPayload = {
//           active: this.state.status === "false" ? false : true
//         };
//         axios
//           .put(Constants.updateUrls.updateLevels + id, statusPayload)
//           .then(() => {
//             axios.get(Constants.getUrls.devices + "all=true").then(resp => {
//               this.setState({
//                 devices: resp.data.devices
//               });
//             });
//             // window.location.reload();
//           });
//       }
//     );
//   };

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Device Information</h3>
                {/* <Link to="/admin/addlevel">
                  <Button className="" color="warning">
                    Add Level
                  </Button>
                </Link> */}
                <Table
                  tableHead={[
                    "Sr No.", 
                    "Course Name", 
                    "OS & Ip Address",
                    "Address", 
                    "Date",
                    "Time",
                    ]}
                  tableData={this.state.devices.map((sec, index) => [
                    [index + 1],
                    [sec.courses.TITLE],
                    [sec.ip_address],
                    [sec.device_name],
                    [sec.date],
                    [sec.time],
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.devices.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default DeviceInfo;
