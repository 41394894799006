/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
const classes = makeStyles(styles);
class AddAppFaculty extends Component {
  state = {
    name: "",
    email: "",
    levelId: "",
    subjectId: "",
    mobile: "",
    password: "",
    confirm_password: "",
    redirect: false,
    show: false,
    loading: false,
    filteredSubjects: [],
    filteredChapters: [],
    levels: [],
    subjects: [],
    passwordMismatch: false,
    minLength: false
  };
  componentDidMount() {
    axios.get(Constants.getUrls.levels).then(resp => {
      // console.log(resp);

      this.setState({
        levels: resp.data.levels
      });
      // console.log(this.state.levels);
    });
  }
  handleLevel = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const singleLevel = this.state.levels.find(
          level => level.id === this.state.levelId
        );
        // console.log(singleLevel);
        this.setState({
          filteredSubjects: singleLevel.subjects
        });
      }
    );
  };
  minLengthCheck = e => {
    if (e.target.value.length < e.target.minLength) {
      this.setState({
        minLength: true
      });
    } else {
      this.setState({
        minLength: false
      });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    let payLoad = {
      name: this.state.name,
      role: "faculty",
      email: this.state.email,
      level_id: parseInt(this.state.levelId),
      subject_id: parseInt(this.state.subjectId),
      mobile_no: parseInt(this.state.mobile),
      password: this.state.password
    };
    if (this.state.password !== this.state.confirm_password) {
      this.setState({
        passwordMismatch: true,
        loading: false
      });
    } else {
      axios.post(Constants.postUrls.facultyCreate, payLoad).then(() => {
        // console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 2000);
        this.setState({
          show: true,
          loading: false
        });
      });
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/facultydetails"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.show ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Faculty Added Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Add Faculty</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ name: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Email"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "email",
                            onChange: e =>
                              this.setState({ email: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Mobile No."
                          id="float"
                          inputProps={{
                            required: true,
                            type: "number",
                            onChange: e =>
                              this.setState({ mobile: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.levelId}
                            inputProps={{
                              required: true,
                              name: "levelId",
                              id: "simple-select",
                              onChange: this.handleLevel
                            }}
                          >
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null} */}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.subjectId}
                            inputProps={{
                              name: "subjectId",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({ subjectId: e.target.value });
                              }
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.filteredSubjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null} */}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Password"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "password",
                            minLength: "6",
                            onInput: this.minLengthCheck,
                            onChange: e =>
                              this.setState({ password: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.minLength ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Password should be of minimum 6 characters
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Confirm Password"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "password",
                            onChange: e =>
                              this.setState({
                                confirm_password: e.target.value
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.passwordMismatch ? (
                          <div>
                            <span style={{ color: "red" }}>
                              *Password Mismatch
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Faculty"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/facultydetails"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddAppFaculty;
