import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class AddProductKey extends Component {
  state = {
    type: "",
    pkey: "",
    category: "",
    courses: [],
    facultyArr: [],
    showBoth: false,
    catData: [],
    redirect: false,
    aAlert: false,
    bAlert: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios
      // .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
      .get("https://admin.akclasses.in/api/coursesForFilter")
      .then(resp => {
        console.log(resp);
        this.setState({
          courses: resp.data
        });
      });
    axios.get("https://admin.akclasses.in/api/facultyR").then(resp => {
      //console.log(resp.data);
      this.setState({
        facultyArr: resp.data
      });
    });
  }
  findFaculty = data => {
    let obj = this.state.facultyArr.find(v => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleDrop = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        showBoth: false
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.type)
          .then(resp => {
            //console.log(resp.data);
            this.setState({
              catData: resp.data.product.variant
            });
            let viewIds = [];
            let views = [];
            this.state.catData.forEach(v => {
              if (viewIds.includes(v.view_and_validity.id) === false) {
                viewIds.push(v.view_and_validity.id);
                views.push(v.view_and_validity);
              }
            });
            //console.log(views);
            this.setState({ catData: views }, () => {
              this.setState({
                showBoth: this.state.catData.length >= 1 ? true : false
              });
            });
          });
      }
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    let payLoad = {
      ref_course_id: this.state.type,
      view_validity_id: this.state.category,
      product_key: this.state.pkey
    };
    if (this.state.type === "") {
      this.setState({
        aAlert: true
      });
    } else if (this.state.category === "") {
      this.setState({
        bAlert: true
      });
    } else {
      this.setState({
        loading: true
      });
      axios
        .post("https://admin.akclasses.in/api/courseKey/create", payLoad)
        .then(() => {
          //console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/productkeys"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Product Key Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Product Key</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Course
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            required={true}
                            value={this.state.type}
                            inputProps={{
                              required: true,
                              name: "type",
                              id: "simple-select",
                              onChange: e => {
                                this.handleDrop(e);
                              }
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {
                                  <span>
                                    {v.TITLE}
                                    (
                                    {v.faculties === null
                                      ? "AK Team"
                                      : (v.faculties.name)}
                                    )
                                  </span>
                                }
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                        {this.state.showBoth ? (
                          <div>
                            <FormControl
                              style={{ marginTop: "12px" }}
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Views Validity
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.category}
                                inputProps={{
                                  name: "category",
                                  id: "simple-select",
                                  onChange: e => {
                                    this.setState({
                                      category: e.target.value,
                                      bAlert: false
                                    });
                                    // //console.log(e.target.value);
                                  }
                                }}
                              >
                                {this.state.catData.map((v, i) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    key={i}
                                    value={v.id}
                                  >
                                    {v.view_and_validity}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {this.state.bAlert ? (
                              <div>
                                <span style={{ color: "red" }}>*required</span>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Product Key"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ pkey: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Product Key"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/productkeys"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddProductKey;
