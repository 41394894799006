import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import editorConfiguration from "variables/EditorConfiguration";
import Datetime from "react-datetime";
import Constants from "variables/Constants";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
const classes = makeStyles(styles);

let fd = new FormData();
class AddAmenity extends Component {
  state = {
    type: "",
    title: "",
    value: "",
    description: "",
    batch_date: "",
    registration_start_date: "",
    registration_end_date: "",
    capacity: "",
    category: "",
    image: [],
    courses: [],
    facultyArr: [],
    showBoth: false,
    catData: [],
    redirect: false,
    aAlert: false,
    bAlert: false,
    notification: false,
    loading: false,
    icon: [],
    description: "",
  };
  componentDidMount() {
    // axios
    //   .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
    //   .then(resp => {
    //     // console.log(resp.data);
    //     this.setState({
    //       courses: resp.data
    //     });
    //   });
    // axios.get("https://admin.akclasses.in/api/facultyR").then(resp => {
    //   //console.log(resp.data);
    //   this.setState({
    //     facultyArr: resp.data
    //   });
    // });
  }
  findFaculty = (data) => {
    let obj = this.state.facultyArr.find((v) => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleDrop = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        showBoth: false,
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.type)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              catData: resp.data.product.variant,
            });
            let viewIds = [];
            let views = [];
            this.state.catData.forEach((v) => {
              if (viewIds.includes(v.view_and_validity.id) === false) {
                viewIds.push(v.view_and_validity.id);
                views.push(v.view_and_validity);
              }
            });
            //console.log(views);
            this.setState({ catData: views }, () => {
              this.setState({
                showBoth: this.state.catData.length >= 1 ? true : false,
              });
            });
          });
      }
    );
  };
  handleFile = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
    console.log(e.target.files[0]);
  };
  handleChange(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        description: content,
      },
      () => {
        console.log(this.state.description);
      }
    );
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.title,
    //   value: this.state.value,
    //   image: this.state.image,
    // };
    // console.log(payLoad)
    this.setState({
      loading: true,
    });
    // fd.append("title", this.state.title)
    // fd.append("value", this.state.value)
    // fd.append("image", this.state.image)
    // fd.append("icon", this.state.icon)
    let payload = {
      descriptions: this.state.description,
      title: this.state.title,
    };
    axios.post(Constants.postUrls.addAmemity, payload).then((resp) => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false,
      });
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/amenities"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Amenity Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Amenity</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Amenity"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <div style={{ color: "black" }}>
                        <SunEditor
                          setDefaultStyle="font-family: Roboto; font-size: 10px;"
                          // width="50%"
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize"],
                              // ['paragraphStyle', 'blockquote'],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              // ["fontColor", "hiliteColor"],
                              ["align", "list", "lineHeight"],
                              ["outdent", "indent"],

                              [
                                "table",
                                "horizontalRule",
                                "link",
                                "image",
                                "video",
                              ],
                              // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                              // ['imageGallery'], // You must add the "imageGalleryUrl".
                              // ["fullScreen", "showBlocks", "codeView"],
                              ["preview", "print"],
                              ["removeFormat"],

                              // ['save', 'template'],
                              // '/', Line break
                            ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                            defaultTag: "div",
                            minHeight: "300px",
                            showPathLabel: false,
                            // font: sortedFontOptions
                          }}
                          name="description"
                          value={this.state.description}
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>

                      {/* PREVIOUS REQUIREMENTS FOR ICONS AND IMAGES */}

                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Value"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ value: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                      <label>Upload Image</label>
                          <br />
                          <input
                            id="image"
                            name="image"
                            onChange={this.handleFile}
                            accept="image/*"
                            type="file"
                            required={true}
                          />
                          
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                      <label>Select Icon</label>
                          <br />
                          <input
                            width="100"
                            id="icon"
                            name="icon"
                            onChange={this.handleFile}
                            accept="image/*"
                            type="file"
                            required={true}
                           
                          />
                          <br/>
                          <span> *upload size 100 x 100 pixels</span>
                      </GridItem> */}

                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Amenity"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/amenities",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddAmenity;
