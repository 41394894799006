import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Modal from "react-awesome-modal";
import Datetime from "react-datetime";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
const classes = makeStyles(styles);
class VendorCommission extends Component {
  state = {
    notifyStatus: false,
    moving: false,
    moved: false,
    direct: true,
    pay: false,
    notify: false,
    secondaryCategories: [],
    vendors: [],
    tablesArray: [],
    status: "",
    frmDate: "",
    toDate: "",
    vendor: "",
    mailSent: false,
    sendMail: false,
    penEmail: false,
    payLoading: false,
    showStatus: false,
    sentPayment: false,
    paymentMailSent: false,
    mailLoading: false,
    payButton: false,
    active: 0,
    loading: "",
    akCount: 0,
    venderCount: 0,
    totalCount: 0,
    totalCourier: 0,
    totalPool: 0,
    totalVsmart: 0,
    totalFaculty: 0,
    allVendorAmount: 0,
    perPage: 10000,
    downloadBtn: false,
    showSent: false,
    grandTotal: 0,
    centerShare: 0,
    net: 0,
    instituteShare: 0,
    gst: 0,
  };

  componentDidMount() {
    axios
      .get("https://admin.akclasses.in/api/allVendorCommission")
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState(
            {
              secondaryCategories: resp.data.data.data,
              allVendorAmount: resp.data.allVendorAmount,
              grandTotal: resp.data.grandTotal,
              centerShare: resp.data.centerShare,
              net: resp.data.net,
              instituteShare: resp.data.instituteShare,
              gst: resp.data.gst,
            },
            () => {
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender) {
                  tablesArray.push(v);
                }
              });
              // console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        }
      });
    axios.get("https://admin.akclasses.in/api/venderR").then((resp) => {
      console.log(resp);
      this.setState({
        vendors: resp.data,
      });
    });
  }

  searchUser = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/vendor_pending_commission?email_for_vender_status=" +
          this.state.active +
          "&id=" +
          this.state.vendor
      )
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            direct: false,
            secondaryCategories: resp.data.data.vendor_commission_amount,
            allVendorAmount: resp.data.allVendorAmount,
            grandTotal: resp.data.grandTotal,
            centerShare: resp.data.centerShare,
            net: resp.data.net,
            instituteShare: resp.data.instituteShare,
            gst: resp.data.gst,
            payButton: false,
            showSent: this.state.active === 0 ? true : false,
            sentPayment: this.state.active === 1 ? true : false,
            showStatus: true,
            // downloadBtn: true,
          },
          () => {
            let tablesArray = [];
            this.state.secondaryCategories.forEach((v) => {
              if (v.vender_list) {
                tablesArray.push(v);
              }
            });
            //console.log(tablesArray);
            this.setState({ tablesArray });
          }
        );
      });
  };

  showSent = () => {
    if (this.state.direct) {
      axios
        .get(
          "https://admin.akclasses.in/api/get_all_sent_commissions?email_for_vender_status=1"
        )
        .then((resp) => {
          console.log(resp);
          this.setState(
            {
              active: 1,
              secondaryCategories: resp.data.data,
              payButton: false,
              allVendorAmount: resp.data.allVendorAmount,
              grandTotal: resp.data.grandTotal,
              centerShare: resp.data.centerShare,
              net: resp.data.net,
              instituteShare: resp.data.instituteShare,
              gst: resp.data.gst,
            },
            () => {
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              // console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    } else {
      axios
        .get(
          "https://admin.akclasses.in/api/vendor_pending_commission?email_for_vender_status=1&id=" +
            this.state.vendor
        )
        .then((resp) => {
          console.log(resp);
          this.setState(
            {
              active: 1,
              penEmail: false,
              secondaryCategories: resp.data.data.vendor_commission_amount,
              grandTotal: resp.data.grandTotal,
              centerShare: resp.data.centerShare,
              net: resp.data.net,
              instituteShare: resp.data.instituteShare,
              gst: resp.data.gst,
              sentPayment: true,
              payButton: false,
              sendMail: false,
              showSent: false,
            },
            () => {
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              //console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    }
  };

  showUnSent = () => {
    if (this.state.direct) {
      axios
        .get(
          "https://admin.akclasses.in/api/get_all_sent_commissions?email_for_vender_status=0"
        )
        .then((resp) => {
          console.log(resp);
          this.setState(
            {
              active: 0,
              secondaryCategories: resp.data.data,
              allVendorAmount: resp.data.allVendorAmount,
              grandTotal: resp.data.grandTotal,
              centerShare: resp.data.centerShare,
              net: resp.data.net,
              instituteShare: resp.data.instituteShare,
              gst: resp.data.gst,
              payButton: false,
            },
            () => {
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              // console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    } else {
      this.setState({ sendMail: false });
      axios
        .get(
          "https://admin.akclasses.in/api/vendor_pending_commission?email_for_vender_status=0&id=" +
            this.state.vendor
        )
        .then((resp) => {
          console.log(resp);
          this.setState(
            {
              penEmail: true,
              active: 0,
              secondaryCategories: resp.data.data.vendor_commission_amount,
              grandTotal: resp.data.grandTotal,
              centerShare: resp.data.centerShare,
              net: resp.data.net,
              instituteShare: resp.data.instituteShare,
              gst: resp.data.gst,
              payButton: false,
              sentPayment: false,
              showSent: true,
            },
            () => {
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              //console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    }
  };

  showPayment = () => {
    if (this.state.direct) {
      axios
        .get(
          "https://admin.akclasses.in/api/get_all_sent_commissions?email_for_vender_status=2"
        )
        .then((resp) => {
          console.log(resp);
          this.setState(
            {
              active: 2,
              secondaryCategories: resp.data.data,
              totalCount: resp.data.total,
              payButton: false,
              grandTotal: resp.data.grandTotal,
              centerShare: resp.data.centerShare,
              net: resp.data.net,
              instituteShare: resp.data.instituteShare,
              gst: resp.data.gst,
            },
            () => {
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              // console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    } else {
      axios
        .get(
          "https://admin.akclasses.in/api/vendor_pending_commission?email_for_vender_status=2&id=" +
            this.state.vendor
        )
        .then((resp) => {
          console.log(resp);
          this.setState(
            {
              active: 2,
              secondaryCategories: resp.data.data.vendor_commission_amount,
              grandTotal: resp.data.grandTotal,
              centerShare: resp.data.centerShare,
              net: resp.data.net,
              instituteShare: resp.data.instituteShare,
              gst: resp.data.gst,
              payButton: false,
              showSent: false,
              sendMail: false,
              showStatus: true,
              sentPayment: false,
            },
            () => {
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              //console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    }
  };

  sendEmail = () => {
    this.setState({ loading: true });
    axios
      .get(
        "https://admin.akclasses.in/api/email_vender_commission_datevise?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.vendor
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          loading: false,
          mailSent: true,
        });
        setTimeout(() => {
          this.setState({
            mailSent: false,
          });
          window.location.reload();
        }, 4000);
      });
  };

  moveToPending = (id) => {
    this.setState({ moving: true });
    axios
      .post(
        "https://admin.akclasses.in/api/moveCommission_status_to_pending?id=" +
          id +
          "&status=Not_Collected"
      )
      .then((resp) => {
        console.log(resp);
        this.setState({ moving: false, moved: true });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  searchEmails = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_vender_commission_datewise?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.vendor
      )
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            secondaryCategories: resp.data.data.vendor_commission_amount,
            penEmail: true,
            payButton: false,
            sendMail: true,
            grandTotal: resp.data.grandTotal,
            centerShare: resp.data.centerShare,
            net: resp.data.net,
            instituteShare: resp.data.instituteShare,
            gst: resp.data.gst,
          },
          () => {
            let tablesArray = [];
            this.state.secondaryCategories.forEach((v) => {
              if (v.vender) {
                tablesArray.push(v);
              }
            });
            //console.log(tablesArray);
            this.setState({ tablesArray });
          }
        );
      });
  };

  handlePayment = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_vender_commission_payment_datevise?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.vendor
      )
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            secondaryCategories: resp.data.data.vendor_commission_amount,
            sendMail: true,
            payButton: true,
            grandTotal: resp.data.grandTotal,
            centerShare: resp.data.centerShare,
            net: resp.data.net,
            instituteShare: resp.data.instituteShare,
            gst: resp.data.gst,
          },
          () => {
            let tablesArray = [];
            this.state.secondaryCategories.forEach((v) => {
              if (v.vender) {
                tablesArray.push(v);
              }
            });
            //console.log(tablesArray);

            this.setState({ tablesArray });
          }
        );
      });
  };

  sendPaymentMail = () => {
    this.setState({ mailLoading: true });
    axios
      .get(
        "https://admin.akclasses.in/api/email_vender_commission_payment_datevise?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.vendor
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          mailLoading: false,
          paymentMailSent: true,
        });
        setTimeout(() => {
          this.setState({
            paymentMailSent: false,
          });
          window.location.reload();
        }, 4000);
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.mailSent ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Invoices Sent Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.moved ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Moved Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody
                style={{
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "5%",
                    marginTop: "50px",
                  }}
                >
                  <Button
                    onClick={this.showUnSent}
                    style={{
                      position: "relative",
                      bottom: "62px",
                      right: "50px",
                    }}
                    color={this.state.active === 0 ? "secondary" : "primary"}
                  >
                    Pending
                  </Button>
                  <Button
                    onClick={this.showSent}
                    style={{
                      position: "relative",
                      bottom: "62px",
                      right: "50px",
                    }}
                    color={this.state.active === 1 ? "secondary" : "success"}
                  >
                    Sent
                  </Button>
                  <Button
                    onClick={this.showPayment}
                    style={{
                      position: "relative",
                      bottom: "62px",
                      right: "50px",
                    }}
                    color={this.state.active === 2 ? "secondary" : "warning"}
                  >
                    Payment Received
                  </Button>
                  {this.state.sendMail ? (
                    <a
                      href={
                        this.state.penEmail
                          ? "https://admin.akclasses.in/api/download_vender_commission_datevise_pdf?frmdate=" +
                            this.state.frmDate +
                            "&todate=" +
                            this.state.toDate +
                            "&id=" +
                            this.state.vendor
                          : "https://admin.akclasses.in/api/download_vender_commission_payment_pdf?frmdate=" +
                            this.state.frmDate +
                            "&todate=" +
                            this.state.toDate +
                            "&id=" +
                            this.state.vendor
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        style={{
                          position: "absolute",
                          right: "250px",
                          top: "50px",
                        }}
                        color="danger"
                      >
                        Download
                      </Button>
                    </a>
                  ) : null}
                </div>
                <div style={{ position: "relative", top: "0px" }}>
                  <h3 style={{ position: "relative", top: "0px" }}>
                    Vendor Commission
                  </h3>
                  <div
                    style={{
                      position: "relative",
                      bottom: "63px",
                      left: "60%",
                    }}
                  >
                    <GridItem
                      style={{
                        position: "relative",
                        bottom: "17px",
                        right: "10px",
                      }}
                      xs={12}
                      sm={12}
                      md={3}
                    >
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Search By Vendor
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.vendor}
                          inputProps={{
                            name: "vendor",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState({
                                vendor: e.target.value,
                              });
                            },
                          }}
                        >
                          {this.state.vendors.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.institute_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <Button
                      onClick={this.searchUser}
                      style={{
                        position: "relative",
                        bottom: "62px",
                        left: "200px",
                      }}
                      color="success"
                    >
                      Search
                    </Button>

                    {this.state.downloadBtn ? (
                      <GridItem
                        style={{
                          position: "relative",
                          bottom: "126px",
                          left: "500px",
                        }}
                        xs={12}
                        sm={12}
                        md={3}
                      >
                        <a
                          href={
                            "https://admin.akclasses.in/api/vendor_commission_excel_report?vender_id=" +
                            this.state.vendor
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            style={{
                              position: "relative",
                              top: "13px",
                              right: "60px",
                            }}
                            onClick={() => {
                              this.setState({ repButton: false });
                            }}
                            color="danger"
                          >
                            Download
                          </Button>
                        </a>
                      </GridItem>
                    ) : null}
                  </div>

                  {this.state.showSent ? (
                    <GridContainer
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        left: "188px",
                      }}
                    >
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <label
                          style={{
                            color: "black",
                          }}
                        >
                          Search In Pending Mails
                        </label>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  frmDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.frmDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "From Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  toDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.toDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "To Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "20px",
                          }}
                          onClick={this.searchEmails}
                          color="success"
                        >
                          Search
                        </Button>
                        {this.state.sendMail ? (
                          <Button
                            style={{
                              position: "relative",
                              top: "13px",
                              right: "20px",
                            }}
                            onClick={() => {
                              this.setState({ visible: true, pay: false });
                            }}
                            color="primary"
                          >
                            Send Invoices
                          </Button>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  {this.state.sentPayment ? (
                    <GridContainer
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        left: "320px",
                      }}
                    >
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <label
                          style={{
                            color: "black",
                          }}
                        >
                          Search In Sent Mails
                        </label>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  frmDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.frmDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "From Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  toDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.toDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "To Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "20px",
                          }}
                          onClick={this.handlePayment}
                          color="success"
                        >
                          Search
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                </div>

                <table
                  style={{
                    width: "100%",
                    border: "1px grey solid",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr
                    style={{
                      border: "1px grey solid",
                      borderCollapse: "collapse",
                    }}
                  >
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Sr No.
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Order Date
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Subject
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Order Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                        width: "100px",
                      }}
                    >
                      Center Share
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Net
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Institute Share
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      GST@18%
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Amount Payable
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Mail
                    </th>
                  </tr>
                  {this.state.secondaryCategories.map((sec, index) => (
                    <tr
                      style={{
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                      key={index}
                    >
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {sec.order_date}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {sec.student_name}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {/* {sec.course.TITLE} */}
                        Course Name
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {Math.floor(sec.grand_amount)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {Math.floor(sec.vender_amount)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {/* Net */}
                        {Math.floor(
                          parseInt(sec.grand_amount) -
                            parseInt(sec.vender_amount)
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {/* Institute */}
                        {Math.floor(
                          parseInt(sec.grand_amount) -
                            parseInt(sec.vender_amount) -
                            ((parseInt(sec.grand_amount) -
                              parseInt(sec.vender_amount)) /
                              118) *
                              18
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {/* GST */}
                        {Math.floor(
                          ((parseInt(sec.grand_amount) -
                            parseInt(sec.vender_amount)) /
                            118) *
                            18
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {Math.floor(
                          parseInt(sec.grand_amount) -
                            parseInt(sec.vender_amount)
                        )}
                      </td>
                      {/* <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {this.state.active===0 ? "Pending" : this.state.active===1 ? "Sent" : "Collected"}
                      </td> */}

                      {this.state.active === 2 ? (
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse",
                          }}
                        >
                          <Select
                            key={index}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={sec.payment_status}
                            inputProps={{
                              name: "status",
                              id: "simple-select",
                              onChange: () => {
                                this.moveToPending(sec.id);
                              },
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Collected"
                            >
                              Collected
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Not_Collected"
                            >
                              Not Collected
                            </MenuItem>
                          </Select>
                        </td>
                      ) : null}

                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {sec.faculty_email_status === 1 ? (
                          <Button key={index} color="success">
                            Sent
                          </Button>
                        ) : sec.faculty_email_status === 0 ? (
                          <Button key={index} color="primary">
                            Pending
                          </Button>
                        ) : sec.faculty_email_status === 2 ? (
                          <Button key={index} color="warning">
                            Payment Received
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {/* order date */}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {/* student name */}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {/* subject name */}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {Math.floor(this.state.grandTotal)}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {Math.floor(this.state.centerShare)}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {Math.floor(this.state.net)}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {Math.floor(this.state.instituteShare)}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {Math.floor(this.state.gst)}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {Math.floor(this.state.net)}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      {this.state.payButton ? (
                        <Button
                          onClick={() => {
                            this.setState({ pay: true, visible: true });
                          }}
                          color="warning"
                        >
                          Payment Received
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                </table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visible}
          width="1000"
          height="330"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  {this.state.pay ? (
                    <div>
                      <h3>Are You Sure You Have Received the Payment!</h3>
                      {this.state.paymentMailSent ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Payment Invoices Sent Successfully!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <a
                            href={
                              "https://admin.akclasses.in/api/download_vender_commission_payment_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.vendor
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendPaymentMail}
                            >
                              {this.state.mailLoading ? <Loader /> : "Yes"}
                            </Button>
                          </a>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <div>
                      <h3>Are You Sure You Want to Send the Invoices!</h3>
                      {this.state.mailSent ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Invoices Sent Successfully!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <a
                            href={
                              "https://admin.akclasses.in/api/download_vender_commission_datevise_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.vendor
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendEmail}
                            >
                              {this.state.loading ? <Loader /> : "Yes"}
                            </Button>
                          </a>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default VendorCommission;
