import axios from "axios";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Modal, Box, Button, Typography, TextField } from "@mui/material";
import GridContainer from "components/Grid/GridContainer.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const WalletAdd = ({ close, refreshData }) => {
  const [open, setOpen] = useState(true);
  const [success, setSuccess] = useState(false);


  const handleClose = () => {
    setOpen(false);
    close();
  };

  const { id } = useParams();
  const [data, setData] = useState({
    user_id: id,
    amount: "",
    description: "",
  });

  const location = useLocation();
  console.log(location);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://admin.akclasses.in/api/wallet/creditByUserId",
        data
      );
      if (res.data.message === "Amount credited successfully") {

        setSuccess(true);
      

        setData({
          amount: "",
          description: "",
        });

        setTimeout(() => {
          close();
          setSuccess(false);
        }, 1000);

        refreshData();
      }
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyle = {
    borderRadius:"7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            User Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Amount"
              variant="outlined"
              fullWidth
              margin="normal"
              name="amount"
              value={data.amount}
              onChange={handleChange}
              required
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              value={data.description}
              onChange={handleChange}
              required
            />
            <Button style={{background:"#29bf12"}}
             type="submit" variant="contained" sx={{ mt: 2 }}>
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <GridContainer 
      >
        {success && (
          <div style={{ position: "relative", left: "600px",width:'300px' }}>
          <SnackbarContent
            message={"Amount credited successfully!"}
            icon={AddAlert}
            color="success"
          />
          </div>
        )}
      </GridContainer>
    </div>
  );
};

export default WalletAdd;




