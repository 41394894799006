import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
import Modal from "react-awesome-modal";
import Loader from "components/Loader";
import MathJax from "react-mathjax-preview";
import TestSubjects from "./TestSubjects";
import CustomInput from "components/CustomInput/CustomInput.js";
import { constants } from "buffer";
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
const classes = makeStyles(styles);
const perPages = ["10", "50", "100", "250", "500"];

let fd = new FormData();
export default class ScannerQuestion extends Component {
  state = {
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    vDeoLoading: false,
    rowId: 0,
    status: "",
    type: "",
    active: 1,
    questionBank: [],
    per_page: "10",
    page_no: "1",
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    pageArray: [],
    paginationArray: [],
    currentPage: 1,
    perPage: 10,
    is_filter: 0,
    level: "",
    subject: "",
    source: "",
    chapter: "",
    questions: [],
    levels: [],
    subjects: [],
    chapters: [],
    sources: [],
    message: "",
    is_deleted: 0,
    delete_multiple: false,
    greaterQuestion: false,
    alreadyAddedQuestion: false,
    addedQuestions: [],
    selectedQuestion: [],
    multi_dlt_modal: false,
    notifyMultiDelete: false,
    delMessage: "",
    delMultiLoading: false,
    select_all: true,
    file: "",
    question: {},
    permanent_dlt: false,
    restore: false,
    videoModalOpen: false,
    selectedVideoPath: "",
    uploadFileIndex: null,
    selectedFileEnglish: "",
    vId: "",
    visibleUpload: false,
    //  visibleUpload: false,
    selectedFileHindi: "",
    videoKey: "",
    uploadingVideo: false,
  };
  constructor(props) {
    super(props);
    this.inputfileref = React.createRef();
  }

  componentDidMount() {
    this.getquestion();
    this.getsource();
    axios.get(Constants.getUrls.getlevel_without_paginate).then((resp) => {
      console.log(resp.data);
      this.setState({
        levels: resp.data,
      });
    });
  }
  getsource = () => {
    axios.get(Constants.getUrls.scannerActivateSources).then((respsource) => {
      console.log(respsource);
      this.setState({
        sources: respsource.data,
      });
    });
  };
  openVideoModal = (videoPath) => {
    // console.log(videoPath);
    this.setState({
      videoModalOpen: true,
      selectedVideoPath: videoPath,
    });
    console.log(videoPath, "url");
  };
  handleFileChangeEnglish = (event) => {
    console.log(event.target.files[0]);
    // Handle file change and update selectedFileEnglish in state
    this.setState(
      {
        selectedFileEnglish: event.target.files[0],
        // videoName: event.target.files[0].name,
      },
      () => {
        console.log(this.state.selectedFileEnglish);
      }
    );
  };

  handleFileChangeHindi = (event) => {
    // Handle file change and update selectedFileEnglish in state
    this.setState({
      selectedFileHindi: event.target.files[0],
      // videoName: event.target.files[0].name,
    });
  };
  closeVideoModal = () => {
    this.setState({
      videoModalOpen: false,
      selectedVideoPath: "",
      selectedFileEnglish: "",
      selectedFileHindi: "",
      mediaProgress: false,
      videoName: "",
      visibleUpload: false,
    });
    this.inputfileref.current.value = "";
  };
  // componentDidMount() {
  //   this.getquestion();
  //   axios.get(Constants.getUrls.getlevel_without_paginate).then((resp) => {
  //     this.setState({
  //       levels: resp.data,
  //     });
  //   });
  // }

  // Event handler for English video selection
  handleEnglishVideoSelect = (event) => {
    const englishVideoFile = event.target.files[0];
    this.setState({
      selectedEnglishVideo: englishVideoFile,
      videoAlert: false,
    });
  };

  // Event handler for Hindi video selection
  handleHindiVideoSelect = (event) => {
    const hindiVideoFile = event.target.files[0];
    this.setState({
      selectedHindiVideo: hindiVideoFile,
      videoAlert: false,
    });
  };

  getquestion = () => {
    axios
      .get(
        Constants.getUrls.getquestionn +
          "?per_page=" +
          this.state.perPage +
          "&is_filter=" +
          this.state.is_filter +
          "&scanner_level_id=" +
          this.state.level +
          "&scanner_subject_id=" +
          this.state.subject +
          "&scanner_chapter_id=" +
          this.state.chapter +
          "&scnner_source_id=" +
          this.state.source +
          "&is_deleted=" +
          this.state.is_deleted
      )

      .then((resp) => {
        // Handle the response data as needed
        // console.log(resp);
        let pageArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          pageArray.push(i);
        }
        this.setState(
          {
            pageArray,
            questionBank: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let paginationArray = this.state.pageArray.filter(
              (page) => page >= this.state.fromInc && page <= this.state.toInc
            );
            this.setState({
              paginationArray,
            });
          }
        );
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };

  getfilteredQuestion = () => {
    axios
      .get(
        Constants.getUrls.getquestionn +
          "?per_page=" +
          this.state.perPage +
          "&is_filter=1" +
          "&scanner_level_id=" +
          this.state.level +
          "&scanner_subject_id=" +
          this.state.subject +
          "&scanner_chapter_id=" +
          this.state.chapter +
          "&scanner_source_id=" +
          this.state.source +
          "&is_deleted=" +
          this.state.is_deleted
      )
      .then((resp) => {
        console.log(resp);
        let pageArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          pageArray.push(i);
        }
        this.setState(
          {
            pageArray,
            questionBank: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
            notifyStatus: true,
            message: resp.data.message,
          },
          () => {
            let paginationArray = this.state.pageArray.filter(
              (page) => page >= this.state.fromInc && page <= this.state.toInc
            );
            this.setState({
              paginationArray,
            });
          }
        );
        setTimeout(() => {
          this.setState({
            notifyStatus: false,
            message: "",
          });
        }, 4000);
      });
  };

  getLevels = () => {
    axios
      .get(
        Constants.getUrls.getlevel_with_paginate +
          "?perPage=" +
          this.state.perPage
      )
      .then((resp) => {
        // console.log(resp);
        let pageArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          pageArray.push(i);
        }
        this.setState(
          {
            pageArray,
            levels: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let paginationArray = this.state.pageArray.filter(
              (page) => page >= this.state.fromInc && page <= this.state.toInc
            );
            this.setState({
              paginationArray,
            });
          }
        );
      });
  };

  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.subject_with_levels + this.state.level)
          .then((resp) => {
            console.log(resp.data);
            this.setState({
              subjects: resp.data.scanner_subjects,
              subject: "",
              chapters: [],
              chapter: "",
              aAlert: false,
            });
          });
      }
    );
  };

  handleChapter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.getShowScannersubject + this.state.subject)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              chapters: resp.data.scanner_chapters,
              chapter: "",
              bAlert: false,
            });
          });
      }
    );
  };

  handleLast = (e) => {
    // console.log("e.target.value", e.target.value);
    // console.log(e, "hh");
    this.setState({
      [e.target.name]: e.target.value,
      cAlert: false,
    });
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios.delete(Constants.postUrls.deleteScannerQuestion + id).then((resp) => {
      console.log(resp);
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };
  handleModal = (question) => {
    this.setState({
      rowId: question.id,
      visible: true,
      question: question,
    });
  };
  handleUploadVideo = (videoId, key) => {
    console.log(videoId, key);
    this.setState(
      {
        vId: videoId,
        videoKey: key,
        visibleUpload: true,
      },
      () => {
        console.log(this.state.visibleUpload);
      }
    );
  };

  uploadVideos = (vidId, vKey) => {
    this.setState({ vDeoLoading: true });

    console.log("hey");
    console.log(vidId, vKey);

    if (vKey == "english") {
      fd.append("solution_videos_english", this.state.selectedFileEnglish);
    } else {
      fd.append("solution_videos_hindi", this.state.selectedFileHindi);
    }
    axios
      .post(Constants.postUrls.upploadScnnareVideos + vidId, fd, {
        onUploadProgress: (progressEvent) => {
          this.setState({
            progress: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
            mediaProgress: true,
          });
        },
      })

      .then((resp) => {
        console.log(resp);
        this.setState({
          notifyDelete: true,
          vDeoLoading: false,
          visibleUpload: false,
        });
        this.closeVideoModal();
        // setTimeout(() => {
        // }, 2000);
        this.getquestion();
      })
      .catch((error) => {
        console.error("Error uploading video:", error);
      });
  };

  select_All = () => {
    if (this.state.select_all === true) {
      this.setState({
        selectedQuestion: [],
        // select_all: false,
      });
    } else {
      const selectedQuestion = this.state.questionBank.map((item) => item.id);
      this.setState({
        selectedQuestion,
        check: true,
        // select_all: true,
      });
    }
  };
  handleMultiDelete = () => {
    this.setState({
      delMultiLoading: true,
    });
    let payload = {
      ids: this.state.selectedQuestion,
    };
    axios
      .post(Constants.deleteUrls.destroyScannerbulk, payload)
      .then((resp) => {
        console.log(resp);
        this.setState({
          delMultiLoading: false,
          delMessage: "Selected Questions Deleted",
          notifyMultiDelete: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return;
      });
  };
  handlePermanentDelete = () => {
    // return console.log(this.state.selectedQuestion)
    this.setState({
      delMultiLoading: true,
    });
    let payload = {
      question_bank_id: this.state.selectedQuestion,
    };
    axios
      .post(Constants.deleteUrls.permananScannertMultipleDelete, payload)
      .then((resp) => {
        console.log(resp);
        this.setState({
          delMultiLoading: false,
          delMessage: "Selected Questions Deleted Permanently",
          notifyMultiDelete: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return;
      });
  };
  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post(Constants.postUrls.changeScannerquestionStatus + id, payload)
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5,
                },
                () => {
                  this.getquestion();
                }
              );
            }, 1000);
          });
      }
    );
  };

  handleSelectType = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          question_bank_id: id,
          type: this.state.type,
        };
        axios.post(Constants.postUrls.updateType, payload).then((res) => {
          console.log(res);
          this.setState({
            notifyStatus: true,
          });
          setTimeout(() => {
            this.setState(
              {
                notifyStatus: false,
                fromInc: 1,
                active: 1,
                toInc: 5,
              },
              () => {
                this.getquestion();
              }
            );
          }, 1000);
        });
      }
    );
  };

  currentPage = (page) => {
    axios
      .get(
        Constants.getUrls.getquestionn +
          "?per_page=" +
          this.state.per_page +
          "&page=" +
          page +
          "&is_filter=1" +
          "&is_deleted=" +
          this.state.is_deleted +
          "&scanner_level_id=" +
          this.state.level +
          "&scanner_subject_id=" +
          this.state.subject +
          "&scanner_chapter_id=" +
          this.state.chapter +
          "&scanner_source_id=" +
          this.state.source
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          questionBank: resp.data.data,
          total: resp.data.total,
          from: resp.data.from,
          to: resp.data.to,
          active: page,
        });
      });
  };

  changePage = (direction) => {
    if (direction == "back") {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
      let currentpage = this.state.currentPage - 1;
      axios
        .get(
          Constants.getUrls.getquestionn +
            "?per_page=" +
            this.state.per_page +
            "&page=" +
            currentpage +
            "&is_filter=" +
            this.state.is_filter +
            "&is_deleted=" +
            this.state.is_deleted +
            "&scanner_level_id=" +
            this.state.level +
            "&scanner_subject_id=" +
            this.state.subject +
            "&scanner_chapter_id=" +
            this.state.chapter +
            "&scanner_source_id=" +
            this.state.source
        )
        .then((resp) => {
          // console.log(resp);
          this.setState(
            {
              questionBank: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
              active: this.state.currentPage,
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let paginationArray = this.state.pageArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              if (paginationArray.length >= 1) {
                if (paginationArray.length === 1) {
                  this.setState(
                    {
                      fromInc: this.state.fromInc + 4,
                      toInc: this.state.toInc + 4,
                    },
                    () => {
                      let paginationArray = this.state.pageArray.filter(
                        (v) => v >= this.state.fromInc && v <= this.state.toInc
                      );
                      this.setState({ paginationArray });
                    }
                  );
                } else if (paginationArray.length === 2) {
                  this.setState(
                    {
                      fromInc: this.state.fromInc + 3,
                      toInc: this.state.toInc + 3,
                    },
                    () => {
                      let paginationArray = this.state.pageArray.filter(
                        (v) => v >= this.state.fromInc && v <= this.state.toInc
                      );
                      this.setState({ paginationArray });
                    }
                  );
                } else if (paginationArray.length === 3) {
                  this.setState(
                    {
                      fromInc: this.state.fromInc + 2,
                      toInc: this.state.toInc + 2,
                    },
                    () => {
                      let paginationArray = this.state.pageArray.filter(
                        (v) => v >= this.state.fromInc && v <= this.state.toInc
                      );
                      this.setState({ paginationArray });
                    }
                  );
                } else if (paginationArray.length === 4) {
                  this.setState(
                    {
                      fromInc: this.state.fromInc + 1,
                      toInc: this.state.toInc + 1,
                    },
                    () => {
                      let paginationArray = this.state.pageArray.filter(
                        (v) => v >= this.state.fromInc && v <= this.state.toInc
                      );
                      this.setState({ paginationArray });
                    }
                  );
                } else {
                  this.setState({ paginationArray });
                }
              } else {
                this.setState(
                  {
                    fromInc: this.state.fromInc + 5,
                    toInc: this.state.toInc + 5,
                  },
                  () => {
                    let paginationArray = this.state.pageArray.filter(
                      (v) => v >= this.state.fromInc && v <= this.state.toInc
                    );
                    this.setState({ paginationArray });
                  }
                );
              }
            }
          );
        });
    } else if (direction == "next") {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
      let currentpage = this.state.currentPage + 1;
      axios
        .get(
          Constants.getUrls.getquestionn +
            "?per_page=" +
            this.state.per_page +
            "&page=" +
            currentpage +
            "&is_filter=" +
            this.state.is_filter +
            "&is_deleted=" +
            this.state.is_deleted +
            "&scanner_level_id=" +
            this.state.level +
            "&scanner_subject_id=" +
            this.state.subject +
            "&scanner_chapter_id=" +
            this.state.chapter +
            "&scanner_source_id=" +
            this.state.source
        )
        .then((resp) => {
          // console.log(resp);
          this.setState(
            {
              questionBank: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
              active: this.state.currentPage,
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let paginationArray = this.state.pageArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              if (paginationArray.length >= 1) {
                if (paginationArray.length === 1) {
                  this.setState(
                    {
                      fromInc: this.state.fromInc - 4,
                      toInc: this.state.toInc - 4,
                    },
                    () => {
                      let paginationArray = this.state.pageArray.filter(
                        (v) => v >= this.state.fromInc && v <= this.state.toInc
                      );
                      this.setState({ paginationArray });
                    }
                  );
                } else if (paginationArray.length === 2) {
                  this.setState(
                    {
                      fromInc: this.state.fromInc - 3,
                      toInc: this.state.toInc - 3,
                    },
                    () => {
                      let paginationArray = this.state.pageArray.filter(
                        (v) => v >= this.state.fromInc && v <= this.state.toInc
                      );
                      this.setState({ paginationArray });
                    }
                  );
                } else if (paginationArray.length === 3) {
                  this.setState(
                    {
                      fromInc: this.state.fromInc - 2,
                      toInc: this.state.toInc - 2,
                    },
                    () => {
                      let paginationArray = this.state.pageArray.filter(
                        (v) => v >= this.state.fromInc && v <= this.state.toInc
                      );
                      this.setState({ paginationArray });
                    }
                  );
                } else if (paginationArray.length === 4) {
                  this.setState(
                    {
                      fromInc: this.state.fromInc - 1,
                      toInc: this.state.toInc - 1,
                    },
                    () => {
                      let paginationArray = this.state.pageArray.filter(
                        (v) => v >= this.state.fromInc && v <= this.state.toInc
                      );
                      this.setState({ paginationArray });
                    }
                  );
                } else {
                  this.setState({ paginationArray });
                }
              } else {
                this.setState(
                  {
                    fromInc: this.state.fromInc - 5,
                    toInc: this.state.toInc - 5,
                  },
                  () => {
                    let paginationArray = this.state.pageArray.filter(
                      (v) => v >= this.state.fromInc && v <= this.state.toInc
                    );
                    this.setState({ paginationArray });
                  }
                );
              }
            }
          );
        });
    }
  };

  handlePage = (e) => {
    // console.log(e.target.value);
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
        loading: true,
        perPage: e.target.value,
      },
      () => {
        let pageNo = "1";
        let payload = {
          per_page: this.state.per_page,
          page: this.state.page,
          is_deleted: this.state.is_deleted,
        };
        const url =
          this.state.is_filter === 1
            ? Constants.getUrls.getquestionn +
              "?per_page=" +
              e.target.value +
              "&page=" +
              pageNo +
              "&is_filter=" +
              this.state.is_filter +
              "&is_deleted=" +
              this.state.is_deleted +
              "&scanner_level_id=" +
              this.state.level +
              "&scanner_subject_id=" +
              this.state.subject +
              "&scanner_chapter_id=" +
              this.state.chapter +
              "&scanner_source_id=" +
              this.state.source
            : Constants.getUrls.getquestionn +
              "?per_page=" +
              e.target.value +
              "&page=" +
              pageNo +
              "&is_filter=1" +
              "&is_deleted=" +
              this.state.is_deleted;

        axios.get(url).then((resp) => {
          console.log(resp);
          let pageArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            pageArray.push(i);
          }
          this.setState(
            {
              pageArray,
              questionBank: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
              loading: false,
            },
            () => {
              let paginationArray = this.state.pageArray.filter(
                (page) => page >= this.state.fromInc && page <= this.state.toInc
              );
              this.setState({
                paginationArray,
              });
            }
          );
        });
      }
    );
  };

  handleDeleteQuestion = (e) => {
    console.log("log");
  };

  handleFile = (e) => {
    console.log(this.state.question);
    this.setState({
      file: e.target.files[0],
    });
  };

  handleSingleDocx = (question) => {
    this.setState({
      loading: true,
    });
    fd.append("question_id", question);
    fd.append("upload_file", this.state.file);
    fd.append("scanner_subject_id", this.state.question.scanner_subject_id);
    fd.append("scanner_level_id", this.state.question.scanner_level_id);
    fd.append("scanner_chapter_id", this.state.question.scanner_chapter_id);
    fd.append("scanner_source_id", this.state.question.scanner_source_id);

    axios.post(Constants.postUrls.uploadSingleScannerDocx, fd).then((resp) => {
      console.log(resp);
      fd.delete("upload_file");
      if (resp.data.status === "success") {
        this.setState({
          message: resp.data.message,
          notifyStatus: true,
          visible: false,
          loading: false,
          file: "",
        });
        setTimeout(() => {
          // window.location.reload();
          this.setState(
            {
              notification: false,
            },
            () => {
              this.getquestion();
            }
          );
        }, 3000);
      } else {
        this.setState({
          message: resp.data.message,
          notifyStatus: true,
          visible: false,
          loading: false,
          file: "",
        });
        setTimeout(() => {
          // window.location.reload();
          this.setState(
            {
              notification: false,
            },
            () => {
              this.getquestion();
            }
          );
        }, 3000);
      }
    });
  };

  handleRestore = (question) => {
    axios
      .get(Constants.getUrls.restoreScanDeleteQuestion + "/" + question.id)
      .then((resp) => {
        console.log(resp);
        if (resp.data === "Record restored Successfully.") {
          this.setState({
            notification: true,
            message: "Record restored Successfully.",
          });
          setTimeout(() => {
            this.setState(
              {
                notification: false,
              },
              () => {
                this.getquestion();
              }
            );
          }, 1500);
        } else {
          this.setState({
            notification: true,
            message: "Some Error Occured!!!",
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 3000);
        }
      });
  };

  render() {
    const { isModalOpen, videoUrl } = this.state;
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={this.state.message}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                {/* PER PAGE SELECT */}
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <h3>Question</h3>
                <Link to="/admin/uploadScannerDocs">
                  <Button className="" color="warning">
                    Add Questions
                  </Button>
                </Link>
                <Button
                  onClick={(e) => {
                    if (this.state.is_deleted === 0) {
                      this.setState(
                        {
                          is_deleted: 1,
                        },
                        () => {
                          this.getquestion();
                        }
                      );
                    } else {
                      this.setState(
                        {
                          is_deleted: 0,
                        },
                        () => {
                          this.getquestion();
                        }
                      );
                    }
                  }}
                  style={{
                    backgroundColor: "#FE6244",
                  }}
                >
                  {this.state.is_deleted === 0
                    ? "Deleted Questions"
                    : "Non Deleted"}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ is_filter: 1 }, () => {
                      console.log(this.state.is_filter);
                    });
                  }}
                  color="success"
                >
                  Apply Filter
                </Button>
                <Button
                  // color="#AA77FF"
                  style={{
                    backgroundColor: "#AA77FF",
                  }}
                  onClick={() => {
                    this.setState({
                      delete_multiple: true,
                    });
                  }}
                >
                  Delete Multiple
                </Button>
                <br />
                {this.state.delete_multiple === true ? (
                  <Button
                    onClick={() => {
                      this.setState(
                        {
                          select_all: !this.state.select_all,
                        },
                        () => {
                          if (this.state.is_deleted === 1) {
                            this.setState(
                              {
                                permanent_dlt: true,
                              },
                              () => {
                                console.log(this.state.permanent_dlt);
                              }
                            );
                          }
                          this.select_All();
                        }
                      );
                    }}
                    color="warning"
                    // style={{
                    //   backgroundColor: "#FC2947",
                    // }}
                  >
                    {this.state.select_all === false
                      ? "Un-select"
                      : "Select All"}
                  </Button>
                ) : null}
                {this.state.select_all === false ? (
                  <Button
                    onClick={() => {
                      if (this.state.permanent_dlt) {
                        this.setState({
                          visible: true,
                          // multi_dlt_modal: true,
                        });
                      } else {
                        this.setState({
                          visible: true,
                          multi_dlt_modal: true,
                        });
                      }
                    }}
                    style={{
                      backgroundColor: "#FC2947",
                    }}
                  >
                    {this.state.permanent_dlt
                      ? "Delete Permanently"
                      : "Delete All"}
                  </Button>
                ) : null}
                {/* APPLY FILTER CHANGES */}
                {this.state.is_filter === 1 ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Level
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.level}
                          inputProps={{
                            required: true,
                            name: "level",
                            id: "simple-select",
                            onChange: this.handleSubject,
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Level
                          </MenuItem>
                          {this.state.levels.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Subject
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.subject}
                          inputProps={{
                            required: true,
                            name: "subject",
                            id: "simple-select",
                            onChange: this.handleChapter,
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Level First
                          </MenuItem>
                          {this.state.subjects.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Chapter
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.chapter}
                          onChange={this.handleLast} // Adjusted the event handling here
                          inputProps={{
                            required: true,
                            name: "chapter",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Subject First
                          </MenuItem>
                          {this.state.chapters.map(
                            (v, i) => (
                              console.log(v),
                              (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              )
                            )
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Source
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.source}
                          inputProps={{
                            required: true,
                            name: "source",
                            id: "simple-select",
                            onChange: this.handleLast,
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Source
                          </MenuItem>
                          {this.state.sources.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        onClick={() => {
                          this.getfilteredQuestion();
                        }}
                      >
                        Apply
                      </Button>
                    </GridItem>
                  </GridContainer>
                ) : null}
                {this.state.delete_multiple ? (
                  <Table
                    tableHead={[
                      "Sr.No.",
                      "Question Id",
                      "Select",
                      "Question",
                      "Answer",
                      "Source",
                      // "QR code",
                      // "Solution Vedio English",
                      // "Solution Vedio Hindi",
                      // "Status",
                      // "Update",
                      // "Delete",
                    ]}
                    tableData={
                      this.state.questionBank &&
                      this.state.questionBank.map((question, index) => [
                        [index + 1],
                        [question.id],
                        [
                          <input
                            type="checkbox"
                            name="checkQuestion"
                            style={{ marginLeft: "27px" }}
                            checked={this.state.selectedQuestion.includes(
                              question.id
                            )}
                            onChange={(checkbox) => {
                              const selectedQuestion = this.state.selectedQuestion.find(
                                (que) => que === question.id
                              );
                              console.log(selectedQuestion);
                              if (selectedQuestion) {
                                const i = this.state.selectedQuestion.findIndex(
                                  (que) => que === question.id
                                );
                                this.state.selectedQuestion.splice(i, 1);
                                this.setState({
                                  check: true,
                                  selectedQuestion: this.state.selectedQuestion,
                                  products: question.ID,
                                  dAlert: false,
                                  greaterQuestion: false,
                                  alreadyAddedQuestion: false,
                                });
                              } else {
                                this.state.selectedQuestion.push(question.id);
                                this.setState({
                                  check: true,
                                  selectedQuestion: this.state.selectedQuestion,
                                  products: question.id,
                                  dAlert: false,
                                  greaterQuestion: false,
                                  alreadyAddedQuestion: false,
                                });
                              }
                            }}
                          />,
                        ],
                        [<MathJax math={question.question} />],
                        [<MathJax math={question.answer} />],
                        [
                          question.scanner_source
                            ? question.scanner_source.title
                            : "",
                        ],
                        // [
                        //   <Select
                        //     key={index}
                        //     MenuProps={{
                        //       className: classes.selectMenu,
                        //     }}
                        //     classes={{
                        //       select: classes.select,
                        //     }}
                        //     value={question.status}
                        //     inputProps={{
                        //       name: "status",
                        //       id: "simple-select",
                        //       onChange: (e) => {
                        //         this.handleSelect(e, question.id);
                        //       },
                        //     }}
                        //   >
                        //     <MenuItem
                        //       classes={{
                        //         root: classes.selectMenuItem,
                        //         selected: classes.selectMenuItemSelected,
                        //       }}
                        //       value="Active"
                        //     >
                        //       ACTIVE
                        //     </MenuItem>
                        //     <MenuItem
                        //       classes={{
                        //         root: classes.selectMenuItem,
                        //         selected: classes.selectMenuItemSelected,
                        //       }}
                        //       value="Inactive"
                        //     >
                        //       INACTIVE
                        //     </MenuItem>
                        //   </Select>,
                        // ],
                        // [
                        //   <Link
                        //     key={index}
                        //     to={{
                        //       pathname:
                        //         "/admin/updatequestionbank/" + question.id,
                        //       state: { levelData: question },
                        //     }}
                        //   >
                        //     <Button className="btn-round" color="info">
                        //       Update
                        //     </Button>
                        //   </Link>,
                        // ],
                        // [
                        //   <Button
                        //     key={index}
                        //     onClick={() => {
                        //       this.handleModal(question);
                        //     }}
                        //     className="btn-round"
                        //     color="danger"
                        //   >
                        //     {this.state.act === parseInt(question.id) ? (
                        //       <Loader />
                        //     ) : (
                        //       "Delete"
                        //     )}
                        //   </Button>,
                        // ],
                      ])
                    }
                  />
                ) : (
                  <Table
                    tableHead={[
                      "Sr.No.",
                      "Question Id",
                      "Question Number",
                      "Question",
                      // "Answer",
                      "Source",
                      "QR code",
                      "Solution Video Hindi",
                      "Solution Video English",

                      "Status",
                      this.state.is_deleted === 1 && "Update",
                      this.state.is_deleted === 1 ? "Restore" : "Delete",
                    ]}
                    tableData={
                      this.state.questionBank &&
                      this.state.questionBank.map((question, index) => [
                        [index + 1],
                        [question.id],
                        [question.question_number],
                        [<MathJax math={question.question} />],
                        // [<MathJax math={question.answer} />],
                        [
                          question.scanner_source
                            ? question.scanner_source.title
                            : "",
                        ],
                        // console.log(question.source),
                        [
                          question.qr_code ? (
                            <div key={index} style={{ position: "relative" }}>
                              {/* Display QR code image with a link to download on click */}
                              <a
                                href={Constants.imgUrl + question?.qr_code}
                                download={`qr_code_${question.question.charAt(
                                  0
                                )}.png`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={Constants.imgUrl + question?.qr_code}
                                  alt="QR Code"
                                  style={{ cursor: "pointer", width: 64 }}
                                />
                              </a>
                              {/* Display the white label on top of the QR code */}
                              {/* <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  backgroundColor: "#fff", // Set the background color to white
                                  padding: "2px", // Add padding for better visibility
                                  borderRadius: "5px", // Add border-radius for rounded corners
                                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Add a subtle shadow
                                }}
                              > */}
                              {/* <p
                                  style={{
                                    textAlign: "center",
                                    margin: 0, // Remove default margin for cleaner look
                                    color: "#007BFF", // Set the text color to blue
                                  }}
                                >
                                  {question.scanner_chapters.title
                                    ? question.scanner_chapters.title
                                        .charAt(0)
                                        .toUpperCase() +
                                      "-" +
                                      question.question_number
                                    : " "}
                                </p> */}
                              {/* </div> */}
                            </div>
                          ) : (
                            "Generate Code"
                          ),
                          console.log("question____", question),
                          question.qr_code ? (
                            // Display the download icon instead of the button
                            <a
                              href={
                                Constants.imgDomain +
                                "/api/scanner/download-qr-code/" +
                                question.id
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "inline-block",
                                width: "30px", // Set the size of the circle
                                height: "30px",
                                // borderRadius: "50%", // Make it circular
                                // border: "2px solid #007BFF", // Border color
                                textDecoration: "none",
                                position: "relative",
                                marginLeft: "28px",
                              }}
                            >
                              <i
                                className="material-icons"
                                style={{ color: "#007BFF", marginLeft: "-9px" }}
                              >
                                cloud_download
                              </i>
                            </a>
                          ) : (
                            "Generate Code"
                          ),
                        ],

                        [
                          // Button to view Hindi solution video
                          // <Button
                          //   key={index}
                          //   onClick={() =>
                          //     this.openVideoModal(
                          //       question.solution_videos_hindi
                          //     )
                          //   }
                          //   className="btn-round"
                          //   color="primary"
                          // >
                          //   View Hindi Video
                          // </Button>,
                          <Button
                            key={index}
                            onClick={() => {
                              const hindiVideoPath =
                                Constants.imgUrl +
                                question.solution_videos_hindi;
                              window.open(hindiVideoPath, "_blank");
                            }}
                            className="btn-round"
                            color="primary"
                          >
                            <i className="material-icons">&#xe417;</i>
                          </Button>,
                          question.solution_videos_hindi ? (
                            <Button
                              onClick={() =>
                                this.handleUploadVideo(question.id, "hindi")
                              }
                              className="btn-round"
                              color="success"
                            >
                              <i className="material-icons">cloud_done</i>
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                this.handleUploadVideo(question.id, "hindi")
                              }
                              className="btn-round"
                              color="gray" // Use a custom color class for gray (you may need to define this in your styles)
                              style={{ color: "gray" }} // Apply inline style for color
                            >
                              <i className="material-icons">cloud_upload</i>
                            </Button>
                          ),
                          // <Button
                          //   onClick={() =>
                          //     this.handleUploadVideo(question.id, "hindi")
                          //   }
                          //   className="btn-round"
                          //   color="info"
                          // >
                          //   Upload Video
                          // </Button>,
                        ],
                        [
                          // Button to view English solution video
                          <Button
                            key={index}
                            onClick={() => {
                              const englishVideoPath =
                                Constants.imgUrl +
                                question.solution_videos_english;
                              window.open(englishVideoPath, "_blank");
                            }}
                            className="btn-round"
                            color="primary"
                          >
                            <i className="material-icons">&#xe417;</i>
                          </Button>,

                          question.solution_videos_english ? (
                            <Button
                              onClick={() =>
                                this.handleUploadVideo(question.id, "english")
                              }
                              className="btn-round"
                              color="success"
                            >
                              <i className="material-icons">cloud_done</i>
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                this.handleUploadVideo(question.id, "english")
                              }
                              className="btn-round"
                              color="gray" // Use a custom color class for gray (you may need to define this in your styles)
                              style={{ color: "gray" }} // Apply inline style for color
                            >
                              <i className="material-icons">cloud_upload</i>
                            </Button>
                          ),

                          // <Button
                          //   onClick={() =>
                          //     this.handleUploadVideo(question.id, "english")
                          //   }
                          //   className="btn-round"
                          //   color={
                          //     question.solution_videos_english
                          //       ? "success"
                          //       : "info"
                          //   }
                          // >
                          //   Upload Video
                          // </Button>,
                        ],
                        [
                          <Select
                            key={index}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={question.status}
                            inputProps={{
                              name: "status",
                              id: "simple-select",
                              onChange: (e) => {
                                this.handleSelect(e, question.id);
                              },
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Active"
                            >
                              ACTIVE
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Inactive"
                            >
                              INACTIVE
                            </MenuItem>
                          </Select>,
                        ],
                        [
                          this.state.is_deleted === 1 ? (
                            <Button
                              key={index}
                              onClick={() => {
                                this.setState(
                                  {
                                    restore: true,
                                  },
                                  () => {
                                    this.handleModal(question);
                                  }
                                );
                              }}
                              className="btn-round"
                              color="info"
                            >
                              Upload File
                            </Button>
                          ) : null,
                          // <Link
                          //   key={index}
                          //   to={{
                          //     pathname:
                          //       "/admin/updatequestionbank/" + question.id,
                          //     state: { levelData: question },
                          //   }}
                          // >
                          //   <Button className="btn-round" color="info">
                          //     Update
                          //   </Button>
                          // </Link>,
                        ],
                        [
                          this.state.is_deleted === 1 ? (
                            <Button
                              key={index}
                              onClick={() => {
                                this.handleRestore(question);
                              }}
                              className="btn-round"
                              color="warning"
                            >
                              {this.state.act === parseInt(question.id) ? (
                                <Loader />
                              ) : (
                                "Restore"
                              )}
                            </Button>
                          ) : (
                            <Button
                              key={index}
                              onClick={() => {
                                this.handleModal(question);
                              }}
                              className="btn-round"
                              color="danger"
                            >
                              {this.state.act === parseInt(question.id) ? (
                                <Loader />
                              ) : (
                                "Delete"
                              )}
                            </Button>
                          ),
                        ],
                      ])
                    }
                  />
                )}
                {this.state.loading && "Wait new data is loading..."}
                {this.state.delete_multiple ? (
                  <Button
                    onClick={() => {
                      this.setState({
                        visible: true,
                        multi_dlt_modal: true,
                      });
                    }}
                  >
                    Delete Multiple
                  </Button>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    {this.state.multi_dlt_modal ? (
                      <div>
                        <h3>
                          Are You Sure You Want to Delete selected Questions?
                        </h3>
                        {this.state.notifyMultiDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              {this.state.message}
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={this.handleMultiDelete}
                            >
                              {this.state.delMultiLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({
                                  visible: false,
                                });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.is_deleted === 1 && this.state.restore ? (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                              labelText="Select File"
                              id="float"
                              inputProps={{
                                type: "file",
                                required: true,
                                onChange: this.handleFile,
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleSingleDocx(this.state.rowId);
                              }}
                            >
                              {this.state.delLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({
                                  visible: false,
                                });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.permanent_dlt ? (
                      <div>
                        <h3>
                          Are You Sure You Want to Delete Question permanently?
                        </h3>
                        {this.state.notifyDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Question Deleted Successfully!
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handlePermanentDelete(this.state.rowId);
                              }}
                            >
                              {this.state.delLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({
                                  visible: false,
                                });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : (
                      <div>
                        <h3>Are You Sure You Want to Delete It?</h3>
                        {this.state.notifyDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Question Deleted Successfully!
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleDelete(this.state.rowId);
                              }}
                            >
                              {this.state.delLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({
                                  visible: false,
                                });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>

          <Modal
            visible={this.state.visibleUpload}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visibleUpload: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    {/* File input */}
                    <input
                      type="file"
                      name="engHinddoc"
                      ref={this.inputfileref}
                      onChange={
                        this.state.videoKey == "english"
                          ? this.handleFileChangeEnglish
                          : this.handleFileChangeHindi
                      }
                    />

                    {/* Button to trigger the file upload */}
                    <Button
                      color="primary"
                      onClick={() => {
                        this.uploadVideos(this.state.vId, this.state.videoKey);
                      }}
                    >
                      {this.state.vDeoLoading ? <Loader /> : "Yes"}
                    </Button>
                    {this.state.mediaProgress ? (
                      <ProgressBar percentage={this.state.progress} />
                    ) : null}
                    <Button
                      color="danger"
                      onClick={() => {
                        this.closeVideoModal();
                        // this.setState({ visibleUpload: false });
                        // You may want to reset any state related to file upload here
                      }}
                    >
                      Cancel
                    </Button>
                  </CardBody>{" "}
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
        {this.state.questionBank.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div
              style={{
                float: "right",
                border: "1px solid grey",
              }}
            >
              <div
                onClick={() => this.changePage("back")}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    top: "3px",
                  }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.paginationArray.map((page, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(page);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (page % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (page % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (page % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (page % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === page ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === page ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {page}
                </div>
              ))}

              <div
                onClick={() => this.changePage("next")}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.paginationArray.length === 2 ||
                    this.state.paginationArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    top: "3px",
                  }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
        {/* Modal for viewing videos */}
        <Modal
          visible={this.state.videoModalOpen}
          width="800"
          height="600"
          effect="fadeInUp"
          onClickAway={this.closeVideoModal}
        >
          <div>
            <video width="100%" height="100%" controls>
              <source src={this.state.selectedVideoPath} type="video/mp4" />
              <source
                src={this.state.selectedVideoPath}
                type="video/ogg"
              ></source>
              Your browser does not support the video tag.
            </video>
          </div>
          <button
            className="close-button"
            onClick={this.closeVideoModal}
            style={{ border: "2px solid red" }}
          >
            Close
          </button>
        </Modal>
        {/* <Modal
          visible={this.state.videoModalOpen}
          width="800"
          height="600"
          effect="fadeInUp"
          onClickAway={this.closeVideoModal}
        >
          <div>
            <video width="100%" height="100%" controls>
              <source src={this.state.selectedVideoPath} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <button
            className="close-button"
            onClick={this.closeVideoModal}
            style={{ border: "2px solid red" }} // Add a temporary style
          >
            Close
          </button>
        </Modal> */}
      </div>
    );
  }
}
