import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Datetime from "react-datetime";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
// import { Multiselect } from "multiselect-react-dropdown";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { MultiSelect } from "react-multi-select-component";

class AddCouponCode extends Component {
  state = {
    code: "",
    name: "",
    amount: "",
    strDate: "",
    endDate: "",
    faculties: [],
    productIds: [],
    products: [],
    courses: [],
    // selectedCourses: [],
    redirect: false,
    notification: false,
    loading: false,
  };
  componentDidMount() {
    // books
    axios.get("https://admin.akclasses.in/api/faculty").then((resp) => {
      // console.log(resp.data);
      this.setState(
        {
          faculties: resp.data,
        },
        () => {
          axios
            .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
            .then((resp) => {
              // console.log(resp.data);
              resp.data.forEach((v) => {
                this.state.courses.push({
                  label:
                    v.course.TITLE +
                    "(" +
                    (v.course.faculty.length > 1
                      ? "AK Team)"
                      : this.findFaculty(v.course.faculty)
                      ? this.findFaculty(v.course.faculty) + ")"
                      : "Faculty Not Available" + ")") +
                    "(" +
                    v.course?.course_batches[0]?.batch?.batch_name +
                    ")" +
                    "(" +
                    v.course?.course_batches[0]?.batch?.attempt?.title +
                    ")",
                  value: v.course.ID,
                });
              });

              axios
                .get("https://admin.akclasses.in/api/coursesR?type=Books")
                .then((resp) => {
                  // console.log(resp.data);
                  resp.data.forEach((v) => {
                    this.state.courses.push({
                      label:
                        v.course.TITLE +
                        "(" +
                        (v.course.faculty.length > 1
                          ? "AK Team)"
                          : this.findFaculty(v.course.faculty)
                          ? this.findFaculty(v.course.faculty) + ")"
                          : "Faculty Not Available" + ")"),
                      value: v.course.ID,
                    });
                  });

                  this.setState({
                    courses: this.state.courses,
                  });
                });
            });
        }
      );
    });
  }
  findFaculty = (data) => {
    let obj = this.state.faculties.find((v) => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    this.state.products.forEach((v) => {
      this.state.productIds.push(v.value);
    });
    let payLoad = {
      type: "COUPON_CODE",
      order_type: "online",
      code: this.state.code,
      code_name: this.state.name,
      discount_amount: this.state.amount,
      start_date: this.state.strDate,
      end_date: this.state.endDate,
      product_id: this.state.productIds,
    };
    axios
      .post("https://admin.akclasses.in/api/couponCode/create", payLoad)
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/couponcodes"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Coupon Code Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Coupon Code</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        {/* <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="multiple-select"
                            className={classes.selectLabel}
                          >
                            Courses Select
                          </InputLabel>
                          <Select
                            multiple
                            value={this.state.productIds}
                            MenuProps={{
                              className: classes.selectMenu,
                              classes: { paper: classes.selectPaper }
                            }}
                            classes={{ select: classes.select }}
                            inputProps={{
                              name: "productIds",
                              onChange: e =>
                                this.setState(
                                  { productIds: e.target.value },
                                  () => {
                                    // console.log(this.state.productIds);
                                    // let selectedCourses = this.state.courses.filter(
                                    //   v =>
                                    //     this.state.productIds.includes(
                                    //       v.course.ID
                                    //     )
                                    // );
                                    // console.log(selectedCourses);
                                    // this.setState({ selectedCourses });
                                  }
                                )
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.course.ID}
                              >
                                {
                                  <span>
                                    {v.course.TITLE}(
                                    {v.course.faculty.length > 1
                                      ? "AK Team"
                                      : this.findFaculty(v.course.faculty)}
                                    )
                                  </span>
                                }
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}
                        {/* <Multiselect
                          options={this.state.courses} // Options to display in the dropdown
                          selectedValues={this.state.productIds} // Preselected value to persist in dropdown
                          onSelect={e => {
                            this.state.productIds.push(e.target.value);
                            this.setState({
                              productIds: this.state.productIds
                            });
                          }} // Function will trigger on select event
                          onRemove={e => {
                            this.state.productIds.splice(
                              this.state.productIds.indexOf(e.target.value),
                              1
                            );
                            this.setState({
                              productIds: this.state.productIds
                            });
                          }} // Function will trigger on remove event
                          displayValue="Courses" // Property name to display in the dropdown options
                        /> */}
                        <div
                          style={{
                            position: "relative",
                            bottom: "11px",
                            border: "none",
                          }}
                        >
                          <label htmlFor="Courses">Courses</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.courses}
                            value={this.state.products}
                            onChange={(data) => {
                              this.setState({ products: data });
                            }}
                            labelledBy={"Courses"}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Coupon Code"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ code: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Coupon Name/Label"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ name: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Discount Amount"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ amount: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  strDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.strDate);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Start Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  endDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.endDate);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "End Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Coupon Code"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/couponcodes",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddCouponCode;
