import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import Datetime from "react-datetime";
import MenuItem from "@material-ui/core/MenuItem";
import CardBody from "components/Card/CardBody.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";

const classes = makeStyles(styles);
class SendMessages extends Component {
  state = {
    type: "",
    frmDate: "",
    toDate: "",
    userType: "all user",
    types: [],
    template: 0,
    data: [],
    templates: [],
    message: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/get_type").then(resp => {
      //   console.log(resp.data);
      this.setState({
        types: resp.data
      });
    });
    axios
      .get("https://admin.akclasses.in/api/user_template?status=ACTIVE")
      .then(resp => {
        // console.log(resp.data);
        this.setState({
          templates: resp.data
          // template: resp.data[0] ? resp.data[0].id : 0,
          // message: resp.data[0] ? resp.data[0].template : ""
        });
      });
  }
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad;
    if (this.state.data.length === 0) {
      payLoad = {
        type: this.state.type,
        frmdate: this.state.frmDate,
        todate: this.state.toDate,
        message: this.state.message,
        user_type: this.state.userType
      };
    } else {
      payLoad = {
        type: this.state.type,
        frmdate: this.state.frmDate,
        todate: this.state.toDate,
        message: this.state.message,
        attach: this.state.data,
        user_type: this.state.userType
      };
    }
    axios
      .post("https://admin.akclasses.in/api/send_sms_to_user", payLoad)
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/messages"} />;
    } else {
      return (
        <div>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Message Sent!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Send Message</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "7px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.type}
                            inputProps={{
                              name: "type",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  type: e.target.value
                                });
                              }
                            }}
                          >
                            {this.state.types.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.type}
                              >
                                {v.type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "7px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            User Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.userType}
                            inputProps={{
                              name: "userType",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  userType: e.target.value
                                });
                              }
                            }}
                          >
                            {["Interested Users", "All Users"].map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={
                                  v === "All Users"
                                    ? "all user"
                                    : "interested user"
                                }
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem
                        style={{ marginTop: "7px" }}
                        xs={12}
                        sm={12}
                        md={4}
                      >
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="multiple-select"
                            className={classes.selectLabel}
                          >
                            Optional Data
                          </InputLabel>
                          <Select
                            multiple
                            value={this.state.data}
                            MenuProps={{
                              className: classes.selectMenu,
                              classes: { paper: classes.selectPaper }
                            }}
                            classes={{ select: classes.select }}
                            inputProps={{
                              name: "multipleSelect",
                              id: "multiple-select",
                              onChange: e =>
                                this.setState({ data: e.target.value })
                            }}
                          >
                            {["Serial Key", "Password"].map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={
                                  v === "Password" ? "password" : "serial_key"
                                }
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  frmDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.strDate);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "From Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  toDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.endDate);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "To Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "7px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Template
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.template}
                            inputProps={{
                              name: "template",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  template: e.target.value,
                                  message: this.state.templates.find(
                                    v => v.id === e.target.value
                                  ).template
                                });
                              }
                            }}
                          >
                            {this.state.templates.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.template_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label htmlFor="">Message</label>
                        <br />
                        <textarea
                          name="message"
                          onChange={this.handleInput}
                          required
                          value={this.state.message}
                          className="form-control"
                          cols="119"
                          rows="6"
                        ></textarea>
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Send Message"}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/messages"
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default SendMessages;
