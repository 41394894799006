import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
import Modal from "react-awesome-modal";
import Loader from "components/Loader";
import MathJax from "react-mathjax-preview";
import renderHTML from "react-render-html";
const classes = makeStyles(styles);

export default class SubjectQuestion extends Component {
  state = {
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    questionBank: [],
    paperId: window.location.pathname.split("/")[3],
    courseId: window.location.pathname.split("/")[4],
    delete_multiple: false,
    greaterQuestion: false,
    alreadyAddedQuestion: false,
    addedQuestions: [],
    selectedQuestion: [],
    multi_dlt_modal: false,
    notifyMultiDelete: false,
    delMessage: "",
    delMultiLoading: false,
    select_all: false,
  };
  componentDidMount() {
    console.log(this.props.match.params.id);
    this.getquestion();
  }
  getquestion = () => {
    axios
      .get(Constants.getUrls.getSubjectQuestions + this.state.paperId)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            questionBank: resp.data,
            paperId: this.state.paperId,
          });
        } else {
          this.setState({
            questionBank: resp.data,
            paperId: this.state.paperId,
          });
        }
      });
  };

  handleDelete = (id) => {
    // console.log(id);
    this.setState({ delLoading: true });
    axios
      .delete(Constants.postUrls.DeleteSubjectQuestions + id)
      .then((resp) => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };
  handleModal = (questionId) => {
    console.log(questionId);
    this.setState({
      rowId: questionId,
      visible: true,
    });
  };

  select_All = () => {
    const selectedQuestion = this.state.questionBank.map((item) => item.ID);
    this.setState({
      selectedQuestion,
      check: true,
      select_all: !this.state.select_all,
    });
  };

  handleMultiDelete = () => {
    this.setState({
      delMultiLoading: true,
    });
    let payload = {
      ids: this.state.selectedQuestion,
    };
    axios
      .post(Constants.deleteUrls.deletebulkPaperMasterQuestion, payload)
      .then((resp) => {
        this.setState({
          delMultiLoading: false,
          delMessage: "Selected Questions Deleted",
          notifyMultiDelete: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Question</h3>

                {
                  <Link
                    to={{
                      pathname:
                        "/admin/addsubjectquestion/" +
                        this.state.paperId +
                        "/" +
                        this.state.courseId,
                    }}
                  >
                    <Button className="" color="success">
                      Add Questions
                    </Button>
                  </Link>
                }
                <Button
                  // color="#AA77FF"
                  style={{
                    backgroundColor: "#AA77FF",
                  }}
                  onClick={() => {
                    this.setState({
                      delete_multiple: true,
                    });
                  }}
                >
                  Delete Multiple
                </Button>
                {this.state.delete_multiple === true ? (
                  <Button
                    onClick={this.select_All}
                    color="warning"
                    // style={{
                    //   backgroundColor: "#FC2947",
                    // }}
                  >
                    Select All
                  </Button>
                ) : null}
                <br />
                {this.state.delete_multiple ? (
                  <Button
                    onClick={() => {
                      this.setState({
                        visible: true,
                        multi_dlt_modal: true,
                      });
                    }}
                    style={{
                      backgroundColor: "#FC2947",
                    }}
                  >
                    Deleted Selected
                  </Button>
                ) : null}

                {this.state.delete_multiple === true ? (
                  <Table
                    tableHead={[
                      "Sr.No.",
                      "Select",
                      "Level",
                      "Subject",
                      "Chapter",
                      "Question",
                      //   "Delete",
                    ]}
                    tableData={
                      this.state.questionBank &&
                      this.state.questionBank.map((question, index) => [
                        [index + 1],
                        [
                          <input
                            type="checkbox"
                            name="checkQuestion"
                            style={{ marginLeft: "27px" }}
                            checked={this.state.selectedQuestion.includes(
                              question.ID
                            )}
                            onChange={(checkbox) => {
                              const selectedQuestion = this.state.selectedQuestion.find(
                                (que) => que === question.ID
                              );
                              // console.log(selectedQuestion);
                              if (selectedQuestion) {
                                const i = this.state.selectedQuestion.findIndex(
                                  (que) => que === question.ID
                                );
                                this.state.selectedQuestion.splice(i, 1);
                                this.setState(
                                  {
                                    check: true,
                                    selectedQuestion: this.state
                                      .selectedQuestion,
                                    products: question.ID,
                                    dAlert: false,
                                    greaterQuestion: false,
                                    alreadyAddedQuestion: false,
                                  },
                                  () => {
                                    console.log(this.state.selectedQuestion);
                                  }
                                );
                              } else {
                                this.state.selectedQuestion.push(question.ID);
                                this.setState(
                                  {
                                    check: true,
                                    selectedQuestion: this.state
                                      .selectedQuestion,
                                    products: question.ID,
                                    dAlert: false,
                                    greaterQuestion: false,
                                    alreadyAddedQuestion: false,
                                  },
                                  () => {
                                    console.log(this.state.selectedQuestion);
                                  }
                                );
                              }
                            }}
                          />,
                        ],
                        [question.level.title],
                        [question.stream.title],
                        [question.chapter.title],
                        [<MathJax math={question.QUESTION} />],
                        // [
                        //   <Button
                        //     key={index}
                        //     onClick={() => {
                        //       this.handleModal(question.ID);
                        //     }}
                        //     className="btn-round"
                        //     color="danger"
                        //   >
                        //     {this.state.act === parseInt(question.ID) ? (
                        //       <Loader />
                        //     ) : (
                        //       "Delete"
                        //     )}
                        //   </Button>,
                        // ],
                      ])
                    }
                  />
                ) : (
                  <Table
                    tableHead={[
                      "Sr.No.",
                      "Level",
                      "Subject",
                      "Chapter",
                      "Question",
                      "Delete",
                    ]}
                    tableData={
                      this.state.questionBank &&
                      this.state.questionBank.map((que, index) => [
                        [index + 1],
                        [que.level.title],
                        [que.stream.title],
                        [que.chapter.title],
                        [<MathJax math={que.QUESTION} />],
                        [
                          <Button
                            key={index}
                            onClick={() => {
                              this.handleModal(que.ID);
                            }}
                            className="btn-round"
                            color="danger"
                          >
                            {this.state.act === parseInt(que.ID) ? (
                              <Loader />
                            ) : (
                              "Delete"
                            )}
                          </Button>,
                        ],
                      ])
                    }
                  />
                )}
                {this.state.delete_multiple ? (
                  <Button
                    onClick={() => {
                      this.setState({
                        visible: true,
                        multi_dlt_modal: true,
                      });
                    }}
                  >
                    Delete Multiple
                  </Button>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    {this.state.delete_multiple === true ? (
                      <div>
                        <h3>
                          Are You Sure You Want to Delete selected Questions?
                        </h3>
                        {this.state.notifyMultiDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              {this.state.message}
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={this.handleMultiDelete}
                            >
                              {this.state.delMultiLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : (
                      <div>
                        <h3>Are You Sure You Want to Delete It?</h3>
                        {this.state.notifyDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Question Deleted Successfully!
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleDelete(this.state.rowId);
                              }}
                            >
                              {this.state.delLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
      </div>
    );
  }
}
