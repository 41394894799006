import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
import Modal from "react-awesome-modal";
import { Link } from "react-router-dom";
const classes = makeStyles(styles);

const perPages = ["10", "25", "50", "100"];
class FaceToFaceOfflineOrder extends Component {
  state = {
    notifyStatus: false,
    facetofacedata: [],
    status: "",
    active: 1,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    visibleDelete: false,
    deleteId: "",
  };

  componentDidMount() {
    this.getFaceToFace();
  }

  getFaceToFace = () => {
    axios.get(Constants.getUrls.getFaceToFaceOfflineOrder ).then(resp => { 
      console.log(resp);
      let btnIdArray = [];
      for (let i = 1; i <= resp.data.last_page; i++) {
        btnIdArray.push(i);
      }
      this.setState(
        {
          btnIdArray,
          facetofacedata: resp.data.data,
          total: resp.data.total,
          from: resp.data.from,
          to: resp.data.to
        },
        () => {
          let filteredBtnIdArray = this.state.btnIdArray.filter(
            v => v >= this.state.fromInc && v <= this.state.toInc
          );
          this.setState({
            filteredBtnIdArray
          });
        }
      );
    });
  }
  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/facetofaceOfflineOrders?per_page=" +
              this.state.per_page
          )
          .then(resp => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                facetofacedata: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray
                });
              }
            );
          });
      }
    );
  };
  currentPage = v => {
    let commonUrl =
    "https://admin.akclasses.in/api/facetofaceOfflineOrders?per_page=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then(resp => {
      console.log(resp);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        facetofacedata: resp.data.data,
        active: v
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post(Constants.postUrls.changeSourceStatus + id, payload)
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    );
  };

  handleModal = id => {
    this.setState({ visibleDelete: true,deleteId: id });
  }

  collectPayment = id => {
    axios.post("https://admin.akclasses.in/api/collectPayment/" + id).then(resp => {
        console.log(resp);
        if(resp.data.status==="success")
        {
            this.getFaceToFace();
        }
    });
  }

  deleteOrder = () => {
    console.log(this.state.deleteId);
    axios
      .delete("https://admin.akclasses.in/api/facetofaceOfflineOrders/"+ this.state.deleteId)
      .then(res => {
        console.log(res);
        this.setState({
          visibleDelete: false
        });
        this.getFaceToFace();
      });
  }

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>

                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <h3>Offline Orders</h3>
                <Link
                    to={"/admin/addfacetofaceofflineorder"}
                >
                    <Button className="" color="warning">
                    Add Offline Order
                    </Button>
                </Link>
                <Table
                  tableHead={["Sr No.", "Order No", "Order Date", "Days", "Title", "Batch Started Date", "Batch Ended Date", "Student Details", "Total Amount", "Update","Delete"]}
                  tableData={this.state.facetofacedata.map(
                    (sec, index) => [
                      [index + 1],
                      [sec?.order_id],
                      [
                        sec?.created_at.slice(8, 10) +
                        "-" +
                        sec?.created_at.slice(5, 7) +
                        "-" +
                        sec?.created_at.slice(0, 4)],
                      [sec?.facetoface?.day],
                      [sec?.facetoface?.city],
                      [sec?.facetoface?.batch_started_date],
                      [sec?.facetoface?.batch_ended_date],
                      [
                        <div key={index}>
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold"
                          }}
                        >
                          {sec.user
                            ? (sec?.user?.FIRST_NAME
                                ? sec?.user?.FIRST_NAME
                                : "") +
                              " " +
                              (sec?.user?.LAST_NAME
                                ? sec?.user?.LAST_NAME
                                : "")
                            : "NA"}
                          ,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "15px",
                            // fontWeight:'bold',
                            color: "blue"
                          }}
                        >
                          {sec.user
                            ? sec?.user?.email
                            : "NA"}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold"
                          }}
                        >
                          {sec.user
                            ? sec?.user?.MOBILE
                            : "NA"}
                          ,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold"
                          }}
                        >
                          {sec.address
                            ? sec.address
                            : sec.user
                            ? sec.user.ADDRESS_1
                            : "NA"}
                        </span>
                        <br />

                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold"
                          }}
                        >
                          {sec.city
                            ? sec?.city
                            : sec.user
                            ? sec.user.CITY
                            : "NA"}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold"
                          }}
                        >
                          {sec.pincode
                            ? sec.pincode
                            : sec.user
                            ? sec.user.PINCODE
                            : "NA"}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "red"
                          }}
                        >
                          PW :{" "}
                          {sec.user
                            ? sec.user.DE_PASSWORD
                              ? sec.user.DE_PASSWORD
                              : "NA"
                            : "NA"}
                        </span>
                        <br />
                      </div>
                      ],
                      [sec.grand_total],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/updatefacetofaceofflineorder/" + sec.id,
                            state: { categoryData: sec }
                          }}
                        >
                          <Button className="btn-round" color="info">
                            Update
                          </Button>
                        </Link>
                      ],
                      [
                        <Button className="btn-round" color="danger" onClick={ () => {this.handleModal(sec.id)}}>
                          Delete
                        </Button>
                      ]
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visibleDelete}
          width="435px"
          height="277px"
          effect="fadeInUp"
          onClickAway={() => this.setState({
            visibleDelete: false
          })
          }
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <h3>Are You Sure You Want to Delete It?</h3>
                    <div>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="danger"
                          onClick={this.deleteOrder}
                        >
                          Delete
                        </Button>
                        <Button
                          className=""
                          color="warning"
                          onClick={() => {
                            this.setState({ visibleDelete: false });
                          }}
                        >
                          Cancel
                        </Button>
                      </GridItem>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>

        {this.state.facetofacedata.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black"
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FaceToFaceOfflineOrder;
