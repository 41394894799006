import React, { Component } from "react";
import Batches from "../Batches";
import Books from "../Books";
import Footer from "../common/Footer";
import Header from "../common/Header";
import JoinNow from "../JoinNow";
import Poster from "../Poster";
import Testimonials from "../Testimonials";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
export default class HomePage extends Component {
  componentDidMount() {
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount < 1) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  }

  render() {
    return (
      <div>
        <Header />
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1000}
          isVisible={true}
          animateOnce={true}
        > */}
        <Poster />
        {/* </ScrollAnimation> */}
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1000}
          isVisible={true}
          animateOnce={true}
        > */}
        {/* <Batches /> */}
        {/* </ScrollAnimation> */}
        {/* <Books />
        <Testimonials />
        <JoinNow /> */}
        {/* <Footer /> */}
      </div>
    );
  }
}
