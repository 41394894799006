/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
const classes = makeStyles(styles);
class UpdateSeriesQuestion extends Component {
  state = {
    paper: "",
    name: "",
    category: "",
    testSeriesType: "",
    option_value_1: "",
    option_value_2: "",
    option_value_3: "",
    option_value_4: "",
    question: "",
    answer: "",
    solution: "",
    time: "",
    testCategory: [],
    testName: [],
    testPaper: [],
    file: "",
    redirect: false,
    notification: false,
    loading: false,
    showCK: false,
    showUploader: false,
    levels: [],
    streams: [],
    level: "",
    stream: "",
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    options: ["Option 1", "Option 2", "Option 3", "Option 4"],
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    // console.log(categoryData);
    axios.get("https://admin.akclasses.in/api/test_series").then((resp) => {
      // console.log(resp.data);
      this.setState({
        testName: resp.data.test_Series,
        name: categoryData.test_series_id,
        question: categoryData.question,
        time: categoryData.per_question_time,
        option_value_1: categoryData.option_value_1,
        option_value_2: categoryData.option_value_2,
        option_value_3: categoryData.option_value_3,
        option_value_4: categoryData.option_value_4,
        answer:
          categoryData.answer === categoryData.option_value_1
            ? "Option 1"
            : categoryData.answer === categoryData.option_value_2
            ? "Option 2"
            : categoryData.answer === categoryData.option_value_3
            ? "Option 3"
            : categoryData.answer === categoryData.option_value_4
            ? "Option 4"
            : "",
      });
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.question === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.option_value_1 === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.option_value_2 === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.option_value_3 === "") {
      this.setState({
        dAlert: true,
      });
    } else if (this.state.option_value_4 === "") {
      this.setState({
        eAlert: true,
      });
    } else if (this.state.answer === "") {
      this.setState({
        fAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      let valueAnswer =
        this.state.answer === "Option 1"
          ? this.state.option_value_1
          : this.state.answer === "Option 2"
          ? this.state.option_value_2
          : this.state.answer === "Option 3"
          ? this.state.option_value_3
          : this.state.answer === "Option 4"
          ? this.state.option_value_4
          : "";
      let payLoad = {
        test_series_id: this.state.name,
        question: this.state.question,
        option_value_1: this.state.option_value_1,
        option_value_2: this.state.option_value_2,
        option_value_3: this.state.option_value_3,
        option_value_4: this.state.option_value_4,
        per_question_time: parseFloat(this.state.time),
        answer: valueAnswer,
        _method: "put",
      };
      //   console.log(payLoad);
      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.id;
      axios
        .post("https://admin.akclasses.in/api/quest/" + categoryId, payLoad)
        .then(() => {
          //console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/seriesquestions"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Question Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Question</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Series
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.name}
                            inputProps={{
                              name: "name",
                              id: "simple-select",
                              onChange: (e) =>
                                this.setState({ name: e.target.value }),
                            }}
                          >
                            {this.state.testName.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Question</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.props.location.state.categoryData.question}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              question: data,
                              aAlert: false,
                            });
                          }}
                        />
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Option 1</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={
                            this.props.location.state.categoryData
                              .option_value_1
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              option_value_1: data,
                              bAlert: false,
                            });
                          }}
                        />
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Option 2</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={
                            this.props.location.state.categoryData
                              .option_value_2
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              option_value_2: data,
                              cAlert: false,
                            });
                          }}
                        />
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Option 3</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={
                            this.props.location.state.categoryData
                              .option_value_3
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              option_value_3: data,
                              dAlert: false,
                            });
                          }}
                        />
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Option 4</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={
                            this.props.location.state.categoryData
                              .option_value_4
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              option_value_4: data,
                              eAlert: false,
                            });
                          }}
                        />
                        {this.state.eAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Answer
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.answer}
                            inputProps={{
                              name: "answer",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  answer: e.target.value,
                                });
                              },
                            }}
                          >
                            {this.state.options.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Time"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.time,
                            type: "float",
                            onChange: (e) =>
                              this.setState({
                                time: e.target.value,
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={7}>
                            <Button color="success" onClick={this.addMore}>
                              Add More
                            </Button>
                          </GridItem> */}
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Question"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/seriesquestions",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateSeriesQuestion;
