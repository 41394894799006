import React, { Component } from "react";
import akLogo from "../../images/AK-logo1.png";
import Fb from "../../images/facebook-circular-logo.png";
import twitter from "../../images/twitter.png";
import instagram from "../../images/instagram.png";
import linkedin from "../../images/linkedin.png";
import sendIcon from "../../images/paper-plane.png";
import headphones from "../../images/headphones.png";
import "../../../css/Footer.css";
import "../../scss/Footer.scss";

export default class Footer extends Component {
  render() {
    return (
      <div className="newFooter">
        <div className="row">
          <div className="col-sm-3">
            <img src={akLogo} alt="" className="newfooterLogo" />
            <div
              className={
                window.innerWidth === 1396 ? "details1396" : "Footerdetails"
              }
            >
              <h5 className="forDetail">
                For Details: <br />
                48/101, The Park Meadows, Kachimet, <br />
                Amravati Road, Nagpur, 440033 <br />
                (Maharashtra) India <br />
                <br />
                Email Us: bvlhelpdesk@gmail.com
              </h5>
              <br />
              <br />
              <div className=" iconFlexDiv">
                <img src={Fb} alt="" className="fbLogo" />
                <img src={twitter} alt="" className="fbLogo" />{" "}
                <img src={instagram} alt="" className="fbLogo" />
                <img src={linkedin} alt="" className="fbLogo" />
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xs-6 fonts">
            <div>
              <h3 style={{ fontWeight: "bold" }}>Quick Links</h3>
            </div>
            <div className="instructor">
              <h4>Become Instructor</h4>
              <h4>Issue Redressal</h4>
              <h4>Privacy Policy</h4>
              <h4>Terms & Conditions</h4>
              <h4>Return Policy</h4>
            </div>
          </div>
          <div className="col-sm-3 col-xs-6 fonts">
            <div>
              <h3 style={{ fontWeight: "bold" }}>Explore</h3>
            </div>
            <div className="explore">
              <h4>CA Courses</h4>
              <h4>Study Material</h4>
              <h4>Franchise Centers</h4>
              <h4>Franchise Login</h4>
              <h4>Quick Enquiry</h4>
            </div>
          </div>
          <div className="col-sm-3 fonts">
            <div>
              <h3 className="subscribe" style={{ fontWeight: "bold" }}>
                Subscribe Free Newsletter
              </h3>
            </div>
            <div className="mailbox">
              {/* <span className="emailHexFooter">&#9993;</span> */}
              <i class="fa-regular fa-envelope emailHexFooter"></i>
              <input
                type="text"
                className={
                  window.innerWidth === 1396
                    ? "inputMailFooter1396"
                    : "inputMailFooter"
                }
                placeholder="Enter Email"
              />
              <span className="sendEmailFooter">
                <img src={sendIcon} alt="" className="sendIcon" />
              </span>
            </div>
            <div className="downloadAppText">
              <h2>Download App</h2>
            </div>
          </div>
        </div>

        <div className="row footerBottom">
          <div className="col-md-4 borderRight">
            <h4 className="akName">
              Ak Classes. <br />
              Designed and developed by <br />
              The Space Element
            </h4>
          </div>

          <div className="col-md-4 borderRight haveQuestionMain">
            <span>
              <img src={headphones} alt="" className="headphone" />
              <span className="haveQuestion">
                Have a question? Call us <br />
                <span className="mobileNumber">+91 9356624677</span> <br />
                <br />
              </span>
            </span>
          </div>
          <div className="col-md-4">
            <h4 className="akNamePara">
              In case of Pen Drive issues/ Videos Lectures issues: <br />
              Please Call: +91 8766578508 <br />
              Call Timings: 10am to 1pm & 2pm to 5pm
              <br />
              In case phone is busy, please Whatsapp on +91 9356624677
            </h4>
          </div>

          <div className="col-md-4 borderRight akName1">
            <h4 className="akName1">
              Ak Classes. <br />
              Designed and developed by The Space Element
            </h4>
          </div>
        </div>
      </div>
    );
  }
}
