/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";

class UpdateBookCategory extends Component {
  state = {
    // type: "",
    // catData: ["COURSE", "TEST_SERIES"],
    category: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    //console.log(categoryData);

    this.setState({
      // type: categoryData.TYPE,
      category: categoryData.CATEGORY
    });
  }

  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      type: "BOOKS",
      category: this.state.category
    };
    let categoryData = this.props.location.state.categoryData;
    let categoryId = categoryData.ID;
    axios
      .post(
        "https://admin.akclasses.in/api/courseCategory/update/" + categoryId,
        payLoad
      )
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/bookcategories"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Category Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Book Category</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      {/* <GridItem xs={12} sm={12} md={5}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Type Select
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.type}
                            inputProps={{
                              name: "type",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({ type: e.target.value });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.catData.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                          labelText="Category"
                          inputProps={{
                            value: this.state.category,
                            required: true,
                            onChange: e =>
                              this.setState(
                                { category: e.target.value },
                                () => {
                                  //console.log(this.state.category);
                                }
                              )
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" type="submit" color="info">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Update Book Category"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/bookcategories"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateBookCategory;
