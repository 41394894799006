import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import Constants from "variables/Constants";
import renderHTML from "react-render-html";
import Datetime from "react-datetime";
import { MultiSelect } from "react-multi-select-component";
import CustomInput from "components/CustomInput/CustomInput.js";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
};

export default class FaceToFaceBatchDays extends Component {
  state = {
    aAlert: false,
    loading: false,
    visible: false,
    visibleQr: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    allTopics: [],
    selected_topic: [],
    secondaryCategories: [],
    batch_id: "",
    notifyUpdate: false,
    rowId: "",
    ftof_batch_id: "",
    ftof_batches_day_id: "",
    topic_id: "",
    sub_topic: "",
    sub_topic_date: "",
    qr_validity_date: "",
    ftofbatchdayid: "",
    qr_validity_date: "",

    active: 1,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    code_status: "Inactive",
    code_validity_date: "",
  };

  componentDidMount() {
    this.setState(
      {
        batch_id: window.location.pathname.split("/")[3],
      },
      () => {
        this.getBatchDays();
      }
    );
    this.getTopics();
  }

  getBatchDays = () => {
    this.setState(
      {
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.facetoaceBatchDays +
              "?batch_id=" +
              this.state.batch_id +
              "&per_page=" +
              this.state.per_page
          )
          // .get("https://admin.akclasses.in/api/ftof_batches_days/get_batch_days?batch_id=4")
          .then((resp) => {
            console.log(resp);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                active: 1,
                secondaryCategories: resp.data.data.data,
                total: resp.data.data.total,
                from: resp.data.data.from,
                to: resp.data.data.to,
              },
              () => {
                console.log(this.state.secondaryCategories);
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.facetoaceBatchDays +
              "?batch_id=" +
              this.state.batch_id +
              "&per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data.data,
                total: resp.data.data.total,
                from: resp.data.data.from,
                to: resp.data.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  currentPage = (v) => {
    let commonUrl =
      Constants.getUrls.facetoaceBatchDays +
      "?batch_id=" +
      this.state.batch_id +
      "&per_page=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.data.total,
        from: resp.data.data.from,
        to: resp.data.data.to,
        secondaryCategories: resp.data.data.data,
        operatinalCategories: resp.data.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  getTopics = () => {
    axios
      .get("https://admin.akclasses.in/api/topic?per_page=200")
      .then((resp) => {
        console.log(resp);
        // resp.data.data.map((item) => {
        //   this.state.allTopics.push({
        //     label: item?.topic?.name,
        //     value: item?.topic?.id,
        //   });
        // });
        this.setState(
          {
            allTopics: resp.data.data,
            topic_id: resp.data.data[0].topic?.id,
          },
          () => {
            console.log(this.state.topic_id);
          }
        );
      });
  };

  handleModal = (sec) => {
    this.setState(
      {
        rowId: sec.id,
        visible: true,
      },
      () => {
        console.log(this.state.rowId);
      }
    );
  };

  handleCode = (sec) => {
    this.setState(
      {
        ftofbatchdayid: sec.id,
        visibleQr: true,
      },
      () => {
        console.log(this.state.ftofbatchdayid);
      }
    );
  };

  addCodeAndValidityDate = (e) => {
    console.log(this.state.ftofbatchdayid);
    e.preventDefault();
    const payload = {
      code_validity_date: this.state.code_validity_date,
    };
    axios
      .post(Constants.postUrls.create_code + this.state.ftofbatchdayid, payload)
      .then((res) => {
        console.log(res);
        this.setState(
          {
            notification: false,
            message: "",
            visibleQr: false,
          },
          () => {
            this.getBatchDays();
          }
        );
      });
  };

  handleSelect = (e, id) => {
    console.log(e.target.value, id);
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          code_status: e.target.value,
        };
        axios
          // .post(Constants.postUrls.change_code_status + id, payload)
          .post(Constants.postUrls.change_code_status + id, payload)
          .then((res) => {
            console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              // window.location.reload();
              this.setState(
                {
                  notification: false,
                  message: "",
                },
                () => {
                  this.getBatchDays();
                }
              );
            }, 1000);
          });
      }
    );
  };

  generateQR = (e) => {
    console.log("function call");
    e.preventDefault();

    this.setState({ loading: true, visibleQr: false });

    let payload = {
      ftofbatchday_id: this.state.ftofbatchdayid,
      qrValidityDate: this.state.qr_validity_date,
    };
    axios.post(Constants.postUrls.addQrValidityDate, payload).then((res) => {
      console.log(res);
    });

    axios
      .get(Constants.getUrls.createQrCode + this.state.ftofbatchdayid)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            message: resp.data.status,
            notification: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState(
              {
                notification: false,
                message: "",
              },
              () => {
                this.getBatchDays();
              }
            );
          }, 2000);
        }
      });
  };

  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        console.log(this.state.topic_id);
      }
    );
  };
  updateTopic = (e) => {
    console.log("fuction caleed");
    e.preventDefault();

    this.setState({ loading: true });

    let payload = {
      ftof_batch_id: this.state.batch_id,
      ftof_batches_day_id: this.state.rowId,
      topic_id: this.state.topic_id,
      sub_topic: this.state.sub_topic,
      sub_topic_date: this.state.sub_topic_date,
      status: "active",
    };

    axios
      .post(Constants.postUrls.updateFacetoFaceBatchTopic, payload)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            visible: false,
            loading: false,
            message: resp.data.message,
            notification: true,
            // ftof_batch_id: "",
            // ftof_batches_day_id: "",
            // topic_id: "",
            // sub_topic: "",
            // sub_topic_date: "",
          });
          setTimeout(() => {
            this.setState(
              {
                message: "",
                notification: false,
              },
              () => {
                this.getBatchDays();
              }
            );
          }, 2000);
        }
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={this.state.message}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative", top: "0px" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                    top: "-30px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Link
                  to={{
                    pathname: "/admin/facetofacebatch",
                  }}
                >
                  <Button className="btn-round" color="success">
                    Back to Batches
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Day No",
                    "Topic",
                    "Sub Topic",
                    "Sub Topic Date",
                    // "QR Validity Date",
                    // "QR Code",
                    // "Generate Qr",
                    "Status",
                    "Generate Code",
                    "Verification Code",
                    "Code Status",
                    "Code Validity Date",
                    "Update Topic",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [sec?.topic?.name],
                      [sec?.sub_topic],
                      [sec?.sub_topic_date],
                      // [sec?.qr_validity_date],
                      // [
                      //   sec.qr_code ? (
                      //     <a
                      //       href={Constants.imgUrl + sec?.qr_code}
                      //       target="_blank"
                      //     >
                      //       <img
                      //         src={Constants.imgUrl + sec?.qr_code}
                      //         alt="QR Code"
                      //       />
                      //     </a>
                      //   ) : (
                      //     "Generate Code"
                      //   ),
                      // ],
                      // [
                      //   <Button
                      //     style={{ cursor: "pointer" }}
                      //     onClick={() => {
                      //       this.handleCode(sec);
                      //     }}
                      //     key={index}
                      //     color="danger"
                      //   >
                      //     Generate Qr
                      //   </Button>,
                      // ],
                      [sec?.status],

                      [
                        <Button
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.handleCode(sec);
                          }}
                          key={index}
                          color="danger"
                        >
                          Generate Code
                        </Button>,
                      ],
                      [sec?.code],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sec.code_status}
                          inputProps={{
                            name: "code_status",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleSelect(e, sec.id);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="Active"
                          >
                            Active
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="Inactive"
                          >
                            Inactive
                          </MenuItem>
                        </Select>,
                      ],
                      [sec?.code_validity_date],
                      [
                        <Button
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.handleModal(sec);
                          }}
                          key={index}
                          color="success"
                        >
                          Update Topic
                        </Button>,
                      ],
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visibleQr}
            width="600"
            effect="fadeInUp"
            style={customStyles}
            onClickAway={() => this.setState({ visibleQr: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <div>
                      {/* <form onSubmit={this.addCodeAndValidityDate}> */}
                      <form onSubmit={this.addCodeAndValidityDate}>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            style={{ marginTop: "25px" }}
                          >
                            <Datetime
                              timeFormat={false}
                              onChange={(e) => {
                                let year = e._d.getFullYear().toString();
                                let month = (e._d.getMonth() + 101)
                                  .toString()
                                  .substring(1);
                                let day = (e._d.getDate() + 100)
                                  .toString()
                                  .substring(1);
                                this.setState(
                                  {
                                    code_validity_date:
                                      // qr_validity_date:
                                      year + "/" + month + "/" + day,
                                  },
                                  () => {
                                    //console.log(this.state.endDate);
                                  }
                                );
                              }}
                              inputProps={{
                                required: true,
                                placeholder: "Code Validity Date",
                                // placeholder: "Qr Validity Date",
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            style={{ position: "relative", left: "60%" }}
                          >
                            <Button className="" color="warning" type="submit">
                              {this.state.loading ? <Loader /> : "Update"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visibleQr: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>

          <Modal
            visible={this.state.visible}
            width="600"
            // height="100"
            effect="fadeInUp"
            style={customStyles}
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3>Select Topic for the Day</h3>
                      {this.state.notifyUpdate ? (
                        <div>
                          <span style={{ color: "green" }}>
                            {this.state.message}
                          </span>
                        </div>
                      ) : null}
                      <form onSubmit={this.updateTopic}>
                        <GridContainer style={{ marginLeft: "10px" }}>
                          <select
                            name="topic_id"
                            // onSelect={this.updateTopic}
                            onChange={this.handleInput}
                            style={{
                              width: "350px",
                              height: "40px",
                            }}
                          >
                            {this.state.allTopics.map((item, idx) => (
                              <option key={idx} value={item?.topic?.id}>
                                {item?.topic?.name}
                              </option>
                            ))}
                          </select>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Sub Topic"
                              id="float"
                              inputProps={{
                                required: true,
                                type: "text",
                                onChange: (e) =>
                                  this.setState({ sub_topic: e.target.value }),
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            style={{ marginTop: "25px" }}
                          >
                            <Datetime
                              timeFormat={false}
                              onChange={(e) => {
                                let year = e._d.getFullYear().toString();
                                let month = (e._d.getMonth() + 101)
                                  .toString()
                                  .substring(1);
                                let day = (e._d.getDate() + 100)
                                  .toString()
                                  .substring(1);
                                this.setState(
                                  {
                                    sub_topic_date:
                                      year + "/" + month + "/" + day,
                                  },
                                  () => {
                                    //console.log(this.state.endDate);
                                  }
                                );
                              }}
                              inputProps={{
                                required: true,
                                placeholder: "Sub Topic Date",
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <Button
                              className=""
                              color="warning"
                              type="submit"
                              // onClick={() => {
                              //   this.updateTopic();
                              // }}
                            >
                              {this.state.loading ? <Loader /> : "Update"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
