import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Constants from "variables/Constants";
let fd = new FormData();
class TestInstructions extends Component {
  state = {
    heading: "",
    defGoal: "",
    redirect: false,
    notification: false,
    loading: false,
    img: {},
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/testInstruction").then((resp) => {
      //console.log(resp.data);
      this.setState({
        heading: resp.data.HEADING,
        defGoal: resp.data.CONTENT,
        img: resp.data.image,
      });
    });
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] }, () => {});
  };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    fd.append("heading", this.state.heading);
    fd.append("content", this.state.defGoal);
    fd.append("image", this.state.img);
    // let payLoad = {
    //   heading: this.state.heading,
    //   content: this.state.defGoal
    // };
    axios
      .post("https://admin.akclasses.in/api/testInstruction/update", fd)
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            notification: false,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
        axios
          .get("https://admin.akclasses.in/api/testInstruction")
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              heading: resp.data.HEADING,
              defGoal: resp.data.CONTENT,
              img: resp.data.image,
            });
          });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Content Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4>Stacked Form</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Heading"
                          inputProps={{
                            value: this.state.heading,
                            onChange: (e) =>
                              this.setState({ heading: e.target.value }, () => {
                                //console.log(this.state.heading);
                              }),
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Content</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.state.defGoal}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              defGoal: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ marginTop: "15px" }}
                      >
                        <label>Upload Image</label>
                        <br />
                        <input
                          id="img"
                          name="img"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                          // required={true}
                        />
                        <div>
                          <img
                            src={Constants.imgDomain + this.state.img}
                            style={{ width: "200px", height: "200px" }}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={5}
                      style={{ marginTop: "10px" }}
                    >
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Update"}
                      </Button>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default TestInstructions;
