import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
class AddMeeting extends Component {
  state = {
    topic: "",
    agenda: "",
    startTime: "",
    redirect: false,
    notification: false,
    loading: false
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      topic: this.state.topic,
      agenda: this.state.agenda,
      start_time: this.state.startTime
    };
    axios.post("https://admin.akclasses.in/api/meetings", payLoad).then(() => {
      // console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  formatDate = (date, time) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    // console.log([year, month, day].join("-") + "T"+time);
    this.setState({
      startTime: [year, month, day].join("-") + "T" + time
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/meetings"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Meeting Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Meeting</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                          labelText="Topic"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ topic: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                          labelText="Agenda"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ agenda: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Start Time</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={true}
                            onChange={e => {
                              this.formatDate(
                                e._d.toDateString(),
                                e._d.toString().slice(16, 24)
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Start Time"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Meeting"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/meetings"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddMeeting;
