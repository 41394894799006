import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Modal from "react-awesome-modal";
import { MultiSelect } from "react-multi-select-component";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Constants from "variables/Constants";
const classes = makeStyles(styles);

export default class Monitoring_System extends Component {
  state = {
    batches: [],
    user: "",
    secondaryCategories: [],
    limit: "25",
    notifyStatus: false,
    nextPageUrl: "",
    prevPageUrl: "",
    nextPage: 0,
    lastPage: 0,
    prevPage: 0,
    from: 0,
    to: 0,
    total: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    currentPage: 1,
    active: 1,
    search_by: "",
    notifyStatus: false,
    mail: false,
    secondaryCategories: [],
    status: "",
    emailMess: "",
    pKey: "",
    frmDate: "",
    strMessage: "",
    toDate: "",
    osType: "",
    mediaType: "",
    orders: ["Order Placed", "Activated", "Shipped"],
    extensions: [],
    referenceCodes: [],
    viewValidity: "",
    attempt: "",
    ordType: "Order Placed",
    trackingNo: "",
    city: "",
    state: "",
    pro: 0,
    email: "",
    password: "",
    contact: "",
    message: "",
    per_page: "10",
    active: 1,
    act: 0,
    id: 0,
    mobile: 0,
    logic: "",
    options: [],
    notification: false,
    delModal: false,
    delStrategyModal: false,
    stratId: "",
    mess: false,
    redirect: false,
    repButton: false,
    logistics: [],
    visible: false,
    loading: false,
    delLoading: false,
    extend: false,
    ord: true,
    empty: false,
    statLoading: false,
    userSearch: false,
    proDetail: false,
    notifyDelete: false,
    logDetail: false,
    showStrategy: false,
    mailS: false,
    mailFail: false,
    comment: false,
    print: false,
    printView: true,
    notifyNote: false,
    product: "",
    address: "",
    searchOrderId: "",
    pincode: "",
    notes: "",
    courierCom: "",
    tracLink: "",
    delStatus: "",
    language: "",
    price: "",
    rowId: "",
    title: "",
    category: "",
    student: "",
    payStatus: "",
    shippingDate: "",
    type: "Send Written Message",
    grandTotal: 0,
    productId: 0,
    warn: false,
    pkey: "",
    user: {},
    viewMessage: true,
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    faculties: [],
    allStrategies: [],
    strategies: [],
    previousStrategies: [],
    remainingStrategies: [],
    filteredStrategies: [],
    products: [],
    types: [],
    userData: {},
    addedStrategies: [],
    notaddedStrategies: [],
    notAddedStrats: [],
    resetStrategyModal: false,
    averageStrategy: false,
    message: "",
    notifyStratStatus: false,
    secondArray: [],
    batch_details: "",
    user_details: "",
    delMessage: "",
    attempt_id: "6",
    attempts: [],
  };

  openModal() {
    this.setState({
      visible: true,
    });
  }
  closeModal() {
    this.setState({
      visible: false,
      logDetail: false,
      mail: false,
      proDetail: false,
      import: false,
      showStrategy: false,
      delModal: false,
      delStrategyModal: false,
      print: false,
      addShip: false,
      mess: false,
      comment: false,
      notaddedStrategies: [],
    });
  }
  componentDidMount() {
    this.getData();

    // Attempts

    axios
      .get("https://admin.akclasses.in/api/activeAttempts")
      .then((respattempt) => {
        console.log(respattempt);
        this.setState({
          attempts: respattempt.data,
        });
      });

    // GET ALL BATCHES

    axios.get(Constants.getUrls.getBatches).then((res) => {
      this.setState({
        batches: res.data.data,
      });
    });
  }

  getData = () => {
    let payload = {
      limit: this.state.limit,
      attempt_id: this.state.attempt_id,
    };
    console.log(payload);
    axios
      .post("https://admin.akclasses.in/api/monitoring/get-all", payload)
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            console.log(this.state.secondaryCategories);
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState(
              {
                filteredBtnIdArray,
              },
              () => {
                console.log(this.state.filteredBtnIdArray);
              }
            );
          }
        );
      });
  };
  handlePerPage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        this.getData();
      }
    );
  };
  handleAttempt = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        loading: true,
      },
      () => {
        this.getData();
      }
    );
  };

  currentPage = (v) => {
    // let commonUrl = this.state.ord
    // ? "https://admin.akclasses.in/api/get_alluser?type=premium&attempt=" +
    //   this.state.attempt +
    //   "&per_page=" +
    //   this.state.per_page +
    //   "&page="
    // : this.state.attempt === "All Users"
    // ? "https://admin.akclasses.in/api/search_alluser?type=premium" +
    //   "&s=" +
    //   this.state.user +
    //   "&per_page=" +
    //   this.state.per_page +
    //   "&page="
    // : "https://admin.akclasses.in/api/search_alluser?type=premium&attempt=" +
    //   this.state.attempt +
    //   "&s=" +
    //   this.state.user +
    //   "&per_page=" +
    //   this.state.per_page +
    //   "&page=";
    axios
      .post("https://admin.akclasses.in/api/monitoring/get-all?page=" + v, {
        limit: this.state.limit,
      })
      .then((resp) => {
        // console.log(resp);
        let obj_to_array = Object.entries(resp.data?.data).map((obj) => ({
          key_name: obj[1],
        }));
        // console.log(obj_to_array);
        this.setState({ secondaryCategories: [] });
        obj_to_array.map((sec, idx) => {
          this.state.secondaryCategories.push(sec.key_name);

          if (idx === obj_to_array.length - 1) {
            this.setState(
              {
                secondaryCategories: this.state.secondaryCategories,
              },
              () => {
                console.log(this.state.secondaryCategories);
              }
            );
          }
        });
        // return;

        this.setState({
          total: resp.data.total,
          from: resp.data.from,
          to: resp.data.to,
          // secondaryCategories: this,
          active: v,
        });
      });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  getFiltreduser = (payload) => {
    axios
      .post("https://admin.akclasses.in/api/monitoring/get-all", payload)
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            console.log(this.state.secondaryCategories);
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState(
              {
                filteredBtnIdArray,
              },
              () => {
                console.log(this.state.filteredBtnIdArray);
              }
            );
          }
        );
      });
  };

  searchUser = () => {
    let payload;
    if (this.state.search_by === "user_name") {
      let payload = {
        user_name: this.state.user,
      };
      this.getFiltreduser(payload);
    }
    if (this.state.search_by === "email") {
      let payload = {
        email: this.state.user,
      };
      this.getFiltreduser(payload);
    }
    if (this.state.search_by === "mobile_no") {
      let payload = {
        mobile_no: this.state.user,
      };
      this.getFiltreduser(payload);
    }
    if (this.state.search_by === "batch_id") {
      let payload = {
        batch_id: this.state.user,
      };
      this.getFiltreduser(payload);
    }
  };

  handleModal = (sec) => {
    this.setState(
      {
        product: sec.course ? sec.course.TITLE : "",
        orderId: sec.ORDER_ID,
        rowId: sec?.user?.ID,
        osType: sec.variant ? sec.variant?.os_type?.os_type : "",
        mediaType: sec.variant ? sec.variant?.media_type?.media_type : "",
        viewValidity: sec.variant
          ? sec.variant?.view_and_validity?.view_and_validity
          : "",
        delStatus: sec.DELIVERY_STATUS ? sec?.DELIVERY_STATUS : "",
        language: sec.course ? sec?.course?.language : "",
        attempt: sec.attempt ? sec?.attempt?.title : "",
        address: sec.ADDRESS ? sec.ADDRESS : sec.user ? sec.user.ADDRESS_1 : "",
        // address: sec.ADDRESS ? sec.ADDRESS : "",
        city: sec.CITY ? sec.CITY : sec.user ? sec.user.CITY : "",
        pincode: sec.PINCODE ? sec?.PINCODE : sec.user ? sec?.user.PINCODE : "",
        pKey: sec?.PRODUCT_KEY ? sec?.PRODUCT_KEY : "",
        price: sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "",
        student: sec.NAME
          ? sec.NAME
          : sec.user
          ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
            " " +
            (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
          : "",
        // password: sec.user ? sec.user.DE_PASSWORD : null,
        email: sec.user ? sec.user.email : sec.EMAIL,
        mobile: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        trackingNo: sec.TRACKING_NO ? sec.TRACKING_NO : "",
        logic: sec.logistic_provider ? sec.logistic_provider.id : "",
        tracLink: sec.logistic_provider
          ? sec.logistic_provider.tracking_link
          : "",
        courierCom: sec.logistic_provider ? sec.logistic_provider.name : "",
        shippingDate: sec.SHIPPING_DATE ? sec.SHIPPING_DATE : "",
        orderDate: sec.ORDER_DATE,
        notes: sec.NOTES ? sec.NOTES : "",
        payStatus: sec.PAYMENT_STATUS ? sec.PAYMENT_STATUS : "",
        // pincode: sec.PINCODE ? sec.PINCODE : "",
        // city: sec.CITY ? sec.CITY : "",
        userData: sec.user,
        state: sec.STATE ? sec.STATE : "",
        contact: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        productId: sec.course ? sec.course.id : "",
        reference: sec.vender_list ? sec.vender_list.id : "",
      },
      () => {
        if (this.state.delStatus === "Shipped") {
          this.setState({
            options: [
              "Send Login Credentials",
              "Send Product Key",
              "Send Written Message",
              "Tracking Details",
            ],
          });
        } else {
          this.setState({
            options: [
              "Send Login Credentials",
              "Send Product Key",
              "Send Written Message",
            ],
          });
        }
      }
    );
    this.openModal();
  };

  handleStratReset = (strat_id) => {
    let payload = {
      status: "INACTIVE",
      user_id: strat_id?.user_id,
      strategy_id: strat_id?.strategies?.id,
    };
    axios
      .post("https://admin.akclasses.in/api/reset_user_strategy", payload)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "failed") {
          console.log("loggeg in if state ment");
          this.setState({
            message: resp.data.message,
            notifyStratStatus: true,
          });
          setTimeout(() => {
            this.setState(
              {
                message: resp.data.message,
                notifyStratStatus: false,
              },
              () => {
                this.getData();
                this.closeModal();
              }
            );
          }, 3000);
        } else {
          console.log("logged in else");
          this.setState({
            message: resp.data.message,
            notifyStratStatus: true,
          });
          setTimeout(() => {
            this.setState(
              {
                message: resp.data.message,
                notifyStratStatus: false,
              },
              () => {
                this.getData();
                this.closeModal();
              }
            );
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    let payload = {
      user_id: id,
    };
    console.log(payload);
    // return;
    // axios
    //   .post("https://admin.akclasses.in/api/user/delete", payload)
    //   .then((resp) => {
    //     // console.log(resp);
    //     this.setState({
    //       delMessage: resp.data.message,
    //       notifyDelete: true,
    //       delLoading: false,
    //     });
    //     setTimeout(() => {
    //       this.setState(
    //         {
    //           notifyDelete: false,
    //         },
    //         () => {
    //           this.getData();
    //         }
    //       );
    //     }, 4000);
    //   });
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.notifyStratStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={this.state.message}
                // icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Monitoring Table</h4>
              </CardHeader>
              <CardBody
                style={{
                  position: "relative",
                }}
              >
                <FormControl
                  style={{
                    position: "absolute",
                    right: "5%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    // s
                    // key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.limit}
                    inputProps={{
                      name: "limit",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePerPage(e);
                      },
                    }}
                  >
                    {/* {perPages.map((v, i) => ( */}
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="100">100</MenuItem>
                    {/* ))} */}
                  </Select>
                </FormControl>

                <div style={{ position: "relative", top: "50px" }}>
                  <h3>Monitoring System</h3>
                  <div
                    style={{
                      position: "relative",
                      bottom: "63px",
                      left: "63%",
                    }}
                  >
                    <h4 style={{ marginRight: "10px" }}>
                      <FormControl
                        style={{ marginTop: "12px", marginLeft: "15px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Attempt
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.attempt_id}
                          inputProps={{
                            required: true,
                            name: "attempt_id",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState(
                                {
                                  [e.target.name]: e.target.value,
                                },
                                () => {
                                  this.handleAttempt(e);
                                }
                              );
                              // //console.log(e.target.value);
                            },
                          }}
                          style={{ width: "100px", borderBottom: "none" }}
                        >
                          {this.state.attempts.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v?.id}
                            >
                              {v?.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </h4>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormControl
                        style={{
                          // position: "absolute",
                          width: "120px",
                        }}
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Search by
                        </InputLabel>
                        <Select
                          // s
                          // key={1}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.search_by}
                          inputProps={{
                            name: "search_by",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState(
                                {
                                  [e.target.name]: e.target.value,
                                },
                                () => {
                                  console.log(this.state.search_by);
                                }
                              );
                            },
                          }}
                        >
                          <MenuItem value="user_name">User Name</MenuItem>
                          <MenuItem value="email">Email Id</MenuItem>
                          <MenuItem value="mobile_no">Mobile Number</MenuItem>
                          <MenuItem value="batch_id">User Batch</MenuItem>
                        </Select>
                      </FormControl>
                      {this.state.search_by === "batch_id" ? (
                        <FormControl
                          style={{
                            // position: "absolute",
                            left: "30px",
                            width: "120px",
                          }}
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Batch
                          </InputLabel>
                          <Select
                            // s
                            // key={1}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.user}
                            inputProps={{
                              name: "user",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    [e.target.name]: e.target.value,
                                  },
                                  () => {
                                    console.log(this.state.user);
                                    this.searchUser();
                                  }
                                );
                              },
                            }}
                          >
                            {this.state.batches.map((batch, index) => (
                              <MenuItem key={index} value={batch?.id}>
                                {batch?.batch_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : null}
                      <CustomInput
                        labelText={"Search Users"}
                        id="float"
                        inputProps={{
                          onChange: (e) =>
                            this.setState({ user: e.target.value }),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                      <Button
                        onClick={this.searchUser}
                        style={{
                          position: "relative",
                          bottom: "62px",
                          left: "230px",
                        }}
                        color="success"
                      >
                        Search
                      </Button>
                    </GridItem>
                  </div>
                </div>

                <Table
                  tableHead={[
                    "Sr No.",
                    "Name",
                    "Email",
                    "Date of Order",
                    "Mobile",
                    // "Password",
                    "Subject",
                    "Status",
                    "Batch",
                    "Attempt",
                    "Vendor",
                    "City",
                    "Actions",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [sec?.NAME],
                      [sec?.EMAIL],
                      [sec?.ORDER_DATE],
                      [sec?.CONTACT_NO],
                      // [sec?.user?.DE_PASSWORD],
                      [sec?.course?.TITLE],
                      [
                        <span
                          key={index}
                          style={{
                            color: sec?.user?.is_active === 1 ? "green" : "red",
                          }}
                        >
                          {sec?.user?.is_active === 1 ? "ACTIVE" : "INACTIVE"}
                        </span>,
                      ],
                      [sec?.batch ? sec.batch.batch_name : "NA"],
                      [sec?.attempt ? sec.attempt.title : "NA"],
                      [
                        sec?.vender_list?.institute_name
                          ? sec.vender_list.institute_name
                          : "NA",
                      ],
                      [sec?.CITY ? sec.CITY : "NA"],
                      [
                        <span key={1}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                visible: true,
                                proDetail: true,
                                addShip: false,
                                mess: false,
                                import: false,
                                mail: false,
                                print: false,
                                logDetail: false,
                                delModal: false,
                                comment: false,
                              });
                            }}
                          >
                            <i className="material-icons">&#xe417;</i>
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                visible: true,
                                comment: true,
                                print: false,
                                mail: false,
                                mess: false,
                                showStrategy: false,
                                import: false,
                                addShip: false,
                                logDetail: false,
                                delModal: false,
                                proDetail: false,
                              });
                            }}
                          >
                            <i className="material-icons">&#xe0b9;</i>
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                visible: true,
                                showStrategy: false,
                                comment: false,
                                logDetail: false,
                                mess: false,
                                mail: false,
                                import: false,
                                addShip: false,
                                delModal: false,
                                print: true,
                                proDetail: false,
                              });
                            }}
                          >
                            <i className="material-icons">&#xe8ad;</i>
                          </span>
                          <Link
                            key={index}
                            style={{ color: "black" }}
                            to={{
                              pathname:
                                "/admin/update" +
                                this.state.category +
                                "offlineorder/" +
                                sec.ID,
                              state: { categoryData: sec },
                            }}
                          >
                            <i className="material-icons">&#xe3c9;</i>
                          </Link>
                          <span
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                visible: true,
                                comment: false,
                                logDetail: false,
                                mail: false,
                                showStrategy: false,
                                import: false,
                                addShip: false,
                                mess: false,
                                print: false,
                                delModal: true,
                                proDetail: false,
                              });
                            }}
                          >
                            <i className="material-icons">&#xe5c9;</i>
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              bottom: "4px",
                            }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                visible: true,
                                mess: true,
                                comment: false,
                                logDetail: false,
                                addShip: false,
                                import: false,
                                showStrategy: false,
                                mail: false,
                                print: false,
                                delModal: false,
                                proDetail: false,
                              });
                            }}
                          >
                            <i className="fab">&#xf39f;</i>
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              // bottom: "4px"
                            }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                visible: true,
                                mess: false,
                                comment: false,
                                logDetail: false,
                                addShip: false,
                                mail: true,
                                showStrategy: false,
                                import: false,
                                print: false,
                                delModal: false,
                                proDetail: false,
                              });
                            }}
                          >
                            <i className="material-icons">&#xe0be;</i>
                          </span>
                          <br />
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              // bottom: "4px"
                            }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                statLoading: true,
                                visible: true,
                                mess: false,
                                comment: false,
                                logDetail: false,
                                showStrategy: true,
                                addShip: false,
                                mail: false,
                                print: false,
                                import: false,
                                delModal: false,
                                proDetail: false,
                                batch_details: sec?.batch,
                                user_details: sec?.user,
                              });
                              axios
                                .get(
                                  // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                                  Constants.getUrls.getUserStrat +
                                    "?ordId=" +
                                    sec.ID +
                                    "&user_id=" +
                                    sec.user.ID +
                                    "&course_id=" +
                                    sec.course.ID
                                )
                                .then((resp) => {
                                  // console.log(resp);
                                  if (resp.data.status === "success") {
                                    resp.data.notAddedData.forEach((v) => {
                                      this.state.notaddedStrategies.push({
                                        label:
                                          v.strategy.course.TITLE +
                                          " (" +
                                          v.strategy.duration +
                                          " days, " +
                                          v.strategy.strategy_type +
                                          " , " +
                                          v.strategy.type +
                                          ")",
                                        value: v.strategy.id,
                                      });
                                    });

                                    this.setState({
                                      statLoading: false,
                                      addedStrategies:
                                        resp.data.addedData.length > 0
                                          ? resp.data.addedData
                                          : [],
                                      // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                                    });
                                  } else {
                                    this.setState({
                                      statLoading: false,
                                      addedStrategies: [],
                                      notaddedStrategies: [],
                                    });
                                  }
                                });
                            }}
                          >
                            <i
                              className="fas fa-lightbulb"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              // bottom: "4px"
                            }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                visible: true,
                                mess: false,
                                comment: false,
                                logDetail: false,
                                showStrategy: false,
                                import: true,
                                addShip: false,
                                mail: false,
                                print: false,
                                delModal: false,
                                proDetail: false,
                              });
                            }}
                          >
                            <i
                              title="Import User"
                              className="fas fa-file-import"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </span>
                        </span>,
                      ],
                      // [
                      //   <span key={1}>
                      //     <span
                      //       style={{
                      //         cursor: "pointer",
                      //         fontSize: "20px",
                      //         position: "relative",
                      //         // bottom: "4px"
                      //       }}
                      //       onClick={() => {
                      //         this.handleModal(sec);
                      //         this.setState({
                      //           statLoading: true,
                      //           visible: true,
                      //           mess: false,
                      //           comment: false,
                      //           logDetail: false,
                      //           showStrategy: true,
                      //           import: false,
                      //           addShip: false,
                      //           mail: false,
                      //           print: false,
                      //           delModal: false,
                      //           proDetail: false,
                      //         });
                      //         axios
                      //           .get(
                      //             // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                      //             Constants.getUrls.getUserStrat +
                      //               "?ordId=" +
                      //               sec?.ID +
                      //               "&user_id=" +
                      //               sec?.user?.ID +
                      //               "&course_id=" +
                      //               sec?.course?.ID
                      //           )
                      //           .then((resp) => {
                      //             console.log(resp);
                      //             if (resp.data.status === "success") {
                      //               resp.data.notAddedData.forEach((v) => {
                      //                 this.state.notaddedStrategies.push({
                      //                   label:
                      //                     v.strategy.course.TITLE +
                      //                     " (" +
                      //                     v.strategy.duration +
                      //                     " days, " +
                      //                     v.strategy.strategy_type +
                      //                     " , " +
                      //                     v.strategy.type +
                      //                     ")",
                      //                   value: v.strategy.id,
                      //                 });
                      //               });

                      //               this.setState({
                      //                 statLoading: false,
                      //                 addedStrategies:
                      //                   resp.data.addedData.length > 0
                      //                     ? resp.data.addedData
                      //                     : [],
                      //                 // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                      //               });
                      //             } else {
                      //               this.setState({
                      //                 statLoading: false,
                      //                 addedStrategies: [],
                      //                 notaddedStrategies: [],
                      //               });
                      //             }
                      //           });
                      //       }}
                      //     >
                      //       <i
                      //         className="fas fa-lightbulb"
                      //         style={{ fontSize: "24px" }}
                      //       ></i>
                      //     </span>
                      //   </span>,
                      // ],
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visible}
          minWidth="1000"
          minHeight="330"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                <CardBody>
                  {this.state.proDetail ? (
                    <div>
                      <h3>Product Details</h3>
                      <Table
                        tableHead={[
                          "Product Name",
                          "Price",
                          "Product Key",
                          "OS Type",
                          "Media Type",
                          "Views & Validity",
                          // "Tracking No.",
                          // "Shipping Date",
                          "Payment Status",
                          "Delivery Status",
                          "Language",
                          "Attempt",
                        ]}
                        tableData={[
                          [
                            this.state.product,
                            this.state.price,
                            this.state.pKey,
                            this.state.osType,
                            this.state.mediaType,
                            this.state.viewValidity,
                            // this.state.trackingNo,
                            // this.state.shippingDate,
                            this.state.payStatus,
                            this.state.delStatus,
                            this.state.language,
                            this.state.attempt,
                          ],
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.showStrategy ? (
                    <div>
                      <h5 style={{ textAlign: "center" }}>
                        Alloted Strategies To User
                      </h5>
                      {/* {this.state.mailS ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Strategy Added Successfully!
                            </span>
                          </div>
                        ) : null} */}

                      {this.state.mailFail ? (
                        <div>
                          <span
                            style={{
                              color: "red",
                              textTransform: "capitalize",
                            }}
                          >
                            {this.state.strMessage}
                          </span>
                        </div>
                      ) : null}
                      {this.state.statLoading ? (
                        <h6 style={{ textAlign: "center" }}>
                          Fetching Strategy Details...
                        </h6>
                      ) : (
                        <div style={{ width: "600px" }}>
                          {/* <label htmlFor="Courses">Strategies</label> */}
                          {/* <MultiSelect
                              disableSearch
                              options={this.state.notaddedStrategies}
                              value={this.state.notAddedStrats}
                              onChange={data => {
                                this.setState({ notAddedStrats: data });
                              }}
                              labelledBy={"Strategies"}
                            /> */}
                          <h6 style={{ textAlign: "center" }}>
                            Alloted Strategies
                          </h6>
                          {this.state.addedStrategies.length > 0 ? (
                            <Table
                              tableHead={[
                                "Course",
                                "Duration",
                                "Strategy Type",
                                "User Type",
                                "Action",
                              ]}
                              tableData={this.state.addedStrategies.map(
                                (sec) => [
                                  [sec?.strategies?.course?.TITLE],
                                  [sec?.strategies?.duration],
                                  [sec?.strategies?.strategy_type],
                                  [sec?.strategies?.type],
                                  [
                                    <Link
                                      to={{
                                        pathname:
                                          "/admin/monitorUserStrategy/" +
                                          sec.id,
                                        state: {
                                          strategy_data: this.state
                                            .addedStrategies,
                                          user_name: sec?.user?.FIRST_NAME,
                                          user_id: sec?.user_id,
                                          average: sec?.average?.average,
                                          course_details: sec,
                                          batch_details: this.state
                                            .batch_details,
                                          user_details: this.state.user_details,
                                        },
                                      }}
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      <Button
                                        className=""
                                        color="success"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              stratId: sec,
                                              visible: true,
                                              averageStrategy: true,
                                              showStrategy: false,
                                            },
                                            () => {
                                              console.log(this.state.stratId);
                                            }
                                          );
                                        }}
                                      >
                                        Average
                                      </Button>
                                    </Link>,
                                    <Button
                                      className=""
                                      color="primary"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            stratId: sec,
                                            visible: true,
                                            resetStrategyModal: true,
                                            showStrategy: false,
                                          },
                                          () => {}
                                        );
                                      }}
                                    >
                                      Reset
                                    </Button>,
                                    <Button
                                      className=""
                                      color="danger"
                                      onClick={() => {
                                        this.setState({
                                          stratId: sec.id,
                                          visible: true,
                                          delStrategyModal: true,
                                          showStrategy: false,
                                        });
                                      }}
                                    >
                                      Delete
                                    </Button>,
                                  ],
                                ]
                              )}
                            />
                          ) : (
                            <span>NO STRATEGIES ARE ALLOTED TO THIS USER</span>
                          )}

                          <GridContainer>
                            {/* SEND MAIL BUTTONS  */}

                            {/* <GridItem xs={12} sm={12} md={4}>
                              <br />
                              <Button
                                className=""
                                color="warning"
                                onClick={this.sendEmail}
                              >
                                {this.state.loading ? <Loader /> : "Yes"}
                              </Button>
                              <Button
                                className=""
                                color="danger"
                                onClick={() => {
                                  this.closeModal();
                                  this.setState({
                                    visible: false,
                                    showStrategy: false,
                                  });
                                }}
                              >
                                No
                              </Button>
                            </GridItem> */}
                          </GridContainer>
                        </div>
                      )}
                    </div>
                  ) : this.state.print ? (
                    <div>
                      <div>
                        <img
                          src="https://akclasses.in/static/media/akLogo.93e45c6ce5fc7e0fbea5.png"
                          alt=""
                          width="200px"
                          style={{
                            paddingBottom: "15px",
                            textAlign: "center",
                            marginLeft: "350px",
                            marginRight: "350px",
                          }}
                        />
                      </div>
                      <div>
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          To,
                        </h6>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.student}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.address}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.city},{this.state.state},
                          {this.state.pincode}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.contact}
                        </span>
                      </div>
                      <br />
                      <div>
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          From,
                        </h6>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Aman Khedia Classes,
                          <br />
                          The Park Meadows, Flat No. 101,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Plot No. 48, B.U. Kothari Layout Kachimet,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Amaravati Road, Nagpur. (MH) 440033
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Office: +91 876 657 5808
                        </span>
                      </div>
                      {this.state.printView ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ printView: false });
                                setTimeout(() => {
                                  window.print();
                                }, 1);
                                setTimeout(() => {
                                  this.setState({ printView: true });
                                }, 2);
                              }}
                            >
                              Print
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      ) : null}
                    </div>
                  ) : this.state.comment ? (
                    <div>
                      <h3>Add Note</h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Note</label>
                          <br />
                          <CKEditor
                            required={true}
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={this.state.notes}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                notes: data,
                              });
                            }}
                          />
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Note Added Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.handleSubmit}
                          >
                            {this.state.loading ? <Loader /> : "Add"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.delModal ? (
                    <div>
                      <h3>Are You Sure You Want to Delete user Permanently?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            {this.state.delMessage}{" "}
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.resetStrategyModal ? (
                    <div>
                      <h3>Are You Sure You Want to Reset User's Strategy?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Strategy Reset Successfully!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleStratReset(this.state.stratId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.delStrategyModal ? (
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Strategy Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleStratDelete(this.state.stratId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.logDetail ? (
                    <div>
                      <h3>Shipping Details</h3>
                      <Table
                        tableHead={[
                          "Courier Company",
                          "Tracking No.",
                          "Track Now",
                          "Update",
                        ]}
                        tableData={[
                          [
                            this.state.courierCom,
                            this.state.trackingNo,
                            <a
                              key={1}
                              href={this.state.tracLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button color="danger">
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            </a>,
                            <Button
                              key={1}
                              onClick={() => {
                                this.setState({
                                  visible: true,
                                  proDetail: false,
                                  addShip: false,
                                  print: false,
                                  logDetail: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                              className="btn-round"
                              color="info"
                            >
                              Update
                            </Button>,
                          ],
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.addShip ? (
                    <form>
                      <h4 style={{ textAlign: "center" }}>
                        Order ID:{this.state.orderId}
                      </h4>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Product Name :" + this.state.product}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                          <CustomInput
                            labelText={"Student Name :" + this.state.student}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Address :" + this.state.address}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Producy Key :" + this.state.pKey}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Logistics</label>
                            <select
                              style={{ padding: "11px" }}
                              name="logic"
                              // value={this.state.logic}
                              onChange={this.handleInput}
                            >
                              <option value="">Select</option>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Order Status</label>
                            <select
                              style={{ padding: "11px" }}
                              name="delStatus"
                              value={this.state.delStatus}
                              onChange={(e) => {
                                this.handleSelect(
                                  e,
                                  this.state.orderId,
                                  this.state.rowId
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {this.state.orders.map((v, i) => (
                                <option
                                  key={i}
                                  value={
                                    v === "Activated" || v === "Extended"
                                      ? "Packaged"
                                      : v
                                  }
                                >
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Tracking ID"
                            id="float"
                            inputProps={{
                              disabled: false,
                              onChange: (e) =>
                                this.setState({ trackingNo: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              disabled: false,
                              onChange: (e) =>
                                this.setState({ pKey: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          {this.state.notification ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Shipping Details Added Successfuly!
                              </span>
                            </div>
                          ) : null}
                          <Button
                            className=""
                            color="warning"
                            onClick={this.addShipping}
                          >
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                                className="material-icons"
                              >
                                &#xe558;
                              </i>
                            )}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            <i
                              style={{
                                color: "white",
                                fontSize: "18px",
                                margin: "0px",
                              }}
                              className="material-icons"
                            >
                              &#xe5c9;
                            </i>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  ) : this.state.import ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Import{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Type</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.type}
                              onChange={(e) => {
                                this.setState({ type: e.target.value });
                              }}
                            >
                              {this.state.types.map((v, i) => (
                                <option key={i} value={v.type}>
                                  {v.type}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                User Imported Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.importUser}
                          >
                            {this.state.loading ? "Importing..." : "Import"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.mess ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Send Message to{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>You Want To</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.type}
                              onChange={(e) => {
                                this.setState({ type: e.target.value }, () => {
                                  if (
                                    this.state.type === "Send Written Message"
                                  ) {
                                    this.setState({ viewMessage: true });
                                  } else {
                                    this.setState({ viewMessage: false });
                                  }
                                });
                              }}
                            >
                              {this.state.options.map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        {this.state.viewMessage ? (
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Message</label>
                            <br />
                            <textarea
                              name="message"
                              cols="100"
                              rows="5"
                              onChange={(e) => {
                                this.setState({ message: e.target.value });
                              }}
                            >
                              {this.state.message}
                            </textarea>
                            {this.state.notifyNote ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Message Sent Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                        ) : null}

                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.sendMessage}
                          >
                            {this.state.loading ? "Sending..." : "Send"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.mail ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Send Mail to{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Mail</label>
                          <br />
                          <textarea
                            name="mail"
                            cols="100"
                            rows="5"
                            onChange={(e) => {
                              this.setState({ emailMess: e.target.value });
                            }}
                          >
                            {this.state.message}
                          </textarea>
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Mail Sent Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.sendMail}
                          >
                            {this.state.loading ? "Sending..." : "Send"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.extend ? (
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Product</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.pro}
                              onChange={(e) => {
                                this.setState({ pro: e.target.value });
                              }}
                            >
                              <option value="">Select</option>
                              {this.state.extensions.map((v, i) => (
                                <option key={i} value={v.ID}>
                                  {v.TITLE}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              required: true,
                              onChange: (e) =>
                                this.setState({ pkey: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>
                                {this.state.message + "!!!"}
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.applyKey}
                          >
                            {this.state.loading ? "Submitting..." : "Submit"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <form>
                      <h4 style={{ textAlign: "center" }}>
                        Update Shipping Details
                      </h4>
                      {/* <h4 style={{ textAlign: "center" }}>
                        Order ID:{this.state.orderId}
                      </h4> */}
                      <GridContainer>
                        {/*   <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Product Name :" + this.state.product}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                          <CustomInput
                            labelText={"Student Name :" + this.state.student}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Address :" + this.state.address}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Producy Key :" + this.state.pKey}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Logistics</label>
                            <select
                              style={{ padding: "11px" }}
                              name="logic"
                              value={this.state.logic}
                              onChange={this.handleInput}
                            >
                              <option value="">Select</option>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        {/*  <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Order Status</label>
                            <select
                              style={{ padding: "11px" }}
                              name="delStatus"
                              value={this.state.delStatus}
                              onChange={e => {
                                this.handleSelect(
                                  e,
                                  this.state.orderId,
                                  this.state.rowId
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {orders.map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem> */}

                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Tracking ID"
                            id="float"
                            inputProps={{
                              disabled: false,
                              value: this.state.trackingNo,
                              onChange: (e) =>
                                this.setState({ trackingNo: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              disabled: false,
                              value: this.state.pKey,
                              onChange: (e) =>
                                this.setState({ pKey: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          {this.state.notification ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Shipping Details Updated Successfuly!
                              </span>
                            </div>
                          ) : null}
                          <Button
                            className=""
                            color="info"
                            onClick={this.addShipping}
                          >
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                                className="material-icons"
                              >
                                &#xe558;
                              </i>
                            )}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            <i
                              style={{
                                color: "white",
                                fontSize: "18px",
                                margin: "0px",
                              }}
                              className="material-icons"
                            >
                              &#xe5c9;
                            </i>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    top: "3px",
                  }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    top: "3px",
                  }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
