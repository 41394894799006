/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Constants from "../variables/Constants";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
const classes = makeStyles(styles);

// const selectid = window.location.pathname.split("/")[3]

class UpdateAmenity extends Component {
  state = {
    type: "",
    id: 0,
    title: "",
    value: "",
    image: [],
    category: "",
    redirect: false,
    notification: false,
    loading: false,
    icon: [],
    description: "",
  };

  componentDidMount() {
    let ID = window.location.pathname.split("/")[3];
    this.setState({
      selectId: ID,
    });

    axios.get(Constants.getUrls.getSingleAmenities + ID).then((resp) => {
      console.log(resp);
      this.setState(
        {
          title: resp.data.data.title,
          description: resp.data.data.descriptions,
        },()=>{
          console.log(this.state.description)
        }
      );
    });

    this.setState({ id: ID });

    // this. getUpdateAmenity()
  }
  // getUpdateAmenity =()=>{
  //   axios.get(Constants.getUrls.getSingleAmenities + selectid )
  //   .then((resp)=>{
  //     console.log(resp.data)
  //     this.setState({
  //       title: resp.data.title,
  //       value: resp.data.value,
  //       image: resp.data.image,
  //     })
  //   })
  // }

  handleFile = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
    // console.log(e.target.files[0])
  };
  
  handleChange(content) {
    this.setState(
      {
        description: content,
      },
      () => {
        console.log(this.state.description);
      }
    );
  }
  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });
    // let fd ={
    //   title: this.state.title,
    //   value: this.state.value,
    // }
    const fd = new FormData();
    // fd.append("title", this.state.title)
    // fd.append("value", this.state.value)
    // fd.append("image", this.state.image)
    // fd.append("icon", this.state.icon)
    // fd.append("_method", "PUT")
    let payload = {
      descriptions: this.state.description,
      title: this.state.title,
    };
    axios
      .put(Constants.postUrls.updateAmenity + this.state.id, payload)
      .then((resp) => {
        console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
  };

  // updateAmenities =(id)=>{

  // }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/amenities"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Amenity Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Update Amenity</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Update Amenities"
                          id="float"
                          inputProps={{
                            value: this.state.title,
                            required: true,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <div style={{ color: "black" }}>
                        <SunEditor
                          // width="50%"
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize"],
                              // ['paragraphStyle', 'blockquote'],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              // ["fontColor", "hiliteColor"],
                              ["align", "list", "lineHeight"],
                              ["outdent", "indent"],

                              [
                                "table",
                                "horizontalRule",
                                "link",
                                "image",
                                "video",
                              ],
                              // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                              // ['imageGallery'], // You must add the "imageGalleryUrl".
                              // ["fullScreen", "showBlocks", "codeView"],
                              ["preview", "print"],
                              ["removeFormat"],

                              // ['save', 'template'],
                              // '/', Line break
                            ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                            defaultTag: "div",
                            minHeight: "300px",
                            showPathLabel: false,
                            // font: sortedFontOptions
                          }}
                          setDefaultStyle="font-family: Roboto; font-size: 10px;"
                          name="description"
                          setContents={this.state.description}
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>

                      {/* PREVIOUS CHANGES  */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Update Value"
                          
                          id="float"
                          inputProps={{
                            value: this.state.value,
                            required: true,
                            onChange: e =>
                              this.setState({ value: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      {/* <GridItem
                        xs={12} sm={12} md={6}
                      >
                       
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                      
                      <label>Update Image</label>
                          <br />
                          <input
                            id="image"
                            name="image"
                            
                            onChange={this.handleFile}
                            accept="image/*"
                            type="file"
                            // required={true}
                          />
                          
                          <img 
                              src={Constants.imgUrl + this.state.image}
                              
                              height="100px"
                              width="100px"
                            />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                      <label>Select Icon</label>
                          <br />
                          <input
                            id="icon"
                            name="icon"
                            onChange={this.handleFile}
                            accept="image/*"
                            type="file"
                            // required={true}
                          />

                            <img 
                              src={Constants.imgUrl + this.state.icon}
                              
                              height="100px"
                              width="100px"
                            />
                      </GridItem> */}

                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Value"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ value: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}></GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Amenity"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/amenities",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateAmenity;
