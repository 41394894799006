/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import { makeStyles } from "@material-ui/core/styles";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
// import MenuItem from "@material-ui/core/MenuItem";
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
// const classes = makeStyles(styles);

// const perPages = ["10", "25", "50", "100"];
class Monitor_UserStrategies extends Component {
  state = {
    notifyStatus: false,
    empty: true,
    secondaryCategories: [],
    status: "",
    user: "",
    active: 1,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    progress: 0,
    average: 0,
    count: 0,
    from: 0,
    strategyProgress: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    data: [],
    strategyDays: [],
    user_details: "",
    user_id: "",
    batch_details: "",
    user_details: "",
  };

  componentDidMount() {
    this.setState(
      {
        // btnIdArray,
        secondaryCategories: this.props.location.state.strategy_data,
        user_details: this.props.location.state.user_details,
        user: this.props.location.state.name,
        average: this.props.location.state.average,
        user_id: this.props.location.state.user_id,
        batch_details: this.props.location.state.batch_details,
        user_details: this.props.location.state.user_details,
      },
      () => {
        let arr = [];
        let count = 0;
        console.log(this.state.secondaryCategories);
      }
    );
    //   });
  }

  // getAverageStrategy = () => {
  //   let payload = {
  //     user_id: this.state.user_id,
  //     strategy_id: this.state.strategy_id,
  //   };

  //   axios
  //     .post("https://admin.akclasses.in/api/monitoring/get-average", payload)
  //     .then((resp) => {
  //       console.log(resp);
  //     });
  // };

  // getProgress = data => {
  //   console.log(data)
  //   this.setState({
  //    data
  //   })
  //   let count = 0;
  //   // this.state.data.forEach(v => {
  //     if (data.strategy_day_status  === "complete") {
  //       count++;
  //     }
  //   // });
  //   return parseInt(100 * (count / data.length)) + "%";
  // };
  // handlePage = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //       fromInc: 1,
  //       active: 1,
  //       toInc: 5,
  //     },
  //     () => {
  //       axios
  //         .get(
  //           "https://admin.akclasses.in/api/courseCategories?per_page=" +
  //             this.state.per_page
  //         )
  //         .then((resp) => {
  //           //console.log(resp.data);
  //           let btnIdArray = [];
  //           for (let i = 1; i <= resp.data.last_page; i++) {
  //             btnIdArray.push(i);
  //           }
  //           this.setState(
  //             {
  //               btnIdArray,
  //               secondaryCategories: resp.data.data,
  //               total: resp.data.total,
  //               from: resp.data.from,
  //               to: resp.data.to,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({
  //                 filteredBtnIdArray,
  //               });
  //             }
  //           );
  //         });
  //     }
  //   );
  // };
  // currentPage = (v) => {
  //   let commonUrl =
  //     "https://admin.akclasses.in/api/courseCategories?per_page=" +
  //     this.state.per_page +
  //     "&page=";
  //   axios.get(commonUrl + v).then((resp) => {
  //     // console.log(resp.data.data);
  //     this.setState({
  //       total: resp.data.total,
  //       from: resp.data.from,
  //       to: resp.data.to,
  //       secondaryCategories: resp.data.data,
  //       active: v,
  //     });
  //   });
  // };

  // prevPages = () => {
  //   this.setState(
  //     {
  //       fromInc: this.state.fromInc - 5,
  //       toInc: this.state.toInc - 5,
  //     },
  //     () => {
  //       let filteredBtnIdArray = this.state.btnIdArray.filter(
  //         (v) => v >= this.state.fromInc && v <= this.state.toInc
  //       );
  //       if (filteredBtnIdArray.length >= 1) {
  //         if (filteredBtnIdArray.length === 1) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 4,
  //               toInc: this.state.toInc + 4,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 2) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 3,
  //               toInc: this.state.toInc + 3,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 3) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 2,
  //               toInc: this.state.toInc + 2,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 4) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 1,
  //               toInc: this.state.toInc + 1,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else {
  //           this.setState({ filteredBtnIdArray });
  //         }
  //       } else {
  //         this.setState(
  //           {
  //             fromInc: this.state.fromInc + 5,
  //             toInc: this.state.toInc + 5,
  //           },
  //           () => {
  //             let filteredBtnIdArray = this.state.btnIdArray.filter(
  //               (v) => v >= this.state.fromInc && v <= this.state.toInc
  //             );
  //             this.setState({ filteredBtnIdArray });
  //           }
  //         );
  //       }
  //     }
  //   );
  // };

  // nextPages = () => {
  //   this.setState(
  //     {
  //       fromInc: this.state.fromInc + 5,
  //       toInc: this.state.toInc + 5,
  //     },
  //     () => {
  //       let filteredBtnIdArray = this.state.btnIdArray.filter(
  //         (v) => v >= this.state.fromInc && v <= this.state.toInc
  //       );
  //       if (filteredBtnIdArray.length >= 1) {
  //         if (filteredBtnIdArray.length === 1) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc - 4,
  //               toInc: this.state.toInc - 4,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 2) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc - 3,
  //               toInc: this.state.toInc - 3,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 3) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc - 2,
  //               toInc: this.state.toInc - 2,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 4) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc - 1,
  //               toInc: this.state.toInc - 1,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else {
  //           this.setState({ filteredBtnIdArray });
  //         }
  //       } else {
  //         this.setState(
  //           {
  //             fromInc: this.state.fromInc - 5,
  //             toInc: this.state.toInc - 5,
  //           },
  //           () => {
  //             let filteredBtnIdArray = this.state.btnIdArray.filter(
  //               (v) => v >= this.state.fromInc && v <= this.state.toInc
  //             );
  //             this.setState({ filteredBtnIdArray });
  //           }
  //         );
  //       }
  //     }
  //   );
  // };

  // handleSelect = (e, id) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       const payload = {
  //         status: this.state.status,
  //       };
  //       axios
  //         .post(
  //           "https://admin.akclasses.in/api/courseCategory/status/" + id,
  //           payload
  //         )
  //         .then(() => {
  //           //console.log(res);
  //           this.setState({
  //             notifyStatus: true,
  //           });
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 1000);
  //         });
  //     }
  //   );
  // };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                {/* <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <h3>{this.state.user} Strategy Stats</h3>
                <Link
                  to={
                    "/admin/monitoringSystem/"
                    //   "/admin/" + this.props.location.state.type
                    // === "premium"
                    //   ? "premiumusers"
                    //   : "freeusers"
                  }
                >
                  <Button className="" color="danger">
                    Go Back
                  </Button>
                </Link>
                <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                  {"Overall Progress : " + parseInt(this.state.average) + "%"}
                </h3>
                {/* {console.log(this.state.average, "aveg")} */}
                <Table
                  tableHead={[
                    "Course",
                    "Duration",
                    // "Status",
                    "Actions",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) =>
                      //  return val.map(
                      //     (sec, index) =>
                      [
                        [sec.strategies?.course?.TITLE],
                        [sec.strategies?.duration + " Days"],
                        // [
                        //   <span style={{ fontWeight: "bold" }}>
                        //     {this.getProgress(sec)}
                        //   </span>
                        // ],
                        [
                          <Link
                            key={index}
                            to={{
                              pathname: "/admin/monitorUserStatDays/" + sec.id,
                              state: {
                                strategy_data: sec.strategy_day_start_date,
                                strategyDays: sec.strategy_days,
                                user_id: this.state.user_id,
                                id: sec?.strategies?.id,
                                user_details: this.state.user_details,
                                batch_details: this.state.batch_details,
                              },
                            }}
                          >
                            <Button className="btn-round" color="info">
                              View Days
                            </Button>
                          </Link>,
                        ],
                      ]
                    // )
                    // }
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty ? null : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Monitor_UserStrategies;
