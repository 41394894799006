import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Loader from "components/Loader";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Modal from "react-awesome-modal";
import MenuItem from "@material-ui/core/MenuItem";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);

// const perPages = ["10", "25", "50", "100"];
class Messages extends Component {
  state = {
    notifyNote: false,
    notifyStatus: false,
    notifyDelete: false,
    typeClicked: false,
    dateClicked: false,
    mess: false,
    loading: false,
    searchClicked: false,
    empty: true,
    type: "",
    user: "",
    searchData: "",
    comment: "",
    frmDate: "",
    toDate: "",
    types: [],
    secondaryCategories: [],
    status: "",
    active: 1,
    per_page: "10",
    currentPage: 1,
    delLoading: false,
    idToDelete: 0,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    visible: false,
    firstPageView: false,
    delNot: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    act: 0,
    btnIdArray: [],
    filteredBtnIdArray: [],
  };

  componentDidMount() {
    this.getMessages();
    axios.get("https://admin.akclasses.in/api/get_type").then((resp) => {
      // console.log(resp.data);
      this.setState({
        types: resp.data,
      });
    });
  }
  getMessages = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/user_sms/index?per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        // console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/courseCategories?per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  currentPage = (v) => {
    let commonUrl =
      "https://admin.akclasses.in/api/courseCategories?per_page=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post("https://admin.akclasses.in/api/user_sms/status/" + id, payload)
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  visible: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5,
                },
                () => {
                  this.getMessages();
                }
              );
            }, 1000);
          });
      }
    );
  };
  handleDelete = (id) => {
    this.setState({ delLoading: true });

    axios
      .post("https://admin.akclasses.in/api/user_sms/delete?id=" + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          this.getMessages();
          // window.location.reload();
        }, 2000);
      });
  };
  sendMessage = () => {
    this.setState({ loading: true });
    axios
      .post(
        "https://admin.akclasses.in/api/user_sms/comment/" +
          this.state.idToDelete,
        { comment: this.state.comment }
      )
      .then(() => {
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          this.getMessages();
          // window.location.reload();
        }, 2000);
      });
  };
  handleModal = (id, name, comment) => {
    this.setState({
      idToDelete: id,
      user: name,
      comment: comment ? comment : "",
      visible: true,
    });
  };
  handleTypeDelete = () => {
    this.setState({ typeClicked: true });
    axios
      .post(
        "https://admin.akclasses.in/api/user_sms/delete?type=" + this.state.type
      )
      .then(() => {
        // console.log(res.data);
        this.setState({
          delNot: true,
        });
        setTimeout(() => {
          this.getMessages();
          // window.location.reload();
        }, 2000);
      });
  };
  handleDateDelete = () => {
    this.setState({ dateClicked: true });
    axios
      .post(
        "https://admin.akclasses.in/api/user_sms/delete?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then(() => {
        // console.log(res.data);
        this.setState({
          delNot: true,
        });
        setTimeout(() => {
          this.getMessages();
          // window.location.reload();
        }, 2000);
      });
  };
  handleSearch = () => {
    this.setState({ searchClicked: true });
    axios
      .get(
        "https://admin.akclasses.in/api/user_sms/search?s=" +
          this.state.searchData
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          searchClicked: false,
          secondaryCategories: resp.data,
        });
      });
  };
  handleOrders = (btn, index) => {
    let status = btn === "Interested" ? 1 : btn === "Not Interested" ? 0 : 2;
    if (status === 2) {
      axios
        .get(
          "https://admin.akclasses.in/api/user_sms/index?per_page=" +
            this.state.per_page
        )
        .then((resp) => {
          // console.log(resp.data);
          this.setState({
            act: index,
            secondaryCategories: resp.data,
          });
        });
    } else {
      axios
        .get("https://admin.akclasses.in/api/user_sms/search?status=" + status)
        .then((resp) => {
          // console.log(resp.data);
          this.setState({
            act: index,
            secondaryCategories: resp.data,
          });
        });
    }
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.delNot ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Messages Deleted!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                {/* <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <h6>Search By User Status</h6>
                {["All", "Interested", "Not Interested"].map((btn, index) => (
                  <Button
                    key={index}
                    id={btn}
                    color={this.state.act === index ? "danger " : "info"}
                    onClick={() => {
                      this.handleOrders(btn, index);
                    }}
                  >
                    {btn}
                  </Button>
                ))}
                <h6>Delete By Type & Date Range</h6>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormControl
                      style={{ marginTop: "7px" }}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={this.state.type}
                        inputProps={{
                          name: "type",
                          id: "simple-select",
                          onChange: (e) => {
                            this.setState({
                              type: e.target.value,
                            });
                          },
                        }}
                      >
                        {this.state.types.map((v, i) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            key={i}
                            value={v.type}
                          >
                            {v.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button
                      style={{
                        position: "relative",
                        top: "9px",
                        right: "20px",
                      }}
                      onClick={() => {
                        this.handleTypeDelete();
                      }}
                      className="btn-round"
                      color="danger"
                    >
                      {this.state.typeClicked ? "Deleting..." : "Delete"}
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              frmDate: year + "/" + month + "/" + day,
                            },
                            () => {
                              //console.log(this.state.strDate);
                            }
                          );
                        }}
                        inputProps={{
                          required: true,
                          placeholder: "From Date",
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              toDate: year + "/" + month + "/" + day,
                            },
                            () => {
                              //console.log(this.state.endDate);
                            }
                          );
                        }}
                        inputProps={{
                          required: true,
                          placeholder: "To Date",
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button
                      style={{
                        position: "relative",
                        top: "9px",
                        right: "20px",
                      }}
                      onClick={() => {
                        this.handleDateDelete();
                      }}
                      className="btn-round"
                      color="danger"
                    >
                      {this.state.dateClicked ? "Deleting..." : "Delete"}
                    </Button>
                  </GridItem>
                </GridContainer>
                <h6>Search By User Name or Mobile No.</h6>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="User Name or Mobile No."
                      id="float"
                      inputProps={{
                        required: true,
                        value: this.state.searchData,
                        onChange: (e) =>
                          this.setState({ searchData: e.target.value }),
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button
                      style={{
                        position: "relative",
                        top: "12px",
                        right: "20px",
                      }}
                      onClick={() => {
                        this.handleSearch();
                      }}
                      className="btn-round"
                      color="success"
                    >
                      {this.state.searchClicked ? "Searching..." : "Search"}
                    </Button>
                  </GridItem>
                </GridContainer>
                <h3>Messages</h3>
                <Link to="/admin/sendmessages">
                  <Button className="" color="warning">
                    Send Messages
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Type",
                    "Date",
                    "User Details",
                    "Status",
                    "Actions",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [sec.type],
                      [
                        sec.date.slice(8, 10) +
                          "-" +
                          sec.date.slice(5, 7) +
                          "-" +
                          sec.date.slice(0, 4),
                      ],
                      [
                        <div key={index}>
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {sec.name ? sec.name : "NA"},
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "15px",
                              // fontWeight:'bold',
                              color: "blue",
                            }}
                          >
                            {sec.email ? sec.email : "NA"}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {sec.mobile_no ? sec.mobile_no : "NA"},
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            PW : {sec.password ? sec.password : "NA"}
                          </span>
                        </div>,
                      ],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sec.status}
                          inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleSelect(e, sec.id);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={0}
                          >
                            Not Interested
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={1}
                          >
                            Interested
                          </MenuItem>
                        </Select>,
                      ],
                      [
                        <div key={index}>
                          <i
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              bottom: "4px",
                            }}
                            onClick={() => {
                              this.setState({ mess: true });
                              this.handleModal(sec.id, sec.name, sec.comment);
                            }}
                            className="fab"
                          >
                            &#xf39f;
                          </i>
                          <i
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => {
                              this.setState({ mess: false });
                              this.handleModal(sec.id, sec.name, sec.comment);
                            }}
                            className="material-icons"
                          >
                            &#xe5c9;
                          </i>
                        </div>,
                      ],
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty ? null : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
        <Modal
          visible={this.state.visible}
          width="1000"
          height="330"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  {this.state.mess ? (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h3 style={{ textAlign: "center" }}>
                          Comment on {this.state.user}
                        </h3>
                        <label>Comment</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.state.comment}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              comment: data,
                            });
                          }}
                        />
                        {this.state.notifyNote ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Commented Successfuly!
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          className=""
                          color="warning"
                          onClick={this.sendMessage}
                        >
                          {this.state.loading ? <Loader /> : "Comment"}
                        </Button>
                        <Button
                          className=""
                          color="danger"
                          onClick={() => {
                            this.setState({ visible: false });
                          }}
                        >
                          Cancel
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Message Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.idToDelete);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
      </div>
    );
  }
}

export default Messages;
