import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const classes = makeStyles(styles);

class AddTopic extends Component {
  state = {
    allCourses: [],
    course: "",
    lectures: "",
    topic: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount(){
    this.getAllCourses()
  }
  getAllCourses =()=>{
    axios.get("https://admin.akclasses.in/api/coursesForFilter?type=COURSES")
      .then((resp)=>{
        // console.log(resp)
        this.setState({
          allCourses: resp.data
        })
      })
  }
  handleInput=(e)=>{
    this.setState({
      [e.target.name]: e.target.value
    },()=>{console.log(this.state.course)})
  }
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      name: this.state.topic,
      no_of_lecture: this.state.lectures,
      course_id: this.state.course,
    };
    axios.post("https://admin.akclasses.in/api/topic", payLoad).then(() => {
      //console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/topics"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Topic Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Topic</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Topic"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ topic: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Courses
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                             value={this.state.course}
                              inputProps={{
                                name: "course",
                                id: "simple-select",
                                onChange: this.handleInput
                              }}
                            >
                              {this.state.allCourses.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={i}
                                  value={v.ID}
                                >
                                  {v.TITLE}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          
                        </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="No. of Lectures"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "number",
                            onChange: e =>
                              this.setState({ lectures: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                 
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Topic"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/topics"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddTopic;
