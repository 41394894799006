import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import FormControl from "@material-ui/core/FormControl";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { MenuItem, Select, InputLabel, TextField } from "@material-ui/core";
import { WbIncandescentOutlined } from "@material-ui/icons";

export default class Activate_Strategies extends Component {
  state = {
    mobile_no: "",
    course_id: "",
    strategy_id: "",
    status: "ACTIVE",
    timing: "",
    epoch_time: "",
    notification: false,
    message: "",
    loading: false,
  };

  handleTime = (e) => {
    let date = new Date(e.target.value).getTime() / 1000;
    // console.log(date);
    this.setState({
      epoch_time: date,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let payload = {
      mobile_no: this.state.mobile_no,
      course_id: this.state.course_id,
      strategy_id: this.state.strategy_id,
      status: this.state.status,
      timing: this.state.epoch_time,
    };
    // console.log(payload)
    axios
      .post("https://admin.akclasses.in/api/noval/start_strategy", payload, 
      // {
      //   withCredentials: true,
      //   headers: {
      //     NoauthKey:
      //       "lIgcNQQlOnNyAdeCRrnyNwVyeZHEQm0ylJ80YH12GiVJihMQouDMkarPclkM",
      //   },
      // }
      )
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "failed") {
          this.setState({
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              message: resp.data.message,
              loading: false,
            });
          }, 3000);
        } else {
          this.setState({
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState(
              {
                notification: false,
                message: resp.data.message,
              },
              () => {
                window.location.reload();
              }
            );
          }, 3000);
        }
      });
  };

  render() {
    return (
      <GridContainer>
        {this.state.notification ? (
          <div style={{ position: "relative", left: "600px" }}>
            <SnackbarContent
              message={this.state.message}
              icon={AddAlert}
              color="success"
            />
          </div>
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger">
                <EditIcon />
              </CardIcon>
              <h4 style={{ color: "black" }}>Activate Strategy</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={this.handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="User Mobile Number"
                      id="float"
                      inputProps={{
                        required: true,
                        onChange: (e) =>
                          this.setState({ mobile_no: e.target.value }),
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Course Id"
                      id="float"
                      inputProps={{
                        required: true,
                        onChange: (e) =>
                          this.setState({ course_id: e.target.value }),
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Strategy Id"
                      id="float"
                      inputProps={{
                        required: true,
                        onChange: (e) =>
                          this.setState({ strategy_id: e.target.value }),
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      labelText="Status"
                      id="float"
                      inputProps={{
                        required: true,
                      }}
                      value={this.state.status}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      type="datetime-local"
                      labelText="Date-Time"
                      id="float"
                      inputProps={{
                        required: true,
                        onChange: (e) => this.handleTime(e),
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <br />
                </GridContainer>
                {/* <GridItem xs={12} sm={12} md={6}> */}
                <Button className="" color="warning" type="submit">
                  {this.state.loading ? <Loader /> : "Activate Strategy"}
                </Button>
                {/* </GridItem> */}
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
