import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Datetime from "react-datetime";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-awesome-modal";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import TimePicker from "react-time-picker";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";
import { btoa } from "js-base64";
import { TextField } from "@material-ui/core";
let strTime = "12:00";
let endTime = "12:00";
let time = "12:00";
let fd = new FormData();
const classes = makeStyles(styles);
class AddLiveQuiz extends Component {
  state = {
    name: "",
    title: "",
    price: "",
    total: "",
    right: "",
    wrong: "",
    question: "",
    type: "free",
    fee: 0,
    percent: "",
    time: "",
    image: [],
    faculty: [],
    strDate: "",
    strTime: "",
    endDate: "",
    endTime: "",
    examDate: "",
    visible: false,
    redirect: false,
    notification: false,
    loading: false,
    last_insert_id: "",
    questionsLimit: "",
    max: false,
    startDate: "",
    endDate: "",
    testEndTime: "",
    app_flash_image: "",
  };

  componentDidMount() {
    axios
      .get(Constants.getUrls.getQuestionBank + "?per_page=10")
      .then((resp) => {
        // console.log(resp);
        this.setState({
          questionsLimit: resp.data.total,
        });
      });
  }

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    fd.append("reg_type", this.state.type);
    fd.append("reg_fee", this.state.type === "free" ? 0 : this.state.fee);
    fd.append("name", this.state.name);
    fd.append("reg_start_date", this.state.strDate.substring(0, 10));
    fd.append("reg_start_time", this.state.strDate.substring(11, 16));
    fd.append("reg_end_date", this.state.endDate.substring(0, 10));
    fd.append("reg_end_time", this.state.endDate.substring(11, 16));
    fd.append("exam_date", this.state.examDate.substring(0, 10));
    fd.append("image", this.state.image);
    fd.append("topScore", JSON.stringify(this.state.faculty));
    fd.append("test_timing", this.state.examDate.substring(11, 16));
    fd.append("total_mark", parseInt(this.state.total));
    fd.append("right_mark", parseInt(this.state.right));
    fd.append("wrong_mark", parseFloat(this.state.wrong));
    fd.append("no_of_questions", parseFloat(this.state.question));
    fd.append("app_flash_image", this.state.app_flash_image);
    // let payLoad = {
    //   reg_type: this.state.type,
    //   reg_fee: this.state.type === "free" ? 0 : this.state.fee,
    //   name: this.state.name,
    //   reg_start_date: this.state.strDate,
    //   reg_start_time: strTime,
    //   reg_end_date: this.state.endDate,
    //   reg_end_time: endTime,
    //   exam_date: this.state.examDate,
    //   test_timing: time,
    //   total_mark: parseInt(this.state.total),
    //   right_mark: parseFloat(this.state.right),
    //   wrong_mark: parseFloat(this.state.wrong)
    // };
    axios.post(Constants.postUrls.createTestSeries, fd).then((resp) => {
      console.log(resp);
      this.setState({
        notification: true,
        loading: false,
        // last_insert_id:resp.data.last_insert_id
        last_insert_id: resp.data.test_Series.id,
      });
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 1000);
    });
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={"/admin/addlivequizquestion/" + btoa(this.state.last_insert_id)}
        />
      );
      // return <Redirect to={"/admin/livequiz"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Live Quiz Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Live Quiz</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ name: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Registration Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.type}
                            inputProps={{
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  type: e.target.value,
                                  bAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {["Free", "Paid"].map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v === "Free" ? "free" : "paid"}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      {this.state.type !== "free" ? (
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Registration Fee"
                            id="float"
                            inputProps={{
                              required: true,
                              type: "number",
                              onChange: (e) =>
                                this.setState({ fee: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                      ) : null}

                      <GridItem xs={12} sm={12} md={4}>
                        Registration Start
                        <br />
                        <TextField
                          style={{
                            width: "100%",
                            marginTop: "7px",
                          }}
                          type="datetime-local"
                          labelText="Registration Start"
                          value={this.state.strDate}
                          onChange={(e) => {
                            this.setState({
                              strDate: e.target.value,
                            });
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        Registration End
                        <br />
                        <TextField
                          style={{
                            width: "100%",
                            marginTop: "7px",
                          }}
                          type="datetime-local"
                          labelText="Registration End"
                          value={this.state.endDate}
                          onChange={(e) => {
                            this.setState({
                              endDate: e.target.value,
                            });
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        Exam Date
                        <br />
                        <TextField
                          style={{
                            marginTop: "7px",
                            width: "100%",
                          }}
                          type="datetime-local"
                          value={this.state.examDate}
                          labelText="Exam Date"
                          onChange={(e) => {
                            this.setState({
                              examDate: e.target.value,
                            });
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Total Marks"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ total: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Right Answer Marks"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "float",
                            onChange: (e) =>
                              this.setState({
                                right: e.target.value,
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Wrong Answer Marks"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "float",
                            onChange: (e) =>
                              this.setState({
                                wrong: e.target.value,
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="No. Of Questions"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "number",
                            onChange: (e) =>
                              this.setState(
                                { question: e.target.value },
                                () => {
                                  if (
                                    this.state.question >
                                    this.state.questionsLimit
                                  ) {
                                    this.setState({ max: true });
                                  } else {
                                    this.setState({ max: false });
                                  }
                                }
                              ),
                            // onChange: (e) =>
                            //   this.setState({
                            //     question: e.target.value,
                            //   }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              You can add only {this.state.questionsLimit}{" "}
                              questions.
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Outer Image</label>
                        <input
                          id="image"
                          name="app_flash_image"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                          required={true}
                          style={{ marginTop: "15px" }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <label htmlFor="">Inner Image</label>
                        <input
                          id="image"
                          name="image"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                          required={true}
                          style={{ marginTop: "15px" }}
                        />
                      </GridItem>

                      {this.state.faculty.length >= 1 ? (
                        <GridItem xs={12} sm={12} md={12}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px black solid",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tr
                              style={{
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Sr.No
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Title
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Price
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Percent
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                            {this.state.faculty.map((v, i) => (
                              <tr
                                style={{
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                                key={i}
                              >
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {i + 1}
                                </td>
                                <td
                                  style={{
                                    padding: "4px 10px 4px 10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {v.title}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {v.price}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {v.percent}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <input
                                    style={{
                                      backgroundColor: "red",
                                      borderRadius: "10%",
                                      border: "none",
                                      padding: "10px",
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      this.state.faculty.splice(i, 1);
                                      this.setState({
                                        faculty: this.state.faculty,
                                      });
                                    }}
                                    type="button"
                                    value="Delete"
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          style={{ marginTop: "20px" }}
                          onClick={() => {
                            this.setState({
                              visible: true,
                            });
                          }}
                          className="btn-round"
                          color="success"
                        >
                          Add Top Scores
                        </Button>
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={12}>
                        <label>Image</label>
                        <br />
                        <input
                          name="image"
                          onChange={this.handleFile}
                          type="file"
                          required={true}
                          accept="image/*"
                        />
                      </GridItem> */}
                      <br />
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Live Quiz"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/livequiz",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Modal
            visible={this.state.visible}
            width="700"
            height="175"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <GridContainer style={{ paddingLeft: "5px", paddingTop: "20px" }}>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Title"
                  id="float"
                  inputProps={{
                    required: true,
                    value: this.state.title,
                    onChange: (e) => this.setState({ title: e.target.value }),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Price"
                  id="float"
                  inputProps={{
                    type: "float",
                    required: true,
                    value: this.state.price,
                    onChange: (e) => this.setState({ price: e.target.value }),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Percent"
                  id="float"
                  inputProps={{
                    value: this.state.percent,
                    required: true,
                    type: "float",
                    onChange: (e) =>
                      this.setState({
                        percent: e.target.value,
                      }),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <Button
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    this.state.faculty.push({
                      title: this.state.title,
                      price: parseFloat(this.state.price),
                      percent: parseFloat(this.state.percent),
                    });
                    this.setState({
                      faculty: this.state.faculty,
                      title: "",
                      percent: "",
                      price: "",
                    });
                    this.setState({
                      visible: false,
                    });
                  }}
                  className="btn-round"
                  color="success"
                >
                  Add
                </Button>
              </GridItem>
            </GridContainer>
          </Modal>
        </div>
      );
    }
  }
}

export default AddLiveQuiz;
