import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import MenuItem from "@material-ui/core/MenuItem";



const useStyles = makeStyles(styles);
const Trackerdrops = () => {
    const classes = useStyles();
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [subChapters, setSubChapters] = useState([]);
  const [tableData,setTableData] = useState([])

  useEffect(() => {}, []);

  const subject = async () => {
    try {
      const res = await axios.get(`api`);
    } catch (error) {
      console.log(error);
    }
  };

  const chapter = async () => {
    try {
      const res = await axios.get(`api`);
    } catch (error) {
      console.log(error);
    }
  };

  const subchapter = async () => {
    try {
      const res = await axios.get(`api`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger"></CardIcon>
            </CardHeader>
            <CardBody>
            <Table
                tableHead={["Id", " Tracker Title", " Tracker Description", "Status", "Actions"]}
                tableData={tableData.map((item, index) => [
                  index + 1,
                  item.title,
                  item.description,

                  [
                    <Select
                      key={index}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={item.status}
                      inputProps={{
                        name: "status",
                      }}
                    //   onChange={(e) =>
                    //     handleStatusChange(item.id, e.target.value)
                    //   }
                      onClick={(e) => console.log(e.target.value)}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="active"
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="inactive"
                      >
                        Inactive
                      </MenuItem>
                    </Select>,
                  ],
                  [
                    <Button
                      key={`update-${index}`}
                    //   onClick={() => handleOpenUpdateModal(item)}
                      className="btn-round"
                      color="warning"
                    >
                      Update
                    </Button>,
                    <Button
                      key={`delete-${index}`}
                    //   onClick={() => handleDelete(item)}
                      className="btn-round"
                      color="danger"
                    >
                      Delete
                    </Button>,
                  ],
                ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Trackerdrops;
