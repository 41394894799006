import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import Notification from "components/Notification";
import CardIcon from "components/Card/CardIcon.js";
import Constants from "../variables/Constants";
import CardBody from "components/Card/CardBody.js";

class TechnicalSupport extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios.get(Constants.getUrls.coursesMaster).then(resp => {
      //console.log(resp.data.data);
      this.setState({
        name: resp.data.heading,
        email: resp.data.freeStudyMaterial
      });
    });
  }
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      heading: this.state.name,
      content: this.state.email,
      subject: this.state.subject,
      message: this.state.message
    };
    axios.post(Constants.postUrls.updateAboutUs, payLoad).then(() => {
      //console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: false
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <div>
          {this.state.notification ? (
            <Notification title={"Text Below Slider Updated"} duration={3000} />
          ) : null}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Technical Support</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"
                          inputProps={{
                            value: this.state.name,
                            onChange: e =>
                              this.setState({ name: e.target.value }, () => {
                                //console.log(this.state.name);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Email"
                          inputProps={{
                            value: this.state.email,
                            onChange: e =>
                              this.setState({ email: e.target.value }, () => {
                                //console.log(this.state.email);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Subject"
                          inputProps={{
                            // value: this.state.subject,
                            onChange: e =>
                              this.setState({ subject: e.target.value }, () => {
                                //console.log(this.state.subject);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label htmlFor="">Your Message</label>
                        <br />
                        <textarea
                          name="message"
                          onChange={this.handleInput}
                          className="form-control"
                          cols="119"
                          rows="6"
                        >
                          {/* {this.state.defGoal} */}
                        </textarea>
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Update"}
                      </Button>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default TechnicalSupport;
