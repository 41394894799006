/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { MultiSelect } from "react-multi-select-component";
import Constants from "../variables/Constants";

const classes = makeStyles(styles);

// console.log(selectId)
class UpdateCourseSubject extends Component {
  state = {
    type: "",
    pkey: "",
    category: "",
    combo_type: "",
    courses: [],
    redirect: false,
    aAlert: false,
    bAlert: false,
    notification: false,
    loading: false,
    batch_wings_id: [],
    addedWings: [],
    unAddedWings: [],
  };
  componentDidMount() {
    let selectId = window.location.pathname.split("/")[3];
    console.log(selectId);
    let categoryData = this.props.location.state.categoryData;
    console.log(categoryData);
    this.setState({
      combo_type: categoryData.combo_type,
    });
    axios
      .get("https://admin.akclasses.in/api/stream_without_paginate?type=Course")
      // .get("https://admin.akclasses.in/api/stream/" + selectId)
      .then((resp) => {
        console.log(resp);
        this.setState({
          courses: resp.data,
          category: categoryData.stream_id,
          pkey: categoryData.title,
        });
      });
    //   axios
    //   .get(Constants.getUrls.getBatchWings)
    //   .then((resp)=>{
    //     console.log(resp)
    //    resp.data.forEach((getid)=>{
    //       this.state.gotIdArray.push({
    //        value:  getid.id
    //     }, ()=> console.log(this.state.gotIdArray))
    //   })
    // })

    let payload = {
      subject_id: selectId,
    };
    axios
      .get(
        "https://admin.akclasses.in/api/show_alloted_batchwings?subject_id=" +
          selectId
      )
      .then((resp) => {
        resp.data.addedData.map((v) => {
          this.state.addedWings.push({
            label: v.title,
            value: v.id,
          });
        });
      });
    axios.get(Constants.getUrls.getBatchWings).then((resp) => {
      console.log(resp);
      resp.data.map((v) => {
        this.state.unAddedWings.push({
          // label: v.title + " " + "(" + v.attempt.title + ")",
          label: v.title,
          value: v.id,
        });
      });
    });

    // axios.get("https://admin.akclasses.in/api/subject_master/show/" + selectId)
    // axios
    // .get("https://admin.akclasses.in/api/stream/" + this.sta)
    // .then((res)=>{
    //   console.log(res)
    //   // this.setState({
    //   //   combo_type: res.data.stream.combo_type
    //   // })
    // })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.state.addedWings.forEach((v) => {
      this.state.batch_wings_id.push({ value: v.value });
    });
    let payLoad = {
      type: "Course",
      stream_id: this.state.category,
      title: this.state.pkey,
      combo_type: this.state.combo_type,
      batch_wings_id: this.state.batch_wings_id,
    };
    if (this.state.category === "") {
      this.setState({
        bAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.id;
      axios
        .post(
          "https://admin.akclasses.in/api/subject_master/update/" + categoryId,
          payLoad
        )
        .then((resp) => {
          console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/coursesubjects"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Subject Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Update Course Subject</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  category: e.target.value,
                                  bAlert: false,
                                });
                                // // console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Update Combo Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.combo_type}
                            inputProps={{
                              name: "combo_type",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  combo_type: e.target.value,
                                });
                                console.log(e.target.value);
                              },
                            }}
                          >
                            <MenuItem value="Normal">Normal</MenuItem>
                            <MenuItem value="Combo">Combo</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "20px", zIndex: "100" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <span>Select Wings</span>
                          <MultiSelect
                            disableSearch
                            options={this.state.unAddedWings}
                            name="wings"
                            value={this.state.addedWings}
                            onChange={(data) => {
                              this.setState(
                                {
                                  addedWings: data,
                                },
                                () => {
                                  console.log(this.state.addedWings);
                                }
                              );
                            }}
                          />
                          {/* <MultiSelect
                              disableSearch
                              options={this.state.unAddedWings}
                              value={this.state.addedWings}
                              onChange={data=>{
                                this.setState({ addedWings: data}, ()=>{console.log(this.state.addedWings)})
                              }}
                             /> */}
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            value: this.state.pkey,
                            required: true,
                            onChange: (e) =>
                              this.setState({ pkey: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Update Course Subject"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/coursesubjects",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateCourseSubject;
