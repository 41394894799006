import React, { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { Modal, Box, Typography, TextField, Snackbar } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles(styles);

const Rchapter = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [chapterId, setChapterId] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [subject, setSubject] = useState([]);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleteChapterId, setDeleteChapterId] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateChapterId, setUpdateChapterId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchAllChapter();
    fetchSubjects();
  }, []);

  // const fetchAll = async () => {
  //   try {
  //     const res = await axios.get(`https://admin.akclasses.in/api/chapter/?subject_id=${subjectId}`);
  //     console.log(res.data.data);
  //     setTableData(res.data.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchAllChapter = async () => {
    try {
      const res = await axios.get(`https://admin.akclasses.in/api/chapter/`);
      console.log(res.data.data, "chapters");
      setTableData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubjects = async () => {
    try {
      const res = await axios.get(`https://admin.akclasses.in/api/subject/`);
      setSubject(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDelete = (chapterId) => {
    setDeleteChapterId(chapterId);  // Set the chapter to delete
    setOpenDeleteConfirm(true);  // Open confirmation modal
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://admin.akclasses.in/api/chapter/${deleteChapterId}`
      );
      setDeleteSnackbarOpen(true);  // Show success message
      fetchAllChapter();  // Refresh chapter list
      setOpenDeleteConfirm(false);  // Close the confirmation modal
    } catch (error) {
      console.error(error);  // Handle error
    }
  };

  const handleOpenUpdateModal = (chapter) => {
    setTitle(chapter.title);
    setDescription(chapter.description);
    setSubjectId(chapter.subject.id);
    setUpdateChapterId(chapter.id);
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setSubjectId("");
    setTitle("");
    setDescription("");
    setUpdateChapterId(null);
    setOpenUpdate(false);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const payLoad = { title, description, subject_id: subjectId };
    try {
      await axios.post(
        `https://admin.akclasses.in/api/chapter/${updateChapterId}`,
        payLoad
      );
      setSnackbarOpen(true);
      handleCloseUpdate();
      fetchAllChapter();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title,
      description,
      subject_id: subjectId,
    };
    try {
      await axios.post(`https://admin.akclasses.in/api/chapter/`, payload);
      fetchAllChapter();
      setOpenCreate(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStatusChange = async (id, status) => {
    try {
      const updatedStatus = status === "active" ? "active" : "inactive";
      await axios.post(`https://admin.akclasses.in/api/chapter/${id}`, {
        status: updatedStatus,
      });
      fetchAllChapter();
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      {/* Create Chapter Modal */}
      <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6">Chapter Form</Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
              >
                {subject.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button color="info" type="submit">
              Submit
            </Button>
            <Button color="danger" onClick={() => setOpenCreate(false)}>
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal open={openDeleteConfirm} onClose={handleCloseDeleteConfirm}>
        <Box sx={modalStyle}>
          <Typography variant="h6">Confirm Deletion</Typography>
          <Typography>Are you sure you want to delete this chapter?</Typography>
          <Button color="danger" onClick={handleDelete}>
            Confirm
          </Button>
          <Button color="info" onClick={handleCloseDeleteConfirm}>
            Cancel
          </Button>
        </Box>
      </Modal>

      {/* Update Chapter Modal */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Chapter
          </Typography>
          <form onSubmit={handleUpdate}>
            {/* Subject Dropdown */}
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)} // Update the subject ID when changed
              >
                {subject.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="danger"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleCloseUpdate}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Updated Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />
      <Snackbar
        open={deleteSnackbarOpen}
        autoHideDuration={1000}
        onClose={() => setDeleteSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Deleted Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger"></CardIcon>
            </CardHeader>
            <CardBody>
              {/* <FormControl style={{width:100,marginLeft:90,marginRight:90}}>
              <label>subject</label>
              <Select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
              >
                {subject.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
              <Button color="success" onClick={() => setOpenCreate(true)}>
                Add Chapter
              </Button>

              <Table
                tableHead={[
                  "ID",
                  "SUBJECT NAME",
                  "TITLE",
                  "DESCRIPTION",
                  "STATUS",
                  "Actions",
                ]}
                tableData={tableData.map((item, index) => [
                  index + 1,
                  item.subject?.title,
                  item.title,
                  item.description,

                  [
                    <Select
                      key={index}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={item.status}
                      inputProps={{
                        name: "status",
                      }}
                      onChange={(e) =>
                        handleStatusChange(item.id, e.target.value)
                      }
                      onClick={(e) => console.log(e.target.value)}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="active"
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="inactive"
                      >
                        Inactive
                      </MenuItem>
                    </Select>,
                  ],
                  [
                    <Button
                      key={`update-${index}`}
                      onClick={() => handleOpenUpdateModal(item)}
                      className="btn-round"
                      color="warning"
                    >
                      Update
                    </Button>,
                 <Button
                 key={`delete-${index}`}
                 onClick={() => confirmDelete(item.id)}  // Corrected: Pass chapter ID
                 className="btn-round"
                 color="danger"
               >
                 Delete
               </Button>
                  ],
                ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Rchapter;
