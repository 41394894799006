/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class AddReplica extends Component {
  state = {
    category: "",
    courses: [],
    faculties: [],
    redirect: false,
    aAlert: false,
    bAlert: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/faculty").then(resp => {
      // console.log(resp.data);
      this.setState({
        faculties: resp.data
      });
    });
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
      .then(resp => {
        console.log(this.props.location.id);
        console.log(resp.data);
        this.setState({
          courses: resp.data
        });
      });
  }
  findFaculty = data => {
    let obj = this.state.faculties.find(v => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    let payLoad = {
      course_id: this.state.category,
      strategy_id: this.props.location.id
    };
    if (this.state.category === "") {
      this.setState({
        bAlert: true
      });
    } else {
      this.setState({
        loading: true
      });
      axios
        .post("https://admin.akclasses.in/api/createStrategyCopy", payLoad)
        .then(() => {
          //console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/strategies"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Replica Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Strategy Replica</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Course
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  category: e.target.value,
                                  bAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.course.ID}
                              >
                                {
                                  <span>
                                    {v.course.TITLE}(
                                    {v.course.faculty.length > 1
                                      ? "AK Team"
                                      : this.findFaculty(v.course.faculty)}
                                    )
                                  </span>
                                }
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Replica"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/strategies"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddReplica;
