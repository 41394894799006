import React, { Component } from "react";
import axios from "axios";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
class AppFaculty extends Component {
  state = {
    faculties: [],
    loader: true,
    noData: false,
    status: false
  };
  componentDidMount() {
    axios.get(Constants.getUrls.faculties).then(resp => {
      console.log(resp.data);
      this.setState({
        loader: false,
        faculties: resp.data
      });
    });
  }

  // handleStatus = e => {
  //   const id = e.target.id;
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value
  //     },
  //     () => {
  //       console.log(this.state.status);
  //       const statusPayload = {
  //         active: this.state.status === "false" ? false : true
  //       };
  //       axios
  //         .put(Constants.updateUrls.updatefaculties + id, statusPayload)
  //         .then(res => {
  //           window.location.reload();
  //         });
  //     }
  //   );
  // };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.show ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Question Deleted Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Faculty Details</h3>
                <Link to="/admin/addappfaculty">
                  <Button className="" color="warning">
                    Add Faculty
                  </Button>
                </Link>
                <Table
                  tableHead={["Sr No.", "Name", "Email", "Mobile No."]}
                  tableData={this.state.faculties.map((question, index) => [
                    [index + 1],
                    [question.name],
                    [question.email],
                    [question.mobile_no]
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.faculties.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default AppFaculty;
