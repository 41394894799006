import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Modal from "react-awesome-modal";
import renderHTML from "react-render-html";
import Loader from "components/Loader";
import Constants from "variables/Constants";

const useStyles = makeStyles({
  selectFormControl: {
    // Your custom styles
  },
  selectLabel: {
    // Your custom styles
  },
  selectMenu: {
    // Your custom styles
  },
  select: {
    // Your custom styles
  },
  selectMenuItem: {
    // Your custom styles
  },
  selectMenuItemSelected: {
    // Your custom styles
  },
});

export default class Manual_Notification extends Component {
  state = {
    allNotifcation: [],
    per_page: "10",
    currentPage: 1,
    visible: false,
    notificationData: {
      message: "",
      heading: "",
      project: "",
      image: "",
      buttonLink: "",
      buttonTitle: "",
      video: "",
      id: ""
    },
    confirmationModalVisible: false,
    notifyDelete: false,
    delLoading: false,
  };

  componentDidMount() {
    this.getAllNotification();
  }

  getAllNotification = () => {
    axios
      .get(`${Constants.getUrls.getManualNotification}?is_deleted=0`)
      .then((resp) => {
        console.log(resp.data.data.data)
        this.setState({
          allNotifcation: resp.data.data.data,
        });
      });
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios
      .delete(`${Constants.postUrls.addManualNotification}/${id}`)
      .then((resp) => {
        this.setState({ notifyDelete: true, delLoading: false });
        setTimeout(() => {
          this.setState({ notifyDelete: false, visible: false }, this.getAllNotification);
        }, 1000);
      });
  };

  handleModal = (sec) => {
    this.setState({ rowId: sec.id, visible: true });
  };

  openConfirmationModal = (sec) => {
    this.setState({ confirmationModalVisible: true, notificationData: sec });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      notificationData: {
        ...prevState.notificationData,
        [name]: value,
      },
    }));
  };

  handleConfirmNotification = async () => {
    const { notificationData } = this.state;
    const data = {
      app_id: "662dd520-ab02-40da-9dae-3b8db6c8de5a",
      included_segments: ["All"],
      headings: { en: notificationData.heading },
      contents: { en: notificationData.message },
      url: "https://www.example.com",
      chrome_web_image: "https://admin.akclasses.in" + notificationData.image,
      ios_attachments: { id: "https://www.example.com/image.jpg" },
      data: { project_name: notificationData.project },
    };

    try {
      const resp = await axios.post(`https://onesignal.com/api/v1/notifications/?id=${notificationData.id}`, data, {
        headers: {
          Authorization: "Basic NWI4NWRjZTMtOWFjYi00ZDc0LTlhYjctMjVkYTdhZDU1NjY2",
          "Content-Type": "application/json",
        },
      });
      console.log(resp);
      this.setState({ confirmationModalVisible: false });
    } catch (err) {
      console.error("Error sending notification:", err);
      this.setState({ confirmationModalVisible: false });
    }
  };

  handleCancelNotification = () => {
    this.setState({ confirmationModalVisible: false });
  };

  handleStatusChange = async (event, id) => {
    const { value } = event.target;
    const { allNotifcation } = this.state;
    const notification = allNotifcation.find((notif) => notif.id === id);
    
    const data = {
      ...notification,
      status: value, 
     
    };
  
    try {
      await axios.put(`https://admin.akclasses.in/api/onesignal-notification/${id}`, data);
      this.setState((prevState) => ({
        allNotifcation: prevState.allNotifcation.map((notif) =>
          notif.id === id ? { ...notif, status: value } : notif
        ),
      }));
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  

  

  render() {
    const { allNotifcation, confirmationModalVisible, notificationData } = this.state;

    return (
      <div>
        <GridContainer>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger">
                <Assignment />
              </CardIcon>
              <h4>Manual Notification</h4>
            </CardHeader>
            <CardBody>
              <FormControl>
                <InputLabel htmlFor="simple-select">Per Page</InputLabel>
                <Select
                  MenuProps={{ className: useStyles.selectMenu }}
                  value={this.state.per_page}
                  inputProps={{
                    name: "per_page",
                    id: "simple-select",
                    onChange: (e) => this.setState({ per_page: e.target.value }),
                  }}
                >
                  <MenuItem value="25">25</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                </Select>
              </FormControl>
              <Link to="/admin/addManualNotification">
                <Button color="warning">Add Notification</Button>
              </Link>
              <Table
                tableHead={[
                  "S.no",
                  "Heading",
                  "Image",
                  "Description",
                  "Button Title",
                  "Button Link",
                  "Video",
                  "Project",
                  "Likes",
                  "Views",
                  "Shared",
                  "status",
                  "Actions",
                ]}
                tableData={allNotifcation.map((sec, index) => [
                  [index + 1],
                  [sec.heading ? sec.heading : "NA"],
                  [
                    sec.image ? (
                      <img
                        src={"https://admin.akclasses.in" + sec.image}
                        style={{ height: "100px", width: "100px" }}
                      />
                    ) : null,
                  ],
                  [sec.message ? renderHTML(sec.message) : "NA"],
                  [sec.button_title],
                  [sec.button_link],
                  [
                    sec.video_upload_file ? (
                      <video
                        width="100"
                        height="100"
                        controls
                        src={"https://admin.akclasses.in" + sec.video_upload_file}
                      >
                      </video>
                    ) : "No Video"
                  ],
                  [sec.project ? sec.project : "NA"],
                  [sec.like_count],
                  [sec.views_count],
                  [sec.share_count],
                  [
                    <Select
                      value={sec.status}
                      inputProps={{
                        name: "status",
                        id: "simple-select",
                        onChange: (e) => this.handleStatusChange(e, sec.id),
                      }}
                    >
                      <MenuItem value="active">ACTIVE</MenuItem>
                      <MenuItem value="inactive">INACTIVE</MenuItem>
                    </Select>,
                  ],
                  [
                    <Link to={{ pathname: `/admin/updateManualNotification/${sec.id}`, state: sec }}>
                      <Button color="info">Update</Button>
                    </Link>,
                    <Button onClick={() => this.handleModal(sec)} color="danger">Delete</Button>,
                    <Button onClick={() => this.openConfirmationModal(sec)} color="success">SEND NOTIFICATION</Button>,
                  ],
                ])}
              />
            </CardBody>
          </Card>

          {/* Confirmation Modal for Deleting */}
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody>
                    <h3>Are You Sure You Want to Delete It?</h3>
                    {this.state.notifyDelete && <span style={{ color: "green" }}>Notification Deleted Successfully!</span>}
                    <GridContainer>
                      <GridItem xs={12}>
                        <Button color="warning" onClick={() => this.handleDelete(this.state.rowId)}>
                          {this.state.delLoading ? <Loader /> : "Yes"}
                        </Button>
                        <Button color="danger" onClick={() => this.setState({ visible: false })}>No</Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>

          {/* Confirmation Modal for Sending Notification */}
          <Modal
            visible={confirmationModalVisible}
            width="600"
            height="200"
            effect="fadeInUp"
            onClickAway={this.handleCancelNotification}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody>
                    <h3>Are you sure you want to send this notification?</h3>
                    <textarea
                      name="message"
                      value={notificationData.message}
                      onChange={this.handleChange}
                      placeholder="Enter notification message"
                      rows="4"
                      cols="50"
                    />
                    <GridContainer>
                      <GridItem xs={12}>
                        <Button color="rose" onClick={this.handleConfirmNotification}>Yes</Button>
                        <Button color="danger" onClick={this.handleCancelNotification}>No</Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
      </div>
    );
  }
}
