import React, { Component } from "react";
import axios from "axios";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);

class AppSubjects extends Component {
  state = {
    subjects: [],
    status: false,
    loader: true,
    noData: false
  };
  componentDidMount() {
    axios.get(Constants.getUrls.subjects + "all=true").then(resp => {
      // console.log(resp.data);
      this.setState({
        loader: false,
        subjects: resp.data.subjects
      });
    });
  }

  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const statusPayload = {
          active: this.state.status === "false" ? false : true
        };
        axios
          .put(Constants.updateUrls.updateSubject + id, statusPayload)
          .then(() => {
            axios.get(Constants.getUrls.subjects + "all=true").then(resp => {
              // console.log(resp.data);
              this.setState({
                loader: false,
                subjects: resp.data.subjects
              });
            });
            // window.location.reload();
          });
      }
    );
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.show ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Question Deleted Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Subjects</h3>
                <Link to="/admin/addappsubject">
                  <Button className="" color="warning">
                    Add Subject
                  </Button>
                </Link>
                <Table
                  tableHead={["Sr No.", "Name", "Chapter", "Status", "Action"]}
                  tableData={this.state.subjects.map((question, index) => [
                    [index + 1],
                    [question.name],
                    [
                      question.chapters.map((chapter, index) => (
                        <span key={index}>
                          {chapter.name}
                          {index === question.chapters.length - 1 ? "." : ","}
                        </span>
                      ))
                    ],
                    [
                      <Select
                        key={index}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={question.active}
                        inputProps={{
                          name: "status",
                          id: "simple-select",
                          onChange: e => {
                            this.handleStatus(e, question.id);
                          }
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="true"
                        >
                          ACTIVE
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="false"
                        >
                          INACTIVE
                        </MenuItem>
                      </Select>
                    ],
                    [
                      <Link
                        key={index}
                        to={{
                          pathname: "/admin/updateappsubject/" + question.id,
                          state: { subjectData: question }
                        }}
                      >
                        <Button className="btn-round" color="info">
                          Update
                        </Button>
                      </Link>
                    ]
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.subjects.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default AppSubjects;
