import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import FormControl from "@material-ui/core/FormControl";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Datetime from "react-datetime";

class ExpenseTypeReport extends Component {
  state = {
    notifyStatus: false,
    ord: true,
    secondaryCategories: [],
    // frmDate: "2000/01/01",
    // toDate: "",
    status: "",
    expenseType: window.location.pathname.split("/")[3],
    tableArray: [],
    count: 0,
    active: 1,
    btnIdArray: [],
    download: false
  };
  componentDidMount() {
    console.log(this.state.expenseType);
    let expenseType = this.state.expenseType.replace(/%20/g, ' ');
    axios.get("https://admin.akclasses.in/api/expenseTypeReport" + "?expense_type=" + expenseType).then(resp => {
      console.log(resp);
      let btnIdArray = [];
      for (let i = 1; i <= resp.data.last_page; i++) {
        btnIdArray.push(i);
      }
      this.setState({
        secondaryCategories: resp.data.data,
        count: resp.data.total
        // btnIdArray
      });
    });
  }
  searchReport = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_expense?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then(resp => {
        //console.log(resp.data);
        this.setState({
          secondaryCategories: resp.data.data,
          count: resp.data.total,
          download: true,
          ord: false
        });
      });
  };
  handleDownload = () => {
    this.setState({ download: false });
  };
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h5>Filters</h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      frmDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.frmDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "From Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      toDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.toDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "To Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button
              style={{ position: "relative", top: "13px", right: "20px" }}
              onClick={this.searchReport}
              color="warning"
            >
              Search
            </Button>
            {this.state.download ? (
              <a
                href={
                  "https://admin.akclasses.in/api/expense_report?frmdate=" +
                  this.state.frmDate +
                  "&todate=" +
                  this.state.toDate
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  style={{
                    position: "relative",
                    bottom: "37px",
                    left: "70%"
                  }}
                  color="success"
                  onClick={this.handleDownload}
                >
                  Download Report
                </Button>
              </a>
            ) : null}
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody>
                <h3>Expense Reports</h3>

                {/* <Table
                  tableHead={[
                    "Sr No.",
                    "Date",
                    "Description",
                    "Mode",
                    "Category",
                    "Amount"
                  ]}
                  tableData={this.state.tableArray}
                /> */}
                <table
                  style={{
                    width: "100%",
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                >
                  <tr
                    style={{
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Sr No.
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Date
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Expense Type
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Description
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Category
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Mode
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                  {this.state.secondaryCategories.map((sec, index) => (
                    <tr
                      style={{
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                      key={index}
                    >
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.EXPENSE_DATE}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.EXPENSE_TYPE}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.DESCRIPTION}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.MODE ? sec.MODE : "NA"}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.CATEGORY ? sec.CATEGORY : "NA"}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.AMOUNT}
                      </td>
                    </tr>
                  ))}
                  {this.state.secondaryCategories.length > 0 ? (
                    <tr>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Totals
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        ₹{this.state.count}
                      </th>
                    </tr>
                  ) : null}
                </table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
        <div style={{ float: "right" }}>
          {this.state.btnIdArray.map((btnId, index) => (
            <div
              style={{
                width: "34px",
                position: "relative",
                height: "30px",
                borderRadius: "50%",
                backgroundColor:
                  this.state.active === index + 1 ? "#ef5350" : "transparent",
                border: "none",
                margin: "8px 2px",
                padding: "4px 12px",
                fontWeight: "bold",
                display: "inline-block",
                cursor: "pointer",
                color: this.state.active === index + 1 ? "white" : "grey"
              }}
              key={index}
              onClick={() => {
                this.nextPageUrl(btnId);
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)"
                }}
              >
                {btnId}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ExpenseTypeReport;
