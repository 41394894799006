/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
import { Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
const classes = makeStyles(styles);

export default class UpdateLiveQuizQuestion extends Component {
  state = {
    question: "",
    level: "",
    subject: "",
    chapter: "",
    products: [],
    productIds: [],
    questions: [],
    levels: [],
    subjects: [],
    chapters: [],
    redirect: false,
    notification: false,
    loading: false,
    submission: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
  };

  componentDidMount() {
    axios.get(Constants.getUrls.getQuestionBankLevelData).then((resp) => {
      console.log(resp.data);
      this.setState({
        levels: resp.data,
      });
    });
  }

  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_with_levels + this.state.level)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.subjects,
              subject: "",
              chapters: [],
              chapter: "",
              questions: [],
              question: "",
              aAlert: false,
            });
          });
      }
    );
  };

  handleChapter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_subject + this.state.subject)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              chapters: resp.data.chapter,
              chapter: "",
              questions: [],
              question: "",
              bAlert: false,
            });
          });
      }
    );
  };

  handleQuestion = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.show_questions +
              this.state.level +
              "/" +
              this.state.subject +
              "/" +
              e.target.value
          )
          .then((resp) => {
            // console.log(resp);
            resp.data.forEach((v) => {
              this.state.questions.push({
                label: v.question,
                value: v.id,
              });
            });
            this.setState({
              questions: this.state.questions,
              question: "",
              cAlert: false,
            });
          });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.level === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.subject === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.chapter === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.questions === "") {
      this.setState({
        dAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      const quizId = window.location.pathname.split("/")[3];
      // console.log(atob(quizId));

      this.state.products.forEach((v) => {
        this.state.productIds.push(v.value);
      });
      console.log(this.state.productIds);
      let payLoad = {
        test_series_id: atob(quizId),
        level_id: this.state.level,
        subject_id: this.state.subject,
        chapter_id: this.state.chapter,
        question_id: this.state.productIds,
      };
      axios
        .post(Constants.postUrls.addLiveQuizQuestion, payLoad)
        .then((resp) => {
          // console.log(resp);
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/livequiz"} />;
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Update Question</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleSubject,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleChapter,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Chapter
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.chapter}
                            inputProps={{
                              required: true,
                              name: "chapter",
                              id: "simple-select",
                              onChange: this.handleQuestion,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Subject First
                            </MenuItem>
                            {this.state.chapters.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <br />
                        <div
                          style={{
                            position: "relative",
                            bottom: "11px",
                            border: "none",
                          }}
                        >
                          <label htmlFor="Questions">Add Questions</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.questions}
                            value={this.state.products}
                            onChange={(data) => {
                              // console.log(data);
                              this.setState({
                                products: data,
                                dAlert: false,
                              });
                            }}
                            labelledBy={"Questions"}
                          />
                        </div>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Questions"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/livequiz",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>

                      {this.state.submission ? (
                        <div style={{ position: "relative", left: "600px" }}>
                          <SnackbarContent
                            message={"Questions Added Successfully!"}
                            icon={AddAlert}
                            color="success"
                          />
                        </div>
                      ) : null}
                      {this.state.notification ? (
                        <div style={{ position: "relative", left: "600px" }}>
                          <SnackbarContent
                            message={"Question Added!"}
                            icon={AddAlert}
                            color="success"
                          />
                        </div>
                      ) : null}
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
