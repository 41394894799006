import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
const classes = makeStyles(styles);
let fd = new FormData();
class AddFaceToFace extends Component {
  state = {
    name: "",
    description: "",
    redirect: false,
    notification: false,
    loading: false,
    categories: [],
    levels: [],
    streams: [],
    subjects: [],
    category: "",
    level: "",
    stream: "",
    subject: "",
    day: "",
    city: "",
    batch_started_date: "",
    batch_ended_date: "",
    fees: "",
    spot_registration_fees: "",
    color: "",
    image: {},
    aAlert: false,
    cAlert: false,
    dAlert: false,
  };

  componentDidMount() { 
    axios.get("https://admin.akclasses.in/api/getfaceCats").then(resp => {
      console.log(resp);
      this.setState({
        categories: resp.data.data
      }, ()=>{console.log(this.state.categories)});
    });
   }

    handleLevel = e => {
      this.setState(
        {
          [e.target.name]: e.target.value,
          aAlert: false
        },
        () => {
          //console.log(this.state.category);
          axios
            .get(
              "https://admin.akclasses.in/api/getCategyLevels?category_id=" + this.state.category + "&type=COURSE"
            )
            .then(resp => {
              console.log(resp);
              this.setState({
                levels: resp.data
              });
            });
        }
      );
    };

    handleStream = e => {
      this.setState(
        {
          [e.target.name]: e.target.value,
          cAlert: false
        },
        () => {
          //console.log(this.state.level);
          axios
            .get("https://admin.akclasses.in/api/getLevelsStream?level_id=" + this.state.level + "&type=COURSE")
            .then(resp => {
              console.log(resp);
              this.setState({
                streams: resp.data
              });
            });
        }
      );
    };

    handleSubject = e => {
      console.log(this.state.stream);
      this.setState(
        {
          [e.target.name]: e.target.value,
          cAlert: false
        },
        () => {
          //console.log(this.state.level);
          axios
            .get("https://admin.akclasses.in/api/getFaceSubjects?stream_id=" + this.state.stream)
            .then(resp => {
              console.log(resp);
              this.setState({
                subjects: resp.data.data
              });
            });
        }
      );
    };

    handleChange = e => {
      this.setState({
        [e.target.name]: e.target.value,
        dAlert: false
      })
    }

    handleFile = e => {
      this.setState({ [e.target.name]: e.target.files[0] }, () => {
      });
    };

    handleSubmit = e => {
      e.preventDefault();
      if (this.state.category === "") {
        this.setState({
          aAlert: true
        });
      } else if (this.state.level === "") {
        this.setState({
          cAlert: true
        });
      } else if (this.state.stream === "") {
        this.setState({
          dAlert: true
        });
      } else if (this.state.subject === "") {
        this.setState({
          eAlert: true
        });
      } else {
        this.setState({
          loading: true
        });
      fd.append("category_id", this.state.category);
      fd.append("level_id", this.state.level);
      fd.append("stream_id", this.state.stream);
      fd.append("subject_id", this.state.subject);
      fd.append("day", this.state.day);
      fd.append("image", this.state.image);
      fd.append("city", this.state.city);
      fd.append("batch_started_date", this.state.batch_started_date);
      fd.append("batch_ended_date", this.state.batch_ended_date);
      fd.append("fees", this.state.fees);
      fd.append("spot_registration_fees", this.state.spot_registration_fees);
      fd.append("color", this.state.color);
      this.setState({
        loading: true
      });
      axios.post(Constants.postUrls.addfacetoface, fd).then(resp => {
        console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/facetoface"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Face To Face Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Face To Face</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel
                            }}
                          >
                            {this.state.categories.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: this.handleSubject
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleChange
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Stream First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Days"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ day: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Batch Started Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              let time = new Date(); // for now
                              let hour = time.getHours();
                              let minute = time.getMinutes();
                              let second = time.getSeconds();

                              this.setState(
                                {
                                  batch_started_date: year + "/" + month + "/" + day,
                                  // orderTime: hour + ":" + minute + ":" + second,
                                },
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Batch Started Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <label>Batch Ended Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              let time = new Date(); // for now
                              let hour = time.getHours();
                              let minute = time.getMinutes();
                              let second = time.getSeconds();

                              this.setState(
                                {
                                  batch_ended_date: year + "/" + month + "/" + day,
                                  // orderTime: hour + ":" + minute + ":" + second,
                                },
                                () => {
                                  // console.log(this.state.orderDate);
                                  // console.log(this.state.orderTime);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Batch Ended Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="City Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ city: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Fees"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ fees: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Spot Registration Fees"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ spot_registration_fees: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/* <CustomInput
                          labelText="Color Code"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ color: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        /> */}

                      <GridItem xs={12} sm={12} md={4}>
                      <label>Select Color</label>
                          <br />
                          <input
                            id="color"
                            name="color"
                            onChange={ (e)=>{
                              this.setState({ color: e.target.value},()=> {console.log(this.state.color)})
                            }}
                            
                            type="color"
                            required={true}
                            style={{ marginTop: "15px", height: "50px", width: "200px"}}
                          />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <label>Image</label>
                        <br />
                        <input
                          id="img"
                          name="image"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                          required={true}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Face To Face"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/facetoface"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddFaceToFace;
