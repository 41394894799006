import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Loader from "components/Loader";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
import Modal from "react-awesome-modal";
const classes = makeStyles(styles);

class IncomeType extends Component {
  state = {
    incomeTypes: [],
    loader: true,
    noData: false,
    status: false,
    visible: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    imgDelete: false,
  };

  componentDidMount() {
    axios.get(Constants.getUrls.getIncomeTypes).then(resp => {
        console.log(resp);
      this.setState({
        loader: false,
        incomeTypes: resp.data.data
      });
    });
  }
  getIncomeType = () => {
    axios.get(Constants.getUrls.getIncomeTypes).then(resp => {
        // console.log(resp);
      this.setState({
        loader: false,
        incomeTypes: resp.data.data
      });
    });
  }

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post(
              Constants.postUrls.changeIncomeTypeStatus + id,
            payload
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5
                },
                () => {
                  this.getIncomeType();
                }
              );
            }, 1000);
          });
      }
    );
  };

  handleModal = (sec) => {
    this.setState({
      rowId: sec.id,
      visible: true
    }, ()=>{console.log(this.state.rowId, this.state.visible)});
  };

  handleDelete = id => {
    this.setState({ delLoading: true });
    axios
      .delete(Constants.postUrls.deleteIncomeType + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          this.getIncomeType();
          this.setState({ visible: false });
        }, 2000);
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
        {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.imgDelete ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Deleted Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Income Type</h3>
                <Link to="/admin/addincometype">
                  <Button className="" color="warning">
                    Add Income Type
                  </Button>
                </Link>
                <Table
                  tableHead={["Sr No.", "Title", "Status", "Action"]}
                  tableData={this.state.incomeTypes.map((sec, index) => [
                    [index + 1],
                    [sec.title],
                    [
                        <Select
                            key={index}
                            MenuProps={{
                            className: classes.selectMenu
                            }}
                            classes={{
                            select: classes.select
                            }}
                            value={sec.status}
                            inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: e => {
                                this.handleSelect(e, sec.id);
                            }
                            }}
                        >
                            <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                            }}
                            value="Active"
                            >
                            Active
                            </MenuItem>
                            <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                            }}
                            value="Inactive"
                            >
                            Inactive
                            </MenuItem>
                        </Select>
                    ],
                    [
                        <Button
                            className="btn-round"
                            color="danger"
                            onClick={() => this.handleModal(sec)}
                          >
                            Delete
                          </Button>
                    //   <Link
                    //     key={index}
                    //     to={{
                    //       pathname: "/admin/updatelevel/" + sec.id,
                    //       state: { levelData: sec }
                    //     }}
                    //   >
                    //     <Button className="btn-round" color="info">
                    //       Update
                    //     </Button>
                    //   </Link>
                    ]
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Income Type Deleted Successfully!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
          
        {this.state.incomeTypes.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default IncomeType;
