/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "react-awesome-modal";
import Loader from "components/Loader.jsx";
import EditIcon from "@material-ui/icons/Edit";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
class Variant extends Component {
  state = {
    products: [],
    variantArray: [],
    mediaTypes: [],
    osTypes: [],
    viewsValidity: [],
    media_id: "",
    os_id: "",
    view_id: "",
    price: "",
    variantId: "",
    productId: 0,
    updateLoading: false,
    loading: false,
    redirect: false,
    visible: false,
    notification: false,
    status: "",
    notifyStatus:false
  };
  finalSubmit = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        notification: true
      });
    }, 500);
    setTimeout(() => {
      this.setState({
        redirect: true
      });
    }, 1000);
  };
  componentDidMount() {
    this.getVarients();
    axios.get("https://admin.akclasses.in/api/indexall_media").then(resp => {
      //console.log(resp.data);
      this.setState({
        mediaTypes: resp.data
      });
    });
    axios.get("https://admin.akclasses.in/api/indexall_os").then(resp => {
      //console.log(resp.data);
      this.setState({
        osTypes: resp.data
      });
    });
    axios.get("https://admin.akclasses.in/api/indexall_view").then(resp => {
      //console.log(resp.data);
      this.setState({
        viewsValidity: resp.data
      });
    });
  }

  getVarients = () => {
    this.setState({ productId: this.props.match.params.id }, () => {
      //console.log(this.state.productId);
    });
    axios
      .get(
        Constants.getUrls.getCoursesVarient + this.props.match.params.id
      )
      .then(resp => {
        // console.log(resp.data);
        this.setState({
          variantArray: resp.data.product.variant
        });
      });
  };

  updateVariant = () => {
    //console.log(id);
    this.setState({ updateLoading: true });
    let payLoad = {
      media_type_id: parseInt(this.state.media_id),
      os_type_id: parseInt(this.state.os_id),
      view_and_validity_id: parseInt(this.state.view_id),
      price: this.state.price
    };
    axios
      .post(
        "https://admin.akclasses.in/api/variant/update/" + this.state.variantId,
        payLoad
      )
      .then(() => {
        // console.log(resp.data);

        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.productId)
          .then(resp => {
            // console.log(resp.data);

            this.setState({
              variantArray: resp.data.product.variant,
              updateLoading: false,
              visible: false
            });
          });
      });
  };

  deleteVariant = id => {
    //console.log(id);

    axios
      .post("https://admin.akclasses.in/api/variant/delete/" + id)
      .then(() => {
        // console.log(resp);

        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.productId)
          .then(resp => {
            // console.log(resp.data);

            this.setState({
              variantArray: resp.data.product.variant
            });
          });
      });
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post(Constants.postUrls.changeVariantStatus + id,
            payload
          )
          .then(res => {
            // console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5
                },
                () => {
                  this.getVarients();
                }
              );
            }, 1000);
          });
      }
    );
  };

  openModal = sec => {
    this.setState({
      media_id: sec.media_type.id,
      os_id: sec.os_type.id,
      view_id: sec.view_and_validity.id,
      price: sec.price,
      variantId: sec.id,
      visible: true
    });
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/coursesmaster"
          }}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            {
              this.state.notifyStatus ? (
                <div style={{ position: "relative", left: "600px" }}>
                  <SnackbarContent
                    message={"Status Changed!"}
                    icon={AddAlert}
                    color="success"
                  />
                </div>
              ) : null
            }
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Success!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <Assignment />
                  </CardIcon>
                  <h4>Simple Table</h4>
                </CardHeader>
                <CardBody>
                  <h3>Course Variants</h3>
                  <Table
                    tableHead={[
                      "Sr No.",
                      "Media Type",
                      "OS Type",
                      "Views & Validity",
                      "Price",
                      "Status",
                      "Update",
                      "Delete"
                    ]}
                    tableData={this.state.variantArray.map((sec, index) => [
                      [index + 1],
                      [sec.media_type.media_type],
                      [sec.os_type.os_type],
                      [sec.view_and_validity.view_and_validity],
                      [sec.price],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={sec.status}
                          inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: e => {
                              this.handleSelect(e, sec.id)
                            }
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Active"
                          >
                            ACTIVE
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Inactive"
                          >
                            INACTIVE
                          </MenuItem>
                        </Select>
                      ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.openModal(sec);
                          }}
                          className="btn-round"
                          color="info"
                        >
                          Update
                        </Button>
                      ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.deleteVariant(sec.id);
                          }}
                          color="danger"
                        >
                          Delete
                        </Button>
                      ]
                    ])}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <Link
              to={{
                pathname: "/admin/addcoursevariant/" + this.state.productId
              }}
            >
              <Button color="info" style={{ marginLeft: "15px" }}>
                Add Variant
              </Button>
            </Link>
            <Button onClick={this.finalSubmit} color="warning">
              {this.state.loading ? <Loader /> : "Submit"}
            </Button>
            <Link
              to={{
                pathname: "/admin/coursesmaster"
              }}
            >
              <Button color="danger">Cancel</Button>
            </Link>
          </GridContainer>
          <Modal
            visible={this.state.visible}
            width="1000"
            height="330"
            effect="fadeInUp"
            onClickAway={() => {
              this.setState({ visible: false });
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                    <h4>Stacked Form</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <label>Media Type</label>
                          <select
                            style={{ padding: "11px" }}
                            name="media_id"
                            value={this.state.media_id}
                            onChange={this.handleInput}
                          >
                            <option value="">Select</option>
                            {this.state.mediaTypes.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.media_type}
                              </option>
                            ))}
                          </select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <label>OS Type</label>
                          <select
                            style={{ padding: "11px" }}
                            name="os_id"
                            value={this.state.os_id}
                            onChange={this.handleInput}
                          >
                            <option value="">Select</option>
                            {this.state.osTypes.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.os_type}
                              </option>
                            ))}
                          </select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <label>View & Validity</label>
                          <select
                            style={{ padding: "11px" }}
                            name="view_id"
                            value={this.state.view_id}
                            onChange={this.handleInput}
                          >
                            <option value="">Select</option>
                            {this.state.viewsValidity.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.view_and_validity}
                              </option>
                            ))}
                          </select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Price"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.price,
                            required: true,
                            onChange: e =>
                              this.setState({ price: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button
                          onClick={this.updateVariant}
                          className=""
                          color="warning"
                          type="submit"
                        >
                          {this.state.updateLoading ? "Updating..." : "Update"}
                        </Button>
                        <Button
                          onClick={() => {
                            this.setState({ visible: false });
                          }}
                          className="btn-round"
                          color="danger"
                        >
                          Cancel
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </div>
      );
    }
  }
}

export default Variant;
