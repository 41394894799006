/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Datetime from "react-datetime";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class UpdateIncomeEntry extends Component {
  state = {
    amount: "",
    description: "",
    date: "",
    category: "",
    income_type: "",
    mode: "",
    courses: [],
    modes: [],
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios
      .get("https://admin.akclasses.in/api/expenseCategoriesR")
      .then(resp => {
        //console.log(resp.data);
        this.setState({
          courses: resp.data
        });
      });
    axios.get("https://admin.akclasses.in/api/expenseModesR").then(resp => {
      //console.log(resp.data);
      this.setState({
        modes: resp.data
      });
    });
    let categoryData = this.props.location.state.categoryData;
    // console.log(categoryData);

    this.setState({
      income_type: categoryData.INCOME_TYPE,
      amount: categoryData.AMOUNT,
      description: categoryData.DESCRIPTION,
      date: categoryData.INCOME_DATE,
      category: categoryData.REF_CATEGORY_ID,
      mode: categoryData.MODE_ID ? categoryData.MODE_ID : ""
    });
  }

  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      income_type: this.state.income_type,
      ref_category_id: this.state.category,
      description: this.state.description,
      amount: this.state.amount,
      income_date: this.state.date,
      mode: this.state.mode
    };
    let categoryData = this.props.location.state.categoryData;
    let categoryId = categoryData.ID;
    axios
      .post(
        "https://admin.akclasses.in/api/income/update/" + categoryId,
        payLoad
      )
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/incomeentry"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Income Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Income</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Income Type"
                          id="float"
                          inputProps={{
                            value: this.state.income_type,
                            required: true,
                            onChange: e =>
                              this.setState({ income_type: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Mode
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  category: e.target.value
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.mode}
                            inputProps={{
                              name: "mode",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  mode: e.target.value
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.modes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.MODE}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Amount"
                          id="float"
                          inputProps={{
                            value: this.state.amount,
                            type: "number",
                            required: true,
                            onChange: e =>
                              this.setState({ amount: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  date: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.date);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Income Date",
                              value: this.state.date
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Description"
                          id="float"
                          inputProps={{
                            value: this.state.description,
                            onChange: e =>
                              this.setState({ description: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Income"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/incomeentry"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateIncomeEntry;
