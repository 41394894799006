/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
let fd = new FormData();
class UpdateFranchiseeCenter extends Component {
  state = {
    title: "",
    description: "",
    subject: "",
    faculty: "",
    redirect: false,
    max: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    //console.log(categoryData);

    this.setState({
      title: categoryData.CITY,
      description: categoryData.ADDRESS,
      subject: categoryData.CONTACT,
      faculty: categoryData.CENTER_NAME
    });
  }
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.max === true) {
      this.setState({ max: true });
    } else {
      this.setState({
        loading: true
      });
      fd.append("city", this.state.title);
      fd.append("address", this.state.description);
      fd.append("contact", this.state.subject);
      fd.append("center_name", this.state.faculty);
      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.ID;
      axios
        .post(
          "https://admin.akclasses.in/api/franchiseeCenter/update/" +
            categoryId,
          fd
        )
        .then(() => {
          //console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        });
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/franchiseecenters"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Franchisee Center Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Franchisee Center</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            value: this.state.title,
                            required: true,
                            onChange: e =>
                              this.setState({ title: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Center Name"
                          id="float"
                          inputProps={{
                            value: this.state.faculty,
                            required: true,
                            onChange: e =>
                              this.setState({ faculty: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Contact No."
                          id="float"
                          inputProps={{
                            value: this.state.subject,
                            type: "number",
                            required: true,
                            onChange: e =>
                              this.setState({ subject: e.target.value }, () => {
                                if (this.state.subject.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <label htmlFor="">Address</label>
                        <br />
                        <textarea
                          name="description"
                          onChange={this.handleInput}
                          className="form-control"
                          cols="56"
                          rows="6"
                          required={true}
                          value={this.state.description}
                        ></textarea>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Update Franchisee Center"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/franchiseecenters"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateFranchiseeCenter;
