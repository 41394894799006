import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
class ChangePassword extends Component {
  state = {
    oldPass: "",
    newPass: "",
    reenterNewPass: "",
    mismatch: false,
    redirect: false,
    notification: false,
    oldFail: false,
    misNoti: false,
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.newPass !== this.state.reenterNewPass) {
      this.setState({ misNoti: true });
    } else {
      this.setState({
        loading: true
      });
      let payLoad = {
        old_pass: this.state.oldPass,
        new_pass: this.state.newPass
      };
      axios
        .post("https://admin.akclasses.in/api/admin_pass_change", payLoad)
        .then(resp => {
          // console.log(resp.data);
          if (resp.data.status === "fail") {
            this.setState({ oldFail: true, loading: false });
            setTimeout(() => {
              this.setState({ oldFail: false });
            }, 2000);
          } else {
            this.setState({
              notification: true,
              loading: false
            });
            setTimeout(() => {
              this.setState({ notification: false });
            }, 2000);
          }
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Password Changed Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            {this.state.oldFail ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Old Password Invalid!!!"}
                  icon={AddAlert}
                  color="danger"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4>Stacked Form</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Old Password"
                          inputProps={{
                            value: this.state.oldPass,
                            type: "password",
                            onChange: e =>
                              this.setState({ oldPass: e.target.value }, () => {
                                //console.log(this.state.oldPass);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="New Password"
                          inputProps={{
                            value: this.state.newPass,
                            type: "password",
                            onChange: e =>
                              this.setState(
                                { newPass: e.target.value, misNoti: false },
                                () => {
                                  //console.log(this.state.newPass);
                                }
                              )
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Reenter New Password"
                          inputProps={{
                            value: this.state.reenterNewPass,
                            type: "password",
                            onChange: e =>
                              this.setState(
                                {
                                  reenterNewPass: e.target.value,
                                  misNoti: false
                                },
                                () => {
                                  //console.log(this.state.reenterNewPass);
                                }
                              )
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.misNoti ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Password Mismatch!!!
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Change"}
                      </Button>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default ChangePassword;
