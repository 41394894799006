import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import MathJax from "react-mathjax-preview";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
class Questions extends Component {
  state = {
    visible: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    ord: true,
    showSearch: false,
    secondaryCategories: [],
    tests: [],
    subjects: [],
    test: "",
    subject: "",
    status: "",
    active: 1,
    act: 0,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    delNot: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: []
  };

  componentDidMount() {
    this.getQuestions();
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=Test_Series")
      .then(resp => {
        this.setState({
          tests: resp.data
        });
      });
  }
  getQuestions = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/questions?per_page=" +
          this.state.per_page
      )
      .then(resp => {
        // console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
  };
  handleDelete = id => {
    this.setState({ delLoading: true });
    axios
      .post("https://admin.akclasses.in/api/question/delete/" + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false
        });
        setTimeout(() => {
          this.getQuestions();
          // window.location.reload();
        }, 2000);
      });
  };
  handleModal = sec => {
    this.setState({
      rowId: sec.id,
      visible: true
    });
  };
  // handleDelete = id => {
  //   this.setState({ act: id });
  //   axios
  //     .post("https://admin.akclasses.in/api/question/delete/" + id)
  //     .then(() => {
  //       // console.log(res.data);
  //       this.setState({
  //         delNot: true,
  //         act: 0
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     });
  // };

  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/questions?per_page=" +
              this.state.per_page
          )
          .then(resp => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                ord: true,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray
                });
              }
            );
          });
      }
    );
  };

  handleDownload = (id, file) => {
    axios
      .get(
        "https://admin.akclasses.in/api/download_question_pdf/" +
          id +
          "?file=" +
          file
      )
      .then(() => {
        //console.log(resp);
      });
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post("https://admin.akclasses.in/api/question/status/" + id, payload)
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5
                },
                () => {
                  this.getQuestions();
                }
              );
            }, 1000);
          });
      }
    );
  };

  currentPage = v => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/questions?per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/questions_by_subject?subject_id=" +
        this.state.subject +
        "&per_page=" +
        this.state.per_page +
        "&page=";
    axios.get(commonUrl + v).then(resp => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleDrop = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/search_subject_by_test_series?test_series_id=" +
              this.state.test
          )
          .then(resp => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.subject
            });
          });
      }
    );
  };

  searchUser = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/questions_by_subject?subject_id=" +
          this.state.subject +
          "&per_page=" +
          this.state.per_page
      )
      .then(resp => {
        // console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            ord: false,
            btnIdArray,
            fromInc: 1,
            active: 1,
            toInc: 5,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.delNot ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Question Deleted!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <GridItem
                  style={{
                    position: "relative"
                    // left: "68%",
                    // bottom: "40px"
                  }}
                  xs={12}
                  sm={12}
                  md={3}
                >
                  <h6 stye={{ position: "absolute", top: "100px" }}>Filter</h6>
                  <FormControl
                    style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Test Series
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.test}
                      inputProps={{
                        name: "test",
                        id: "simple-select",
                        onChange: e => {
                          this.handleDrop(e);
                        }
                      }}
                    >
                      {this.state.tests.map((v, i) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          key={i}
                          value={v.course.ID}
                        >
                          {v ? v.course ? v.course.TITLE : "" : ""}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <div>
                  <GridItem
                    style={{
                      position: "relative"
                      // left: "68%",
                      // bottom: "40px"
                    }}
                    xs={12}
                    sm={12}
                    md={3}
                  >
                    <FormControl
                      style={{ marginTop: "12px" }}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Subject
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.subject}
                        inputProps={{
                          name: "subject",
                          id: "simple-select",
                          onChange: e => {
                            this.setState({
                              subject: e.target.value,
                              showSearch: true
                            });
                            // //console.log(e.target.value);
                          }
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value=""
                        >
                          Select Test Series First
                        </MenuItem>
                        {this.state.subjects.map((v, i) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            key={i}
                            value={v.ID}
                          >
                            {v ? v.course ? v.course.TITLE : "" : ""}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {this.state.showSearch ? (
                      <Button
                        onClick={this.searchUser}
                        style={{
                          position: "relative",
                          left: "45%"
                        }}
                        color="success"
                      >
                        Search
                      </Button>
                    ) : null}
                  </GridItem>
                </div>
                <h3>Questions</h3>
                <Link to="/admin/addingQuestion">
                  <Button className="" color="warning">
                    Add Question
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Category",
                    "Test Series",
                    "Subject",
                    "Question/Uploaded Paper",
                    "Status",
                    "Update",
                    "Delete"
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [
                        sec.course_category
                          ? sec.course_category.CATEGORY
                          : "NA"
                      ],
                      [sec.course ? sec.course.TITLE : "NA"],
                      [sec.subject ? sec.subject.TITLE : "NA"],
                      [
                        sec.QUESTION ? (
                          <MathJax math={sec.QUESTION} />
                        ) : (
                          <a
                            href={
                              "https://admin.akclasses.in/api/download_question_pdf/" +
                              sec.ID +
                              "?file=" +
                              sec.UPLOADED_PAPER
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              color="danger"
                              onClick={() => {
                                this.handleDownload(sec.ID, sec.UPLOADED_PAPER);
                              }}
                            >
                              View Paper
                            </Button>
                          </a>
                        )
                      ],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={sec.STATUS}
                          inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: e => {
                              this.handleSelect(e, sec.ID);
                            }
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="ACTIVE"
                          >
                            ACTIVE
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="INACTIVE"
                          >
                            INACTIVE
                          </MenuItem>
                        </Select>
                      ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/updatequestion/" + sec.ID,
                            state: { categoryData: sec }
                          }}
                        >
                          <Button className="btn-round" color="info">
                            Update
                          </Button>
                        </Link>
                      ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.handleModal(sec);
                          }}
                          className="btn-round"
                          color="danger"
                        >
                          {this.state.act === sec.ID ? <Loader /> : "Delete"}
                        </Button>
                      ]
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Question Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black"
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Questions;
