/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { MultiSelect } from "react-multi-select-component";
import Constants from "variables/Constants";
class AddCourseStrategy extends Component {
  state = {
    getAllStrategies: [],
    strategies: [],
    allStrategies: [],
    products: [],
    productIds: [],
    redirect: false,
    notification: false,
    loading: false,
    id: "",
    strategy: [],
  };
  componentDidMount() {
    console.log("hii");
    let courseId = window.location.pathname.split("/")[3];
    console.log(courseId);
    this.setState(
      {
        id: courseId,
      },
      () => {
        console.log(this.state.id);
      }
    );
    // axios.post(axios.get(Constants.getUrls.strategy)
    //   .then((resp)=>{
    //     console.log(resp)
    // resp.data.strategies.map((v)=>{
    //   this.state.strategies.push({
    //     label: v.course.TITLE + "( " + v.duration + " Days)",
    //     value: v.id
    //   })

    // })
    // this.setState({
    //   strategies: this.state.strategies
    // }, ()=>{console.log(this.state.strategies)})
    // })
    let payload = {
      course_id: courseId,
    };
    console.log(payload);
    axios.post(Constants.getUrls.getCourseStrategies, payload).then((resp) => {
      console.log(resp);
      resp.data.strategies_course.map((v) => {
        this.state.strategies.push({
          label: v.course.TITLE + "(" + v.duration + "Days)",
          value: v.id,
        });
        this.setState(
          {
            strategies: this.state.strategies,
          },
          () => {
            console.log(this.state.strategies);
          }
        );
      });
      this.setState({
        getAllStrategies: this.state.getAllStrategies,
        // allStrategies: this.state.strategies
      });
    });
    axios.get(Constants.getUrls.strategy).then((res) => {
      console.log(res);
      res.data.map((v) => {
        this.state.getAllStrategies.push({
          label: v.course.TITLE + "(" + v.duration + " Days)",
          value: v.id,
        });
      });
    });
  }

  // comparer = otherArray => {
  //   return function(current) {
  //     return (
  //       otherArray.filter(function(other) {
  //         return other.id == current.id;
  //       }).length == 0
  //     );
  //   };
  // };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    this.state.strategies.forEach((v) => {
      this.state.productIds.push(v.value);
    });
    let payLoad = {
      course_id: this.state.id,
      strategies: this.state.productIds,
    };
    console.log(payLoad);
    axios
      .post("https://admin.akclasses.in/api/course_With_strategy", payLoad)
      .then((resp) => {
        // console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/coursesmaster"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Strategies Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Course Strategies</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: "11px" }}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <div
                          style={{
                            position: "relative",
                            bottom: "11px",
                            border: "none",
                          }}
                        >
                          <label htmlFor="Courses">Strategies</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.getAllStrategies}
                            value={this.state.strategies}
                            onChange={(data) => {
                              this.setState({ strategies: data }, () =>
                                console.log(this.state.strategies)
                              );
                            }}
                            labelledBy={"Strategies"}
                          />
                        </div>
                      </GridItem>
                      {this.state.allStrategies.length >= 1 ? (
                        <GridItem xs={12} sm={12} md={12}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px black solid",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tr
                              style={{
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Sr.No
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Strategy
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Days
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                            {this.state.allStrategies.map((v, i) => (
                              <tr
                                style={{
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                                key={i}
                              >
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {i + 1}
                                </td>
                                <td
                                  style={{
                                    padding: "4px 10px 4px 10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {v.label}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {v.duration == null ? "" : v.duration}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <input
                                    style={{
                                      backgroundColor: "red",
                                      borderRadius: "10%",
                                      border: "none",
                                      padding: "10px",
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      this.state.allStrategies.splice(i, 1);
                                      this.setState({
                                        allStrategies: this.state.allStrategies,
                                      });
                                      axios
                                        .post(
                                          "https://admin.akclasses.in/api/deleteAssignedStrategy",
                                          {
                                            course_id: this.state.course_id,
                                            strategy_id: v.id,
                                          }
                                        )
                                        .then((resp) => {
                                          console.log(resp);
                                        });
                                    }}
                                    type="button"
                                    value="Delete"
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Add Course Strategies"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/coursesmaster",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddCourseStrategy;
