import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import AddAlert from "@material-ui/icons/AddAlert";
import Modal from "react-awesome-modal";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
const classes = makeStyles(styles);
class FacultyCommission extends Component {
  state = {
    notifyStatus: false,
    direct: true,
    secondaryCategories: [],
    download: false,
    payButton: false,
    status: "",
    tableArray: [],
    faculties: [],
    faculty: "",
    notify: false,
    facultyDisplay: true,
    pay: false,
    active: 0,
    frmDate: "",
    toDate: "",
    mailSent: false,
    penEmail: false,
    set: false,
    sendMail: false,
    payLoading: false,
    showSent: false,
    showStatus: false,
    sentPayment: false,
    paymentMailSent: false,
    mailLoading: false,
    loading: "",
    akCount: 0,
    venderCount: 0,
    totalCount: 0,
    grandTotal: 0,
    subjectShare: 0,
    academyShare: 0,
    centerShare: 0,
    netAmt: 0,
    facultyPayable: 0,
    faculty_email_status: 0,
    gstAmt:0,
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/facultyR").then(resp => {
      // console.log(resp.data);
      this.setState({ faculties: resp.data });
    });
    axios
      .get(
        "https://admin.akclasses.in/api/all_faculty_commission?faculty_email_status=0"
      )
      .then(resp => {
        console.log(resp);
        this.setState({
          secondaryCategories: resp.data.data,
          grandTotal: resp.data.grandTotal,
          subjectShare: resp.data.subjectShare,
          academyShare: resp.data.academyShare,
          centerShare: resp.data.centerShare,
          netAmt: resp.data.netAmt,
          gstAmt: resp.data.gstAmt,
          facultyPayable: resp.data.facultyPayable,
          faculty_email_status: resp.data.faculty_email_status
        });
      });
  }

  searchUser = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/faculty_commission/" +
          this.state.faculty +
          "?faculty_email_status=" +
          this.state.active
      )
      .then(resp => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            direct: false,
            facultyDisplay: false,
            secondaryCategories: resp.data.data,
            grandTotal: resp.data.grandTotal,
            subjectShare: resp.data.subjectShare,
            academyShare: resp.data.academyShare,
            centerShare: resp.data.centerShare,
            netAmt: resp.data.netAmt,
            gstAmt: resp.data.gstAmt,
            facultyPayable: resp.data.facultyPayable,
            faculty_email_status: resp.data.faculty_email_status,
            payButton: false,
            showSent: this.state.active === 0 ? true : false,
            sentPayment: this.state.active === 1 ? true : false,
            showStatus: true
          });
        }
      });
  };

  showSent = () => {
    if (this.state.direct) {
      axios
        .get(
          "https://admin.akclasses.in/api/all_faculty_commission?faculty_email_status=1"
        )
        .then(resp => {
          // console.log(resp.data);
          this.setState({
            active: 1,
            secondaryCategories: resp.data.data,
            payButton: false,
            grandTotal: resp.data.grandTotal,
            subjectShare: resp.data.subjectShare,
            academyShare: resp.data.academyShare,
            centerShare: resp.data.centerShare,
            netAmt: resp.data.netAmt,
            gstAmt: resp.data.gstAmt,
            facultyPayable: resp.data.facultyPayable,
            faculty_email_status: resp.data.faculty_email_status,
          });
        });
    } else {
      axios
        .get(
          "https://admin.akclasses.in/api/faculty_commission/" +
            this.state.faculty +
            "?faculty_email_status=1"
        )
        .then(resp => {
          // console.log(resp.data);
          if (resp.data.status === "success") {
            this.setState({
              active: 1,
              penEmail: false,
              set: true,
              secondaryCategories: resp.data.data,
              grandTotal: resp.data.grandTotal,
              subjectShare: resp.data.subjectShare,
              academyShare: resp.data.academyShare,
              centerShare: resp.data.centerShare,
              netAmt: resp.data.netAmt,
              gstAmt: resp.data.gstAmt,
              facultyPayable: resp.data.facultyPayable,
              faculty_email_status: resp.data.faculty_email_status,
              payButton: false,
              sentPayment: true,
              sendMail: false,
              showSent: false
            });
          }
        });
    }
  };

  showUnSent = () => {
    if (this.state.direct) {
      axios
        .get(
          "https://admin.akclasses.in/api/all_faculty_commission?faculty_email_status=0"
        )
        .then(resp => {
          // console.log(resp.data);
          this.setState({
            active: 0,
            secondaryCategories: resp.data.data,
            payButton: false,
            grandTotal: resp.data.grandTotal,
            subjectShare: resp.data.subjectShare,
            academyShare: resp.data.academyShare,
            centerShare: resp.data.centerShare,
            netAmt: resp.data.netAmt,
            gstAmt: resp.data.gstAmt,
            facultyPayable: resp.data.facultyPayable,
            faculty_email_status: resp.data.faculty_email_status,
          });
        });
    } else {
      this.setState({ sendMail: false });
      axios
        .get(
          "https://admin.akclasses.in/api/faculty_commission/" +
            this.state.faculty +
            "?faculty_email_status=0"
        )
        .then(resp => {
          // console.log(resp.data);
          if (resp.data.status === "success") {
            this.setState({
              active: 0,
              penEmail: true,
              set: false,
              secondaryCategories: resp.data.data,
              grandTotal: resp.data.grandTotal,
              subjectShare: resp.data.subjectShare,
              academyShare: resp.data.academyShare,
              centerShare: resp.data.centerShare,
              netAmt: resp.data.netAmt,
              gstAmt: resp.data.gstAmt,
              facultyPayable: resp.data.facultyPayable,
              faculty_email_status: resp.data.faculty_email_status,
              payButton: false,
              sentPayment: false,
              showSent: true
            });
          }
        });
    }
  };

  showPayment = () => {
    if (this.state.direct) {
      axios
        .get(
          "https://admin.akclasses.in/api/all_faculty_commission?faculty_email_status=2"
        )
        .then(resp => {
          // console.log(resp.data);
          this.setState({
            active: 2,
            secondaryCategories: resp.data.data,
            payButton: false,
            grandTotal: resp.data.grandTotal,
            subjectShare: resp.data.subjectShare,
            academyShare: resp.data.academyShare,
            centerShare: resp.data.centerShare,
            netAmt: resp.data.netAmt,
            gstAmt: resp.data.gstAmt,
            facultyPayable: resp.data.facultyPayable,
            faculty_email_status: resp.data.faculty_email_status,
          });
        });
    } else {
      axios
        .get(
          "https://admin.akclasses.in/api/faculty_commission/" +
            this.state.faculty +
            "?faculty_email_status=2"
        )
        .then(resp => {
          // console.log(resp.data);
          if (resp.data.status === "success") {
            this.setState({
              active: 2,
              secondaryCategories: resp.data.data,
              grandTotal: resp.data.grandTotal,
              subjectShare: resp.data.subjectShare,
              academyShare: resp.data.academyShare,
              centerShare: resp.data.centerShare,
              netAmt: resp.data.netAmt,
              gstAmt: resp.data.gstAmt,
              facultyPayable: resp.data.facultyPayable,
              faculty_email_status: resp.data.faculty_email_status,
              payButton: false,
              showSent: false,
              penEmail: false,
              set: false,
              sendMail: false,
              showStatus: true,
              sentPayment: false
            });
          }
        });
    }
  };

  sendEmail = () => {
    this.setState({ loading: true });
    axios
      .get(
        "https://admin.akclasses.in/api/faculty_commission_email?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.faculty
      )
      .then(() => {
        // console.log(resp.data);
        this.setState({
          loading: false,
          mailSent: true
        });
        setTimeout(() => {
          this.setState({
            mailSent: false
          });
          window.location.reload();
        }, 4000);
      });
  };

  searchEmails = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/faculty_commission_datevise_index?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.faculty +
          "&faculty_email_status=" +
          this.state.active
      )
      .then(resp => {
        console.log(resp);
        this.setState({
          secondaryCategories: resp.data.data,
          penEmail: true,
          payButton: false,
          set: false,
          sendMail: true,
          grandTotal: resp.data.grandTotal,
          subjectShare: resp.data.subjectShare,
          academyShare: resp.data.academyShare,
          centerShare: resp.data.centerShare,
          netAmt: resp.data.netAmt,
          gstAmt: resp.data.gstAmt,
          facultyPayable: resp.data.facultyPayable,
          faculty_email_status: resp.data.faculty_email_status,
        });
      });
  };

  handlePayment = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/faculty_commission_datevise_index?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.faculty +
          "&faculty_email_status=" +
          this.state.active
      )
      .then(resp => {
        console.log(resp);
        this.setState({
          secondaryCategories: resp.data.data,
          sendMail: true,
          payButton: true,
          grandTotal: resp.data.grandTotal,
          subjectShare: resp.data.subjectShare,
          academyShare: resp.data.academyShare,
          centerShare: resp.data.centerShare,
          netAmt: resp.data.netAmt,
          gstAmt: resp.data.gstAmt,
          facultyPayable: resp.data.facultyPayable,
          faculty_email_status: resp.data.faculty_email_status,
        });
      });
  };

  sendPaymentMail = () => {
    this.setState({ mailLoading: true });
    axios
      .get(
        "https://admin.akclasses.in/api/faculty_commission_payment_email?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.faculty
      )
      .then(() => {
        // console.log(resp.data);
        this.setState({
          mailLoading: false,
          paymentMailSent: true
        });
        setTimeout(() => {
          this.setState({
            paymentMailSent: false
          });
          window.location.reload();
        }, 4000);
      });
  };

  handleDownload = () => {
    this.setState({ download: false });
    axios
      .get(
        "https://admin.akclasses.in/api/faculty_commission?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then(() => {
        //console.log(resp);
      });
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.mailSent ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Invoices Sent Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody
                style={{
                  position: "relative"
                }}
              >
                {/* {this.state.showStatus ? ( */}
                <div
                  style={{
                    position: "relative",
                    left: "5%",
                    marginTop: "50px"
                  }}
                >
                  <Button
                    onClick={this.showUnSent}
                    style={{
                      position: "relative",
                      bottom: "62px",
                      right: "50px"
                    }}
                    color={this.state.active === 0 ? "secondary" : "primary"}
                  >
                    Pending
                  </Button>
                  <Button
                    onClick={this.showSent}
                    style={{
                      position: "relative",
                      bottom: "62px",
                      right: "50px"
                    }}
                    color={this.state.active === 1 ? "secondary" : "success"}
                  >
                    Sent
                  </Button>
                  <Button
                    onClick={this.showPayment}
                    style={{
                      position: "relative",
                      bottom: "62px",
                      right: "50px"
                    }}
                    color={this.state.active === 2 ? "secondary" : "warning"}
                  >
                    Payment Sent
                  </Button>
                  {this.state.sendMail ? (
                    <a
                      href={
                        this.state.penEmail
                          ? "https://admin.akclasses.in/api/download_pending_commission_pdf?frmdate=" +
                            this.state.frmDate +
                            "&todate=" +
                            this.state.toDate +
                            "&id=" +
                            this.state.faculty
                          : this.state.set
                          ? "https://admin.akclasses.in/api/download_settelement_commission_pdf?frmdate=" +
                            this.state.frmDate +
                            "&todate=" +
                            this.state.toDate +
                            "&id=" +
                            this.state.faculty
                          : "https://admin.akclasses.in/api/donload_faculty_commission_pdf?frmdate=" +
                            this.state.frmDate +
                            "&todate=" +
                            this.state.toDate +
                            "&id=" +
                            this.state.faculty
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        style={{
                          position: "absolute",
                          right: "65px",
                          bottom: "60px"
                        }}
                        color="danger"
                      >
                        Download
                      </Button>
                    </a>
                  ) : null}
                </div>
                {/* ) : null} */}
                <div style={{ position: "relative", top: "50px" }}>
                  <h3 style={{ position: "relative", top: "70px" }}>
                    Due Reports
                  </h3>
                  <div
                    style={{
                      position: "relative",
                      bottom: "63px",
                      left: "63%"
                    }}
                  >
                    <GridItem
                      style={{
                        position: "relative",
                        bottom: "17px",
                        right: "10px"
                      }}
                      xs={12}
                      sm={12}
                      md={3}
                    >
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Search By Faculty
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.faculty}
                          inputProps={{
                            name: "faculty",
                            id: "simple-select",
                            onChange: e => {
                              this.setState({
                                faculty: e.target.value
                              });
                            }
                          }}
                        >
                          {this.state.faculties.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <Button
                      onClick={this.searchUser}
                      style={{
                        position: "relative",
                        bottom: "62px",
                        left: "200px"
                      }}
                      color="success"
                    >
                      Search
                    </Button>
                  </div>
                  {this.state.showSent ? (
                    <GridContainer
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        left: "188px"
                      }}
                    >
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <label
                          style={{
                            color: "black"
                          }}
                        >
                          Search In Pending Mails
                        </label>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  frmDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.frmDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "From Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  toDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.toDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "To Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "20px"
                          }}
                          onClick={this.searchEmails}
                          color="success"
                        >
                          Search
                        </Button>
                        {this.state.sendMail ? (
                          <Button
                            style={{
                              position: "relative",
                              top: "13px",
                              right: "20px"
                            }}
                            onClick={() => {
                              this.setState({ visible: true, pay: false });
                            }}
                            color="primary"
                          >
                            Send Mails
                          </Button>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  {this.state.sentPayment ? (
                    <GridContainer
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        left: "320px"
                      }}
                    >
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <label
                          style={{
                            color: "black"
                          }}
                        >
                          Search In Sent Mails
                        </label>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  frmDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.frmDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "From Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  toDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.toDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "To Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "20px"
                          }}
                          onClick={this.handlePayment}
                          color="success"
                        >
                          Search
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                </div>
                {/* <Table
                  tableHead={[
                    "Sr No.",
                    "Faculty/Student Name",
                    "Order ID",
                    "Product Details",
                    "AK Classes Share",
                    "Faculty Share",
                    "Order Amount",
                    "Mail"
                  ]}
                  tableData={this.state.tableArray}
                /> */}
                <table
                  style={{
                    width: "100%",
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                >
                  <tr
                    style={{
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Sr No.
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                        width: "100px"
                      }}
                    >
                      Order Date
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                        width: "100px"
                      }}
                    >
                      Order Number
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Subject
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Media Type
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                        width: "100px"
                      }}
                    >
                      Order Type
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Order Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Subject Share
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Academy Share(Vsmart share+Pool)
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Center Share
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      NET
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      GST
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty Payable
                    </th>
                    {/* <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Payment Status
                    </th> */}
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Mail
                    </th>
                  </tr>
                  {this.state.secondaryCategories.map((sec, index) => (
                    <tr
                      style={{
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                      key={index}
                    >
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.order_date}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.order_number}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.student_name}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.subject}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.media_type}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.order_type}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(sec.order_amount))}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(sec.subject_share))}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(sec.academy_share))}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(sec.center_share))}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(sec.netAmt))}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(sec.gstAmt))}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(sec.faculty_payable))}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.faculty_email_status === 1 ? (
                          <Button key={index} color="success">
                            Sent
                          </Button>
                        ) : sec.faculty_email_status === 0 ? (
                          <Button key={index} color="primary">
                            Pending
                          </Button>
                        ) : sec.faculty_email_status === 2 ? (
                          <Button key={index} color="warning">
                            Payment Sent
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                  {this.state.secondaryCategories.length > 0 ? (
                    <tr>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >Totals</td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                       
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(this.state.grandTotal))}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(this.state.subjectShare))}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(this.state.academyShare))}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(this.state.centerShare))}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(this.state.netAmt))}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(this.state.gstAmt))}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(parseInt(this.state.facultyPayable))}
                      </th>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {this.state.payButton ? (
                          <Button
                            onClick={() => {
                              this.setState({ pay: true, visible: true });
                            }}
                            color="warning"
                          >
                            Payment Sent
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  ) : null}
                </table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visible}
          width="1000"
          height="330"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  {this.state.pay ? (
                    <div>
                      <h3>Are You Sure You Have Sent the Payment!</h3>
                      {this.state.paymentMailSent ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Payment Invoices Sent Successfully!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <a
                            href={
                              "https://admin.akclasses.in/api/download_settelement_commission_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.faculty
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendPaymentMail}
                            >
                              {this.state.mailLoading ? <Loader /> : "Yes"}
                            </Button>
                          </a>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <div>
                      <h3>Are You Sure You Want to Send the Mails!</h3>
                      {this.state.mailSent ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Mails Sent Successfully!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <a
                            href={
                              "https://admin.akclasses.in/api/download_pending_commission_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.faculty
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendEmail}
                            >
                              {this.state.loading ? <Loader /> : "Yes"}
                            </Button>
                          </a>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default FacultyCommission;
