import React, { Component } from "react";
import axios from "axios";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
// import Loader from "components/Loader";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MathJax from "react-mathjax-preview";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import Datetime from "react-datetime";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);

const perPages = ["10", "25", "50", "100"];
class UploadAnAnswer extends Component {
  state = {
    questions: [],
    description: "",
    subjectId: "",
    levelId: "",
    chapterId: "",
    levels: [],
    filteredSubjects: [],
    filteredChapters: [],
    subjects: [],
    filteredQuestions: [],
    per_page: "10",
    sortedQuestions: [],
    empty: false,
    activeOpen: false,
    activeClose: false,
    loader: true,
    noData: false,
    active: 1,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: []
  };
  componentDidMount() {
    axios
      .get(
        Constants.getUrls.questions +
          "status=open,closed&perPage=" +
          this.state.per_page
      )
      .then(resp => {
        // console.log(resp.data.answers);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.answers.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            questions: resp.data.answers.data,
            filteredQuestions: resp.data.answers.data,
            sortedQuestions: resp.data.answers.data,
            btnIdArray,
            total: resp.data.answers.total,
            from: resp.data.answers.from,
            to: resp.data.answers.to
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
    axios.get(Constants.getUrls.levels).then(resp => {
      this.setState({
        levels: resp.data.levels
      });
      // console.log(this.state.levels);
    });
  }
  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5
      },
      () => {
        axios
          .get(
            Constants.getUrls.questions +
              "status=open,closed&perPage=" +
              this.state.per_page
          )
          .then(resp => {
            // console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.answers.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                empty: false,
                questions: resp.data.answers.data,
                filteredQuestions: resp.data.answers.data,
                sortedQuestions: resp.data.answers.data,
                btnIdArray,
                total: resp.data.answers.total,
                from: resp.data.answers.from,
                to: resp.data.answers.to
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray
                });
              }
            );
          });
      }
    );
  };

  currentPage = v => {
    let commonUrl =
      "https://core.akclasses.in/api/qanda?perPage=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then(resp => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.answers.total,
        from: resp.data.answers.from,
        to: resp.data.answers.to,
        sortedQuestions: resp.data.answers.data,
        active: v
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleLevel = e => {
    if (e.target.value !== "") {
      this.setState(
        {
          [e.target.name]: e.target.value,
          chapterId: "",
          subjectId: "",
          filteredChapters: []
        },
        () => {
          const singleLevel = this.state.levels.find(
            level => level.id === this.state.levelId
          );
          // console.log(singleLevel);

          this.setState({
            filteredSubjects: singleLevel.subjects
          });
        }
      );
    } else {
      this.setState({});
    }
  };
  handleSubject = e => {
    if (e.target.value !== "") {
      this.setState(
        {
          [e.target.name]: e.target.value,
          chapterId: ""
        },
        () => {
          const singleSubject = this.state.filteredSubjects.find(
            subject => subject.id === this.state.subjectId
          );
          // console.log(singleSubject);

          this.setState({
            filteredChapters: singleSubject.chapters
          });
        }
      );
    } else {
      this.setState({
        chapterId: ""
      });
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    const filteredQuestions = [];
    this.state.questions.forEach(question => {
      if (
        question.level_id === this.state.levelId &&
        question.subject_id === this.state.subjectId &&
        question.chapter_id === this.state.chapterId
      ) {
        filteredQuestions.push(question);
      }
    });
    // console.log(filteredQuestions);
    this.setState({
      empty: true,
      filteredQuestions,
      sortedQuestions: filteredQuestions,
      activeClose: false,
      activeOpen: false
    });
  };
  showOpen = () => {
    const openQuestions = [];
    this.state.filteredQuestions.forEach(question => {
      if (question.status === "open") {
        openQuestions.push(question);
      }
    });

    this.setState({
      empty: true,
      sortedQuestions: openQuestions,
      activeClose: false,
      activeOpen: true
    });
  };
  showClosed = () => {
    const closedQuestions = [];
    this.state.filteredQuestions.forEach(question => {
      if (question.status === "closed") {
        closedQuestions.push(question);
      }
    });

    this.setState({
      empty: true,
      sortedQuestions: closedQuestions,
      activeClose: true,
      activeOpen: false
    });
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={
                  this.state.chat ? "Chat Status Changed!" : "Status Changed!"
                }
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.delNot ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Schedule Deleted!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <h6 stylle={{ marginBottom: "0px" }}>Filter</h6>
                    <FormControl
                      style={{ marginTop: "12px" }}
                      required={true}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Level
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.levelId}
                        inputProps={{
                          name: "levelId",
                          id: "simple-select",
                          onChange: this.handleLevel
                        }}
                      >
                        {this.state.levels.map((v, i) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            key={i}
                            value={v.id}
                          >
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {this.state.aAlert ? (
                      <div>
                        <span style={{ color: "red" }}>*required</span>
                      </div>
                    ) : null}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControl
                      style={{ marginTop: "48px" }}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Subject
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.subjectId}
                        inputProps={{
                          required: true,
                          name: "subjectId",
                          id: "simple-select",
                          onChange: this.handleSubject
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value=""
                        >
                          Select Subject First
                        </MenuItem>
                        {this.state.filteredSubjects.map((v, i) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            key={i}
                            value={v.id}
                          >
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {this.state.bAlert ? (
                      <div>
                        <span style={{ color: "red" }}>*required</span>
                      </div>
                    ) : null}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControl
                      style={{ marginTop: "48px" }}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Chapter
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.chapterId}
                        inputProps={{
                          required: true,
                          name: "chapterId",
                          id: "simple-select",
                          onChange: e => {
                            this.setState({
                              cAlert: false,
                              chapterId: e.target.value
                            });
                          }
                          // //console.log(e.target.value);
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value=""
                        >
                          Select Level First
                        </MenuItem>
                        {this.state.filteredChapters.map((v, i) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            key={i}
                            value={v.id}
                          >
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {this.state.cAlert ? (
                      <div>
                        <span style={{ color: "red" }}>*required</span>
                      </div>
                    ) : null}
                  </GridItem>
                  <Button
                    onClick={this.handleSubmit}
                    style={{
                      marginTop: "55px"
                    }}
                    color="success"
                  >
                    Search
                  </Button>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button
                          color={
                            this.state.activeOpen === true
                              ? "info"
                              : "secondary"
                          }
                          onClick={this.showOpen}
                        >
                          Open
                        </Button>
                        <Button
                          color={
                            this.state.activeClose === true
                              ? "info"
                              : "secondary"
                          }
                          onClick={this.showClosed}
                        >
                          Closed
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <label
                          style={{
                            color: "black"
                          }}
                        >
                          Search By Date
                        </label>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  frmDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.frmDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "From Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  toDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.toDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "To Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "20px"
                          }}
                          onClick={this.searchEmails}
                          color="success"
                        >
                          Search
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem> */}
                </GridContainer>
                <h3>Queries</h3>
                {/* <Link to="/admin/addschedule">
                  <Button className="" color="warning">
                    Add Schedule
                  </Button>
                </Link> */}
                <Table
                  tableHead={["Sr No.", "Questions", "Status", "Action"]}
                  tableData={this.state.sortedQuestions.map(
                    (question, index) => [
                      [index + 1],
                      [<MathJax key={index} math={question.question} />],
                      [question.status],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/answeraquestion/" + question.id,
                            state: { questionData: question }
                          }}
                        >
                          <Button
                            onClick={() => {
                              localStorage.setItem("id", 1);
                              localStorage.setItem("width", 852);
                              localStorage.setItem("height", 480);
                            }}
                            className="btn-round"
                            color="info"
                          >
                            Answer
                          </Button>
                        </Link>
                      ]
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.questions.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty ? null : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black"
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UploadAnAnswer;
