import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import Constants from "variables/Constants";
import renderHTML from "react-render-html";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
// const orders = ["UnUsed", "Used"];

class Batches extends Component {
  state = {
    visible: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    secondaryCategories: [],
    operatinalCategories: [],
    filteredSecondaryCategories: [],
    courses: [],
    batches: [],
    catData: [],
    status: "",
    category: "",
    course: "",
    showButtons: false,
    delNot: false,
    loading: false,
    showBoth: false,
    showSearch: false,
    active: 0,
    btnIdArray: [], 
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    catchNull: [],
    per_page: "10",
  };
  componentDidMount() {
    // axios
    // .get("https://admin.akclasses.in/api/coursesR?type=Courses")
    // .then(resp => {
    //   // console.log(resp.data);
    //   this.setState({
    //     courses: resp.data
    //   });
    // });

    axios
      .get(Constants.getUrls.getBatches + "?per_page=" + this.state.per_page)
      .then(res => {
        console.log(res);
        let btnIdArray = [];
        for (let i = 1; i <= res.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState({
          btnIdArray,
          batches: res.data.data,
          total: res.data.total,
          from: res.data.from,
          to: res.data.to
        },
        () => {
          let filteredBtnIdArray = this.state.btnIdArray.filter(
            v => v >= this.state.fromInc && v <= this.state.toInc
          );
          this.setState({
            filteredBtnIdArray
          }, () => {
            console.log(
              this.state.filteredBtnIdArray
            );
          });
        });
      });
  }

  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5
      },
      () => {
        axios
      .get(Constants.getUrls.getBatches + "?per_page=" + this.state.per_page)
      .then(res => {
        console.log(res);
        let btnIdArray = [];
        for (let i = 1; i <= res.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState({
          btnIdArray,
          batches: res.data.data,
          total: res.data.total,
          from: res.data.from,
          to: res.data.to
        },
        () => {
          let filteredBtnIdArray = this.state.btnIdArray.filter(
            v => v >= this.state.fromInc && v <= this.state.toInc
          );
          this.setState({
            filteredBtnIdArray
          });
        });
      });
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post(
            "https://admin.akclasses.in/api/courseKey/status/" + id,
            payload
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    );
  };

  handleDelete = id => {
    this.setState({ delLoading: true });
    axios
      .delete("https://admin.akclasses.in/api/batches/" + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false
        });
        setTimeout(() => {
          window.location.reload();
          this.handlePage();
        }, 2000);
      });
  };
  handleModal = (sec) => {
    this.setState({
      rowId: sec.id,
      visible: true
    }, ()=>{console.log(this.state.rowId)});
  };
  // handleModal = id => {
  //   this.setState({ active: id });
  //   axios
  //     .post("https://admin.akclasses.in/api/courseKey/delete/" + id)
  //     .then(() => {
  //       // console.log(res.data);
  //       this.setState({
  //         delNot: true,
  //         active: 0
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     });
  // };
  handleDrop = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        showBoth: false
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.course)
          .then(resp => {
            //console.log(resp.data);
            this.setState({
              catData: resp.data.product.variant
            });
            let viewIds = [];
            let views = [];
            this.state.catData.forEach(v => {
              if (viewIds.includes(v.view_and_validity.id) === false) {
                viewIds.push(v.view_and_validity.id);
                views.push(v.view_and_validity);
              }
            });
            //console.log(views);
            this.setState({ catData: views }, () => {
              this.setState({
                showBoth: this.state.catData.length >= 1 ? true : false
              });
            });
          });
      }
    );
  };
  // searchUser = () => {
  //   axios
  //     .get(
  //       "https://admin.akclasses.in/api/course_with_key/" +
  //         this.state.course +
  //         "?view_validity_id=" +
  //         this.state.category
  //     )
  //     .then(resp => {
  //       // console.log(resp.data);
  //       this.setState({
  //         operatinalCategories: resp.data.courses_key,
  //         secondaryCategories: resp.data.courses_key,
  //         showButtons: true
  //       });
  //     });
  // };
  // handleOrders = index => {
  //   let filteredSecondaryCategories = [];
  //   this.state.operatinalCategories.forEach(v => {
  //     if (v.IS_USED === index) {
  //       filteredSecondaryCategories.push(v);
  //     }
  //   });
  //   // console.log(filteredSecondaryCategories);
  //   this.setState({
  //     secondaryCategories: filteredSecondaryCategories,
  //     act: index
  //   });
  // };
  currentPage = v => {
    let commonUrl =
      "https://admin.akclasses.in/api/batches?per_page=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then(resp => {
      console.log(resp.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        batches: resp.data.data,
        active: v
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };


  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.delNot ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Batch Deleted!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody>
              <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div
                  style={{
                    position: "relative",
                    top: "40px"
                  }}
                >
                  {/* {this.state.showButtons ? (
                    <div>
                      {orders.map((btn, index) => (
                        <Button
                          key={index}
                          id={btn}
                          color={this.state.act === index ? "danger " : "info"}
                          onClick={() => {
                            this.handleOrders(index);
                          }}
                        >
                          {btn}
                        </Button>
                      ))}
                    </div>
                  ) : null} */}
                  {/* <GridItem
                    style={{
                      position: "relative",
                      left: "68%",
                      bottom: "40px"
                    }}
                    xs={12}
                    sm={12}
                    md={3}
                  >
                    <FormControl
                      style={{ marginTop: "12px" }}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Search By Course
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.course}
                        inputProps={{
                          name: "course",
                          id: "simple-select",
                          onChange: e => {
                            this.handleDrop(e);
                          }
                        }}
                      >
                        {this.state.courses.map((v, i) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            key={i}
                            value={v.course.ID}
                          >
                            {v.course.TITLE}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem> */}
                  {this.state.showBoth ? (
                    <div>
                      <GridItem
                        style={{
                          position: "relative",
                          left: "68%",
                          bottom: "40px"
                        }}
                        xs={12}
                        sm={12}
                        md={3}
                      >
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Views Validity
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  category: e.target.value,
                                  showSearch: true
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.catData.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.view_and_validity}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.showSearch ? (
                          <Button
                            onClick={this.searchUser}
                            style={{
                              position: "relative",
                              left: "45%"
                            }}
                            color="success"
                          >
                            Search
                          </Button>
                        ) : null}
                      </GridItem>
                    </div>
                  ) : null}
                </div>

                <h3>Batches</h3>
                <Link to="/admin/addbatch">
                  <Button className="" color="warning">
                    Add Batch
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Batch",
                    "Attempt",
                    "Image",
                    "Description",
                    "Batch Date",
                    "Registration Start Date",
                    "Registration End Date",
                    "Capacity",
                    // "Amenity",
                    "Update",
                    "Delete"
                  ]}
                  tableData={
                    
                     this.state.batches.map((sec, index) => [
                      [index + 1],
                      [sec.batch_name == null ? "" : sec.batch_name],
                      [
                        sec.attempt == null ? "NA" : sec.attempt.title
                      ],
                      [
                        <img src={Constants.imgUrl + sec.image} width="100px" />
                      ],
                      [ sec.description == null ? "" : renderHTML(sec.description)],
                      [ sec.batch_date == null ? "" : sec.batch_date],
                      [sec.registration_start_date == null ? "" : sec.registration_start_date],
                      [ sec.registration_end_date == null ? "" : sec.registration_end_date],
                      [ sec.batch_date == null ? "" : sec.capacity],
                      // [
                      //   <Link
                      //     key={index}
                      //     to={{
                      //       pathname: "/admin/addAmenities/" + sec.id,
                      //       state: { categoryData: sec }
                      //     }}
                      //   >
                      //   <Button
                      //     key={index}
                      //     className="btn-round"
                      //     color="primary"
                      //   >
                      //     Add Amenity
                      //   </Button>
                      //   </Link>
                      // ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/updatebatch/" + sec.id,
                            state: { categoryData: sec }
                          }}
                        >
                          <Button className="btn-round" color="info">
                            Update
                          </Button>
                        </Link>
                      ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.handleModal(sec);
                          }}
                          className="btn-round"
                          color="danger"
                        >
                          Delete
                        </Button>
                      ]
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Batch Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
        {this.state.batches.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black"
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Batches;
