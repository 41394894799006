/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
// let ProgressBar = props => {
//   return (
//     <div className="progress-bar">
//       <Filler percentage={props.percentage} />
//     </div>
//   );
// };
// let Filler = props => {
//   return (
//     <div className="filler" style={{ width: `${props.percentage}%` }}></div>
//   );
// };
// let fd = new FormData();
class AddQuestion extends Component {
  state = {
    paper: "",
    name: "",
    category: "",
    testSeriesType: "",
    option_value_1: "",
    option_value_2: "",
    option_value_3: "",
    option_value_4: "",
    question: "",
    answer: "",
    solution: "",
    questions: [],
    testCategory: [],
    testName: [],
    testPaper: [],
    file: [],
    levels: [],
    streams: [],
    level: "",
    stream: "",
    preview: false,
    redirect: false,
    que: false,
    quesLoading: false,
    added: false,
    notification: false,
    loading: false,
    showCK: false,
    showUploader: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    quesArray: [],
    options: ["Option 1", "Option 2", "Option 3", "Option 4"],
    subjects: [],
    subject: "",
    chapters: [],
    chapter: "",
    sources: [],
    source: "",
  };
  componentDidMount() {
    axios.get(Constants.getUrls.getQuestionBankLevelData).then((resp) => {
      console.log(resp.data);
      this.setState({
        levels: resp.data,
      });
    });
    axios.get(Constants.getUrls.getActivateSources).then((resp) => {
      // console.log(resp.data);
      this.setState({
        sources: resp.data,
      });
    });
    axios.get("https://admin.akclasses.in/api/testCategoriesR").then((resp) => {
      //console.log(resp.data);
      this.setState({
        testCategory: resp.data,
      });
    });
  }
  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_with_levels + this.state.level)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.subjects,
              subject: "",
              chapters: [],
              chapter: "",
              aAlert: false,
            });
          });
      }
    );
  };

  handleChapter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_subject + this.state.subject)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              chapters: resp.data.chapter,
              chapter: "",
              bAlert: false,
            });
          });
      }
    );
  };
  handleLast = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      cAlert: false,
    });
  };

  // handleSecondaryCategory = e => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value
  //     },
  //     () => {
  //       // console.log(this.state.stream);
  //       axios
  //         .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
  //         .then(resp => {
  //           // console.log(resp.data);
  //           this.setState({
  //             testName: resp.data.stream.product,
  //             name: "",
  //             testPaper: [],
  //             paper: ""
  //           });
  //         });
  //     }
  //   );
  // };
  // handleLevel = e => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value
  //     },
  //     () => {
  //       //console.log(this.state.category);
  //       axios
  //         .get(
  //           "https://admin.akclasses.in/api/showCategory_with_level/" +
  //             this.state.category
  //         )
  //         .then(resp => {
  //           //console.log(resp.data);
  //           this.setState({
  //             levels: resp.data.level,
  //             level: "",
  //             streams: [],
  //             stream: "",
  //             testPaper: [],
  //             paper: "",
  //             testName: [],
  //             name: ""
  //           });
  //         });
  //     }
  //   );
  // };

  // handleStream = e => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value
  //     },
  //     () => {
  //       //console.log(this.state.level);
  //       axios
  //         .get("https://admin.akclasses.in/api/level/" + this.state.level)
  //         .then(resp => {
  //           //console.log(resp.data);
  //           this.setState({
  //             streams: resp.data.stream,
  //             stream: "",
  //             testPaper: [],
  //             paper: "",
  //             testName: [],
  //             name: ""
  //           });
  //         });
  //     }
  //   );
  // };

  // handleTernaryCategory = e => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value
  //     },
  //     () => {
  //       // console.log(this.state.name);
  //       axios
  //         .get("https://admin.akclasses.in/api/course/" + this.state.name)
  //         .then(resp => {
  //           // console.log(resp.data);
  //           this.setState({
  //             testPaper: resp.data.product.subject,
  //             testSeriesType: resp.data.product.TEST_SERIES_TYPE,
  //             paper: ""
  //           });
  //         });
  //     }
  //   );
  // };
  handleType = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        preview: true,
        fAlert: false,
      },
      () => {
        //console.log(this.state.category);
        if (this.state.testSeriesType === "") {
          setTimeout(() => {
            this.setState({
              preview: false,
            });
          }, 1);
        }
        if (this.state.testSeriesType === "Upload") {
          setTimeout(() => {
            this.setState({
              showUploader: true,
              preview: false,
              showCK: false,
            });
          }, 1);
        }
        if (this.state.testSeriesType === "Optional") {
          setTimeout(() => {
            this.setState({
              showCK: true,
              preview: false,
              showUploader: false,
            });
          }, 1);
        }
      }
    );
  };
  handleFile = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.files[0],
      },
      () => {
        //console.log(this.state.file);
      }
    );
  };
  addMore = () => {
    if (this.state.question === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.option_value_1 === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.option_value_2 === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.option_value_3 === "") {
      this.setState({
        dAlert: true,
      });
    } else if (this.state.option_value_4 === "") {
      this.setState({
        eAlert: true,
      });
    } else if (this.state.answer === "") {
      this.setState({
        fAlert: true,
      });
    } else if (this.state.solution === "") {
      this.setState({
        gAlert: true,
      });
    } else {
      this.setState({ quesLoading: true });
      let valueAnswer =
        this.state.answer === "Option 1"
          ? this.state.option_value_1
          : this.state.answer === "Option 2"
          ? this.state.option_value_2
          : this.state.answer === "Option 3"
          ? this.state.option_value_3
          : this.state.answer === "Option 4"
          ? this.state.option_value_4
          : "";
      let payLoad = {
        REF_COURSE_ID: this.state.name,
        category_id: this.state.category,
        ref_subject_id: this.state.paper,
        level_id: this.state.level,
        stream_id: this.state.stream,
        question: this.state.question,
        option_value_1: this.state.option_value_1,
        option_value_2: this.state.option_value_2,
        option_value_3: this.state.option_value_3,
        option_value_4: this.state.option_value_4,
        solution: this.state.solution,
        answer: valueAnswer,
      };
      let question = [];
      question.push(payLoad);
      // console.log(questions);
      this.setState({ questions: question });

      axios
        .post("https://admin.akclasses.in/api/question/create", {
          question,
        })
        .then(() => {
          //console.log(resp);
          this.setState({
            quesLoading: false,
            added: true,
            testSeriesType: "",
            option_value_1: "",
            option_value_2: "",
            option_value_3: "",
            option_value_4: "",
            question: "",
            solution: "",
            answer: "",
          });
          setTimeout(() => {
            this.setState({ added: false });
          }, 2000);
        });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.questions.length === 0) {
      this.setState({
        loading: false,
        que: true,
      });
      setTimeout(() => {
        this.setState({
          que: false,
        });
      }, 3000);
    } else {
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
          notification: true,
        });
      }, 1000);
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 2000);
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/questions"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Question Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            {this.state.que ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Add Atleast One Question"}
                  icon={AddAlert}
                  color="danger"
                />
              </div>
            ) : null}

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Question</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Paper(s)
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.paper}
                            inputProps={{
                              name: "paper",
                              id: "simple-select",
                              onChange: this.handleType,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                            >
                              Select Name First
                            </MenuItem>
                            {this.state.testPaper.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.TITLE}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleSubject,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleChapter,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Chapter
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.chapter}
                            inputProps={{
                              required: true,
                              name: "chapter",
                              id: "simple-select",
                              onChange: this.handleLast,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Subject First
                            </MenuItem>
                            {this.state.chapters.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Source
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.source}
                            inputProps={{
                              required: true,
                              name: "source",
                              id: "simple-select",
                              onChange: this.handleLast,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Source
                            </MenuItem>
                            {this.state.sources.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.kAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                            >
                              Select Category
                            </MenuItem>
                            {this.state.testCategory.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null} */}
                      {/* </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: this.handleSecondaryCategory
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null} */}
                      {/* </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Series Name
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.name}
                            inputProps={{
                              name: "name",
                              id: "simple-select",
                              onChange: this.handleTernaryCategory
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.testName.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.course.ID}
                              >
                                {v.course.TITLE}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>   */}

                      <GridItem xs={12} sm={12} md={6}></GridItem>
                      {this.state.preview ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                marginTop: "20px",
                              }}
                            >
                              {"Loading..."}
                            </div>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <div>
                          {this.state.showCK ? (
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <br />
                                <label>Question</label>
                                <br />
                                <CKEditor
                                  required={true}
                                  // config={editorConfiguration}
                                  editor={ClassicEditor}
                                  data={this.state.question}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      question: data,
                                      aAlert: false,
                                    });
                                  }}
                                />
                                {this.state.aAlert ? (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      *required
                                    </span>
                                  </div>
                                ) : null}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <br />
                                <label>Option 1</label>
                                <br />
                                <CKEditor
                                  required={true}
                                  editor={ClassicEditor}
                                  // config={editorConfiguration}
                                  data={this.state.option_value_1}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      option_value_1: data,
                                      bAlert: false,
                                    });
                                  }}
                                />
                                {this.state.bAlert ? (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      *required
                                    </span>
                                  </div>
                                ) : null}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <br />
                                <label>Option 2</label>
                                <br />
                                <CKEditor
                                  required={true}
                                  editor={ClassicEditor}
                                  // config={editorConfiguration}
                                  data={this.state.option_value_2}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      option_value_2: data,
                                      cAlert: false,
                                    });
                                  }}
                                />
                                {this.state.cAlert ? (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      *required
                                    </span>
                                  </div>
                                ) : null}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <br />
                                <label>Option 3</label>
                                <br />
                                <CKEditor
                                  required={true}
                                  editor={ClassicEditor}
                                  // config={editorConfiguration}
                                  data={this.state.option_value_3}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      option_value_3: data,
                                      dAlert: false,
                                    });
                                  }}
                                />
                                {this.state.dAlert ? (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      *required
                                    </span>
                                  </div>
                                ) : null}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <br />
                                <label>Option 4</label>
                                <br />
                                <CKEditor
                                  required={true}
                                  editor={ClassicEditor}
                                  // config={editorConfiguration}
                                  data={this.state.option_value_4}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      option_value_4: data,
                                      eAlert: false,
                                    });
                                  }}
                                />
                                {this.state.eAlert ? (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      *required
                                    </span>
                                  </div>
                                ) : null}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  style={{ marginTop: "12px" }}
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                  >
                                    Answer
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={this.state.answer}
                                    inputProps={{
                                      name: "answer",
                                      id: "simple-select",
                                      onChange: (e) => {
                                        this.setState({
                                          answer: e.target.value,
                                        });
                                      },
                                    }}
                                  >
                                    {this.state.options.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v}
                                      >
                                        {v}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {this.state.fAlert ? (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      *required
                                    </span>
                                  </div>
                                ) : null}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <label>Solution</label>
                                <br />
                                <CKEditor
                                  editor={ClassicEditor}
                                  // config={editorConfiguration}
                                  data={this.state.solution}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      solution: data,
                                      gAlert: false,
                                    });
                                  }}
                                />
                                {this.state.gAlert ? (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      *required
                                    </span>
                                  </div>
                                ) : null}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={7}>
                                <Button color="success" onClick={this.addMore}>
                                  {this.state.quesLoading
                                    ? "Adding..."
                                    : "Add Questions"}
                                </Button>
                              </GridItem>
                              {/* <GridItem xs={12} sm={12} md={12}>
                                <br />
                                {this.state.mediaProgress ? (
                                  <ProgressBar
                                    percentage={this.state.progress}
                                  />
                                ) : null}
                              </GridItem> */}
                              <br />
                              <GridItem xs={12} sm={12} md={12}>
                                <Button
                                  className=""
                                  color="warning"
                                  type="submit"
                                >
                                  {this.state.loading ? <Loader /> : "Submit"}
                                </Button>
                                <Link
                                  to={{
                                    pathname: "/admin/questions",
                                  }}
                                >
                                  <Button className="btn-round" color="danger">
                                    Cancel
                                  </Button>
                                </Link>
                              </GridItem>
                            </GridContainer>
                          ) : null}
                          {this.state.added ? (
                            <div
                              style={{ position: "relative", left: "600px" }}
                            >
                              <SnackbarContent
                                message={"Question Added"}
                                icon={AddAlert}
                                color="success"
                              />
                            </div>
                          ) : null}
                          {this.state.showUploader ? (
                            <div>
                              <GridItem xs={12} sm={12} md={12}>
                                <br />
                                <label>Upload Paper</label>
                                <br />
                                <input
                                  name="file"
                                  onChange={this.handleFile}
                                  type="file"
                                  accept="application/pdf"
                                  required={true}
                                />
                              </GridItem>
                              {/* <GridItem xs={12} sm={12} md={12}>
                                <br />
                                {this.state.mediaProgress ? (
                                  <ProgressBar
                                    percentage={this.state.progress}
                                  />
                                ) : null}
                              </GridItem> */}
                              <br />
                              <GridItem xs={12} sm={12} md={12}>
                                <Button
                                  className=""
                                  color="warning"
                                  type="submit"
                                >
                                  {this.state.loading ? <Loader /> : "Submit"}
                                </Button>
                                <Link
                                  to={{
                                    pathname: "/admin/questions",
                                  }}
                                >
                                  <Button className="btn-round" color="danger">
                                    Cancel
                                  </Button>
                                </Link>
                              </GridItem>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddQuestion;
