import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import { Input, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import editorConfiguration from "variables/EditorConfiguration";
import Datetime from "react-datetime";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";
const classes = makeStyles(styles);
let fd = new FormData();
export default class StudentRegistration extends Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    ftof_batch_id: [],
    ftof_batches: [],
    ftof_subject_id: "",
    ftof_Subjects: [],
    seletedBatches: [],
    loading: false,
    notification: false,
    message: "",
    redirect: false,
    csv_file: null,
  };

  componentDidMount() {
    this.getFaceToFaceBatch();

    // this.getFaceToFaceBatch();
    // this.getSubjects();
  }

  getFaceToFaceBatch = () => {
    axios.get(Constants.getUrls.faceToFaceBatch).then((resp) => {
      // console.log(resp);
      let array = [];
      resp.data.data.map((item) => {
        array.push({
          label: item?.batch_name,
          value: item?.id,
        });
      });
      this.setState(
        {
          ftof_batches: array,
        },
        () => {
          console.log(this.state.ftof_batches);
        }
      );
    });
  };

  getSubjects = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/facetofaceSubject?type=Course&page=1"
      )
      .then((resp) => {
        // console.log(resp.data);

        this.setState({
          ftof_Subjects: resp.data.data,
        });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let batch_ids = [];
    this.state.seletedBatches.map(
      (item) => {
        batch_ids.push(item.value);
      },
      () => {
        console.log(this.state.batch_ids);
      }
    );
    let payload = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      ftof_batch_id: batch_ids,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/ftof_batches_days/student-register",
        payload
      )
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            visible: false,
            message: resp.data.message,
            notification: true,
          });
          setTimeout(() => {
            this.setState({ message: "", notification: false, redirect: true });
          }, 2000);
        }
      });
  };

  downloadCsv = () => {
    // Replace this URL with the actual URL of your Laravel API endpoint
    const apiUrl = "https://admin.akclasses.in/api/downloadSampleCsv";

    window.location.href = apiUrl;
  };

  handleFileChange = (e) => {
    // Update the state with the selected file when the input changes
    this.setState({
      csv_file: e.target.files[0],
    });
  };

  uploadCsv = () => {
    const fd = new FormData();
    fd.append("csv_file", this.state.csv_file);

    axios
      .post(Constants.postUrls.importStudentData, fd)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState(
            {
              notification: true,
              message: resp.data.message,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  redirect: true,
                  notification: false,
                });
              }, 2000);
            }
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // downloadCsv = () => {
  //   // Replace this URL with the actual URL of your Laravel API endpoint
  //   const apiUrl = 'https://admin.akclasses.in/api/downloadSampleCsv';

  //   fetch(apiUrl)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       // Create a temporary anchor element to trigger the download
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'sample_data.csv';
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error('Error downloading CSV:', error);
  //     });
  // };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/facetofacebatch"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={this.state.message}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4>Simple Table</h4>
                </CardHeader>
                <CardBody>
                  <h3>Student Registration</h3>
                  {/* <Link to="/admin/studentregistration/">
                    <Button className="" color="warning">
                      Add Batch
                    </Button>
                  </Link> */}

                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ name: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            required: true,
                            onChange: (e) =>
                              this.setState({ email: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Mobile Number"
                          id="float"
                          inputProps={{
                            type: "Number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ mobile: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={4} md={6}>
                        <FormControl
                          style={{ marginTop: "20px", zIndex: "100" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Batch
                          </InputLabel>

                          <span> Select Batch </span>
                          <MultiSelect
                            disableSearch
                            options={this.state.ftof_batches}
                            name="batch"
                            value={this.state.seletedBatches}
                            onChange={(data) => {
                              this.setState(
                                {
                                  seletedBatches: data,
                                },
                                () => {
                                  console.log(this.state.seletedBatches);
                                }
                              );
                            }}
                          />
                        </FormControl>
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Batch
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.ftof_batch_id}
                            inputProps={{
                              name: "ftof_batch_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  ftof_batch_id: e.target.value,
                                  bAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.ftof_batches.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v?.batch_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.ftof_subject_id}
                            inputProps={{
                              name: "ftof_subject_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  ftof_subject_id: e.target.value,
                                  bAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.ftof_Subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v?.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem> */}
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        type="submit"
                        className="btn-round"
                        color="warning"
                      >
                        {this.state.loading ? <Loader /> : "Add Student"}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/facetofacebatch",
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                  <GridItem xs={12} sm={4} md={3}>
                    <FormControl
                      style={{ marginTop: "20px", zIndex: "100" }}
                      required={true}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Upload File
                      </InputLabel>

                      <input
                        type="file"
                        name="csv_file"
                        onChange={this.handleFileChange}
                      />
                      {/* <button onClick={this.uploadCsv}>Upload CSV</button> */}
                      <Button
                        type="button"
                        className="btn-round"
                        color="success"
                        onClick={this.uploadCsv}
                      >
                        Upload File
                      </Button>
                    </FormControl>

                    <a href="#" onClick={this.downloadCsv}>
                      Click Here To Download Sample File
                    </a>

                    {/* <FormControl
                          style={{ marginTop: "20px", zIndex: "100" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Download Sample File
                          </InputLabel>

                          <Button
                            type="button"
                            className="btn-round"
                            color="success"
                            onClick={this.uploadCsv}
                          >
                            Download Sample File
                          </Button>
                        </FormControl> */}
                  </GridItem>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
