import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
let fd = new FormData();
class AddLectureLink extends Component {
  state = {
    file: {},
    redirect: false,
    notification: false,
    loading: false
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    fd.append('file',this.state.file);
    axios.post("https://admin.akclasses.in/api/lecturelink", fd).then(resp => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  handleFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/lectureslink"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Lecture Link Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Lecture Link</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={6} style={{marginTop:"15px"}}>
                        <label>Upload File</label>
                        <br />
                        <input
                          id="file"
                          name="file"
                          onChange={this.handleFile}
                          type="file"
                          required={true}
                        />
                        <div>
                          <img src={Constants.imgDomain + this.state.img} style={{width:"200px",height:"200px"}}/>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Lecture Link"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/lectureslink"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddLectureLink;
