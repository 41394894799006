import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import CardIcon from "components/Card/CardIcon.js";
import AddAlert from "@material-ui/icons/AddAlert";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

class CommissionReport extends Component {
  state = {
    notifyStatus: false,
    secondaryCategories: [],
    download: false,
    warn: false,
    tableArray: [],
    status: "",
    active: 1,
    akCount: 0,
    venderCount: 0,
    grandTotal: 0,
    centerShare: 0,
    vsmartShare: 0,
    poolAmt: 0,
    netAmt: 0,
    gstAmt: 0,
    facultyPayable: 0,
    btnIdArray: []
  };
  componentDidMount() {
    axios
      .get("https://admin.akclasses.in/api/index_commission_excel")
      .then(resp => {
        console.log(resp);
        this.setState({
          secondaryCategories: resp.data.datas
            .sort((a, b) => {
              a = a.Order_Date.split("/")
                .reverse()
                .join("");
              b = b.Order_Date.split("/")
                .reverse()
                .join("");
              // return a > b ? 1 : a < b ? -1 : 0; <-- alternative
              return a.localeCompare(b);
            })
            .reverse(),
          grandTotal: resp.data.grandTotal,
          centerShare: resp.data.centerShare,
          vsmartShare: resp.data.vsmartShare,
          poolAmt: resp.data.poolAmt,
          netAmt: resp.data.netAmt,
          gstAmt: resp.data.gstAmt,
          facultyPayable: resp.data.facultyPayable,
        },() => {
          console.log(parseFloat(this.state.grandTotal));
        });
      });
  }
  searchReport = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_commission_excel?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then(resp => {
        console.log(resp);
        if (resp.data.status === "fail") {
          this.setState({ warn: true });
          setTimeout(() => {
            this.setState({ warn: false });
          }, 2000);
        } else {
          this.setState({
            secondaryCategories: resp.data.datas,
            grandTotal: resp.data.grandTotal,
            centerShare: resp.data.centerShare,
            vsmartShare: resp.data.vsmartShare,
            poolAmt: resp.data.poolAmt,
            netAmt: resp.data.netAmt,
            gstAmt: resp.data.gstAmt,
            facultyPayable: resp.data.facultyPayable,
            download: true
          });
        }
      });
  };
  handleDownload = () => {
    this.setState({ download: false });
    axios
      .get(
        "https://admin.akclasses.in/api/commission_report_excel?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then(() => {
        //console.log(resp);
      });
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.warn ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Select A Date After 3 Jun 2020"}
                icon={AddAlert}
                color="danger"
              />
            </div>
          ) : null}
          <GridItem xs={12} sm={12} md={12}>
            <h5>Filters</h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      frmDate: year + "-" + month + "-" + day
                    },
                    () => {
                      //console.log(this.state.frmDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "From Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      toDate: year + "-" + month + "-" + day
                    },
                    () => {
                      //console.log(this.state.toDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "To Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button
              style={{ position: "relative", top: "13px", right: "20px" }}
              onClick={this.searchReport}
              color="warning"
            >
              Search
            </Button>
            {this.state.download ? (
              <a
                href={
                  "https://admin.akclasses.in/api/commission_report_excel?frmdate=" +
                  this.state.frmDate +
                  "&todate=" +
                  this.state.toDate
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  style={{
                    position: "relative",
                    top: "12px",
                    left: "10%"
                  }}
                  color="success"
                  onClick={this.handleDownload}
                >
                  Download Report
                </Button>
              </a>
            ) : null}
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{overflowX:"auto"}}>
                <h3>Commission Reports</h3>

                {/* <Table
                  tableHead={[
                    "Sr No.",
                    "Date",
                    "Product & Variant Name",
                    "Name",
                    "Mode",
                    "Turn-Over",
                    "AK Share"
                  ]}
                  tableData={this.state.tableArray}
                /> */}
                <table
                  style={{
                    width: "100%",
                    border: "1px grey solid",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr
                    style={{
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Sr No.
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Date Of Order
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Order Number
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Subject
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Media Type
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Attempt
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Order Type
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Centre Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Order Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Centre Share
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Vsmart Academy Share
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Pool
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Net Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      GST
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty1 Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty1 Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty2 Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty2 Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty3 Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty3 Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty4 Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty4 Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty5 Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty5 Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty6 Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty6 Amount
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      Faculty Payable
                    </th>
                  </tr>
                  {this.state.secondaryCategories.map((sec, index) => (
                    <tr
                      style={{
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                      key={index}
                    >
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Order_Date}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Order_Number}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Student_Name}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Subject}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Media_Type}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Attempt}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Order_Type}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Centre_Name}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(sec.Order_Amount)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(sec.Centre_Share)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(sec.Vsmart_Share)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(sec.Pool)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(sec.Net_Amount)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(sec.GST)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.facultyName1}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Faculty1}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.facultyName2}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Faculty2}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.facultyName3}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Faculty3}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.facultyName4}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Faculty4}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.facultyName5}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Faculty5}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.facultyName6}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {sec.Faculty6}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {Math.floor(sec.Faculty_Payable)}
                      </td>
                    </tr>
                  ))}
                  {this.state.secondaryCategories.length > 0 ? (
                    <tr>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >Total</th>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      ></td>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {"Rs." +  Math.floor(this.state.grandTotal)} 
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {"Rs." + Math.floor(this.state.centerShare)}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {"Rs." + Math.floor(this.state.vsmartShare)}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {"Rs." + Math.floor(this.state.poolAmt)}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {"Rs." + Math.floor(this.state.netAmt)}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {"Rs." + Math.floor(this.state.gstAmt)}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        {"Rs." + Math.floor(this.state.facultyPayable)}
                      </th>
                    </tr>
                  ) : null}
                </table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
        <div style={{ float: "right" }}>
          {this.state.btnIdArray.map((btnId, index) => (
            <div
              style={{
                width: "34px",
                position: "relative",
                height: "30px",
                borderRadius: "50%",
                backgroundColor:
                  this.state.active === index + 1 ? "#ef5350" : "transparent",
                border: "none",
                margin: "8px 2px",
                padding: "4px 12px",
                fontWeight: "bold",
                display: "inline-block",
                cursor: "pointer",
                color: this.state.active === index + 1 ? "white" : "grey"
              }}
              key={index}
              onClick={() => {
                this.nextPageUrl(btnId);
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)"
                }}
              >
                {btnId}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CommissionReport;
