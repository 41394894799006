/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Modal from "react-awesome-modal";
import Loader from "components/Loader.jsx";
import EditIcon from "@material-ui/icons/Edit";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
const classes = makeStyles(styles);
class CourseBudgets extends Component {
  state = {
    products: [],
    variantArray: [],
    budgets: [],
    budget: "",
    onAmount: "",
    offAmount: "",
    variantId: "",
    productId: 0,
    updateLoading: false,
    loading: false,
    redirect: false,
    visible: false,
    notification: false
  };
  finalSubmit = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        notification: true
      });
    }, 500);
    setTimeout(() => {
      this.setState({
        redirect: true
      });
    }, 1000);
  };
  componentDidMount() {
    this.setState({ productId: this.props.match.params.id }, () => {
      //console.log(this.state.productId);
    });
    axios
      .get(
        "https://admin.akclasses.in/api/budget/index?product_id=" +
          this.props.match.params.id
      )
      .then(resp => {
        // console.log(resp.data);
        this.setState({
          variantArray: resp.data
        });
      });
    axios.get("https://admin.akclasses.in/api/budget_mode/index").then(resp => {
      //console.log(resp.data);
      this.setState({
        budgets: resp.data
      });
    });
  }

  updateVariant = () => {
    //console.log(id);
    this.setState({ updateLoading: true });
    let payLoad = {
      budget_mode_id: parseInt(this.state.budget),
      online_price: this.state.onAmount,
      offline_price: this.state.offAmount
    };
    axios
      .post(
        "https://admin.akclasses.in/api/budget/update/" + this.state.variantId,
        payLoad
      )
      .then(() => {
        // console.log(resp.data);

        axios
          .get(
            "https://admin.akclasses.in/api/budget/index?product_id=" +
              this.state.productId
          )
          .then(resp => {
            // console.log(resp.data);
            this.setState({
              variantArray: resp.data,
              updateLoading: false,
              visible: false
            });
          });
      });
  };

  deleteVariant = id => {
    //console.log(id);
    axios
      .post("https://admin.akclasses.in/api/budget/delete/" + id)
      .then(() => {
        // console.log(resp);

        axios
          .get(
            "https://admin.akclasses.in/api/budget/index?product_id=" +
              this.state.productId
          )
          .then(resp => {
            // console.log(resp.data);

            this.setState({
              variantArray: resp.data
            });
          });
      });
  };

  openModal = sec => {
    this.setState({
      budget: sec.budget_mode_id,
      onAmount: sec.online_price,
      offAmount: sec.offline_price,
      variantId: sec.id,
      visible: true
    });
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/coursesmaster"
          }}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Success!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <Assignment />
                  </CardIcon>
                  <h4>Simple Table</h4>
                </CardHeader>
                <CardBody>
                  <h3>Course Budgets</h3>
                  <Table
                    tableHead={[
                      "Sr No.",
                      "Budget Mode",
                      "Online Price",
                      "Offline Price",
                      "Update",
                      "Delete"
                    ]}
                    tableData={this.state.variantArray.map((sec, index) => [
                      [index + 1],
                      [sec.budget_mode.title],
                      [sec.online_price],
                      [sec.offline_price],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.openModal(sec);
                          }}
                          className="btn-round"
                          color="info"
                        >
                          Update
                        </Button>
                      ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.deleteVariant(sec.id);
                          }}
                          color="danger"
                        >
                          Delete
                        </Button>
                      ]
                    ])}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <Link
              to={{
                pathname: "/admin/addcoursebudget/" + this.state.productId
              }}
            >
              <Button color="info" style={{ marginLeft: "15px" }}>
                Add Course Budget
              </Button>
            </Link>
            <Button onClick={this.finalSubmit} color="warning">
              {this.state.loading ? <Loader /> : "Submit"}
            </Button>
            <Link
              to={{
                pathname: "/admin/coursesmaster"
              }}
            >
              <Button color="danger">Cancel</Button>
            </Link>
          </GridContainer>
          <Modal
            visible={this.state.visible}
            width="1000"
            height="330"
            effect="fadeInUp"
            onClickAway={() => {
              this.setState({ visible: false });
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                    <h4>Stacked Form</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <label>Budget Mode</label>
                          <select
                            style={{ padding: "11px" }}
                            name="budget"
                            value={this.state.budget}
                            onChange={this.handleInput}
                          >
                            <option value="">Select</option>
                            {this.state.budgets.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.title}
                              </option>
                            ))}
                          </select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Online Amount"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.onAmount,
                            onChange: e =>
                              this.setState({ onAmount: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Offline Amount"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.offAmount,
                            onChange: e =>
                              this.setState({ offAmount: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button
                          onClick={this.updateVariant}
                          className=""
                          color="warning"
                          type="submit"
                        >
                          {this.state.updateLoading ? "Updating..." : "Update"}
                        </Button>
                        <Button
                          onClick={() => {
                            this.setState({ visible: false });
                          }}
                          className="btn-round"
                          color="danger"
                        >
                          Cancel
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </div>
      );
    }
  }
}

export default CourseBudgets;
