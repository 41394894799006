import React from 'react';

export default function AnnotationTemplate(annotation) {
    const { data } = annotation;
    return (
      <svg className="annotation">
        <rect x={0} y={0} className="border"></rect>
        <text x="0" y="60">
          <tspan className="caption">Status:</tspan>
          <tspan className="Status" dx="5">{data.heading}</tspan>
          <tspan dy="14" x="0" className="caption">Count:</tspan>
          <tspan className="Count" dx="5">{data.count}</tspan>
          
        </text>
      </svg>
    );
  }