/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
let fd = new FormData();
let ProgressBar = props => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = props => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
class UpdateExtensionMaster extends Component {
  state = {
    price: "",
    validity: "",
    category: "",
    courses: [],
    image: "",
    title: "",
    mediaProgress: false,
    imgUpdated: false,
    progress: 0,
    redirect: false,
    aAlert: false,
    bAlert: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    // console.log(categoryData);
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=Courses")
      .then(resp => {
        //console.log(resp.data);
        this.setState({
          courses: resp.data,
          price: categoryData.extend_course_price,
          validity: categoryData.extend_validity,
          title: categoryData.TITLE,
          image: categoryData.THUMB_IMAGE_PATH,
          category: categoryData.extend_course_id
        });
      });
  }
  handleFile = e => {
    this.setState(
      { [e.target.name]: e.target.files[0], imgUpdated: true },
      () => {
        //console.log(this.state.img3);
      }
    );
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.category === "") {
      this.setState({
        bAlert: true
      });
    } else {
      this.setState({
        loading: true
      });
      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.ID;
      fd.append("extend_course_price", this.state.price);
      fd.append("extend_course_id", this.state.category);
      fd.append("type", "Extend_Validity");
      fd.append("extend_validity", this.state.validity);
      fd.append("title", this.state.title);
      if (this.state.imgUpdated) {
        fd.append("image", this.state.image);
      }
      axios
        .post(
          "https://admin.akclasses.in/api/extend/update/" + categoryId,
          fd,
          {
            onUploadProgress: progressEvent => {
              this.setState({
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
                mediaProgress: true
              });
              if (this.state.progress === 100) {
                this.setState({ mediaProgress: false });
              }
            }
          }
        )
        .then(() => {
          //console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/extensionmaster"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Extension Master Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Extension Master</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Course
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  category: e.target.value,
                                  bAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.course.ID}
                              >
                                {v.course.TITLE}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.title,
                            type: "text",
                            onChange: e =>
                              this.setState({ title: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Price"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "number",
                            value: this.state.price,
                            onChange: e =>
                              this.setState({ price: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Validity"
                          id="float"
                          inputProps={{
                            value: this.state.validity,
                            type: "number",
                            required: true,
                            onChange: e =>
                              this.setState({ validity: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Image</label>
                        <br />
                        <input
                          name="image"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                        />
                        <img
                          width="200px"
                          src={Constants.imgUrl + this.state.image}
                          alt=""
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridItem xs={12} sm={12} md={6}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? (
                          <Loader />
                        ) : (
                          "Update Extension Master"
                        )}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/extensionmaster"
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateExtensionMaster;
